import { Vertical } from '@/shared/design-system/v2';
import { ICellRendererParams } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { ArtifactFilterState } from '../../../../generated/api';
import { Dataset } from '../../../../queries/datasets/list';
import { TableActionMenu } from '../../../table-action-menu/TableActionMenu';
import { useDatasetAddEditTags } from '../../use-dataset-add-edit-tags';
import { useDatasetArchiveUnarchive } from '../../use-dataset-archive-unarchive';
import { DEFAULT_ROW_HEIGHT, DEFAULT_TABLE_MENU_WIDTH } from './util';

interface ActionsRendererParams extends ICellRendererParams<Dataset> {
  state: ArtifactFilterState;
}

export const ActionsRenderer = ({ state, data }: ActionsRendererParams) => {
  const resourceId = data?.datasetId ?? '';
  const tags = data?.tags;

  const { openAddEditTagsModal } = useDatasetAddEditTags(resourceId ?? '', tags);

  // TODO, currently BE API does not send the archival state, this will be fixed from BE side and then FE
  const { openArchiveUnarchiveModal } = useDatasetArchiveUnarchive(
    [resourceId],
    state ?? ArtifactFilterState.Active,
  );

  if (!resourceId || !data) {
    return null;
  }

  const tagAction = {
    id: 'tag-action',
    label: 'Edit Tags',
    handler: openAddEditTagsModal,
  };

  const archiveAction = {
    id: 'archive-dataset',
    label: state === ArtifactFilterState.Active ? 'Archive' : 'Unarchive',
    handler: openArchiveUnarchiveModal,
  };

  const actions = [tagAction, archiveAction];

  return (
    <Vertical justify="center" h={DEFAULT_ROW_HEIGHT}>
      <TableActionMenu actions={actions} width={DEFAULT_TABLE_MENU_WIDTH} />
    </Vertical>
  );
};
