import { ampli } from '@/shared/ampli';
import { Burger, useMarkovTheme } from '@/shared/design-system/v2';
import { useLayoutContext } from '../../contexts/layout/LayoutContext';

export const SidebarBurger = (): JSX.Element => {
  const theme = useMarkovTheme();
  const { sidebarExpanded, setSidebarExpanded } = useLayoutContext();

  const updateSideBarOpenStatus = () => {
    ampli.navbarMenuClicked();
    setSidebarExpanded(!sidebarExpanded);
  };

  return (
    <Burger
      aria-label="Toggle navigation menu"
      opened={false}
      onClick={updateSideBarOpenStatus}
      size="sm"
      color={theme.colors.dark[1]}
      ml="sm"
      mr="md"
    />
  );
};
