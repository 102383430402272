import {
  FilterChangedEvent,
  RowClassParams,
  RowClassRules,
  SortChangedEvent,
} from '@ag-grid-community/core';
import { ForwardedRef, MutableRefObject, RefObject, forwardRef, useMemo } from 'react';
import { EmptyState } from '../../../../../../apps/main/components/common/EmptyState';
import { DEFAULT_ROW_HEIGHT } from '../../../../../../apps/main/components/datasets-table/v2/cell-renderers/util';
import { Box } from '../../misc';
import { Loading } from './Loading';
import type {
  CellClickedEvent,
  ColDef,
  GetRowIdFunc,
  PaginationChangedEvent,
  RowClickedEvent,
  RowSelectedEvent,
} from './ag-grid';
import { AgGridReact } from './ag-grid';
import { HeaderComponent } from './components/Header';
import './table.css';
import { DEFAULT_PAGE_SIZE } from './util';

export enum TableThemes {
  THEME_V1 = 'mkv-table-v1',
  THEME_V2 = 'mkv-table-v2',
}

export interface TableProps<TData> {
  theme?: TableThemes;
  rowData?: TData[];
  columns?: ColDef[];
  pagination?: boolean;
  rowHeight?: number;
  getRowId?: GetRowIdFunc<TData>;
  onCellClicked?(event: CellClickedEvent<TData>): void;
  onRowClicked?(event: RowClickedEvent<TData>): void;
  onRowSelection?(event: RowSelectedEvent<TData>): void;
  onPaginationChanged?(event: PaginationChangedEvent<TData>): void;
  onSortChanged?(event: SortChangedEvent<TData>): void;
  onFilterChanged?(event: FilterChangedEvent<TData>): void;
  getRowClass?(params: RowClassParams<TData>): string | string[] | undefined;
  paginationPageSize?: number;
  rowClassRules?: RowClassRules<TData>;
  noRowsOverlayComponent?: ((props: any) => JSX.Element) | null;
  withColumnHeaderTooltip?: boolean;
  suppressScrollOnNewData?: boolean;
  suppressFieldDotNotation?: boolean;
  enableCellTextSelection?: boolean;
}

const InnerTable = <T,>(
  {
    theme = TableThemes.THEME_V1,
    rowData,
    columns,
    pagination = false,
    rowHeight = DEFAULT_ROW_HEIGHT,
    onCellClicked,
    onRowClicked,
    getRowId,
    onRowSelection,
    onPaginationChanged,
    onSortChanged,
    onFilterChanged,
    getRowClass,
    rowClassRules,
    paginationPageSize = DEFAULT_PAGE_SIZE,
    noRowsOverlayComponent = EmptyState,
    withColumnHeaderTooltip = true,
    suppressScrollOnNewData = false,
    suppressFieldDotNotation = false,
    enableCellTextSelection = false,
  }: TableProps<T>,
  ref: ForwardedRef<AgGridReact>,
) => {
  const defaultColDef: ColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    [],
  );

  const columnDefs = columns?.map(col => {
    // Fallback to field name needed for when field contains dot '.'
    const headerName = col.headerName ?? col.field;
    const headerTooltip = col.headerTooltip ?? headerName;
    return {
      ...col,
      headerName,
      headerTooltip: withColumnHeaderTooltip ? headerTooltip : undefined,
    };
  });

  return (
    <Box className={`${theme} ag-theme-alpine`} w="100%" h="100%" pb="md">
      <AgGridReact<T>
        ref={ref}
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowSelection="multiple"
        rowHeight={rowHeight + 2}
        suppressRowClickSelection
        onCellClicked={onCellClicked}
        onRowClicked={onRowClicked}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        cacheOverflowSize={2}
        maxConcurrentDatasourceRequests={2}
        infiniteInitialRowCount={1}
        maxBlocksInCache={2}
        pagination={pagination}
        getRowId={getRowId}
        onRowSelected={onRowSelection}
        suppressPaginationPanel
        noRowsOverlayComponent={noRowsOverlayComponent}
        noRowsOverlayComponentParams={{ title: 'No records to display' }}
        onPaginationChanged={onPaginationChanged}
        loadingOverlayComponent={Loading}
        loadingOverlayComponentParams={{
          columns: (
            ref as MutableRefObject<AgGridReact<any> | null>
          )?.current?.columnApi?.getColumns(),
          variant: 'dots',
          size: 'xl',
        }}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        getRowClass={getRowClass}
        rowClassRules={rowClassRules}
        ensureDomOrder
        suppressScrollOnNewData={suppressScrollOnNewData}
        suppressFieldDotNotation={suppressFieldDotNotation}
        enableCellTextSelection={enableCellTextSelection}
        // think about extending this
        components={{
          agColumnHeader: HeaderComponent,
        }}
      />
    </Box>
  );
};

export const Table = forwardRef(InnerTable) as (<T>(
  props: TableProps<T> & { ref?: RefObject<AgGridReact> },
) => ReturnType<typeof InnerTable>) & {
  displayName?: string;
};

Table.displayName = 'TableV2';

export default Table;

export type TypedTable = typeof Table;
