import { openModal } from '@mantine/modals';
import { Text } from '@/shared/design-system/v2';
import { GenerateAppFromDatasetContainer } from './generate-app-modal/GenerateAppFromDataset.container';
import { GenerateAppFromModelContainer } from './generate-app-modal/GenerateAppFromModel.container';

interface useGenerateAppModalProps {
  modelId?: string;
  datasetId?: string;
  projectId?: string;
}

export const useGenerateAppModal = ({
  modelId,
  datasetId,
  projectId,
}: useGenerateAppModalProps) => {
  const open = ({ isRestart = false }: { isRestart?: boolean } = {}) =>
    openModal({
      title: (
        <Text variant="subTitle02" color="gray.8">
          Configure your app
        </Text>
      ),
      children: modelId ? (
        <GenerateAppFromModelContainer
          modelId={modelId}
          projectId={projectId}
          isRestart={isRestart}
        />
      ) : (
        <GenerateAppFromDatasetContainer datasetId={datasetId ?? ''} />
      ),
    });

  return {
    open,
  };
};
