import { NodeViewProps } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';
import merge from 'lodash/merge';
import { useRef } from 'react';
import { Layout } from 'react-grid-layout';
import { GridLayout } from '../../../../../../../apps/main/components/common/grid-layout/GridLayout';
import { useSnippetDetail } from '../../../../../../../apps/main/components/snippets/detail/context/SnippetContext';
import { useAddComment } from '../util';
import { useGridItemStyles } from './ChartGrid.styles';
import { ItemActions } from './ItemActions';
import { PublicSnippetVizFactory } from './factory/PublicSnippetVizFactory';
import { VisualizationFactory } from './factory/VisualizationFactory';

export const ChartGrid = ({ node, getPos, updateAttributes, editor, selected }: NodeViewProps) => {
  const { editing, showVersions, isPublicSnippet, activeConversation } = useSnippetDetail();
  const { classes, cx } = useGridItemStyles();

  const { handleSetActiveConversation } = useAddComment();

  const nodeAttrs = node.attrs;
  const gridLayout = JSON.parse(nodeAttrs['data-grid']);
  const ref = useRef<HTMLDivElement | undefined>();

  const handleCommentClick = (id: string, conversationId?: string) => {
    editor.commands.focus(getPos(), { scrollIntoView: false });

    const newConversationId = handleSetActiveConversation(conversationId, id);
    if (newConversationId) {
      const updatedGridLayout = gridLayout.map((gl: any) => {
        if (gl.layout.i === id) {
          return merge(gl, {
            layout: {
              conversationId: newConversationId,
            },
          });
        }
        return gl;
      });

      updateAttributes({
        'data-grid': JSON.stringify(updatedGridLayout),
      });
    }
  };

  const handleLayoutChange = (layout: Layout[]) => {
    const dataGrid = gridLayout.map((prevGrid: any, i: number) => ({
      ...prevGrid,
      layout: { ...prevGrid.layout, ...layout[i] },
    }));

    updateAttributes({
      'data-grid': JSON.stringify(dataGrid),
    });
  };

  const handleDeleteViz = (id: string) => {
    const dataGrid = gridLayout.filter((gridItem: any) => gridItem.layout.i !== id);

    updateAttributes({
      'data-grid': JSON.stringify(dataGrid),
    });
  };

  const VizFactory = isPublicSnippet ? PublicSnippetVizFactory : VisualizationFactory;

  return (
    <NodeViewWrapper className="react-component-with-content" contentEditable={false} ref={ref}>
      <GridLayout
        className="grid"
        layout={gridLayout.map((l: any) => l.layout)}
        readOnlyMode={!editing}
        onLayoutChange={handleLayoutChange}
        onClick={() => {
          editor.commands.focus(getPos(), { scrollIntoView: false });
        }}
      >
        {gridLayout.map((l: any) => (
          <GridLayout.Item
            key={l.layout.i}
            data-layout-id={l.layout.i}
            className={cx(classes.item, {
              [classes.itemActive]:
                (l.layout.conversationId &&
                  l.layout.conversationId === activeConversation.conversationId) ||
                selected,
              [classes.itemAdded]: l.layout.isAdded,
              [classes.itemDeleted]: l.layout.isDeleted,
            })}
          >
            {!isPublicSnippet && !showVersions && (
              <ItemActions
                onCommentClick={handleCommentClick}
                onDelete={handleDeleteViz}
                item={l}
                readOnlyMode={!editing}
              />
            )}
            <VizFactory
              visualizationType={l.item.visualizationType}
              visualizationParameters={l.item.visualizationParameters}
              showTitle
            />
          </GridLayout.Item>
        ))}
      </GridLayout>
    </NodeViewWrapper>
  );
};
