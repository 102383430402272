import { Link } from '@mantine/tiptap';

/**
 * This extension adds a unique identifier to each link element in the editor.
 *
 * The unique ID is maintained to:
 * - Support document export functionality to DOCX format
 * - Track newly created links
 * - Monitor existing links' modifications
 */
export const CustomLink = Link.extend({
  // https://tiptap.dev/docs/editor/api/schema#inclusive
  inclusive: false,
  exitable: true,

  addAttributes() {
    const parentAttributes = this.parent?.();
    return {
      ...parentAttributes,
      'data-id': {
        default: null,
      },
    };
  },
});
