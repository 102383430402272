import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { logger } from '@/shared/initializers/logging';
import { apiClient, userManagementApi } from '@/shared/lib/api';
import { HTTPError } from '@/shared/lib/api/api';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import { BaseUserUpdate, BodyCheckUsernameUniqueV1 } from '../generated/api';
import { USER } from './queryConstants';

export const useUserInfo = (userId?: string) => {
  const { userId: currentUserId } = useAppMetadata();
  const _userId = userId || currentUserId;

  return useQuery(
    [USER.GET_USER_AND_ACCOUNT_INFO, _userId],
    () => userManagementApi.getUserAndAccountInfoV1(_userId),
    {
      enabled: !!_userId,
      select: data => data.data,
    },
  );
};

export const useUpdateUserMutation = () => {
  const { userId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError<HTTPError>, BaseUserUpdate>(
    (user: BaseUserUpdate) => userManagementApi.updateUserV1(userId, user),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USER.GET_USER_AND_ACCOUNT_INFO, userId]);
      },
      onError: (err: AxiosError) => {
        logger.error(err);
      },
    },
  );
};

export const useCheckUsernameUniqueMutation = () => {
  const { userId } = useAppMetadata();
  return useMutation<AxiosResponse, AxiosError<HTTPError>, BodyCheckUsernameUniqueV1>(
    (username: BodyCheckUsernameUniqueV1) =>
      userManagementApi.checkUsernameUniqueV1(userId, username),
  );
};

const fetchLatestCliVersion = async () => {
  const { data } = await apiClient.get('/v1/markovml/latest-version');
  return data ?? '';
};

export const useCliLatestVersion = (): UseQueryResult<string, string> =>
  useQuery(['cli-latest-version'], () => fetchLatestCliVersion());
