import { PropsWithChildren } from 'react';
import { Accordion, Center, Text } from '@/shared/design-system/v2';
import { InfoTooltip } from '../../common/InfoTooltip';
import { useSectionWrapperStyles } from './SectionWrapper.style';

interface SectionWrapperProps {
  title: string;
  description: string;
}

// TODO: Work with design to consolidate this with DatasetSection component.
// Currently there are differences preventing component reuse.
export const SectionWrapper = ({
  title,
  description,
  children,
}: PropsWithChildren<SectionWrapperProps>): JSX.Element => {
  const { classes } = useSectionWrapperStyles();

  return (
    <Accordion defaultValue={title} classNames={classes}>
      <Accordion.Item value={title}>
        <Accordion.Control>
          <Center inline>
            <Text variant="subTitle02" color="dark.7">
              {title}
            </Text>
            <InfoTooltip text={description} maw={250} />
          </Center>
        </Accordion.Control>
        <Accordion.Panel>{children}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
