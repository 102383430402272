import { useState } from 'react';
import { Horizontal, Text, Vertical } from '@/shared/design-system/v2';
import { CopyToClipboardTooltip } from '../../common/CopyToClipboardTooltip';

interface CredentialInfoProps {
  credentialId: string;
  credentialName: string;
}

export const CredentialInfo = ({
  credentialId,
  credentialName,
}: CredentialInfoProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Vertical spacing={0} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Text variant="subTitle03" lineClamp={1} title={credentialName}>
        {credentialName}
      </Text>

      <Horizontal>
        <Text variant="small01" color="dark.1">
          ID: {credentialId}
        </Text>
        {isHovered && <CopyToClipboardTooltip valueToCopy={credentialId} />}
      </Horizontal>
    </Vertical>
  );
};
