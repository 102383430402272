import { Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import capitalize from 'lodash/capitalize';
import isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { Box, Carousel, Center, Loader, Modal } from '@/shared/design-system/v2';
import { Analysis } from '../../../generated/api';
import { DatasetDetails } from '../../../queries/datasets/list';
import { ModalVizHeader } from '../../analyser/visualization/ModalVizHeader';
import { replaceSeriesName, segmentToColors } from '../../dataset-details/util';
import { ClassDistribution } from './ClassDistribution';

const HEADER_HEIGHT = 56;

interface ClassDistributionExpandModalProps {
  datasetName: string;
  list: Analysis[];
  currentIndex?: number;
  onCloseCarousel: () => void;
  datasetDetails?: DatasetDetails;
}

export const ClassDistributionExpandModal = ({
  datasetName,
  list,
  currentIndex,
  onCloseCarousel,
  datasetDetails,
}: ClassDistributionExpandModalProps) => {
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [showCarousel, setShowCarousel] = useState(false);

  // We are opening carousel in a modal. So, this case applies
  // https://mantine.dev/others/carousel/#carousel-container-animation-offset
  useAnimationOffsetEffect(embla, 200);

  useEffect(() => {
    if (!isUndefined(currentIndex)) {
      setTimeout(() => {
        setShowCarousel(true);
      }, 200);
    }
  }, [currentIndex, embla]);

  const handleCarouselClose = () => {
    setShowCarousel(false);
    onCloseCarousel();
  };

  return (
    <Modal
      opened={!isUndefined(currentIndex)}
      onClose={handleCarouselClose}
      size="90vw"
      keepMounted
      withinPortal
      transitionProps={{ duration: 200 }}
      centered
      dataTestId="class-distribution"
    >
      {showCarousel ? (
        <Carousel
          height="70vh"
          slideSize="100%"
          slidesToScroll={1}
          align="center"
          getEmblaApi={setEmbla}
          controlSize={42}
          initialSlide={currentIndex}
        >
          {list.map(item => {
            const { analysisId, title = '', segmentType } = item.metadata;
            const segment = datasetDetails?.segments?.find(
              segment => segment.segmentType === segmentType,
            );
            const chartColors = segmentToColors[segmentType];

            return (
              <Carousel.Slide
                key={analysisId}
                data-testid={`carousel-slide-class-distribution-${segmentType}`}
              >
                <Box h="100%" px="xl">
                  <ModalVizHeader
                    title={capitalize(replaceSeriesName(title))}
                    datasetName={datasetName}
                    segmentType={segmentType}
                  />
                  <Box h={`calc(100% - ${HEADER_HEIGHT}px)`}>
                    <ClassDistribution
                      analysis={item}
                      segment={segment}
                      numRecords={datasetDetails?.numRecords as number}
                      colors={chartColors}
                      showActions={false}
                      showTitle={false}
                      legendsTitle="Classes"
                    />
                  </Box>
                </Box>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      ) : (
        <Center mih="70vh">
          <Loader variant="dots" />
        </Center>
      )}
    </Modal>
  );
};
