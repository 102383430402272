import { Box, LinkButton } from '@/shared/design-system/v2';
import { EmptyState } from '../components/common/EmptyState';
import { AppRoutes, getRoute } from '../router/constants';

export const UnavailablePage = () => (
  <Box w="100%">
    <EmptyState
      iconName="404"
      iconSize="lg"
      title="404"
      subtitle="Oops! Seems like you are trying to reach an unreachable place."
      button={
        <LinkButton to={getRoute(AppRoutes.BASE_ROUTE)} variant="primary">
          Go to Home page
        </LinkButton>
      }
    />
  </Box>
);
