import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { ulid } from 'ulid';

export interface UniqueIDOptions {
  attributeName: string;
  nodeTypes: string[];
  markTypes: string[];
  generateID: () => string;
}

/**
 * UniqueIdV2 Extension
 *
 * This extension ensures that specified node types and mark types within the ProseMirror document
 * have unique identifiers. It automatically assigns unique IDs to nodes and marks based on the
 * provided `attributeName` and `generateID` options. This is particularly useful for scenarios
 * where nodes or marks need to be uniquely identified, such as tracking, referencing, or applying
 * specific transformations, and helps prevent ID collisions that can occur during operations like
 * copying and pasting.
 * (v1 offers a more extensive handling of specific editor interactions (like drag-and-drop and paste) and is more complex,
 * while v2 is focused on ensuring unique IDs across nodes and marks with a simplified structure.)
 */
export const UniqueIdV2 = Extension.create<UniqueIDOptions>({
  name: 'uniqueIdV2',

  priority: 10000,

  addOptions() {
    return {
      attributeName: 'data-id',
      nodeTypes: [], // Specify node types that require unique IDs
      markTypes: [], // Specify mark types that require unique IDs
      generateID: () => ulid(), // Function to generate unique IDs
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (_transactions, _oldState, newState) => {
          const tr = newState.tr;
          let modified = false;

          const typeIdMaps: { [key: string]: Map<string, boolean> } = {};

          const { doc, schema } = newState;

          doc.descendants((node, pos) => {
            // Process specified node types
            if (this.options.nodeTypes.includes(node.type.name)) {
              const typeName = node.type.name;
              const attrName = this.options.attributeName;
              const dataId = node.attrs[attrName];

              if (!typeIdMaps[typeName]) {
                typeIdMaps[typeName] = new Map();
              }

              const idMap = typeIdMaps[typeName];

              if (!dataId || idMap.has(dataId)) {
                // Assign a new ID if duplicate or missing
                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  [attrName]: this.options.generateID(),
                });
                modified = true;
              } else {
                idMap.set(dataId, true);
              }
            }

            // Process specified mark types
            node.marks.forEach(mark => {
              if (this.options.markTypes.includes(mark.type.name)) {
                const typeName = mark.type.name;
                const attrName = this.options.attributeName;
                const dataId = mark.attrs[attrName];

                if (!typeIdMaps[typeName]) {
                  typeIdMaps[typeName] = new Map();
                }

                const idMap = typeIdMaps[typeName];

                if (!dataId || idMap.has(dataId)) {
                  // Assign a new ID if duplicate
                  const newMark = schema.marks[mark.type.name].create({
                    ...mark.attrs,
                    [attrName]: this.options.generateID(),
                  });
                  tr.addMark(pos, pos + node.nodeSize, newMark);
                  modified = true;
                } else {
                  idMap.set(dataId, true);
                }
              }
            });
          });

          return modified ? tr : null;
        },
      }),
    ];
  },
});
