import debounce from 'lodash/debounce';
import React, { Children, ReactNode, Suspense, cloneElement, useCallback, useEffect } from 'react';
import { Card, CardProps, Center, Collapse, Loader } from '@/shared/design-system/v2';
import { useElementSize } from '@/shared/design-system/v2/hooks';
import { ChartProps } from '../../../charts/Chart';
import { useChartsContext } from '../../../charts/context/Context';
import { useIntersection } from '../../../hooks/useIntersectionObserver';
import { Header } from './Header';

const LazyChart = React.lazy(() => import('../../../charts/Chart'));

export interface VisualizationProps {
  children: ReactNode;

  cardProps?: Omit<CardProps, 'children'>;
  headerHeight?: number;

  error?: string;
  loading?: boolean;
  noData?: boolean;

  collapsed?: boolean;

  dataTestId?: string;
}

const LazyLoadedChart = (props: ChartProps) => (
  <Suspense fallback={<Loader />}>
    <LazyChart {...props} />
  </Suspense>
);

export const VisualizationComponent = ({
  dataTestId,
  cardProps,
  collapsed,
  headerHeight = 16,
  children,
}: VisualizationProps) => {
  const chartContext = useChartsContext();
  const chartRef = chartContext?.chartRef;
  const { ref, width, height } = useElementSize();

  const {
    ref: chartContainerRef,
    entry,
    observer,
    el,
  } = useIntersection({
    threshold: 0,
  });

  useEffect(() => {
    if (entry?.isIntersecting && el.current) {
      observer.current?.unobserve(el.current);
    }
  }, [entry?.isIntersecting]);

  const chartReflow = useCallback(
    debounce(() => {
      if (chartRef) {
        chartRef?.current?.chart.reflow();
      }
    }, 200),
    [chartRef],
  );

  useEffect(() => {
    chartReflow();
  }, [width, height, chartReflow]);

  const content = Children.toArray(children).map(child => {
    if (typeof child === 'object' && child && 'type' in child) {
      if (child.type === Header) {
        return (
          <Card.Section key={child.key} pb="0px" pt="md" px="lg" sx={{ ...child.props.sx }}>
            {cloneElement(child)}
          </Card.Section>
        );
      }

      if (child.type === LazyLoadedChart || child.type === Collapse) {
        const padding = collapsed ? '0px' : 'lg';

        return (
          <Card.Section
            px={padding}
            py={padding}
            key={child.key}
            h={`calc(100% - ${headerHeight}px)`}
            ref={chartContainerRef}
          >
            {entry?.isIntersecting ? (
              cloneElement(child)
            ) : (
              <Center>
                <Loader />
              </Center>
            )}
          </Card.Section>
        );
      }
    }

    return child;
  });

  return (
    <Card
      hoverEffect={false}
      shadow="xl"
      h="100%"
      w="100%"
      ref={ref}
      data-testid={dataTestId}
      {...cardProps}
    >
      {content}
    </Card>
  );
};

VisualizationComponent.Header = Header;

VisualizationComponent.Chart = LazyLoadedChart;
