import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Node, ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import { DIFF_TYPE } from '@/main/components/common/constants';
import { CitationPositionType, CopyEditCitationText } from '@/main/generated/api';
import { MarkovReferenceComponentContainer } from './MarkovReference.container';

export interface MarkovReferenceOptions {
  onClick: (referenceId: string) => void;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mkvReference: {
      setDocumentInTextReferences: (references: CopyEditCitationText[]) => ReturnType;
      setDocumentFullTextReferences: (references: CopyEditCitationText[]) => ReturnType;
    };
  }
}

export const MarkovReference = Node.create<MarkovReferenceOptions>({
  name: 'mkvReference',

  group: 'inline',

  inline: true, // Inline node
  atom: true, // Leaf node, should not contain other nodes
  draggable: true,

  addOptions() {
    return {
      onClick: () => undefined,
    };
  },

  addStorage() {
    return {
      documentInTextReferences: [],
      documentFullTextReferences: [],
    };
  },

  addCommands() {
    return {
      setDocumentInTextReferences: references => () => {
        if (!isEqual(this.storage.documentInTextReferences, references)) {
          this.storage.documentInTextReferences = references;
        }
        return true;
      },

      setDocumentFullTextReferences: references => () => {
        if (!isEqual(this.storage.documentFullTextReferences, references)) {
          this.storage.documentFullTextReferences = references;
        }
        return true;
      },
    };
  },

  addAttributes() {
    return {
      format: {
        default: CitationPositionType.InText,
      },
      documentId: {
        default: '',
      },
      referenceIds: {
        default: '[]',
      },
      highlightType: {
        default: DIFF_TYPE.EQUAL,
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state, dispatch }) => {
          let isReference = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === MarkovReference.name) {
              isReference = true;

              if (dispatch) {
                // Ensure the transaction is valid before applying
                tr.delete(pos, pos + node.nodeSize);
                dispatch(tr);
              }
              return false;
            }
          });

          return isReference;
        }),
    };
  },

  parseHTML() {
    return [
      {
        tag: 'mkvReference',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['mkvReference', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MarkovReferenceComponentContainer);
  },

  addProseMirrorPlugins() {
    // eslint-disable-next-line
    const self = this;
    const { onClick } = this.options;

    return [
      new Plugin({
        key: new PluginKey('mkvReference'),
        props: {
          /**
           * Refer: https://prosemirror.net/docs/ref/#view.EditorProps.handleClickOn
           */
          handleClickOn(_view, _, node) {
            const nodeAttributes = node.attrs;
            if (nodeAttributes.format === CitationPositionType.Bibliography) {
              // For Bibliography, there will only be 1 reference
              const referenceId: string = first(JSON.parse(nodeAttributes.referenceIds)) ?? '';
              onClick(referenceId);
              return true;
            }
          },
        },
      }),
    ];
  },
});
