import styled, { keyframes } from 'styled-components';
import { Box } from '@/shared/design-system';

const loadingRingAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

interface LoadingRingProps {
  color?: string;
}

const LoadingRing = styled(Box)<LoadingRingProps>`
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;

  ${Box} {
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 3px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: ${loadingRingAnimation} 1.3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color ?? props.theme.colors.brightBlue} transparent transparent
      transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

const SpinnerWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  pointer-events: none;
`;

interface LoadingProps {
  isLoading?: boolean;
  color?: string;
}

export const Loading = ({ isLoading = true, color }: LoadingProps): JSX.Element => {
  if (!isLoading) return <></>;
  return (
    <LoadingWrapper>
      <SpinnerWrapper>
        <LoadingRing color={color}>
          <Box></Box>
          <Box></Box>
          <Box></Box>
          <Box></Box>
        </LoadingRing>
      </SpinnerWrapper>
    </LoadingWrapper>
  );
};
