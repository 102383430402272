import map from 'lodash/map';
import { BaseAPIFilter, Operator } from '@/main/generated/api';
import { FilterOptions } from '@/shared/design-system/v2/core/data-display/table/filters/util';

export const supportedOperators = [Operator.Eq, Operator.Gte, Operator.Lte, Operator.Regex];
export type supportedOperators = Extract<
  Operator,
  Operator.Eq | Operator.Gte | Operator.Lte | Operator.Regex
>;

export const filterTypeToOperator = {
  [FilterOptions.Contains]: Operator.Regex,
  [FilterOptions.Equals]: Operator.Eq,
  [FilterOptions.In]: Operator.In,
  [FilterOptions.GreaterThan]: Operator.Gte,
  [FilterOptions.LessThan]: Operator.Lte,
};

export const operatorToFilterType = {
  [Operator.Regex]: FilterOptions.Contains,
  [Operator.Eq]: FilterOptions.Equals,
  [Operator.Gte]: FilterOptions.GreaterThan,
  [Operator.Lte]: FilterOptions.LessThan,
};

// TODO:: This should move to BE as field filter type
export enum ListFilterTypes {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Enum = 'Enum',
  Relational = 'Relational',
}

export type SupportedFilters = Extract<
  ListFilterTypes,
  ListFilterTypes.Text | ListFilterTypes.Number | ListFilterTypes.Date
>;
type filterTypeToAgGridFilter = {
  [key in SupportedFilters]: string;
};

export const filterTypeToAgGridFilter: filterTypeToAgGridFilter = {
  [ListFilterTypes.Text]: 'agTextColumnFilter',
  [ListFilterTypes.Number]: 'agNumberColumnFilter',
  [ListFilterTypes.Date]: 'agDateColumnFilter',
};

export type FilterModel = Record<string, Record<string, unknown>>;

export const getDatasetFiltersFromFilterModel = (
  filterModel: FilterModel | undefined,
): BaseAPIFilter[] =>
  map(filterModel, (val, key) => ({
    field: key === 'dataFamily' ? 'dataFamilyId' : key,
    operator: filterTypeToOperator[val.type as FilterOptions] ?? Operator.Regex,
    value: key === 'dataQualityScore' ? (val.filter as number) * 0.01 : val.filter,
  }));
