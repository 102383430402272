import { openModal } from '@mantine/modals';
import { Container, Space, useMarkovTheme } from '@/shared/design-system/v2';
import { PricingPlansHeader } from './PricingPlansHeader';
import { PricingPlansTable } from './PricingPlansTable';
import { UPGRADE_PLAN_MODAL_ID } from './util';

const UpgradePlanModal = (): JSX.Element => (
  <Container size="md" pt="sm">
    <PricingPlansHeader
      title="Your trial of the Team plan has ended"
      subtitle="To continue using the platform, please choose the plan that works best for you."
    />
    <Space h={24} />
    <PricingPlansTable />
  </Container>
);

export const useUpgradePlanModal = () => {
  const theme = useMarkovTheme();

  const open = () =>
    openModal({
      modalId: UPGRADE_PLAN_MODAL_ID,
      size: '80vw',
      closeOnEscape: false,
      closeOnClickOutside: false,
      withCloseButton: false,
      children: <UpgradePlanModal />,
      overlayProps: {
        color: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[2],
        opacity: 0.25,
        blur: 2,
      },
      styles: {
        content: {
          maxHeight: 'calc(100vh - 60px) !important',
        },
        body: {
          paddingTop: theme.spacing.lg,
          paddingBottom: theme.spacing.lg,
        },
      },
    });

  return { open };
};
