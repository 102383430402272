import { Alert, Center, Loader } from '@/shared/design-system/v2';
import { useNodeRunLogsQuery } from '../../../../../queries/workflows/operators';
import { LogsView } from './LogsView';

interface LogsViewContainerProps {
  workflowId: string;
  runId: string;
  nodeId: string;
}

export const LogsViewContainer = ({ workflowId, runId, nodeId }: LogsViewContainerProps) => {
  const { isLoading, isError, data } = useNodeRunLogsQuery(workflowId, runId, nodeId);

  if (isLoading) {
    return (
      <Center mih={200} w="100%">
        <Loader />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center mih={200} w="100%">
        <Alert>Error loading node logs</Alert>
      </Center>
    );
  }

  return <LogsView nodeLogs={data} />;
};
