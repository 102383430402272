import { MantineTheme } from '@mantine/styles';
import {
  FallbackRender,
  ErrorBoundary as SentryErrorBoundary,
  ErrorBoundaryProps as SentryErrorBoundaryProps,
} from '@sentry/react';
import { LinkAnchor, Text, Vertical } from '@/shared/design-system/v2';
import { ErrorState } from './components/common/ErrorState';

export const ErrorFallback: FallbackRender = ({ error }) => {
  const subtitleMessage = (
    <Vertical spacing={1}>
      <Text variant="bodyLong01" color="dark.6">
        Our engineers have been notified. We&apos;re fixing this issue.
      </Text>
      <Text variant="bodyLong01" color="dark.6">
        Meanwhile, please reach out to{' '}
        <LinkAnchor
          external
          href="mailto:support@markovml.com"
          sx={(theme: MantineTheme) => ({
            textDecoration: 'underline',
            color: theme.colors.blue[6],
          })}
        >
          support@markovml.com
        </LinkAnchor>
      </Text>
      {import.meta.env.VITE_APP_MODE === 'debug' && (
        <pre>{error instanceof Error ? error.message : String(error)}</pre>
      )}
    </Vertical>
  );

  return (
    <ErrorState
      iconName="broken_plate"
      iconSize="lg"
      title="Something is broken"
      subtitle={subtitleMessage}
    />
  );
};

interface ErrorBoundaryProps extends SentryErrorBoundaryProps {
  tags?: { key: string; value: string }[];
}

export const ErrorBoundary = ({ children, tags, ...rest }: ErrorBoundaryProps): JSX.Element => (
  <SentryErrorBoundary
    beforeCapture={scope => {
      tags?.map(tag => scope.setTag(tag.key, tag.value));
    }}
    {...rest}
  >
    {children}
  </SentryErrorBoundary>
);
