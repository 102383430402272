import Papa, { UnparseObject } from 'papaparse';
import { useState } from 'react';
import { logger } from '@/shared/initializers/logging';

const blobToSaveAs = (fileName: string, blob: Blob) => {
  try {
    const url = URL.createObjectURL(blob);
    const tmpDownloadEl = document.createElement('a');
    tmpDownloadEl.setAttribute('href', url);
    tmpDownloadEl.setAttribute('download', fileName);
    tmpDownloadEl.click();
  } catch (e) {
    logger.error(`BlobToSaveAs error ${e}`);
  }
};

const TYPES = {
  PDF: 'application/pdf',
  DOCX: 'application/docx',
};

export const useCsvDownload = () => {
  const [isDownloading, setDownloading] = useState(false);

  const handleDownload = ({
    data,
    fileName = 'raw-data',
  }: {
    data: string[][] | UnparseObject<string[]>;
    fileName?: string;
  }) => {
    setDownloading(true);
    if (data) {
      const downloadData = Papa.unparse(data, {
        delimiter: ',',
        skipEmptyLines: true,
      });

      const blob = new Blob([downloadData], { type: 'data:text/csv;charset=utf-8' });
      blobToSaveAs(`${fileName}.csv`, blob);

      setTimeout(() => {
        setDownloading(false);
      }, 200);
    }
  };

  return { handleDownload, isDownloading };
};

export const useDownloadData = (type: keyof typeof TYPES = 'PDF') => {
  const [isDownloading, setDownloading] = useState(false);

  const handleDownload = ({ fileName = 'untitled', data }: { fileName?: string; data: string }) => {
    setDownloading(true);
    if (data) {
      const blob = new Blob([data], { type: TYPES[type] });
      // Remove file ext if any, and add the ext according to type of data
      blobToSaveAs(`${fileName.replace(/\.[^/.]+$/, '')}.${type.toLowerCase()}`, blob);

      setTimeout(() => {
        setDownloading(false);
      }, 200);
    }
  };

  return { handleDownload, isDownloading };
};
