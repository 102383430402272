import first from 'lodash/first';
import { Box } from '@/shared/design-system/v2';
import { Table, TableThemes } from '@/shared/design-system/v2/core/data-display/table';

interface TableViewProps {
  content: Record<string, unknown>[];
}

export const TableView = ({ content }: TableViewProps) => {
  const firstRow = first(content);
  const columnKeys = firstRow ? Object.keys(firstRow) : [];

  const columns = columnKeys.map(cl => ({
    colId: cl,
    field: cl,
    filter: false,
    sortable: false,
    minWidth: 100,
    flex: Number(columnKeys.length < 5),
  }));

  return (
    <Box mah={550} h={content.length * 55 + 60}>
      <Table
        theme={TableThemes.THEME_V2}
        columns={columns}
        rowData={content}
        paginationPageSize={content.length}
        enableCellTextSelection
        suppressFieldDotNotation
        noRowsOverlayComponent={null}
      />
    </Box>
  );
};
