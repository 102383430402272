import { createStyles } from '@/shared/design-system/v2';

export const useHelpCategoryCardAccordionStyles = createStyles(theme => ({
  root: {
    backgroundColor: theme.white,
    border: 'none',
  },

  item: {
    backgroundColor: theme.white,
    transition: 'transform 150ms ease',
    paddingLeft: 0,
  },

  label: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  control: {
    backgroundColor: theme.white,
    border: 'none',
    paddingLeft: 0,
  },

  panel: {
    paddingLeft: '20px',
  },
}));
