import { IconInfoCircle } from '@tabler/icons-react';
import {
  Box,
  Button,
  Horizontal,
  Image,
  Text,
  Tooltip,
  openModal,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { ChartTypes } from '../../charts';
import { ChartData } from '../../charts/config/build';
import { colors } from '../../charts/config/colors';
import { RecordMetaData, RecordMetaType } from '../../generated/api';
import { VisualizationComponent } from '../analyser/visualization/Visualization';
import { DownloadVisualization } from '../analyser/visualization/actions/DownloadVisualization';
import { DataInfoBox } from '../common/DataInfoBox';
import { MetadataFieldGroup } from './MetadataFieldGroup';

export interface MetadataBoxProps {
  metadata: RecordMetaData[];
}

const FEAT_COLOR_NEGATIVE = '#C4A8CC';

interface MetadataHistogramProps {
  chartData: ChartData;
  title?: string;
  tooltipLabel?: string;
}

export const MetadataHistogram = ({
  chartData,
  title = 'Histogram',
  tooltipLabel = '',
}: MetadataHistogramProps) => (
  <VisualizationComponent
    cardProps={{
      shadow: '',
      withBorder: true,
    }}
  >
    <VisualizationComponent.Header actions={<DownloadVisualization fileName={title} />}>
      <Horizontal spacing="xs">
        <VisualizationComponent.Header.Title title={title} titleProps={{ variant: 'subTitle02' }} />
        {tooltipLabel && (
          <Tooltip withArrow multiline withinPortal w={300} label={tooltipLabel}>
            <IconInfoCircle size={14} />
          </Tooltip>
        )}
      </Horizontal>
    </VisualizationComponent.Header>
    <VisualizationComponent.Chart
      data={chartData}
      type={ChartTypes.BAR}
      chartOptions={{ spacing: [10, 10, 10, 10] }}
      colors={colors}
    />
  </VisualizationComponent>
);

const MetadataModal = ({ metadata }: MetadataBoxProps) => {
  const theme = useMarkovTheme();
  return (
    <Box px="xl">
      {metadata.map((metadataItem, index) => {
        // FIXME:: BE is currently sending meta_type for disagreements instead of metaType as field
        // Get that fixed from BE
        const metaType = metadataItem.metaType || (metadataItem as any).meta_type;
        if (metaType === RecordMetaType.ImageUrl) {
          return (
            <DataInfoBox key={index} title="Image" wrapperProps={{ my: 'xl' }}>
              <Image src={metadataItem.value} alt="Subject" mah={350} maw={350} mx="auto" />
            </DataInfoBox>
          );
        }

        if (metaType === RecordMetaType.Text) {
          return (
            <DataInfoBox key={index} title="Text">
              <MetadataFieldGroup label={metadataItem.label} value={metadataItem.value} />
            </DataInfoBox>
          );
        }

        if (metaType === RecordMetaType.Uri) {
          return (
            <DataInfoBox key={index} title="Url">
              <MetadataFieldGroup
                label={metadataItem.label}
                value={
                  <a href={metadataItem.value} target="_blank" rel="noopener noreferrer">
                    Link
                  </a>
                }
              />
            </DataInfoBox>
          );
        }

        if (metaType === RecordMetaType.Histogram) {
          const histogramDetails = JSON.parse(metadataItem.value) as Record<string, number>;
          const sortedDetails = Object.entries(histogramDetails).sort((a, b) => a[1] - b[1]);
          const chartData = {
            series: [
              {
                name: 'features',
                data: sortedDetails.map(value => [value[1]]),
                borderRadius: theme.radius.sm,
                negativeColor: FEAT_COLOR_NEGATIVE,
              },
            ],
            categories: sortedDetails.map(value => value[0]),
          };

          return <MetadataHistogram key={index} chartData={chartData} />;
        }

        return null;
      })}
    </Box>
  );
};

export const MetadataBox = ({ metadata }: MetadataBoxProps): JSX.Element => {
  if (!metadata || !metadata.length) {
    return <span>N/A</span>;
  }

  const handleViewMetadataClick = () => {
    openModal({
      title: <Text variant="subTitle01">Metadata</Text>,
      children: <MetadataModal metadata={metadata} />,
      size: 'xl',
    });
  };

  return (
    <Button variant="subtle" onClick={handleViewMetadataClick}>
      View
    </Button>
  );
};
