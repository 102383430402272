import sortBy from 'lodash/sortBy';
import { TagMetadata } from '@/main/generated/api';

/**
 * Returns a new array of tags sorted by name (case insensitive).
 *
 * @param {Array} tags - The input array of tag metadata
 * @returns {Array} The output array of tags sorted by name
 */
export const getSortedTags = (tags: TagMetadata[]) => sortBy(tags, tag => tag.name.toLowerCase());
