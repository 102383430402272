import HighchartsReact from 'highcharts-react-official';
import { useRef, useState } from 'react';
import { Expand, Loader, VizErrorState } from '@/shared/design-system';
import { ActionIcon, Center, Horizontal, Modal, Vertical } from '@/shared/design-system/v2';
import { getIsTaskPending } from '@/shared/lib/analysis';
import { ChartTypes } from '../../../charts';
import { ChartData } from '../../../charts/config/build';
import { ChartsProvider } from '../../../charts/context/Context';
import { getColors } from '../../../charts/utils/colors';
import { AnalysisStateNames, SegmentType } from '../../../generated/api';
import { useGetMissingValuesChartDataQuery } from '../../../queries/datasets/statistics/missing-values';
import { ModalVizHeader } from '../../analyser/visualization/ModalVizHeader';
import { VisualizationComponent } from '../../analyser/visualization/Visualization';
import { DownloadVisualization } from '../../analyser/visualization/actions/DownloadVisualization';
import { EmptyState } from '../../common/EmptyState';
import { InfoMessage } from '../../common/InfoMessage';

const chartColors = getColors(20, 0.8);
const missingValueColor = chartColors[4];
const presentValueColor = chartColors[5];

interface MissingValuesContainerProps {
  datasetId: string;
  segment: SegmentType;
  datasetName?: string;
  showBorders?: boolean;
  expandable?: boolean;
}

export const MissingValuesContainer = ({
  datasetId,
  segment,
  datasetName = '',
  showBorders = true,
  expandable = true,
}: MissingValuesContainerProps) => {
  const {
    isLoading: isMissingValuesDataLoading,
    isError: isMissingValuesDataError,
    data: missingValuesData,
  } = useGetMissingValuesChartDataQuery(datasetId, segment, true);
  const [expanded, showExpanded] = useState(false);
  const chartRef = useRef<HighchartsReact.RefObject | null>(null);

  const analysisState = missingValuesData?.analysisState;
  if (analysisState && getIsTaskPending(analysisState)) {
    return (
      <EmptyState
        compact
        title="Missing data analysis in progress"
        subtitle="Results will become available once the analysis is complete"
        iconName="missing_data"
      />
    );
  }

  if (!missingValuesData?.response) {
    return (
      <EmptyState
        compact
        iconName="missing_data"
        title=""
        subtitle="Missing values data not found"
      />
    );
  }

  const handleExpandViz = () => {
    showExpanded(true);
  };

  const handleCarouselClose = () => {
    showExpanded(false);
  };

  const header = (
    <>
      <VisualizationComponent.Header
        actions={
          !expanded ? (
            <Horizontal>
              <DownloadVisualization
                fileName={'missing_values_chart'}
                isDownloadDisabled={
                  isMissingValuesDataLoading ||
                  isMissingValuesDataError ||
                  !missingValuesData?.response
                }
              />
              {expandable ? (
                <ActionIcon
                  color="gray.6"
                  onClick={handleExpandViz}
                  disabled={
                    isMissingValuesDataLoading ||
                    isMissingValuesDataError ||
                    !missingValuesData?.response
                  }
                >
                  <Expand />
                </ActionIcon>
              ) : null}
            </Horizontal>
          ) : null
        }
      >
        {!expanded && (
          <Center>
            <VisualizationComponent.Header.Title
              title="Missing Values"
              titleProps={{ mt: 'xs', ml: 'xs', variant: 'subTitle03', color: 'gray.8' }}
            />
          </Center>
        )}
      </VisualizationComponent.Header>
    </>
  );

  let content = null;

  if (isMissingValuesDataLoading) {
    content = (
      <Center h={420}>
        <Loader text="Loading missing values..." />
      </Center>
    );
  } else if (
    isMissingValuesDataError ||
    missingValuesData?.analysisState === AnalysisStateNames.Error
  ) {
    content = (
      <Vertical align="center">
        <VizErrorState />
        <InfoMessage text="Error loading chart data" />
      </Vertical>
    );
  } else if (!isMissingValuesDataLoading && !missingValuesData?.response) {
    content = (
      <Vertical align="center">
        <EmptyState
          compact
          iconName="missing_data"
          title=""
          subtitle="Missing values data not found"
        />
      </Vertical>
    );
  } else if (missingValuesData.response) {
    content = (
      <VisualizationComponent.Chart
        type={ChartTypes.STACKED_COLUMN}
        enableLegends
        data={missingValuesData.response.data as ChartData}
        // TODO:: Fix. Temporary colors
        colors={[missingValueColor, presentValueColor]}
        colorByPoint={false}
        yAxisOptions={{
          title: 'Number of Rows (In Percentage)',
          format: '{value}%',
        }}
        dataLabelStyle={{
          color: '#fff',
          fontWeight: '600',
          fontSize: '14px',
        }}
      />
    );
  }

  const vizContent = (
    <VisualizationComponent
      cardProps={{
        shadow: '',
        sx: { height: '100%' },
      }}
    >
      {header}
      {content}
    </VisualizationComponent>
  );

  return (
    <>
      <ChartsProvider hcRef={chartRef}>{vizContent}</ChartsProvider>
      <Modal
        opened={expanded}
        onClose={handleCarouselClose}
        size="90vw"
        centered
        dataTestId="misssing-value"
      >
        <ModalVizHeader title="Missing Values" datasetName={datasetName} segmentType={segment} />
        {vizContent}
      </Modal>
    </>
  );
};
