import { PreviewAudioButton } from '@/main/components/workflows/create/workflow-builder/operator-parameter-form/operator-data-preview/node-schema-preview/PreviewAudioButton';
import { useGetS3PresignedUrlForFileQuery } from '@/main/queries/workflows/s3-preview';
import { Center, Loader } from '@/shared/design-system/v2';

interface AudioPreviewProps {
  srcUrl: string;
  workflowId: string;
}

export const AudioPreview = ({ srcUrl, workflowId }: AudioPreviewProps): JSX.Element => {
  const { isLoading, isError, data } = useGetS3PresignedUrlForFileQuery(workflowId, srcUrl);

  if (isLoading) {
    return (
      <Center h="100%">
        <Loader size="sm" />
      </Center>
    );
  }

  if (isError || !data) {
    return <></>;
  }

  return <PreviewAudioButton srcUrl={URL.createObjectURL(data)} />;
};
