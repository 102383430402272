import { IconUpload } from '@tabler/icons-react';
import first from 'lodash/first';
import {
  Box,
  Button,
  Dropzone,
  FileWithPath,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { getDropzoneAccept, getSupportedFilesLabel } from './util';

export interface FileUploadInputProps {
  supportedFilesTypes?: string[];
  maximumFileSize?: string;
  onFileDrop: (file: FileWithPath) => void;
}

export const FileUploadInput = ({
  supportedFilesTypes,
  maximumFileSize,
  onFileDrop,
}: FileUploadInputProps) => {
  const theme = useMarkovTheme();

  const handleFileOnDrop = (files: FileWithPath[]) => {
    const file = first(files);

    if (file) {
      onFileDrop(file);
    }
  };

  return (
    <Box pos="relative">
      <Dropzone
        bg={theme.colors.white[0]}
        accept={getDropzoneAccept(supportedFilesTypes)}
        onDrop={handleFileOnDrop}
        py="xl"
        sx={{ borderColor: theme.colors.blue[2], borderRadius: theme.radius.lg }}
      >
        <Vertical align="center" spacing="sm">
          {maximumFileSize && (
            <Text variant="bodyShort03" color="gray.6">
              Maximum file size: {maximumFileSize}
            </Text>
          )}
          <Button variant="primary" leftIcon={<IconUpload size={16} />}>
            Upload a file
          </Button>
          <Text variant="bodyShort03" color="gray.7">
            Drag & drop to upload your file
          </Text>
          {supportedFilesTypes && (
            <Text variant="bodyShort03" color="gray.6">
              {getSupportedFilesLabel(supportedFilesTypes)}
            </Text>
          )}
        </Vertical>
      </Dropzone>
    </Box>
  );
};
