import { useMemo } from 'react';
import { Center, Loader } from '@/shared/design-system/v2';
import { useTableFiltersContext } from '../../../../../contexts/table/TableFilters';
import { useGetDatasetsInfiniteQuery } from '../../../../../queries/datasets/list';
import { ErrorState } from '../../../../common/ErrorState';
import { DatasetChartSelectionStep, useSnippetDetail } from '../../context/SnippetContext';
import { SelectAnalysisContainer } from '../analysers/SelectAnalysis.container';
import { SelectDatasetsList } from './SelectDatasetsList';

export const SelectDatasetsListContainer = (): JSX.Element => {
  const { selectedTags } = useTableFiltersContext();
  const { isLoading, isFetchingNextPage, isError, data, hasNextPage, fetchNextPage } =
    useGetDatasetsInfiniteQuery(
      { tagIds: selectedTags.map(t => t.tagId) },
      {
        pageSize: 10,
      },
    );

  const { addChartStep } = useSnippetDetail();

  const datasets = useMemo(() => data?.pages.flatMap(page => page) || [], [data]);

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader variant="dots" size="md" />
      </Center>
    );
  }

  if (isError) {
    return <ErrorState title="" subtitle="Unable to load datasets" />;
  }

  if (addChartStep === DatasetChartSelectionStep.DatasetSelection)
    return (
      <SelectDatasetsList
        datasets={datasets}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    );

  return <SelectAnalysisContainer />;
};
