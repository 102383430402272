import { TypographyStylesProvider, TypographyStylesProviderProps } from '@mantine/core';
import DOMPurify from 'dompurify';
import { useEffect } from 'react';
import { useStyles } from './HTMLPreview.styles';

export interface HTMLPreviewProps extends Omit<TypographyStylesProviderProps, 'children'> {
  /**
   * The HTML content to be rendered.
   */
  children: string | TrustedHTML;
}

/**
 * HTMLPreview Component
 *
 * This component wraps the Mantine TypographyStylesProvider component
 * and safely renders HTML content using `dangerouslySetInnerHTML`.
 *
 */
export const HTMLPreview = ({ children, ...rest }: HTMLPreviewProps): JSX.Element => {
  const { classes } = useStyles();

  useEffect(() => {
    // Add a hook to modify all anchor tags
    DOMPurify.addHook('afterSanitizeAttributes', node => {
      if (node.tagName === 'A') {
        // Ensure all anchor tags open in a new tab and are safe
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
      }
    });

    return () => {
      DOMPurify.removeHook('afterSanitizeAttributes');
    };
  }, []);

  let sanitizedHTML = children;

  if (typeof children === 'string') {
    sanitizedHTML = DOMPurify.sanitize(children);
  }

  return (
    <TypographyStylesProvider className={classes.provider} {...rest}>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </TypographyStylesProvider>
  );
};
