import { openModal } from '@mantine/modals';
import { Box, Prism } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';

interface JsonPreviewProps {
  data: string;
}

export const JsonPreview = ({ data }: JsonPreviewProps) => {
  let updatedJson = data;
  try {
    updatedJson = JSON.parse(data);
    updatedJson = JSON.stringify(updatedJson, null, 2);
  } catch (e) {
    logger.error(`Unable to parse JSON response: ${data}`);
  }

  return (
    <Box>
      <Prism language="json">{updatedJson}</Prism>
    </Box>
  );
};

export const useJsonPreview = () => (data: string) =>
  openModal({
    title: '',
    size: '65vw',
    h: '70vh',
    children: <JsonPreview data={data} />,
  });
