import {
  IconBook2,
  IconPower,
  IconSelector,
  IconSettings,
  IconUserPlus,
} from '@tabler/icons-react';
import first from 'lodash/first';
import { Link } from 'react-router-dom';
import {
  ActionIcon,
  Avatar,
  Box,
  Card,
  Horizontal,
  Indicator,
  Menu,
  Text,
  Tooltip,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { MARKOVML_DOCS } from '../../../../developer-docs/links';
import { BaseUser } from '../../../../generated/api';
import { useMarkovNavigate } from '../../../../hooks/useMarkovNavigate';
import { getUserProfilePath } from '../../../../router/constants';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { openWorkspaceInviteModal } from '../../../user-invitation/useOpenInviteModal';
import { useWorkspaceSwitcherModal } from '../../workspace-switcher/WorkspaceSwitcherModal';
import { useLogout } from '../LogoutButton';

const MENU_WIDTH = 216;
const MENU_HEIGHT = 328;

interface AccountMenuProps {
  userData: BaseUser;
  workspaceName: string;
  showIndicator: boolean;
}

export const AccountMenu = ({
  userData,
  workspaceName,
  showIndicator,
}: AccountMenuProps): JSX.Element => {
  const handleLogout = useLogout();
  const getNavigateClickHandler = useMarkovNavigate();

  const { open: openWorkspaceSwitcherModal } = useWorkspaceSwitcherModal();
  const theme = useMarkovTheme();

  const { userId = '', name: userName, email, avatar } = userData;
  const navigateToProfile = getNavigateClickHandler(getUserProfilePath(userId));
  const workspaceRoutes = useAbsoluteRoutes();
  const navigateToWorkspaceSettings = getNavigateClickHandler(workspaceRoutes.getSettingsRoute());

  return (
    <Menu offset={8} shadow="xl" width={MENU_WIDTH}>
      <Menu.Target>
        <Indicator
          color="yellow.7"
          processing
          disabled={!showIndicator}
          aria-label={`${userName}'s Account Information`}
        >
          <Tooltip
            offset={12}
            label={<Text variant="bodyShort03">{workspaceName}</Text>}
            color={theme.colors.dark[4]}
          >
            <Card bg="dark.4" py="xs" h={32} radius="8px">
              <Horizontal spacing="sm" h="100%" w="126px" noWrap sx={{ cursor: 'pointer' }}>
                <Text
                  w="100%"
                  variant="small02"
                  color="gray.0"
                  sx={{
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {workspaceName}
                </Text>
                <ActionIcon variant="transparent" aria-label="Open account menu">
                  <Avatar size="sm" src={avatar} alt={userName ?? 'M'}>
                    {first(userName)}
                  </Avatar>
                </ActionIcon>
              </Horizontal>
            </Card>
          </Tooltip>
        </Indicator>
      </Menu.Target>

      <Menu.Dropdown p="sm" sx={{ borderRadius: '8px' }}>
        <Indicator color="yellow.7" processing disabled={!showIndicator}>
          <Box bg="blue.0" sx={{ borderRadius: '8px' }} w={192} mb="sm">
            <Text variant="subTitle03" color="blue.6" align="center" py="sm">
              {workspaceName}
            </Text>
          </Box>
        </Indicator>
        <Menu.Item
          py="sm"
          onClick={openWorkspaceSwitcherModal}
          aria-label="View profile"
          icon={<IconSelector strokeWidth="1.5px" color={theme.colors.gray[6]} />}
        >
          <Text variant="bodyShort02" color={theme.colors.gray[9]} pl="sm">
            Switch Workspace
          </Text>
        </Menu.Item>
        <Menu.Item
          py="sm"
          onClick={openWorkspaceInviteModal}
          aria-label="Invite Members"
          icon={<IconUserPlus strokeWidth="1.5px" color={theme.colors.gray[6]} />}
        >
          <Text variant="bodyShort02" color={theme.colors.gray[9]} pl="sm">
            Invite Members
          </Text>
        </Menu.Item>
        <Menu.Divider />
        {/* <Menu.Item
          py="sm"
          onClick={navigateToProfile}
          aria-label="View profile"
          icon={<IconUserCircle strokeWidth="1.5px" color={theme.colors.gray[6]} />}
        >
          <Text variant="bodyShort02" color={theme.colors.gray[9]} pl="sm">
            Profile
          </Text>
        </Menu.Item> */}
        <Menu.Item
          py="sm"
          onClick={navigateToWorkspaceSettings}
          aria-label="Open settings"
          icon={<IconSettings strokeWidth="1.5px" color={theme.colors.gray[6]} />}
        >
          <Text variant="bodyShort02" color={theme.colors.gray[9]} pl="sm">
            Settings
          </Text>
        </Menu.Item>
        <Menu.Item
          py="sm"
          component={Link}
          to={MARKOVML_DOCS}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open help docs"
          icon={<IconBook2 strokeWidth="1.5px" color={theme.colors.gray[6]} />}
        >
          <Text variant="bodyShort02" color={theme.colors.gray[9]} pl="sm">
            Help docs
          </Text>
        </Menu.Item>
        <Menu.Item
          py="sm"
          onClick={handleLogout}
          aria-label="Logout"
          icon={<IconPower strokeWidth="1.5px" color={theme.colors.gray[6]} />}
        >
          <Text variant="bodyShort02" color={theme.colors.gray[9]} pl="sm">
            Logout
          </Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
