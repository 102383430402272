import { MantineSize } from '@mantine/styles';
import first from 'lodash/first';
import noop from 'lodash/noop';
import { Avatar, Text, Tooltip } from '@/shared/design-system/v2';
import { WorkspaceMember } from '../../generated/api';

const getOpacity = (userId: string, activeUserIds?: string[], showActive?: boolean) => {
  if (!showActive || !activeUserIds) {
    return 1;
  }

  return activeUserIds.includes(userId) ? 1 : 0.4;
};

interface UserAvatarGroupProps {
  users: WorkspaceMember[];
  maxShown?: number;
  otherUsersTooltipLabel?: string;
  onOtherUsersClick?: () => void;
  showActive?: boolean;
  activeUserIds?: string[];
  size?: MantineSize | number;
}

export const UserAvatarGroup = ({
  users,
  maxShown = 4,
  otherUsersTooltipLabel = 'Other members',
  onOtherUsersClick = noop,
  activeUserIds,
  showActive,
  size = 32,
}: UserAvatarGroupProps): JSX.Element => {
  const displayedUsers = users.slice(0, maxShown);
  const remainingUsers = users.length - maxShown;

  return (
    <Tooltip.Group>
      <Avatar.Group spacing="md">
        {displayedUsers.map(user => (
          <Tooltip
            key={user.userId}
            label={user.userDetails?.name ?? ''}
            withArrow
            withinPortal
            position="bottom"
          >
            <Avatar
              size={size}
              src={user.userDetails?.avatar ?? ''}
              alt={user.userDetails?.name}
              styles={{
                placeholder: { opacity: getOpacity(user.userId, activeUserIds, showActive) },
                image: { opacity: getOpacity(user.userId, activeUserIds, showActive) },
              }}
            >
              <Text variant="bodyShort02">{first(user.userDetails?.name ?? '')}</Text>
            </Avatar>
          </Tooltip>
        ))}
        {remainingUsers > 0 && (
          <Tooltip
            label={otherUsersTooltipLabel}
            withArrow
            withinPortal
            position="bottom"
            onClick={onOtherUsersClick}
          >
            <Avatar color="gray.3" size={size}>
              <Text variant="small01" color="dark.6">
                +{remainingUsers}
              </Text>
            </Avatar>
          </Tooltip>
        )}
      </Avatar.Group>
    </Tooltip.Group>
  );
};
