import { Horizontal, Text } from '@/shared/design-system/v2';
import { InfoTooltip } from '../../../common/InfoTooltip';

interface MetricHeaderProps {
  name: string;
  description: string;
}

export const MetricHeader = ({ name, description }: MetricHeaderProps) => (
  <Horizontal spacing={1} py="lg">
    <Text variant="subTitle03" color="gray.8" tt="uppercase">
      {name}
    </Text>
    <InfoTooltip text={description} maw={250} />
  </Horizontal>
);
