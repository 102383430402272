import isEmpty from 'lodash/isEmpty';
import { Box, Button, Center, Loader } from '@/shared/design-system/v2';
import { useGetConnectorsListQuery } from '../../../queries/connectors';
import { PageEmptyState } from '../../common/EmptyState';
import { PageErrorScreen } from '../../common/ErrorState';
import { useCreateConnectorModal } from '../../connectors/modals/CreateConnectorModal';
import { ConnectorsList } from './ConnectorsList';

const LIST_HEIGHT = 505;

export const ConnectorListContainer = (): JSX.Element => {
  const connectorsQuery = useGetConnectorsListQuery();
  const { open: openCreateConnectorModal } = useCreateConnectorModal();

  if (connectorsQuery.isLoading) {
    return (
      <Center h={LIST_HEIGHT}>
        <Loader text="Loading connectors" />
      </Center>
    );
  }

  if (connectorsQuery.isError) {
    return (
      <PageErrorScreen
        title="Unable to load connectors"
        subtitle="If the problem persists, please contact support"
      />
    );
  }

  const connectors = connectorsQuery.data;

  if (isEmpty(connectors)) {
    return (
      <PageEmptyState
        iconName="credential"
        iconSize="lg"
        title="No connectors added yet"
        subtitle="Add a new connector to access your external cloud services"
        button={
          <Button variant="outline" onClick={openCreateConnectorModal}>
            Add connector
          </Button>
        }
      />
    );
  }

  return (
    <Box h={LIST_HEIGHT}>
      <ConnectorsList connectors={connectors} />
    </Box>
  );
};
