import { rem } from '@mantine/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import {
  Button,
  Flex,
  Header,
  Horizontal,
  Image,
  LinkAnchor,
  MediaQuery,
  Text,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useAuthLogin } from '../../contexts/auth-provider/Auth';
import { useAbsoluteRoutes } from '../../router/hooks';
import { MARKOV_LOGO_WHITE_TEXT_URL } from '../common/MarkovLogo';
import { MARKOVML_HOME_PAGE } from '../common/constants';
import { StyledHeaderContainer } from './AppHeader.style';
import { ColorSchemeToggler } from './ColorSchemeToggler';
import { SidebarBurger } from './SidebarBurger';
import { AccountMenuContainer } from './account/account-menu/AccountMenu.container';
import { HelpAndSupport } from './help-and-support/HelpAndSupport';
import { Notifications } from './notifications/Notifications';
import { SubscriptionContainer } from './payment-and-subscriptions/Subscription.container';

export const APP_HEADER_HEIGHT = rem(44);

const AuthenticatedAppHeader = (): JSX.Element => {
  const theme = useMarkovTheme();
  const { featureDarkMode } = useFlags();
  const { getWorkspaceRoute } = useAbsoluteRoutes();

  return (
    <Header
      height={{ base: APP_HEADER_HEIGHT }}
      p="md"
      bg="dark.5"
      sx={{ borderBottomColor: theme.colors.dark[3] }}
      aria-label="App header"
    >
      <Flex justify="space-between" align="center" h="100%">
        <Horizontal spacing="lg">
          <Horizontal spacing={0}>
            <SidebarBurger />

            {/* For smaller screens, hide the logo */}
            <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
              <Link to={getWorkspaceRoute()} aria-label="Go to MarkovML homepage">
                <Flex align="center" w={128} h={60}>
                  <Image src={MARKOV_LOGO_WHITE_TEXT_URL} />
                </Flex>
              </Link>
            </MediaQuery>
          </Horizontal>
        </Horizontal>
        <Horizontal spacing="md">
          <SubscriptionContainer />
          <Notifications />
          <HelpAndSupport />
          {featureDarkMode && (
            <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
              <ColorSchemeToggler />
            </MediaQuery>
          )}
          <AccountMenuContainer />
        </Horizontal>
      </Flex>
    </Header>
  );
};

// Used for pages which don't require user auth, e.g. public user profile page
export const NonAuthenticatedAppHeader = (): JSX.Element => {
  const { handleLoginRedirect, handleSignupRedirect } = useAuthLogin();
  return (
    <StyledHeaderContainer>
      <LinkAnchor to={MARKOVML_HOME_PAGE} aria-label="Go to MarkovML homepage">
        <Flex align="center" w={64}>
          <Image src={MARKOV_LOGO_WHITE_TEXT_URL} />
        </Flex>
      </LinkAnchor>

      <Horizontal>
        <Button variant="subtle" color="dark" onClick={handleLoginRedirect}>
          <Text variant="bodyShort01" color="white.0">
            Login
          </Text>
        </Button>
        <Button variant="outline" onClick={handleSignupRedirect}>
          <Text variant="bodyShort01" color="white.0">
            Sign Up
          </Text>
        </Button>
      </Horizontal>
    </StyledHeaderContainer>
  );
};

export const AppHeader = (): JSX.Element => {
  const { userId } = useAppMetadata();

  return userId ? <AuthenticatedAppHeader /> : <NonAuthenticatedAppHeader />;
};
