import { useQuery } from '@tanstack/react-query';
import { datasetV2Api } from '@/shared/lib/api';
import { useAppMetadata } from '../contexts/app-metadata/AppMetadata';
import {
  BaseAPIFilter,
  ServicesViennaApiModelsV2DatasetsDatasetModelsDataset,
} from '../generated/api';

export const dataViewsKeys = {
  all: ['data-view'] as const,
  list: (workspaceId: string) => [...dataViewsKeys.all, 'list', workspaceId] as const,
};

// TODO: Fix this naming in Vienna
export type DataViewModel = ServicesViennaApiModelsV2DatasetsDatasetModelsDataset;

export const useGetDataViewsListQuery = () => {
  const { workspaceId } = useAppMetadata();

  // TODO: Read from filters param
  const filters: BaseAPIFilter[] = [];

  return useQuery(
    dataViewsKeys.list(workspaceId),
    () => datasetV2Api.getDatasetListV3(workspaceId, { filters }),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data.response,
    },
  );
};
