import { isAxiosError } from 'axios';
import { Center, Loader } from '@/shared/design-system/v2';
import { useGetDataSourcePreviewQuery } from '../../../queries/data-sources';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';
import { DEFAULT_DELIMITER } from '../../common/constants';
import { ResourcePreviewParser } from '../../connectors/modals/ResourcePreviewModal';

interface DataSourcePreviewContainerProps {
  resourceId: string;
  height?: string;
  fileKey?: string;
}

export const DataSourcePreviewContainer = ({
  resourceId,
  height,
  fileKey,
}: DataSourcePreviewContainerProps): JSX.Element => {
  const previewQuery = useGetDataSourcePreviewQuery(resourceId);

  if (previewQuery.isLoading) {
    return (
      <Center h={400}>
        <Loader text="Loading data preview..." />
      </Center>
    );
  }

  if (previewQuery.isError) {
    return (
      <ErrorState
        title={
          isAxiosError(previewQuery.error) && previewQuery.error.request.status === 404
            ? 'No data found for the selected resource. Preview not available.'
            : 'Error loading preview.'
        }
      />
    );
  }

  const { preview, delimiter = DEFAULT_DELIMITER } = previewQuery.data;

  if (!preview.data.length) {
    return <EmptyState title="No data preview available" />;
  }

  return <ResourcePreviewParser preview={preview} delimiter={delimiter} height={height} />;
};
