import { AxisLabelsFormatterContextObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import uniq from 'lodash/uniq';
import { useRef } from 'react';
import { DragHandle, Expand } from '@/shared/design-system';
import {
  ActionIcon,
  Alert,
  Center,
  Collapse,
  Loader,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { ChartTypes } from '../../../charts';
import { ChartsProvider } from '../../../charts/context/Context';
import {
  AnalysisChartTypes,
  AnalysisDataPoint,
  AnalysisMetadata,
  SegmentType,
} from '../../../generated/api';
import { useAnalyserDataQuery } from '../../../queries/datasets/analysis/analysis';
import { replaceSeriesName, segmentToColors } from '../../dataset-details/util';
import { getSegmentText } from '../analyser.util';
import { VisualizationComponent, VisualizationProps } from '../visualization/Visualization';
import { Visualization } from '../visualization/Visualization.util';
import { DownloadVisualization } from '../visualization/actions/DownloadVisualization';

interface AnalyserVisualizationProps
  extends Omit<VisualizationProps, 'visualization' | 'onClickExpand' | 'children'> {
  analysis: Partial<AnalysisMetadata>;
  onClickExpand?: (analysis: Partial<AnalysisMetadata>, viz?: Visualization) => void;
  type?: ChartTypes;
  mode?: 'open' | 'collapsed';
  showTitle?: boolean;
  collapsible?: boolean;
  expandable?: boolean;
}

export const AnalyserVisualizationContainer = ({
  analysis,
  onClickExpand = () => undefined,
  type = ChartTypes.BAR,
  mode = 'open',
  showTitle,
  collapsible,
  expandable,
}: AnalyserVisualizationProps) => {
  const theme = useMarkovTheme();
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const { data, isLoading, isError } = useAnalyserDataQuery(
    analysis.datasetId ?? '',
    analysis.analysisId ?? '',
  );

  const handleExpand = () => {
    onClickExpand(analysis);
  };

  const shouldDisableActions = isLoading || isError;

  const header = showTitle ? (
    <VisualizationComponent.Header
      actions={
        mode === 'open' ? (
          <>
            <DownloadVisualization
              // filename spaces are replaced with underscore, as it is much more effective when referencing via CLI
              fileName={analysis.title?.replace(/ /g, '_') ?? 'chart'}
              isDownloadDisabled={shouldDisableActions}
            />
            {expandable && (
              <ActionIcon color="gray.6" onClick={handleExpand} disabled={shouldDisableActions}>
                <Expand />
              </ActionIcon>
            )}
          </>
        ) : (
          <DragHandle />
        )
      }
      sx={{
        borderLeft: '4px solid',
        borderColor: analysis.segmentType
          ? segmentToColors[analysis.segmentType][1]
          : theme.fn.themeColor('blue.7'),
        borderTopLeftRadius: '4px',
      }}
    >
      <VisualizationComponent.Header.Title
        title={replaceSeriesName(analysis.title ?? '')}
        subTitle={
          analysis.segmentType === SegmentType.Unknown
            ? ''
            : getSegmentText(analysis.segmentType ?? '')
        }
      />
    </VisualizationComponent.Header>
  ) : null;

  const contentHeight = 433;
  let content = null;
  if (isLoading) {
    content = (
      <Center h={contentHeight}>
        <Loader color="yellow" variant="dots" size="xl" />
      </Center>
    );
  }

  if (isError) {
    content = (
      <Center h={contentHeight}>
        <Alert color="red">Data loading resulted in error</Alert>
      </Center>
    );
  }

  if (!isLoading && !isError && !data?.data) {
    content = (
      <Center h={contentHeight}>
        <Alert>No data found</Alert>
      </Center>
    );
  } else if (data) {
    let xAxisOptions = undefined;

    if (analysis.preferredChartType === AnalysisChartTypes.HorizontalHistogram) {
      xAxisOptions = {
        tickPositions: uniq(
          data.data.series.flatMap(
            serie =>
              (serie.data as AnalysisDataPoint[]).flatMap(
                datum => datum?.metadata?.range ?? [],
              ) as number[],
          ),
        ),
        endOnTick: false,
        labels: {
          enabled: true,
          autoRotation: [-90, 45],
          autoRotationLimit: 60,
          step: 2,
          rotation: 90,
          formatter: function (this: AxisLabelsFormatterContextObject): string {
            return `${this.value}`;
          },
        },
      };
    }

    // BE is configured on tasklet level instead of metric level. This means that
    // if a tasklet has two metrics - Topic Distribution and Topic Keywords and both
    // required different charts, currently that is not feasible to define in BE.
    // It is getting fixed. Meanwhile a temporary solution. There is another special
    // case here - Anlyser may define it's chart as different type, but it is interfacing
    // with genAI and if last part fails, it will revert to old histogram model.
    const isTreemap = data.metadata?.preferredChartType === AnalysisChartTypes.TreeMap;

    content = (
      <VisualizationComponent.Chart
        colors={
          analysis.segmentType
            ? data.metadata?.preferredChartType === AnalysisChartTypes.TreeMap
              ? [segmentToColors[analysis?.segmentType][1]]
              : [segmentToColors[analysis.segmentType][1], segmentToColors[analysis.segmentType][0]]
            : []
        }
        colorByPoint={type === ChartTypes.BAR ? false : undefined}
        data={data.data}
        type={isTreemap ? ChartTypes.TREEMAP : type}
        xAxisOptions={xAxisOptions}
      />
    );

    if (collapsible) {
      content = <Collapse in={mode === 'open'}>{content}</Collapse>;
    }
  }

  return (
    <ChartsProvider hcRef={chartRef}>
      <VisualizationComponent collapsed={mode === 'collapsed'}>
        {header}
        {content}
      </VisualizationComponent>
    </ChartsProvider>
  );
};
