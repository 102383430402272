import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { datasetApi } from '@/shared/lib/api';
import { transformSeriesName } from '../../../components/dataset-details/util';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  DatasetOverview,
  DatasetOverviewResponse,
  DatasetVariableChartData,
  DatasetVariableStatsVisualization,
  DatasetVariableTableData,
  DatasetVariableTableResponse,
  DatasetVariablesLayoutResponse,
  DatasetVariablesListResponse,
  GenericChartSeriesModel1,
  SegmentType,
} from '../../../generated/api';
import { useChartQuery } from '../../charts-query-wrapper';
import { AnalysesResponseSelector, analysesResponseSelector } from '../../util';

export const useDatasetSummaryStats = (datasetId: string, segmentType?: SegmentType) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery<
    AxiosResponse<DatasetOverviewResponse>,
    AxiosError,
    AnalysesResponseSelector<DatasetOverview>
  >(
    ['dataset-summary-stats', datasetId, segmentType],
    () =>
      datasetApi.workspaceGetDatasetSummaryOverviewV1(workspaceId, datasetId, segmentType || ''),
    {
      enabled: !!segmentType && !!datasetId,
      select: analysesResponseSelector,
    },
  );
};

export const useVariablesListQuery = (datasetId: string, segmentType: SegmentType) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<DatasetVariablesListResponse>,
    AxiosError,
    DatasetVariablesListResponse
  >(
    ['ds-variables-list', workspaceId, datasetId, segmentType],
    () => datasetApi.workspaceGetDatasetVariablesV1(workspaceId, datasetId, segmentType),
    {
      enabled: Boolean(datasetId && segmentType),
      select: res => ({
        ...res.data,
        variables: sortBy(res.data.response?.variables ?? [], 'name'),
      }),
    },
  );
};

export const useIsGroupByAvailableQuery = (datasetId: string, segmentType: SegmentType) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    ['use-variables-groupby-status', workspaceId, datasetId, segmentType],
    () => datasetApi.workspaceGetGroupByStatusV1(workspaceId, datasetId, segmentType),
    {
      enabled: Boolean(datasetId && segmentType),
      select: res => res.data.status,
    },
  );
};

export const useInvalidateGroupByStatus = (datasetId: string, segmentType: SegmentType) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries([
      'use-variables-groupby-status',
      workspaceId,
      datasetId,
      segmentType,
    ]);
};

export const useVariableLayoutQuery = (
  datasetId: string,
  segmentType: SegmentType,
  variableName: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<DatasetVariablesLayoutResponse>,
    AxiosError,
    DatasetVariableStatsVisualization[]
  >(
    ['ds-variable-layout', workspaceId, datasetId, segmentType, variableName],
    () =>
      datasetApi.workspaceGetDatasetVariableLayoutV1(
        workspaceId,
        datasetId,
        segmentType,
        variableName,
      ),
    {
      select: res => res.data.response.layout,
      enabled: Boolean(datasetId && segmentType && variableName),
    },
  );
};

export const useVariableTableQuery = (
  datasetId: string,
  segmentType: SegmentType,
  variableName: string,
  visualizationId: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery<
    AxiosResponse<DatasetVariableTableResponse>,
    AxiosError,
    DatasetVariableTableData[]
  >(
    ['ds-variable-table', workspaceId, datasetId, segmentType, variableName, visualizationId],
    () =>
      datasetApi.workspaceGetDatasetVariableTableDataV1(
        workspaceId,
        datasetId,
        segmentType,
        variableName,
        visualizationId,
      ),
    {
      // FIXME:: There is an issue with the query cache as it is overriding the cache for different variables
      // After switching segments multiple times, both the segment data becomes same.
      cacheTime: 0,
      select: data => cloneDeep(data.data.response),
    },
  );
};

export const useGetVariablesChartDataQuery = (
  datasetId: string,
  segmentType: SegmentType,
  variableName: string,
  visualizationId: string,
  groupByTarget?: boolean,
) => {
  const { workspaceId } = useAppMetadata();
  return useChartQuery(
    [
      'get-variables-chart-data',
      datasetId,
      segmentType,
      variableName,
      visualizationId,
      groupByTarget,
    ],
    () =>
      datasetApi.workspaceGetDatasetVariableChartDataV1(
        workspaceId,
        datasetId,
        segmentType,
        variableName,
        visualizationId,
        groupByTarget,
      ),
    {
      select: data => {
        const response = cloneDeep(analysesResponseSelector<DatasetVariableChartData>(data));
        if (!response.isResultAvailable) return response;

        if (response.metadata.preferredChartType === 'pie_chart') {
          const seriesData = get(response, 'data.series[0].data', []) as [string, number][];

          const topLabelsToDisplay = seriesData.slice(0, 8);

          const otherLabels = seriesData.slice(8);

          const othersSum = otherLabels.reduce(
            (sum: number, item: [string, number]) => sum + (item[1] || 0),
            0,
          );

          return {
            ...response,
            data: {
              series: [
                {
                  ...get(response, 'data.series[0]', {}),
                  data: [
                    ...topLabelsToDisplay,
                    ...(otherLabels.length > 0
                      ? [
                          {
                            name: 'Others',
                            y: othersSum,
                            metaData: otherLabels,
                          },
                        ]
                      : []),
                  ],
                },
              ],
            },
          };
        }

        if (response.metadata.preferredChartType === 'word_cloud') {
          return {
            data: {
              xCategories: response.data.xCategories?.map(xCategory =>
                transformSeriesName(xCategory),
              ),
              series: response.data.series?.map((serie: GenericChartSeriesModel1) => ({
                data: serie.data?.map(datum => ({
                  weight: datum[1],
                  x: datum[0],
                })),
              })),
            },
          };
        }

        if (response.metadata.preferredChartType === 'kde_plot') {
          return {
            ...response,
            data: {
              xCategories: response.data.xCategories?.map(xCategory =>
                transformSeriesName(xCategory),
              ),
              series: response.data.series?.map((serie: GenericChartSeriesModel1, idx: number) => ({
                ...serie,
                name: transformSeriesName(serie.name),
                linkedTo: serie.type === 'column' ? ':previous' : '',
                showInLegend: serie.type !== 'column',
              })),
            },
          };
        }

        if (response.metadata.preferredChartType === 'normal_qq_plot') {
          return {
            ...response,
            data: {
              xCategories: response.data.xCategories?.map(xCategory =>
                transformSeriesName(xCategory),
              ),
              series: response.data.series?.map((serie: GenericChartSeriesModel1, idx: number) => ({
                ...serie,
                name: transformSeriesName(serie.name),
                linkedTo: serie.type === 'line' ? ':previous' : '',
                showInLegend: serie.type !== 'line',
              })),
            },
          };
        }

        if (response.metadata.preferredChartType === 'bar_chart') {
          return {
            ...response,
            data: {
              xCategories: response.data.xCategories?.map(xCategory =>
                transformSeriesName(xCategory),
              ),
              series: response.data.series?.map((serie: GenericChartSeriesModel1, idx: number) => ({
                ...serie,
                name: transformSeriesName(serie.name),
              })),
            },
          };
        }

        if (response.metadata.preferredChartType === 'bubble_chart') {
          return {
            data: {
              xCategories: response.data.xCategories?.map(xCategory =>
                transformSeriesName(xCategory),
              ),
              series: response.data.series?.map((serie: GenericChartSeriesModel1) => ({
                data: serie.data?.map(datum => ({
                  y: datum[1],
                  x: datum[0],
                })),
              })),
            },
          };
        }

        // FIXME:: There is an issue with the query cache as it is overriding the cache for different variables
        // After switching segments multiple times, both the segment data becomes same.
        return cloneDeep(response);
      },
    },
  );
};
