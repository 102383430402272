import { Popover } from '@/shared/design-system/v2';
import {
  GetWorkspaceCurrentSubscriptionDetailResponseModel,
  PaymentSubscriptionStatusType,
} from '../../../generated/api';
import {
  calculateTrialDaysRemaining,
  isTrialDaysLessThanThreshold,
} from '../../subscriptions/util';
import { SubscriptionButton } from './SubscriptionButton';
import { SubscriptionDropdown } from './SubscriptionDropdown';
import { UpgradeButton } from './UpgradeButton';

interface SubscriptionProps {
  isLoading?: boolean;
  subscriptionDetail?: GetWorkspaceCurrentSubscriptionDetailResponseModel;
}

export const Subscription = ({ isLoading, subscriptionDetail }: SubscriptionProps) => {
  const isTrialing = subscriptionDetail?.status === PaymentSubscriptionStatusType.Trialing;
  const trialDaysRemaining =
    isTrialing && subscriptionDetail
      ? calculateTrialDaysRemaining(subscriptionDetail.currentPeriodEnd)
      : 0;

  const popoverProps = !isTrialing ? { width: 400 } : {};

  const popoverButton =
    isTrialing && isTrialDaysLessThanThreshold(trialDaysRemaining) ? (
      <UpgradeButton />
    ) : (
      <SubscriptionButton isLoading={isLoading} subscriptionDetail={subscriptionDetail} />
    );

  return (
    <Popover position="bottom-end" {...popoverProps}>
      <Popover.Target>{popoverButton}</Popover.Target>

      <Popover.Dropdown
        p={0}
        maw={500}
        sx={theme => ({
          boxShadow:
            '0px 1px 3px 0px #0000000D, 0px 36px 28px -7px #0000000D, 0px 17px 17px -7px #0000000A',
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: theme.radius.md,
        })}
      >
        {!isLoading && subscriptionDetail && (
          <SubscriptionDropdown subscriptionDetail={subscriptionDetail} isTrialing={isTrialing} />
        )}
      </Popover.Dropdown>
    </Popover>
  );
};
