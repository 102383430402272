import { ControlProps, RankedTester, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { BaseAPIFilter } from '@/main/generated/api';
import { ResourceLookupContainer } from '../../../resource-lookup/ResourceLookup.container';

export enum ResourceLookupType {
  MentionsInput = 'mentions-input',
  MultiSelect = 'multi-select',
  Default = '',
}

export const ResourceLookupComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  schema,
  uischema,
  visible,
  enabled,
  data,
  config,
  errors,
}: ControlProps) => {
  if (!visible) {
    return null;
  }

  const uiSchemaOptions = uischema.options;

  const resourceLookupOptions = uiSchemaOptions?.resourceLookupOptions;
  const dropdownOptions = uiSchemaOptions?.dropdownOptions;
  const shouldGeneratePrompt = uiSchemaOptions?.shouldGeneratePrompt;

  const onChange = (value: string | string[]) => {
    handleChange(path, value);
  };

  const type = uiSchemaOptions?.mentionsInput
    ? ResourceLookupType.MentionsInput
    : schema.type === 'array'
    ? ResourceLookupType.MultiSelect
    : ResourceLookupType.Default;

  const isViewOnlyForm = config.viewOnly;

  const applicableFilters = resourceLookupOptions?.filters;
  if (config.appId) {
    applicableFilters.map((filter: BaseAPIFilter) => {
      if (filter.field === 'workflowId') {
        return {
          ...filter,
          field: 'workflowAppId',
        };
      }
      return filter;
    });
  }

  return (
    <ResourceLookupContainer
      resourceKey={resourceLookupOptions?.resourceKey}
      label={label}
      description={description}
      onChange={onChange}
      required={required}
      type={type}
      value={data}
      {...dropdownOptions}
      allowAddResource={enabled && resourceLookupOptions.allowAddResource}
      filters={applicableFilters}
      configs={config}
      error={config.isFormDirty ? errors : undefined}
      disabled={!isViewOnlyForm && !enabled}
      readOnly={isViewOnlyForm}
      shouldGeneratePrompt={shouldGeneratePrompt}
    />
  );
};

export const resourceLookupControlTester: RankedTester = rankWith(
  6,
  uischema => uischema?.options?.resourceLookup,
);

export const ResourceLookupControl = withJsonFormsControlProps(ResourceLookupComponent);
