import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AppShell, Container, useMarkovTheme } from '@/shared/design-system/v2';
import { AppHeader } from '../../components/app-header/AppHeader';
import { PublicSnippetContainer } from '../../components/snippets/public/PublicSnippet.container';

export const PublicSnippetPage = () => {
  const theme = useMarkovTheme();
  const { snippetId = '' } = useParams();
  const [_, setSearchParams] = useSearchParams();

  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  useEffect(() => {
    setSearchParams({ utm_content: 'publicSnippet' });
  }, []);

  return (
    <AppShell styles={{ main: { minHeight: 'calc(100vh - 52px)' } }} header={<AppHeader />}>
      <Container fluid px={isMobileScreen ? 0 : undefined}>
        <PublicSnippetContainer snippetId={snippetId} />
      </Container>
    </AppShell>
  );
};
