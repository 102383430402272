import { IconPlugConnected } from '@tabler/icons-react';
import { Box, Image } from '@/shared/design-system/v2';

interface OperatorIconProps {
  iconUrl: string;
  size?: number;
  isInactive?: boolean;
}

export const OperatorIcon = ({ iconUrl, size = 32, isInactive = false }: OperatorIconProps) => {
  if (iconUrl.length === 0) {
    return <IconPlugConnected size={size} />;
  }

  return (
    <Box
      sx={{
        opacity: isInactive ? 0.5 : 1,
      }}
    >
      <Image src={iconUrl} width={size} height={size} />
    </Box>
  );
};
