import { YAxisPlotBandsOptions } from 'highcharts';
import first from 'lodash/first';
import last from 'lodash/last';
import { useMemo } from 'react';
import { Info } from '@/shared/design-system';
import {
  ActionIcon,
  Horizontal,
  RingProgress,
  SimpleGrid,
  Text,
  Tooltip,
  Vertical,
} from '@/shared/design-system/v2';
import { ChartTypes } from '../../../../charts';
import { chartColors } from '../../../../charts/config/colors';
import {
  BaselineMetricsForClassification,
  BaselineMetricsForClassificationModelTypeEnum,
  BaselineMetricsForRegression,
  BaselineMetricsForRegressionModelTypeEnum,
} from '../../../../generated/api';
import { VisualizationComponent } from '../../../analyser/visualization/Visualization';

const BASELINE_MODEL_DESCRIPTION =
  'Auto-ML model trained on the best ML algorithm and corresponding hyperparameters on this dataset.';

interface BaselineModelStatsProps {
  baselineModelMetrics: BaselineMetricsForClassification | BaselineMetricsForRegression;
}

interface BaselineMetricsRingProgressProps {
  metricName: string;
  metricValue: number;
}

const scoreToPercentage = (score: number) => parseFloat((score * 100).toFixed(1));

export const BaselineMetricsRingProgress = ({
  metricName,
  metricValue,
}: BaselineMetricsRingProgressProps): JSX.Element =>
  metricValue === -1 ? (
    <></>
  ) : (
    <Vertical align="center" spacing="xs" pb="xl">
      <RingProgress
        sections={[{ value: scoreToPercentage(metricValue), color: 'blue.4' }]}
        label={
          <Text variant="subTitle03" ta="center" color="blue.4">
            {scoreToPercentage(metricValue)}%
          </Text>
        }
      />
      <Text variant="overline" tt="uppercase" color="gray.6">
        {metricName}
      </Text>
    </Vertical>
  );

interface BaselineRegressionMetricsProps {
  metricName: string;
  metricValue: number;
}

const plotBands: YAxisPlotBandsOptions[] = [
  {
    from: 0,
    to: 50,
    color: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, chartColors[ChartTypes.GAUGE].yellow],
        [1, chartColors[ChartTypes.GAUGE].red],
      ],
    },
  },
  {
    from: 50,
    to: 100,
    color: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, chartColors[ChartTypes.GAUGE].yellow],

        [1, chartColors[ChartTypes.GAUGE].green],
      ],
    },
  },
];

const BaselineRegressionMetrics = ({ metricName, metricValue }: BaselineRegressionMetricsProps) => {
  const chartData = useMemo(
    () => ({
      series: [{ data: [{ y: metricValue }] }],
    }),
    [metricValue],
  );

  return (
    <Vertical align="center" spacing="xs" pb="xl">
      <VisualizationComponent.Chart
        type={ChartTypes.GAUGE}
        data={chartData}
        yAxisOptions={{
          plotBands,
        }}
      />
      <Text variant="overline" tt="uppercase" color="gray.6">
        {metricName}
      </Text>
    </Vertical>
  );
};

export const BaselineModelStats = ({
  baselineModelMetrics,
}: BaselineModelStatsProps): JSX.Element => {
  let content = null;
  if (
    baselineModelMetrics.modelType === BaselineMetricsForClassificationModelTypeEnum.Classification
  ) {
    content = (
      <SimpleGrid cols={2} spacing="xl">
        {Object.entries(baselineModelMetrics).map((entry, idx) => (
          <BaselineMetricsRingProgress
            key={idx}
            metricName={first(entry)}
            metricValue={last(entry)}
          />
        ))}
      </SimpleGrid>
    );
  } else if (
    baselineModelMetrics.modelType === BaselineMetricsForRegressionModelTypeEnum.Regression &&
    baselineModelMetrics.r2
  ) {
    content = (
      <BaselineRegressionMetrics metricName="R2" metricValue={baselineModelMetrics.r2 * 100} />
    );
  }

  return (
    <VisualizationComponent
      cardProps={{
        shadow: '',
        p: '24px',
      }}
    >
      <Horizontal spacing="xs" pb="lg">
        <VisualizationComponent.Header.Title
          title="Baseline model"
          titleProps={{ variant: 'subTitle03', color: 'gray.8' }}
        />
        <Tooltip label={BASELINE_MODEL_DESCRIPTION} withArrow withinPortal multiline maw={325}>
          <ActionIcon variant="transparent">
            <Info />
          </ActionIcon>
        </Tooltip>
      </Horizontal>
      {content}
    </VisualizationComponent>
  );
};
