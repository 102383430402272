import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { ActionIcon, Horizontal, Text, Vertical } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { WorkspaceInvitationDetails } from '../../../../generated/api';
import { UserInvite } from './UserInvite';

interface InvitationProps {
  invitations: WorkspaceInvitationDetails[];
  onAcceptInvitation: (inviteId: string) => void;
}

export const Invitation = ({ invitations, onAcceptInvitation }: InvitationProps): JSX.Element => {
  const [invitationIndex, setInvitationIndex] = useState(0);

  useEffect(() => {
    // Resetting invitationIndex to 0 if last invitation was clicked
    if (invitations.length === invitationIndex) {
      setInvitationIndex(0);
    }
  }, [invitations]);

  if (invitations.length < invitationIndex) {
    logger.error('Invitation index is greater than invitation length');
    return <></>;
  }

  const { invitation, invitedByUserDetails, workspaceWithAccountDetails } =
    invitations[invitationIndex];

  const handleLeftChevronClick = () => {
    const isFirstElement = invitationIndex === 0;
    const newInvitationIndex = isFirstElement ? invitations.length - 1 : invitationIndex - 1;
    setInvitationIndex(newInvitationIndex);
  };

  const handleRightChevronClick = () => {
    const isLastElement = invitationIndex + 1 === invitations.length;
    const newInvitationIndex = isLastElement ? 0 : invitationIndex + 1;
    setInvitationIndex(newInvitationIndex);
  };

  const handleInvitationAccept = () => {
    onAcceptInvitation(invitation.inviteId);
  };

  return (
    <Vertical spacing="lg">
      <UserInvite
        invitingUser={invitedByUserDetails}
        workspace={workspaceWithAccountDetails}
        onAccept={handleInvitationAccept}
      />

      {invitations.length > 1 && (
        <Horizontal position="center" spacing="xs">
          <ActionIcon size="xs" onClick={handleLeftChevronClick}>
            <IconArrowLeft />
          </ActionIcon>
          <Text variant="small02" mx="2px">
            {invitationIndex + 1}/{invitations.length}
          </Text>
          <ActionIcon size="xs" onClick={handleRightChevronClick}>
            <IconArrowRight />
          </ActionIcon>
        </Horizontal>
      )}
    </Vertical>
  );
};
