import axios, { InternalAxiosRequestConfig } from 'axios';
import { ulid } from 'ulid';
import { Configuration } from '@/main/generated/api';
import { captureConfigChecksum } from '@/shared/initializers/checksum';
import { logger } from '@/shared/initializers/logging';
import { isDevelopment } from '../env.util';

export interface HTTPError {
  detail?: string;
}

export const axiosS3 = axios.create();

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_PYPI_BASE,
});

export const cliDocsUrl = `${import.meta.env.VITE_APP_PYPI_SERVER}/docs/`;

export const apiConfiguration = new Configuration({
  basePath: import.meta.env.VITE_APP_API_BASE,
});

export const registerAxiosInterceptor = (getTokenFn: () => Promise<string>, userId: string) => {
  const addAuthToken = async (config: InternalAxiosRequestConfig<unknown>) => {
    const token = await getTokenFn();

    if (!token) {
      logger.error(`Token not found for userId: ${userId}`);
    }

    config.headers.set('Authorization', `Bearer ${token}`);
    config.headers.set('member-id', userId);

    return config;
  };

  const addXCorrelationId = async (config: InternalAxiosRequestConfig<unknown>) => {
    const correlation_id = ulid();
    config.headers.set('X-Correlation-ID', correlation_id);

    return config;
  };

  const markovInterceptor = async (config: InternalAxiosRequestConfig<unknown>) => {
    if (isDevelopment()) {
      captureConfigChecksum(config);
    }

    config = await addAuthToken(config);
    config = await addXCorrelationId(config);

    return config;
  };

  const apiClientInterceptor = axios.interceptors.request.use(markovInterceptor);

  return () => {
    axios.interceptors.request.eject(apiClientInterceptor);
  };
};
