import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { subsetAnalysesRoutesApi } from '@/shared/lib/api';
import { HTTPError } from '@/shared/lib/api/api';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  CreateSubsetRequest,
  CreateSubsetResponse,
  GetSubsetDetailsResponse,
  Subset,
} from '../../../generated/api';

export const subsetKeys = {
  all: ['subset'] as const,
  list: (workspaceId: string, datasetId: string) => [
    ...subsetKeys.all,
    'default',
    workspaceId,
    datasetId,
  ],
  detail: (workspaceId: string, datasetId: string, subsetId: string) =>
    [...subsetKeys.all, 'detail', workspaceId, datasetId, subsetId] as const,
};

export const useDataSubsetsListQuery = (datasetId: string, enabled = true) => {
  const { workspaceId } = useAppMetadata();

  // TODO: Use infinite query
  return useQuery(
    subsetKeys.list(workspaceId, datasetId),
    () => subsetAnalysesRoutesApi.getDataSubsetsV1(workspaceId, datasetId, 0, 100),
    {
      enabled: Boolean(workspaceId && datasetId) && enabled,
      select: resp => resp.data.response,
    },
  );
};

export const useDataSubsetDetailsQuery = (datasetId: string, subsetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetSubsetDetailsResponse>, AxiosError, Subset>(
    subsetKeys.detail(workspaceId, datasetId, subsetId),
    () => subsetAnalysesRoutesApi.getDataSubsetDetailV1(workspaceId, datasetId, subsetId),
    {
      enabled: Boolean(workspaceId && datasetId && subsetId),
      select: data => data.data.response,
    },
  );
};

export const useCreateDataSubsetMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<CreateSubsetResponse>,
    AxiosError<HTTPError>,
    Omit<CreateSubsetRequest, 'datasetId'>
  >(
    ({ name, description, datasetRecords, regions }) =>
      subsetAnalysesRoutesApi.createDataSubsetV1(workspaceId, datasetId, {
        datasetId,
        name,
        description,
        datasetRecords,
        regions,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(subsetKeys.list(workspaceId, datasetId));
      },
    },
  );
};
