import {
  ControlProps,
  RankedTester,
  and,
  hasType,
  optionIs,
  rankWith,
  schemaMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Text, Vertical } from '@/shared/design-system/v2';
import { FetchTextFromApiContainer } from '../../../resource-lookup/FetchTextFromApi.container';

const FetchTextFromApiControlComponent = ({
  label,
  description,
  schema,
  handleChange,
  path,
  required,
  uischema,
  data,
  visible,
  enabled,
  errors,
  config,
}: ControlProps) => {
  const { workflowId } = config;

  if (!visible) {
    return null;
  }

  const onChange = (value: string) => {
    handleChange(path, value);
  };

  return (
    <Vertical spacing={0}>
      <Text span variant="subTitle02">
        {label}
      </Text>
      <Text span variant="small02" color="gray.7" mb="sm">
        {description}
      </Text>
      <FetchTextFromApiContainer workflowId={workflowId} onChange={onChange} />
    </Vertical>
  );
};

export const fetchTextFromApiControlTester: RankedTester = rankWith(
  4,
  and(
    uiTypeIs('Control'),
    optionIs('fetch', true),
    schemaMatches(schema => hasType(schema, 'string')),
  ),
);

export const FetchTextFromApiControl = withJsonFormsControlProps(FetchTextFromApiControlComponent);
