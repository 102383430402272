import { closeAllModals, openModal } from '@mantine/modals';
import { useExportAsJsonMutation } from '@/main/queries/workflows/operators';
import { Button, Horizontal, Text, Vertical, notifications } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';

export interface ExportWorkflowInputModalProps {
  workflowId: string;
}
const HEADING = 'Export as template';
const SUBHEADING = `You can save the workflow as template, including the source file, operator configurations, and sink destination.`;

const ExportWorkflowInputModal = ({ workflowId }: ExportWorkflowInputModalProps): JSX.Element => {
  const { mutateAsync: exportWorkflow, isLoading: isExporting } = useExportAsJsonMutation();

  const handleConfirmExporting = async () => {
    try {
      await exportWorkflow({ workflowIds: [workflowId] });
      notifications.success('Exported selected workflow');
      closeAllModals();
    } catch (error) {
      notifications.error('Unable to export selected workflow');
      logger.error(error);
    }
  };
  const handleCancel = () => {
    closeAllModals();
  };

  return (
    <Vertical spacing="sm">
      <Text variant="bodyShort02">{SUBHEADING}</Text>
      <Horizontal w="100%" position="right" pt="xl">
        <Button onClick={handleCancel} variant="light" color="gray.7" bg="gray.1">
          Cancel
        </Button>
        <Button
          onClick={handleConfirmExporting}
          loading={isExporting}
          variant="primary"
          color="blue"
        >
          Export
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export const useWorkflowExport = (workflowId: string) => {
  const openExportModal = () =>
    openModal({
      title: <Text variant="heading03">{HEADING}</Text>,
      children: <ExportWorkflowInputModal workflowId={workflowId} />,
    });

  return { openExportModal };
};
