import { Alert, Table } from '@mantine/core';
import times from 'lodash/times';
import { ScrollArea, Text, openModal, useMarkovTheme } from '@/shared/design-system/v2';
import { ModelArtifactColumnSchemaResponse } from '../../../../generated/api';

interface ViewFeaturesModalProps {
  inputText: string;
  modelInputSchema?: ModelArtifactColumnSchemaResponse[];
  prediction?: string;
}

const ViewFeaturesModal = ({ modelInputSchema, inputText, prediction }: ViewFeaturesModalProps) => {
  const theme = useMarkovTheme();
  const features = modelInputSchema?.map(({ featureName }) => featureName) ?? [];

  // Refer for regex: https://stackoverflow.com/a/53774647
  // Here, we treat the entire input as a single entity (disregarding any commas),
  // when the number of features is one.
  const inputValues =
    features.length === 1 ? [inputText] : inputText.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/) ?? [];

  const invalidInput = features.length !== inputValues.length;

  return (
    <>
      {!invalidInput && prediction !== undefined && (
        <Alert color="green">
          <Text variant="bodyShort02">
            Response: <strong>{prediction}</strong>
          </Text>
        </Alert>
      )}

      {invalidInput && (
        <Alert color="red" my="md">
          The input does not conform to the schema, please review it.
        </Alert>
      )}

      <ScrollArea h={428} p="lg" scrollbarSize={8}>
        <Table
          verticalSpacing="md"
          horizontalSpacing="md"
          highlightOnHover
          withBorder
          withColumnBorders
        >
          <thead>
            <tr>
              <th>
                <Text variant="subTitle02" color="gray.8" align="center">
                  Feature Name
                </Text>
              </th>
              <th>
                <Text variant="subTitle02" color="gray.8" align="center">
                  User Input
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {times(Math.max(features.length, inputValues.length)).map(idx => (
              <tr
                key={idx}
                style={
                  !inputValues[idx] || !features[idx] ? { background: theme.colors.red[0] } : {}
                }
              >
                <td>
                  <Text
                    variant="bodyShort02"
                    color={Boolean(inputValues[idx]) ? 'gray.8' : 'red.6'}
                    align="center"
                  >
                    {features[idx]}
                  </Text>
                </td>
                <td>
                  <Text
                    variant="bodyShort02"
                    color={Boolean(features[idx]) ? 'gray.8' : 'red.6'}
                    align="center"
                  >
                    {inputValues[idx]}
                  </Text>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>
    </>
  );
};

export const useViewFeaturesModal = ({
  modelInputSchema,
}: Omit<ViewFeaturesModalProps, 'inputText'>) => {
  const open = (inputText: ViewFeaturesModalProps['inputText'], prediction?: string) =>
    openModal({
      size: '80vw',
      title: <Text variant="subTitle01">Input Features</Text>,
      children: (
        <ViewFeaturesModal
          modelInputSchema={modelInputSchema}
          inputText={inputText}
          prediction={prediction}
        />
      ),
    });

  return {
    open,
  };
};
