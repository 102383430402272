import { useFlags } from 'launchdarkly-react-client-sdk';
import castArray from 'lodash/castArray';
import compact from 'lodash/compact';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { SuggestionDataItem } from 'react-mentions';
import { Box, Button, Flex, SelectProps, Text, useDisclosure } from '@/shared/design-system/v2';
import {
  AsyncDropDownResources,
  AsyncResourcePair,
  BaseAPIFilter,
  Operator,
  StorageType,
} from '../../../generated/api';
import {
  useInvalidateResourceLookup,
  useResourceLookupQuery,
} from '../../../queries/resource-lookup';
import { useGetWorkflowDagNodesSchemaV2Query } from '../../../queries/workflows/operators';
import { ResourceLookupType } from '../form/renderers/controls/ResourceLookupControl';
import { AddResourceModal } from './AddResourceModal';
import { FeatureColumnSelectionDropdown } from './FeatureColumnSelectionDropdown';
import { FeatureColumnsMentionsInput } from './FeatureColumnsMentionsInput';
import { ResourceSelect } from './resource-selection/ResourceSelect';

interface NodeFilterParams {
  resourceKey: AsyncDropDownResources;
  configs: any;
  value: string | string[] | undefined;
}

const transformOptionsToSuggestions = (options: AsyncResourcePair[]): SuggestionDataItem[] =>
  options.map(option => ({
    id: option.value,
    display: option.label,
  }));

const getNodeFilters = ({ resourceKey, configs, value }: NodeFilterParams): BaseAPIFilter[] => {
  const isWorkflowNodeColumns = resourceKey === AsyncDropDownResources.WorkflowNodeColumns;
  // In feature columns and target columns we require node ID and workflow ID to be passed instead of ResourceId

  if (!isWorkflowNodeColumns) {
    return [
      {
        field: 'resourceId',
        operator: Operator.Eq,
        value,
      },
    ];
  }

  const nodeFilters = [
    {
      field: 'workflowId',
      operator: Operator.Eq,
      value: configs.workflowId,
    },
    {
      field: 'nodeId',
      operator: Operator.Eq,
      value: configs.nodeId,
    },
  ];

  if (configs.appId) {
    nodeFilters.push({
      field: 'workflowAppId',
      operator: Operator.Eq,
      value: configs.appId,
    });
  }

  return nodeFilters;
};

interface ResourceLookupContainerProps
  extends Omit<SelectProps, 'options' | 'ariaLabel' | 'value' | 'onChange' | 'disabled'> {
  resourceKey: AsyncDropDownResources;
  label?: string;
  description?: string;
  placeholder?: string;
  allowAddResource?: boolean;
  value: string | string[] | undefined;
  type: ResourceLookupType;
  onChange: (value: string | string[]) => void;
  disabled: boolean;
  filters?: BaseAPIFilter[];
  configs: any;
  shouldGeneratePrompt?: boolean;
}

export const ResourceLookupContainer = ({
  resourceKey,
  label,
  description,
  placeholder,
  allowAddResource,
  type,
  onChange,
  filters = [],
  value,
  configs,
  error,
  shouldGeneratePrompt,
  ...restProps
}: ResourceLookupContainerProps) => {
  const [fieldError, setFieldError] = useState(error);
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();
  const { featureDagNodeSchemaV2 } = useFlags();

  const nodeFilters = getNodeFilters({
    resourceKey,
    configs,
    // Whenever the user selects a value in dropdown, this call triggers again to get
    // value option which is already present. But intermediate call create data undefined
    // because of which there is time duration where selection shows blank. This check fixes
    // it temporarily as value call is needed only for the case when form is readonly.
    // TODO:: Fix with value lookup call
    value: configs.viewOnly ? value : undefined,
  });
  const {
    data: resourcesData,
    isLoading,
    isFetching,
  } = useResourceLookupQuery(
    resourceKey,
    filters.length === 0 ? nodeFilters : filters,
    featureDagNodeSchemaV2,
  );

  const {
    isLoading: loadingNodeSchema,
    isError: errorNodeSchema,
    data: nodeSchema,
  } = useGetWorkflowDagNodesSchemaV2Query(configs.workflowId, configs.nodeId);
  const invalidateResource = useInvalidateResourceLookup(resourceKey);

  const resources = resourcesData ?? [];

  const selectOnChange = (value: string) => {
    onChange(value);
  };

  const multiSelectOnChange = (value: string[]) => {
    onChange(value);
  };

  useEffect(() => {
    if (resources.length > 0) {
      const invalidValues = compact(castArray(value))?.filter(
        selectedValue => !resources.some(option => option.value === selectedValue),
      );

      if (invalidValues.length > 0) {
        const invalidList = invalidValues.join(', ');
        const isOrAre = invalidValues.length === 1 ? 'is' : 'are';
        const invalidMessage = `${invalidList} ${isOrAre} not valid option${
          invalidValues.length > 1 ? 's' : ''
        }.`;

        setFieldError(invalidMessage);
      } else {
        setFieldError(undefined);
      }
    }
  }, [value, resources]);

  if (type === ResourceLookupType.MultiSelect) {
    return (
      <FeatureColumnSelectionDropdown
        value={value as string[]}
        multiSelectOnChange={multiSelectOnChange}
        disabled={restProps.disabled}
        isLoading={isLoading || isFetching}
        readOnly={restProps.readOnly}
        options={resources}
        label={label}
        description={description}
        placeholder={placeholder}
        resourceKey={resourceKey}
        required={restProps.required}
        error={fieldError}
      />
    );
  }

  if (type === ResourceLookupType.MentionsInput) {
    return (
      <FeatureColumnsMentionsInput
        value={value as string}
        onChange={selectOnChange}
        disabled={restProps.disabled}
        isLoading={isLoading || isFetching}
        readOnly={restProps.readOnly}
        suggestions={transformOptionsToSuggestions(resources)}
        label={label}
        description={description}
        placeholder={placeholder}
        required={restProps.required}
        shouldGeneratePrompt={shouldGeneratePrompt}
      />
    );
  }

  const handleResourceSelect = async (resourceId: string) => {
    await invalidateResource();
    if (onChange) {
      onChange(resourceId);
    }
  };

  const connectorType = filters.find(filter => filter.field === 'connectorType')?.value as
    | StorageType
    | undefined;

  const errorWithCodeForNode = get(nodeSchema, `nodeSchemas.${configs.nodeId}.errorsWithCode`);
  const showErrors = !configs.isFormDirty && !loadingNodeSchema;

  return (
    <Box>
      <AddResourceModal
        opened={modalOpened}
        resourceType={resourceKey}
        connectorType={connectorType}
        onSelectResource={handleResourceSelect}
        onClose={closeModal}
      />
      <Flex align="end" gap="xs">
        <ResourceSelect
          resourceKey={resourceKey}
          resources={isFetching ? [] : resources}
          ariaLabel={label ?? resourceKey}
          label={
            <Text span variant="subTitle02">
              {label}
            </Text>
          }
          description={
            <Text span variant="small02" color="gray.7" pb="sm">
              {description}
            </Text>
          }
          placeholder={placeholder}
          loading={isLoading || isFetching}
          onChange={selectOnChange}
          value={value as string}
          error={fieldError}
          errorWithCode={showErrors ? errorWithCodeForNode : undefined}
          {...restProps}
        />
        {allowAddResource ? <Button onClick={openModal}>+ Add new</Button> : null}
      </Flex>
    </Box>
  );
};
