import {
  Accordion,
  Card,
  Center,
  Flex,
  Text,
  UnstyledButton,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { MARKOVML_DOCS } from '../../../developer-docs/links';
import { useHelpCategoryCardAccordionStyles } from './HelpCategoryCardAccordion.style';

interface HelpCategoryCardProps {
  title: string;
  icon: JSX.Element;
  primaryLinkText: string;
  primaryLinkId: string;
  expandableSection?: { id: string; label: string }[];
}

export const HelpCategoryCard = ({
  title,
  icon,
  primaryLinkText,
  primaryLinkId,
  expandableSection,
}: HelpCategoryCardProps) => {
  const theme = useMarkovTheme();
  const { classes } = useHelpCategoryCardAccordionStyles();

  const handlePrimaryButtonClick = () => {
    window.open(`${MARKOVML_DOCS}/docs/${primaryLinkId}`);
  };

  const handleAccordionButtonClick = (id: string) => {
    window.open(`${MARKOVML_DOCS}/docs/${id}`);
  };

  return (
    <Card
      hoverEffect={false}
      radius="sm"
      sx={{ border: `1px solid ${theme.colors.gray[2]}` }}
      p="md"
    >
      <Flex gap="md">
        <Center h={32} w={32} bg="gray.0" sx={{ svg: { color: theme.colors.gray[6] } }}>
          {icon}
        </Center>
        <Vertical spacing="md">
          <Text variant="subTitle04" color="gray.9">
            {title}
          </Text>
          <UnstyledButton onClick={handlePrimaryButtonClick}>
            <Text variant="bodyShort03" color="gray.7">
              {primaryLinkText}
            </Text>
          </UnstyledButton>
          {expandableSection && (
            <Accordion chevronPosition="left" classNames={classes} className={classes.root}>
              <Accordion.Item value="how-tos">
                <Accordion.Control>
                  <Text variant="bodyShort03" color="gray.7">
                    How-To&apos;s
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Vertical spacing="lg" py="xs">
                    {expandableSection.map(section => (
                      <UnstyledButton
                        key={section.id}
                        onClick={() => handleAccordionButtonClick(section.id)}
                      >
                        <Text variant="textLink" color="blue.6">
                          {section.label}
                        </Text>
                      </UnstyledButton>
                    ))}
                  </Vertical>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          )}
        </Vertical>
      </Flex>
    </Card>
  );
};
