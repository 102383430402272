import { Horizontal, Skeleton, Vertical } from '@/shared/design-system/v2';

export const ChipGroupLoading = () => (
  <Vertical>
    <Horizontal>
      <Skeleton width={50} height={24} />
      <Skeleton width={120} height={24} />
      <Skeleton width={78} height={24} />
    </Horizontal>
    <Horizontal>
      <Skeleton width={78} height={24} />
      <Skeleton width={50} height={24} />
      <Skeleton width={120} height={24} />
    </Horizontal>
    <Horizontal>
      <Skeleton width={50} height={24} />
      <Skeleton width={120} height={24} />
      <Skeleton width={78} height={24} />
    </Horizontal>
  </Vertical>
);
