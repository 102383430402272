import { Kbd } from '@mantine/core';
import { IconFileUpload, IconPencilMinus, IconSend } from '@tabler/icons-react';
import { useState } from 'react';
import {
  ActionIcon,
  Box,
  Card,
  Horizontal,
  TextArea,
  Tooltip,
  openModal,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { ModelWithAppMetadata } from '../../../../generated/api';
import { ModelFileInputModalContainer } from './ModelFileInput/ModelFileInputModal.container';
import { ModelSamples } from './ModelSamples';

interface ModelInputPromptProps {
  submitInput: (inputs: string) => void;
  modelAppDetails: ModelWithAppMetadata;
  textBoxSize?: 'lg' | 'md';
  compact?: boolean;
  hideFileInput?: boolean;
  showSamples?: boolean;
}

export const ModelInputPrompt = ({
  modelAppDetails,
  submitInput,
  textBoxSize = 'md',
  compact = false,
  hideFileInput = false,
  showSamples = true,
}: ModelInputPromptProps) => {
  const [input, setInput] = useState('');
  const theme = useMarkovTheme();

  const {
    modelInputSchema = [],
    modelInputSamples = [],
    datasetId = '',
    modelId,
    isBaselineModel,
  } = modelAppDetails ?? {};

  const isInputValid = Boolean(input.trim());
  const featuresOrder = modelInputSchema
    .slice(0, 6)
    .map(({ featureName }) => featureName)
    .join(', ');

  const onSampleSelect = (text: string) => {
    setInput(text);
  };

  const handleSubmitInput = () => {
    if (!isInputValid) {
      return;
    }
    submitInput(input);
    setInput('');
  };

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.metaKey && e.code === 'Enter') {
      handleSubmitInput();
    }
  };

  const handleSubmitFileInput = () => {
    openModal({
      children: <ModelFileInputModalContainer datasetId={datasetId} modelId={modelId} />,
      size: 'xxl',
      shadow: 'xxl',
      withCloseButton: false,
    });
  };

  return (
    <>
      {showSamples && (
        <ModelSamples
          modelInputSchema={modelInputSchema}
          samples={modelInputSamples}
          onSampleSelect={onSampleSelect}
        />
      )}
      <Card withBorder my={compact ? 0 : 'xxl'} shadow={compact ? undefined : 'md'}>
        <Horizontal noWrap align="flex-start">
          <Box my="xs">
            <IconPencilMinus size={22} color={theme.colors.gray[5]} />
          </Box>
          <TextArea
            value={input}
            placeholder={`Enter comma separated input ( ${featuresOrder} )`}
            onChange={text => setInput(text)}
            onKeyDown={handleInputKeyDown}
            minRows={textBoxSize === 'lg' ? 6 : 3}
            maxRows={textBoxSize === 'lg' ? 6 : 3}
            size="md"
            variant="unstyled"
            ariaLabel=""
            w="100%"
          />
          <Horizontal noWrap spacing="xs">
            {!hideFileInput && isBaselineModel && (
              <Tooltip label="File Input">
                <ActionIcon disabled={!datasetId} p="xs" my="xs" onClick={handleSubmitFileInput}>
                  <IconFileUpload color={theme.colors.gray[6]} />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip
              openDelay={200}
              label={
                <>
                  <Kbd size="sm">⌘</Kbd> + <Kbd size="xs">return</Kbd>
                </>
              }
            >
              <ActionIcon p="xs" my="xs" onClick={handleSubmitInput}>
                <IconSend
                  size={24}
                  color={!isInputValid ? theme.colors.gray[5] : theme.colors.blue[6]}
                />
              </ActionIcon>
            </Tooltip>
          </Horizontal>
        </Horizontal>
      </Card>
    </>
  );
};
