import { SelectOption } from '@/shared/design-system/v2';
import { SegmentType } from '../../generated/api';
import { getSegmentText } from '../analyser/analyser.util';

export enum DatasetRegistrationStep {
  IMPORT_DATASET,
  CONFIRM_DELIMITERS,
  APPLY_PREDICTORS,
}

// TODO: Remove deprecated DatasetRegistrationStep once changes on prod
export enum DatasetRegistrationStepV2 {
  IMPORT_DATASET = 'IMPORT_DATASET',
  CONFIRM_COLUMNS = 'CONFIRM_COLUMNS',
  SELECT_ANALYSIS = 'SELECT_ANALYSIS',
  REGISTRATION_COMPLETE = 'REGISTRATION_COMPLETE',
}

export enum NavigationTabState {
  ACTIVE,
  INACTIVE,
  FILLED,
}

export const UNKNOWN_SEGMENT = {
  value: SegmentType.Unknown,
  label: getSegmentText(SegmentType.Unknown),
};

export const UNKNOWN_SEGMENT_DATA = {
  segmentType: { ...UNKNOWN_SEGMENT },
  url: '',
};

export const getSegmentTypeListOptions = (addUnknown: boolean): SelectOption[] => {
  const segments = [
    { value: SegmentType.Train, label: 'Train' },
    { value: SegmentType.Test, label: 'Test' },
    { value: SegmentType.Validate, label: 'Validate' },
  ];

  if (addUnknown) {
    return [UNKNOWN_SEGMENT, ...segments];
  }

  return segments;
};

export const getOptionFromSegment = (segmentValue: SegmentType) =>
  getSegmentTypeListOptions(true).find(segment => segment.value === segmentValue);

export const DEFAULT_CLOUD_DATASET_ORIGIN = 'Cloud Uploaded';

export const DEFAULT_MANUAL_DATASET_ORIGIN = 'User Uploaded';

export const MARKOV_UNLABELLED_IDENTIFIER = '__markov_unlabelled__';
