import { VizErrorState } from '@/shared/design-system';
import { Center } from '@/shared/design-system/v2';
import { EmptyState, EmptyStateProps } from './EmptyState';

type ErrorStateProps = Partial<EmptyStateProps>;

export const ErrorState = ({
  icon = <VizErrorState />,
  title = 'An error was encountered',
  iconName = '',
  ...restProps
}: ErrorStateProps) => (
  <EmptyState
    type="error"
    title={title}
    icon={iconName ? undefined : icon}
    iconName={iconName}
    {...restProps}
  />
);

export const PageErrorScreen = (props: ErrorStateProps) => (
  <Center bg="white.0" py="64px">
    <ErrorState {...props} />
  </Center>
);
