import { Box } from '@/shared/design-system/v2';
import { SettingsSection } from '../SettingsSection';
import { TokenContainer } from './Token.container';

export const TokenWrapper = () => (
  <SettingsSection title="Tokens">
    <Box my={24}>
      <TokenContainer />
    </Box>
  </SettingsSection>
);
