import { IconInfoHexagonFilled } from '@tabler/icons-react';
import {
  ActionIcon,
  Alert,
  Box,
  Center,
  Text,
  Tooltip,
  openModal,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { MetadataHistogram } from '../../../metadata/MetadataBox';
import { ModelAppOutput } from '../use-model-app';

interface ModelExplainabilityProps extends Pick<ModelAppOutput, 'explain'> {
  prediction?: string;
  userInput?: string;
  disabled?: boolean;
}

const FEAT_COLOR_NEGATIVE = '#C4A8CC';

const ExplainabilityModal = ({ explain = [], prediction, userInput }: ModelExplainabilityProps) => {
  const theme = useMarkovTheme();
  const sortedDetails = explain.sort((a, b) => a[1] - b[1]);
  const chartData = {
    series: [
      {
        name: 'SHAP Value',
        data: sortedDetails.map(value => [value[1]]),
        borderRadius: theme.radius.xs,
        negativeColor: FEAT_COLOR_NEGATIVE,
      },
    ],
    categories: sortedDetails.map(value => value[0]),
  };
  return (
    <Box>
      {!!userInput && (
        <Alert color="gray" my="md">
          <Text variant="bodyShort03">
            Input: <strong>{userInput}</strong>
          </Text>
        </Alert>
      )}

      {!!prediction && (
        <Alert color="green" my="md">
          <Text variant="bodyShort03">
            Response: <strong>{prediction}</strong>
          </Text>
        </Alert>
      )}

      <MetadataHistogram
        title="Feature Importance"
        chartData={chartData}
        tooltipLabel="Feature Importance assign an importance value to each feature in a model. Features with positive values positively impact the prediction, while those with negative values have a negative impact. The magnitude is a measure of how strong the effect is."
      />
    </Box>
  );
};

export const ModelExplainability = ({
  explain,
  prediction,
  userInput,
  disabled = false,
}: ModelExplainabilityProps) => {
  const openExplainabilityModal = () => {
    openModal({
      title: <Text variant="subTitle01">Explainability</Text>,
      children: (
        <ExplainabilityModal explain={explain} prediction={prediction} userInput={userInput} />
      ),
      size: 'xl',
    });
  };

  return (
    <Center w="100%">
      <Tooltip withinPortal label={disabled ? 'Explainability not available' : 'Explain'}>
        <ActionIcon disabled={disabled} color="gray.5" onClick={openExplainabilityModal}>
          <IconInfoHexagonFilled size={16} />
        </ActionIcon>
      </Tooltip>
    </Center>
  );
};
