import { ICellRendererParams } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { Workflow } from '../../../../generated/api';
import { AdditionalActionsContainer } from '../../detail-v2/header/actions/AdditionalActions.container';

export const WorkflowActionsRenderer = ({ data }: ICellRendererParams<Workflow>) => {
  if (!data?.workflowId) {
    return null;
  }

  return <AdditionalActionsContainer workflowId={data.workflowId} />;
};
