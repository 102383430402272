import { HotelIcon } from '@/shared/design-system';
import { Box, Horizontal, Text } from '@/shared/design-system/v2';
import { WorkspaceMember } from '../../../generated/api';
import { useWorkspaceSwitchItem } from './WorkspaceSwitcher.style';

interface WorkspaceItemProps {
  workspaceName: string;
  enterpriseAccountName?: string;
  ownerUser?: WorkspaceMember;
  onClick: () => void;
  currentWorkspace?: boolean;
}

export const WorkspaceItem = ({
  workspaceName,
  enterpriseAccountName = '',
  ownerUser,
  currentWorkspace = false,
  onClick,
}: WorkspaceItemProps): JSX.Element => {
  const { classes } = useWorkspaceSwitchItem();
  return (
    <Horizontal className={classes.container} onClick={onClick}>
      <HotelIcon width={24} height={24} />
      <Box mx="12px">
        <Text variant="subTitle03" color="8">
          {workspaceName}
        </Text>
        {ownerUser?.userDetails?.email && (
          <Text variant="small02" color="dark.2">
            Admin: {ownerUser?.userDetails?.email}
          </Text>
        )}
      </Box>
      {enterpriseAccountName && (
        <Text variant="subTitle03" color="3">
          @ {enterpriseAccountName}
        </Text>
      )}
      {currentWorkspace && (
        <Box className={classes.viewingContainer}>
          <Text variant="small02" color="pink.8">
            Viewing
          </Text>
        </Box>
      )}
    </Horizontal>
  );
};
