import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Horizontal,
  Image,
  LinkAnchor,
  Text,
  Vertical,
  notifications,
} from '@/shared/design-system/v2';
import { formatDateLong, toPlural } from '@/shared/lib/ui';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import {
  GetWorkspaceCurrentSubscriptionDetailResponseModel,
  PaymentSubscriptionStatusType,
} from '../../../../generated/api';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import {
  PLAN_HORIZONTAL_SPACING,
  PLAN_ICON_WIDTH,
  calculateTrialDaysRemaining,
  getPaymentSubscriptionProductType,
  getPlanStatus,
  isEnterpriseSubscription,
  subscriptionPlanDetails,
} from '../../../subscriptions/util';

interface PlanDetailsProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
}

const PlanDetails = ({ subscriptionDetail }: PlanDetailsProps) => {
  const { getSubscriptionPlansRoute } = useAbsoluteRoutes();

  const subscriptionStatusType = subscriptionDetail.status;
  const subscriptionType = subscriptionDetail.subscriptionType;

  if (isEnterpriseSubscription(subscriptionType)) {
    // Currently we do not show any plan details in the Enterprise Plan.
    return null;
  }

  if (subscriptionStatusType === PaymentSubscriptionStatusType.Trialing) {
    const trialDaysRemaining = calculateTrialDaysRemaining(subscriptionDetail.currentPeriodEnd);
    const trialDaysText = toPlural(trialDaysRemaining, 'day');

    const trialText =
      trialDaysRemaining > 0 ? (
        <Text variant="bodyShort03">
          Your free trial ends in{' '}
          <Text variant="subTitle04" span>
            {trialDaysText}
          </Text>
          .
        </Text>
      ) : (
        <Text variant="bodyShort03">Your free trial has ended.</Text>
      );

    return (
      <Horizontal spacing="xs">
        {trialText}
        <LinkAnchor
          to={getSubscriptionPlansRoute()}
          sx={theme => ({ color: theme.colors.blue[7] })}
        >
          Upgrade now
        </LinkAnchor>
      </Horizontal>
    );
  }

  if (subscriptionStatusType === PaymentSubscriptionStatusType.Active) {
    const currentPeriodEnd = subscriptionDetail.currentPeriodEnd;
    const currentPeriodEndDate = new Date(currentPeriodEnd * 1000);
    const formattedCurrentPeriodEnd = formatDateLong(currentPeriodEndDate);

    return <Text>Renews on {formattedCurrentPeriodEnd}</Text>;
  }

  if (subscriptionStatusType === PaymentSubscriptionStatusType.Canceled) {
    return (
      <Text>Your previous plan is canceled. Please choose a plan that best suits your needs.</Text>
    );
  }

  return (
    <Text>
      Unknown subscription status. Please contact support@markovml.com in case of any queries.
    </Text>
  );
};

interface ActivePlanSectionProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
  isBillingPortalSessionLoading: boolean;
  isBillingPortalSessionError: boolean;
  canManageSubscription: boolean;
  handleManageSubscription: () => void;
}

export const PlanSection = ({
  subscriptionDetail,
  isBillingPortalSessionLoading = false,
  isBillingPortalSessionError = false,
  canManageSubscription,
  handleManageSubscription,
}: ActivePlanSectionProps) => {
  const { workspaceId } = useAppMetadata();
  const navigate = useNavigate();
  const { getSubscriptionPlansRoute } = useAbsoluteRoutes();
  const subscriptionProductType = getPaymentSubscriptionProductType(
    subscriptionDetail.subscriptionType,
  );
  const { iconUrl, planName } = subscriptionPlanDetails[subscriptionProductType];

  const isCanceled = subscriptionDetail.status === PaymentSubscriptionStatusType.Canceled;
  const planStatus = getPlanStatus(subscriptionDetail.status);
  const canUserManageSubscription =
    !isEnterpriseSubscription(subscriptionDetail.subscriptionType) && canManageSubscription;

  const handleChangePlanClick = () => {
    navigate(getSubscriptionPlansRoute());
  };

  useEffect(() => {
    if (isBillingPortalSessionError) {
      notifications.error(
        'Unable to open Subscription Management page. Please contact support@markovml.com for further assistance.',
      );
    }
  }, [isBillingPortalSessionError]);

  return (
    <Box>
      <Horizontal spacing="md" position="apart" align="flex-start">
        <Vertical spacing="md">
          <Text color="gray.6">Your Plan</Text>
          <Horizontal spacing={PLAN_HORIZONTAL_SPACING}>
            <Image src={iconUrl} width={PLAN_ICON_WIDTH} />
            <Text variant="subTitle02" tt="capitalize">
              {planName}
            </Text>
            <Badge
              variant="outline"
              color={isCanceled ? 'red.6' : 'green.6'}
              bg={isCanceled ? 'red.0' : 'green.0'}
              tt="none"
            >
              <Text variant="small02">{planStatus}</Text>
            </Badge>
          </Horizontal>
          <Box pl={PLAN_ICON_WIDTH + PLAN_HORIZONTAL_SPACING}>
            <PlanDetails subscriptionDetail={subscriptionDetail} />
          </Box>
        </Vertical>
        {canUserManageSubscription ? (
          <Vertical>
            <Button
              variant="outline"
              onClick={handleManageSubscription}
              loading={isBillingPortalSessionLoading}
            >
              Manage billing
            </Button>
            <Button variant="outline" onClick={handleChangePlanClick}>
              Change plan
            </Button>
          </Vertical>
        ) : null}
      </Horizontal>
    </Box>
  );
};
