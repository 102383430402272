import {
  Box,
  Button,
  Horizontal,
  Text,
  TextArea,
  TextAreaProps,
  useInputState,
  useMarkovTheme,
} from '@/shared/design-system/v2';

const BUTTON_WIDTH = 92;
const BUTTON_HEIGHT = 36;
const BUTTON_AND_TEXTAREA_SPACING = 16;

interface ChatPromptEditInputProps extends Partial<TextAreaProps> {
  onSave: (prompt: string) => Promise<void>;
  onCancel: () => void;
  defaultValue: string;
  isAnswerUpdateInProgress: boolean;
}

export const ChatPromptEditInput = ({
  onSave,
  onCancel,
  defaultValue,
  isAnswerUpdateInProgress,
  ...restProps
}: ChatPromptEditInputProps) => {
  const [currentQuery, setCurrentQuery] = useInputState(defaultValue);
  const theme = useMarkovTheme();

  const handleSave = async () => {
    if (!currentQuery) {
      return;
    }
    await onSave(currentQuery);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.shiftKey && e.code === 'Enter') {
      e.preventDefault();
      handleSave();
    }
  };

  return (
    <Box w="100%" pos="relative">
      <TextArea
        variant="filled"
        size="lg"
        ariaLabel="Update input"
        value={currentQuery}
        onChange={setCurrentQuery}
        onKeyDown={handleInputKeyDown}
        placeholder="Update input..."
        autosize
        minRows={3}
        maxRows={15}
        radius="sm"
        disabled={isAnswerUpdateInProgress}
        styles={{
          input: {
            paddingInline: 24,
            paddingTop: '24px !important',
            paddingBottom: `${BUTTON_AND_TEXTAREA_SPACING + BUTTON_HEIGHT + 4}px !important`,
            backgroundColor: theme.colors.gray[1],
          },
        }}
        {...restProps}
      />
      <Horizontal
        pos="absolute"
        bottom={BUTTON_AND_TEXTAREA_SPACING}
        right={BUTTON_AND_TEXTAREA_SPACING}
        spacing="sm"
      >
        <Button
          variant="primary"
          disabled={isAnswerUpdateInProgress}
          onClick={onCancel}
          color="dark"
          h={BUTTON_HEIGHT}
          w={BUTTON_WIDTH}
        >
          <Text variant="subTitle03">Cancel</Text>
        </Button>
        <Button
          variant="primary"
          disabled={!currentQuery}
          loading={isAnswerUpdateInProgress}
          onClick={handleSave}
          h={BUTTON_HEIGHT}
          w={BUTTON_WIDTH}
        >
          <Text variant="subTitle03">Save</Text>
        </Button>
      </Horizontal>
    </Box>
  );
};
