import { openModal } from '@mantine/modals';
import { workflowEvents } from '@/main/analytics';
import { sendAnalytics } from '@/shared/initializers/analytics';
import { ScheduleContainer } from './Schedule.container';

export const useScheduleModal = (workspaceId: string, workflowId: string) => () =>
  openModal({
    title: 'Schedule',
    size: 'lg',
    children: <ScheduleContainer workflowId={workflowId} />,
    onClose: () => {
      sendAnalytics(
        workflowEvents.runs.workflowRunsScheduleModalClosed({ workspaceId, workflowId }),
      );
    },
  });
