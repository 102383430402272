import { IconCreditCoin } from '@/shared/design-system';
import {
  Box,
  Card,
  Horizontal,
  Image,
  ScrollArea,
  Text,
  Vertical,
} from '@/shared/design-system/v2';
import { OperatorIcon } from '../../../workspace/studio-home/workflow/OperatorIcon';
import { OperatorRichTextEditor } from './OperatorRichTextEditor';

interface OperationDescriptionCardProps {
  iconUrl: string;
  title: string;
  description: string;
  example: string;
  creditInfo: string;
  isEnterpriseOperatorInactive?: boolean;
  isEnterpriseOperator?: boolean;
}

export const OperatorDescriptionCard = ({
  iconUrl,
  title,
  description,
  example,
  creditInfo,
  isEnterpriseOperatorInactive = false,
  isEnterpriseOperator = false,
}: OperationDescriptionCardProps) => (
  <Card pl="24px" py="24px" bg="dark.6" sx={theme => ({ borderRadius: theme.spacing.sm })}>
    <ScrollArea.Autosize mah="calc(100vh - 140px)" maw={400} offsetScrollbars>
      <Vertical spacing={0}>
        <Vertical spacing="xs">
          <Horizontal noWrap spacing="xs">
            <Box w={32} h={32}>
              <OperatorIcon iconUrl={iconUrl} />
            </Box>
            <Text color="white.0" variant="subTitle01" style={{ paddingLeft: '8px ' }}>
              {title}
            </Text>
            {isEnterpriseOperator && (
              <Image
                src="https://ik.imagekit.io/markovml/payment-and-subscription/crown_HCqXJDGjV.svg?updatedAt=1731063205547"
                width={18}
                height={18}
                style={{
                  alignContent: 'flex-start',
                  paddingBottom: '16px',
                  paddingRight: '8px',
                }}
              />
            )}
          </Horizontal>
          {creditInfo.length > 0 && (
            <Horizontal noWrap spacing="xs" pl={40}>
              <IconCreditCoin />
              <Text color="gray.4" variant="small01">
                {creditInfo}
              </Text>
            </Horizontal>
          )}
          {isEnterpriseOperatorInactive && (
            <Text color="white.0" variant="subTitle03" pt="s" pl={10}>
              <b>This operator is only included in Enterprise plans</b>
            </Text>
          )}
        </Vertical>
        {example.length === 0 ? (
          <Text color="gray.4" variant="subTitle04" px="md" pt="md">
            {description}
          </Text>
        ) : (
          <OperatorRichTextEditor example={example} />
        )}
      </Vertical>
    </ScrollArea.Autosize>
  </Card>
);
