import sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import { Alert, SelectOption } from '@/shared/design-system/v2';
import { getSortedTags } from '@/shared/lib/tags';
import { useCreateTagMutation, useGetWorkspaceTagsQuery } from '../../queries/tagging';
import { TagSelect } from './TagSelect';

interface TagSelectContainerProps {
  selectedTagIds: string[];
  setSelectedTagIds: (val: string[]) => void;
}

export const TagSelectContainer = ({
  selectedTagIds,
  setSelectedTagIds,
}: TagSelectContainerProps) => {
  const { isLoading: isTagsListLoading, data: workspaceTags } = useGetWorkspaceTagsQuery();
  const {
    mutate,
    isLoading: isCreateTagLoading,
    error: createTagError,
    reset,
  } = useCreateTagMutation();
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    if (createTagError) {
      setErrorMsg(createTagError.response?.data?.detail ?? 'Error creating tag. Please try again.');
    } else if (errorMsg) {
      setErrorMsg('');
    }
  }, [createTagError]);

  const isLoading = isTagsListLoading || isCreateTagLoading;

  const sortedTags = getSortedTags(workspaceTags ?? []);

  const sortedSelectedIds = sortBy(selectedTagIds, tagId =>
    sortedTags.findIndex(tag => tag.tagId === tagId),
  );

  // Options for workspace tags
  const options: SelectOption[] = sortedTags.map(tag => ({
    value: tag.tagId,
    label: tag.name,
  }));

  const handleCreate = (name: string) => {
    reset();
    const newTag = { name };
    mutate(newTag, {
      onSuccess: data => {
        const tagId = data?.data?.tagMetadata?.tagId;
        if (!tagId) {
          // eslint-disable-next-line no-console
          console.warn('tagID was not found on response');
          return;
        }

        setSelectedTagIds([...selectedTagIds, tagId]);
      },
    });
    return undefined;
  };

  const handleChange = (newValue: string[]) => {
    reset();
    setSelectedTagIds(newValue);
  };

  return (
    <>
      {errorMsg && (
        <Alert color="red" mb={8}>
          {errorMsg}
        </Alert>
      )}
      <TagSelect
        value={sortedSelectedIds}
        options={options}
        onChange={handleChange}
        onCreate={handleCreate}
        disabled={isLoading}
      />
    </>
  );
};
