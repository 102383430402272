import { HocuspocusProvider } from '@hocuspocus/provider';
import { Editor } from '@tiptap/react';
import noop from 'lodash/noop';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useToggle } from '@/shared/design-system/v2/hooks';
import {
  EmbeddingsParameters,
  ModelAppParameters,
  VisualizationModel,
  VisualizationType,
} from '@/shared/design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { useGetEditor } from '@/shared/design-system/v2/rich-text-editor/use-editor/useEditor';
import { sendAnalytics } from '@/shared/initializers/analytics';
import { logger } from '@/shared/initializers/logging';
import { snippetEvents } from '../../../../analytics';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { SnippetModel, SnippetState } from '../../../../generated/api';
import { Dataset } from '../../../../queries/datasets/list';
import { getLayoutForItems } from '../../../common/grid-layout/util';
import { TabsTypes } from '../add-charts/AddChartsDrawer';

export enum DatasetChartSelectionStep {
  DatasetSelection,
  AnalyserSelection,
}

export interface SelectedAnalysisModel {
  id: string;
  metadata: VisualizationModel;
}

type BlockType = 'chart-grid' | 'text' | 'embedding-chart';

export interface BlockAttributes {
  blockType: BlockType;
  blockId: string;
  editorPosition: number;
}

export interface AddCommentForText {
  userId: string;
}

export interface AddCommentForViz {
  vizId: string;
  userId?: string;
}

export interface SnippetDetailContext {
  snippetId: string;

  activeUserIds: string[];

  addChartsOpen: TabsTypes | null;
  openAddCharts: (tabName: TabsTypes, endPos: number) => void;
  closeAddCharts: () => void;
  onAddCharts: (selectedAnalysis: SelectedAnalysisModel[]) => void;

  editor: Editor | null;
  editing: boolean;
  setEditing: () => void;

  showVersions: boolean;
  toggleVersionsView: () => void;

  isFullWidth: boolean;
  toggleFullWidth: () => void;

  selectedDatasets: Partial<Dataset>[];
  toggleDatasetSelection: (datasetDetails: Partial<Dataset>) => void;

  addChartStep: number;
  setAddChartStep: (step: number) => void;

  selectedVersion: { versionId: string; content: string };
  setSelectedVersion: (id: SnippetDetailContext['selectedVersion']) => void;
  resetEditorContent: () => void;
  getCurrentVersionContent: () => string;

  isPublicSnippet: boolean;

  activeConversation: { conversationId: string; userId: string };
  setActiveConversation: Dispatch<SetStateAction<SnippetDetailContext['activeConversation']>>;
}

// TODO:: get rid of defaults
// https://kentcdodds.com/blog/how-to-use-react-context-effectively
const defaultDetails = {
  snippetId: '',

  activeUserIds: [],

  addChartsOpen: null,
  openAddCharts: noop,
  closeAddCharts: noop,
  onAddCharts: noop,

  editor: null,
  editing: false,
  setEditing: noop,

  showVersions: false,
  toggleVersionsView: noop,

  isFullWidth: false,
  toggleFullWidth: noop,

  selectedDatasets: [],
  toggleDatasetSelection: noop,

  addChartStep: DatasetChartSelectionStep.DatasetSelection,
  setAddChartStep: noop,

  selectedVersion: { content: '', versionId: '' },
  setSelectedVersion: noop,
  resetEditorContent: noop,
  getCurrentVersionContent: () => '',

  isPublicSnippet: false,

  activeConversation: { conversationId: '', userId: '' },
  setActiveConversation: noop,
};

const SnippetContext = createContext<SnippetDetailContext>(defaultDetails);

export const useSnippetDetail = () => {
  const context = useContext(SnippetContext);
  if (!context) {
    throw new Error('Hook must be used within a SnippetContextProvider');
  }
  return context;
};

interface SnippetContextProviderProps {
  snippet: SnippetModel;
  provider?: HocuspocusProvider;
  activeUserIds: string[];
  isPublicSnippet?: boolean;
}

export const SnippetContextProvider = ({
  children,
  snippet,
  provider,
  activeUserIds,
  isPublicSnippet = false,
}: PropsWithChildren<SnippetContextProviderProps>) => {
  const isInDraftState = snippet.state === SnippetState.Draft;
  const [editing, setEditing] = useToggle([isInDraftState, !isInDraftState]);
  const [showVersions, toggleVersionsView] = useToggle();
  const [isFullWidth, toggleFullWidth] = useToggle();
  const chartPosition = useRef(0);
  const [selectedVersion, setSelectedVersion] = useState({
    content: snippet?.content ?? '',
    versionId: snippet.versionId,
  });
  const [selectedDatasets, setSelectedDatasets] = useState<Partial<Dataset>[]>(
    defaultDetails.selectedDatasets,
  );
  const [addChartStep, setAddChartStep] = useState(defaultDetails.addChartStep);
  const [addChartsTabOpened, setAddChartsTab] = useState<SnippetDetailContext['addChartsOpen']>(
    defaultDetails.addChartsOpen,
  );

  const [activeConversation, setActiveConversation] = useState<
    SnippetDetailContext['activeConversation']
  >(defaultDetails.activeConversation);

  const { userId: currentUserId, workspaceId } = useAppMetadata();

  const openAddCharts = (tabName: TabsTypes, position: number) => {
    setAddChartsTab(tabName);
    chartPosition.current = position;
  };

  const editor = useGetEditor(isPublicSnippet ? snippet?.content ?? '' : undefined, editing, {
    onClickComment: conversationId => {
      if (activeConversation.conversationId === conversationId) {
        return;
      }
      setActiveConversation({ conversationId, userId: currentUserId });
    },
    commandOptions: { openAddCharts },
    collaborationOptions: {
      provider,
    },
  });

  const closeAddCharts = () => {
    setAddChartsTab(defaultDetails.addChartsOpen);
  };

  const toggleDatasetSelection = useCallback<SnippetDetailContext['toggleDatasetSelection']>(
    details => {
      const isDatasetSelected = selectedDatasets.some(d => d.datasetId === details.datasetId);
      if (isDatasetSelected) {
        setSelectedDatasets(dataset => dataset.filter(d => d.datasetId !== details.datasetId));
      } else {
        setSelectedDatasets(datasets => [...datasets, details]);
      }
    },
    [selectedDatasets],
  );

  const resetEditorContent = () => {
    if (snippet.content) {
      editor?.commands.setContent(JSON.parse(snippet.content));
    }
    setSelectedVersion({ content: snippet.content ?? '', versionId: snippet.versionId });
  };

  const getCurrentVersionContent = () => snippet.content ?? '';

  const handleAddNewChartsToEditor = (selectedAnalyses: SelectedAnalysisModel[]) => {
    sendAnalytics(
      snippetEvents.snippet.addItem({
        snippetId: snippet.snippetId,
        workspaceId,
        snippetItem: selectedAnalyses.map(analyser => analyser.id),
      }),
    );

    const analysers: Record<string, SelectedAnalysisModel[]> = {
      embeddingCharts: [],
      modelApps: [],
      otherCharts: [],
    };

    selectedAnalyses.forEach(analyser => {
      if (analyser.id.includes(VisualizationType.DATASET_EMBEDDINGS)) {
        analysers.embeddingCharts.push(analyser);
        return;
      }

      if (analyser.id.includes(VisualizationType.MODEL_APPS)) {
        analysers.modelApps.push(analyser);
        return;
      }

      analysers.otherCharts.push(analyser);
    });

    const layout = getLayoutForItems(analysers.otherCharts).map((l, idx) => ({
      layout: l,
      item: analysers.otherCharts[idx].metadata,
    }));
    closeAddCharts();

    try {
      editor?.commands.setChartGridBlock({
        grid: JSON.stringify(layout),
        position: chartPosition.current,
      });
      if (analysers.embeddingCharts) {
        analysers.embeddingCharts.forEach(embeddingChart => {
          editor?.commands.setEmbeddingChartBlock({
            position: chartPosition.current,
            datasetId: (embeddingChart.metadata.visualizationParameters as EmbeddingsParameters)
              .datasetId,
            subsetId:
              (embeddingChart.metadata.visualizationParameters as EmbeddingsParameters)?.subsetId ??
              '',
            embeddingId: embeddingChart.id,
          });
        });
      }
      if (analysers.modelApps) {
        analysers.modelApps.forEach(modelApp => {
          editor?.commands.setModelAppBlock({
            modelId: (modelApp.metadata.visualizationParameters as ModelAppParameters).modelId,
            position: chartPosition.current,
          });
        });
      }
    } catch (e) {
      logger.error(`Encountered error while adding charts to editor: ${e}`);
    }
  };

  return (
    <SnippetContext.Provider
      value={{
        snippetId: snippet.snippetId,

        activeUserIds,

        addChartsOpen: addChartsTabOpened,
        openAddCharts,
        closeAddCharts,
        onAddCharts: handleAddNewChartsToEditor,

        editor,
        editing: isPublicSnippet ? false : editing,
        setEditing,

        showVersions,
        toggleVersionsView,

        isFullWidth,
        toggleFullWidth,

        selectedDatasets,
        toggleDatasetSelection,

        addChartStep,
        setAddChartStep,

        selectedVersion,
        setSelectedVersion,
        resetEditorContent,
        getCurrentVersionContent,

        activeConversation,
        setActiveConversation,

        isPublicSnippet,
      }}
    >
      {children}
    </SnippetContext.Provider>
  );
};
