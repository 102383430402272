import {
  Box,
  Card,
  Center,
  Container,
  Image,
  ScrollArea,
  SimpleGrid,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { DataOperationType } from '../../generated/api';

const DATA_LABELING_DESC =
  "MarkovML's dataset operations empower you to refine raw datasets into more meaningful data, fully realizing the potential of your datasets. Currently, we have developed 3 purpose-built tools tailored to address the distinctive needs of data-centric projects.";

const ILLUSTRATION_URL =
  'https://ik.imagekit.io/markovml/data-studio/data-labeling-illustration.svg?updatedAt=1711098645758';

const dataOperationTypes = [
  {
    id: DataOperationType.ManualRelabeling,
    title: 'Manual labeling',
    description:
      'Run extensive data analysis on specific segments and seamlessly download the results. Enabling more granular insights and conclusions which can include reports, visualizations, or any relevant findings.',
  },
  {
    id: DataOperationType.RuleBasedRelabeling,
    title: 'Rule-based labeling',
    description:
      'Revise dataset by applying a rule-based labeling mechanism. You can establish rules for each label and assess their effectiveness on a sample. Once the rules are refined and optimized, you can apply them to the entire dataset and generate an updated version.',
  },
  {
    id: DataOperationType.SubsetRelabeling,
    title: 'Auto-labeling',
    description:
      "Generate an updated version of the dataset by resolving inaccurately labeled data points, aiming to improve the dataset's accuracy and reliability. This process offers seamless collaboration with your team.",
  },
];

export const EmptyDataLabelingList = () => {
  const theme = useMarkovTheme();
  return (
    <ScrollArea h="100%">
      <Container size="lg">
        <Card
          m={40}
          p={40}
          sx={{
            borderTop: '2px solid',
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderBottom: '0px',
            borderColor: theme.colors.gray[1],
            background: `linear-gradient(0deg, #F1F3F5 0%, #ffffff 100%)`,
          }}
        >
          <Vertical spacing="xl">
            <Text variant="heading04" color="gray.7">
              Data labeling
            </Text>
            <Text variant="bodyLong01" color="gray.6">
              {DATA_LABELING_DESC}
            </Text>
            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} pt="md">
              <Center>
                <Image mah="324px" maw="452px" src={ILLUSTRATION_URL} />
              </Center>
              <Vertical spacing="xl">
                {dataOperationTypes.map(operation => (
                  <Box key={operation.id}>
                    <Text variant="subTitle02" color="gray.7">
                      {operation.title}
                    </Text>
                    <Text variant="bodyLong01" color="gray.6">
                      {operation.description}
                    </Text>
                  </Box>
                ))}
              </Vertical>
            </SimpleGrid>
          </Vertical>
        </Card>
      </Container>
    </ScrollArea>
  );
};
