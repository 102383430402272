import range from 'lodash/range';
import { ScrollArea, SimpleGrid, Skeleton } from '@/shared/design-system/v2';
import { ChartCard } from '../analysers/ChartsList';
import { useGetEvaluationsCharts } from '../hooks/evaluation';

interface EvaluationsChartsProps {
  evaluationId: string;
}

export const EvaluationsCharts = ({ evaluationId }: EvaluationsChartsProps) => {
  const { isLoading, evaluationCharts } = useGetEvaluationsCharts(evaluationId);

  const content = isLoading
    ? range(6).map(i => <Skeleton key={i} h={200} />)
    : evaluationCharts.map(chart => <ChartCard key={chart.id} {...chart} />);

  return (
    <ScrollArea p="md" w="58%" h="calc(100vh - 120px)">
      <SimpleGrid cols={2}>{content}</SimpleGrid>
    </ScrollArea>
  );
};
