import { ColDef } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { formatDateTime } from '@/shared/lib/ui';
import { ArtifactStateType, Workflow } from '../../../generated/api';
import { OwnerRenderer } from '../../common/table/cell-renderers/OwnerRenderer';
import { SinkOperatorRenderer } from './cell-renderers/DestinationOperatorRenderer';
import { ProcessOperatorRenderer } from './cell-renderers/ProcessOperatorRenderer';
import { SourceOperatorRenderer } from './cell-renderers/SourceOperatorRenderer';
import { TextRenderer } from './cell-renderers/TextRenderer';
import { WorkflowActionsRenderer } from './cell-renderers/WorkflowActionsRenderer';
import { WorkflowNameRenderer } from './cell-renderers/WorkflowNameRenderer';
import { RunsStatusRenderer } from './cell-renderers/WorkflowTotalRunsRenderer';

type WorkflowColDef = ColDef<Workflow>;

const SELECTION: WorkflowColDef = {
  colId: 'select',
  checkboxSelection: true,
  headerCheckboxSelection: true,
  pinned: 'left',
  width: 48,
};

const NAME: WorkflowColDef = {
  colId: 'name',
  field: 'name',
  headerName: 'Name',
  flex: 1,
  sortable: false,
  minWidth: 296,
  cellRenderer: WorkflowNameRenderer,
};

const TOTAL_RUNS: WorkflowColDef = {
  colId: 'totalRuns',
  field: 'totalRuns',
  headerName: 'Total Runs',
  sortable: false,
  width: 284,
  cellRenderer: RunsStatusRenderer,
};

const SOURCE_OPERATORS: WorkflowColDef = {
  colId: 'sourceOperators',
  field: 'operators',
  headerName: 'Inputs',
  sortable: false,
  width: 100,
  cellRenderer: SourceOperatorRenderer,
};

const PROCESS_OPERATORS: WorkflowColDef = {
  colId: 'processOperators',
  field: 'operators',
  headerName: 'Operations',
  sortable: false,
  width: 164,
  cellRenderer: ProcessOperatorRenderer,
};

const SINK_OPERATORS: WorkflowColDef = {
  colId: 'sinkOperators',
  field: 'operators',
  headerName: 'Outputs',
  sortable: false,
  width: 104,
  cellRenderer: SinkOperatorRenderer,
};

const UPDATE_DATE: WorkflowColDef = {
  colId: 'updateDate',
  field: 'updateDate',
  headerName: 'Last updated on',
  width: 164,
  valueGetter: ({ data }) => {
    const updatedDate = data?.updateDate;
    if (updatedDate) {
      return formatDateTime(updatedDate);
    }
    return '--';
  },
  cellRenderer: TextRenderer,
};

const CREATED_BY: WorkflowColDef = {
  colId: 'createdBy',
  headerName: 'Created by',
  field: 'createdBy',
  width: 208,
  sortable: false,
  cellRenderer: OwnerRenderer,
};

const ACTIONS: WorkflowColDef = {
  colId: 'actions',
  sortable: false,
  filter: false,
  resizable: false,
  pinned: 'right',
  cellRenderer: WorkflowActionsRenderer,
  cellRendererParams: { state: ArtifactStateType.Active },
  width: 64,
};

export const ALL_WORKFLOW_COLUMNS: ColDef<Workflow>[] = [
  SELECTION,
  NAME,
  SOURCE_OPERATORS,
  PROCESS_OPERATORS,
  SINK_OPERATORS,
  UPDATE_DATE,
  TOTAL_RUNS,
  CREATED_BY,
  ACTIONS,
];

export const ARCHIVE_WORKFLOW_COLUMNS: ColDef<Workflow>[] = [
  SELECTION,
  NAME,
  TOTAL_RUNS,
  SOURCE_OPERATORS,
  PROCESS_OPERATORS,
  SINK_OPERATORS,
  CREATED_BY,
  {
    ...ACTIONS,
    cellRendererParams: { state: ArtifactStateType.Archived },
  },
];
