import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { useMemo } from 'react';
import { Box, Horizontal, SimpleGrid, Text, Vertical } from '@/shared/design-system/v2';
import { formatNumberLocaleString } from '@/shared/lib/ui';
import { DatasetOverview } from '../../../generated/api';
import { InfoMessage } from '../../common/InfoMessage';

interface SummaryTableRow {
  label: string;
  value: number;
}

const getRows = (summary: DatasetOverview) =>
  Object.entries(summary)
    .filter(([, value]) => typeof value === 'number')
    .map(([key, val]) => {
      const value = formatNumberLocaleString(val);
      // TODO: Read units from BE once available. This is a temp. solution in the mean time.
      const label = startCase(key)
        .replace(/in mega bytes/i, '(MB)')
        .replace(/in bytes/i, '(bytes)');
      return { label, value };
    });

interface DataSummaryTableProps {
  summary: DatasetOverview;
  oneColumn?: boolean;
}

export const DataSummaryTable = ({
  summary,
  oneColumn = false,
}: DataSummaryTableProps): JSX.Element => {
  const rows = useMemo(() => getRows(summary), [summary]);

  if (isEmpty(summary)) {
    return (
      <Box>
        <InfoMessage text="No data summary available" />
      </Box>
    );
  }

  const chunkSize = oneColumn ? rows.length : 5;

  const rowSubsets = Array(Math.ceil(rows.length / chunkSize))
    .fill(null)
    .map((_, index) => rows.slice(index * chunkSize, index * chunkSize + chunkSize));

  const minWidth = 500 * rowSubsets.length;

  return (
    <SimpleGrid miw={minWidth} w="100%" cols={rowSubsets.length} spacing={24}>
      {rowSubsets.map((rowSubset, index) => (
        <Vertical key={index}>
          {rowSubset.map((element, idx) => (
            <Box
              pt="sm"
              key={idx}
              w="100%"
              h="44px"
              sx={theme => ({ borderBottom: `1px solid ${theme.fn.themeColor('gray.2')}` })}
            >
              <Horizontal position="apart" noWrap>
                <Text variant="subTitle04" color="gray.7">
                  {element.label}
                </Text>
                <Text variant="subTitle04" color="gray.7">
                  {element.value}
                </Text>
              </Horizontal>
            </Box>
          ))}
        </Vertical>
      ))}
    </SimpleGrid>
  );
};
