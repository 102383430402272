import isEmpty from 'lodash/isEmpty';
import { Box, Horizontal, Text } from '@/shared/design-system/v2';
import { interleaveComponent } from '@/shared/lib/util-react';
import { ResourceRequest, StorageType } from '../../../generated/api';
import { getConnectorTypeDisplayName, getConnectorTypeIcon } from '../util';

interface ConnectorBreadcrumbsProps {
  connectorType?: StorageType;
  resourcesHierarchy: ResourceRequest[];
  onChangeLevel: (level: number) => void;
}

export const ConnectorBreadcrumbs = ({
  connectorType,
  resourcesHierarchy,
  onChangeLevel,
}: ConnectorBreadcrumbsProps): JSX.Element => {
  const rootLabel = 'Account Root';
  const initialBreadcrumb = isEmpty(resourcesHierarchy) ? (
    <Text variant="subTitle03">{rootLabel}</Text>
  ) : (
    <Text
      variant="subTitle03"
      sx={theme => ({ color: theme.colors.blue[6], cursor: 'pointer' })}
      onClick={() => onChangeLevel(0)}
    >
      {rootLabel}
    </Text>
  );

  const breadcrumbs = [
    <Box key="root">{initialBreadcrumb}</Box>,
    ...resourcesHierarchy.map((item, i) => {
      const displayedName =
        connectorType === StorageType.S3 ? item.value.replace(/\/$/, '') : item.value;
      return (
        <Box key={`${item.value}-${i}`}>
          {i === resourcesHierarchy.length - 1 ? (
            <Text variant="subTitle03">{displayedName}</Text>
          ) : (
            <Text
              variant="subTitle03"
              sx={theme => ({ color: theme.colors.blue[6], cursor: 'pointer' })}
              onClick={() => onChangeLevel(i + 1)}
            >
              {displayedName}
            </Text>
          )}
        </Box>
      );
    }),
  ];

  const connectorTypeName = connectorType ? getConnectorTypeDisplayName(connectorType) : '';
  const connectorTypeIcon = connectorType ? getConnectorTypeIcon(connectorType) : null;

  return (
    <Horizontal py="lg" spacing="xl">
      <Box>
        <Horizontal spacing="sm" py="sm" px="md" bg="red.0" sx={{ borderRadius: 40 }}>
          <>
            {connectorTypeIcon}
            <Text variant="subTitle03">{connectorTypeName}</Text>
          </>
        </Horizontal>
      </Box>
      <Horizontal spacing="sm">
        {interleaveComponent(breadcrumbs, () => (
          <>/</>
        ))}
      </Horizontal>
    </Horizontal>
  );
};
