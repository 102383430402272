import toLower from 'lodash/toLower';
import {
  Text,
  Vertical,
  closeAllModals,
  notifications,
  openModal,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { toPlural } from '@/shared/lib/ui';
import { ArtifactFilterState, ResourceType } from '../../generated/api';
import { ArchiveUnarchiveModal } from '../common/table/ArchiveUnarchiveModal';
import { DATA_ANALYTICS_NAME } from '../data-analytics/util';

const getResourceTypeLabel = (resourceType: ResourceType) => {
  switch (resourceType) {
    case ResourceType.DataOperation:
      return 'data labeling operation';
    case ResourceType.DataAnalytics:
      return 'Data Source';
    default:
      return resourceType;
  }
};

const getSubHeadingText = (
  resourceType: ResourceType,
  displayState: string,
  displayName: string,
  count: number,
) => {
  if (resourceType === ResourceType.DataAnalytics) {
    return `Would you like to ${toLower(displayState)} the selected ${toPlural(
      count,
      'Data Source',
    )} from ${DATA_ANALYTICS_NAME}?`;
  }
  return `Would you like to ${toLower(displayState)} the selected ${displayName}?`;
};

export const useArchiveUnarchive = (
  count: number,
  resourceType: ResourceType,
  artifactState: ArtifactFilterState,
  archiveHandler: any,
  isError: boolean,
  isLoading: boolean,
) => {
  const resourceTypeLabel = getResourceTypeLabel(resourceType);
  const displayName = count === 1 ? resourceTypeLabel : toPlural(count, resourceTypeLabel);
  const displayState = `${artifactState === ArtifactFilterState.Active ? 'Archive' : 'Unarchive'}`;
  const headingText = `${displayState} ${displayName}`;
  const subheading = getSubHeadingText(resourceType, displayState, displayName, count);

  const onClick = async () => {
    try {
      await archiveHandler();
      notifications.success(`${displayState}d selected ${displayName}`);
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${displayState} selected ${displayName}`);
      logger.error(error);
    }
  };

  const openArchiveUnarchiveModal = () =>
    openModal({
      size: 'lg',
      title: <Text variant="heading03">{headingText}</Text>,
      children: (
        <Vertical spacing="lg">
          <Text variant="bodyShort01">{subheading}</Text>
          <ArchiveUnarchiveModal onClick={onClick} isError={isError} isLoading={isLoading} />
        </Vertical>
      ),
    });

  return {
    onClick,
    openArchiveUnarchiveModal,
  };
};
