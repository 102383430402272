import { PropsWithChildren } from 'react';
import { Box, Info } from '@/shared/design-system';
import { ActionIcon, CardProps, Center, Divider, Text, Tooltip } from '@/shared/design-system/v2';
import { DetailsPageSectionWrapper } from './details-page/SectionWrapper';

interface DataInfoBoxProps {
  title: string;
  description?: string;
  hideDivider?: boolean;
  wrapperProps?: Omit<CardProps, 'children'>;
}

export const DataInfoBox = ({
  children,
  title,
  description,
  hideDivider = false,
  wrapperProps,
}: PropsWithChildren<DataInfoBoxProps>): JSX.Element => (
  <DetailsPageSectionWrapper {...wrapperProps}>
    <Center inline p={12}>
      <Text variant="subTitle02" color="gray.9">
        {title}
      </Text>
      {description && (
        <Tooltip label={description} withArrow withinPortal multiline maw={325}>
          <ActionIcon variant="transparent">
            <Info />
          </ActionIcon>
        </Tooltip>
      )}
    </Center>
    {!hideDivider && <Divider color="gray.4" />}
    <Box p={12}>{children}</Box>
  </DetailsPageSectionWrapper>
);
