import { Stripe, loadStripe } from '@stripe/stripe-js';
import { logger } from '@/shared/initializers/logging';

const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY as string;

const MAX_RETRY_ATTEMPTS = 3;

export const getStripeInstanceWithRetry = async (
  attempt = 0,
  error?: unknown,
): Promise<Stripe | null> => {
  if (attempt === MAX_RETRY_ATTEMPTS) {
    logger.error(error);
    return null;
  }

  try {
    const stripeInstance = await loadStripe(STRIPE_PUBLISHABLE_KEY);
    return stripeInstance;
  } catch (error) {
    return getStripeInstanceWithRetry(attempt + 1, error);
  }
};
