import { Box, Text, openModal } from '@/shared/design-system/v2';
import { DataOperationType } from '../../../generated/api';
import { DatasetSelectionContainer } from './DatasetSelectionContainer';

export const useDatasetSelectionModal = () => {
  const open = (dataLabelingType?: DataOperationType) =>
    openModal({
      title: <Text variant="subTitle01">Data Selection</Text>,
      size: '80vw',
      children: (
        <Box h="calc(100vh - 240px)">
          <DatasetSelectionContainer dataLabelingType={dataLabelingType} />
        </Box>
      ),
    });

  return { open };
};
