import { IconCircleCaretRightFilled, IconPencil, IconQuote } from '@tabler/icons-react';
import first from 'lodash/first';
import {
  ActionIcon,
  Box,
  Center,
  Horizontal,
  LinkAnchor,
  MarkdownPreview,
  Prism,
  Text,
  Tooltip,
  Vertical,
  useElementSize,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { ErrorBoundary } from '../../../../../ErrorBoundary';
import { ChartTypes } from '../../../../../charts';
import { Highcharts, HighchartsReact } from '../../../../../charts/providers/highcharts';
import { getBaseColors } from '../../../../../charts/utils/colors';
import {
  AnswerTypes,
  Citation,
  CitationSourceType,
  DataSourceConversationModel,
  QuestionTypes,
} from '../../../../../generated/api';
import { toCamelCaseKeys } from '../../../../app-store/document-ai/commons/utils';
import { CopyToClipboardTooltip } from '../../../CopyToClipboardTooltip';
import { AnswerErrorSQLContainer } from './AnswerErrorSQL.container';
import { TableView } from './TableView';
import { CHART_OPTIONS } from './multi-chart/ChartSelectionModal';
import { MultiChartViewMode, MultiChartViz } from './multi-chart/MultiChartViz';

export interface Author {
  first_name: string;
  last_name: string;
}

export interface Identifier {
  url: string;
  doi?: string;
}

export interface PublishingInformation {
  title: string;
  published_date: Date;
}

export interface JournalInformation extends PublishingInformation {
  publication_volume: string;
  publication_issue: string;
  total_pages: string;
}

export type WebsiteInformation = PublishingInformation;

export type OthersInformation = PublishingInformation;

export interface BaseReference {
  title: string;
  accessed_date: Date;
  authors: Author[];
  identifier: Identifier;
}

export interface MarkovPanelReference extends BaseReference {
  reference_type: CitationSourceType;
  journal_reference?: JournalInformation;
  website_reference?: WebsiteInformation;
  others_reference?: OthersInformation;
}

export interface LLMAnswerAndReferences {
  answer: string;
  references: MarkovPanelReference[];
}

const COLOR_AXIS = {
  minColor: '#fff',
  maxColor: getBaseColors()[3],
};

const overrideConfig = (config: Highcharts.Options): Highcharts.Options => {
  const isHeatmap = config.chart?.type === 'heatmap';
  const isTreemap = config.chart?.type === 'treemap';

  return {
    ...config,
    colors: getBaseColors(),
    colorAxis: isHeatmap || isTreemap ? COLOR_AXIS : undefined,
  };
};

const convertDisplayTypeToChartType = (displayType: string): ChartTypes | undefined => {
  switch (displayType) {
    case 'table':
      // Default viewMode will be set to Table
      return undefined;
    case 'column':
      return ChartTypes.COLUMN;
    case 'bar':
      return ChartTypes.BAR;
    case 'bubble':
      return ChartTypes.PACKED_BUBBLE;
    case 'pie':
      return ChartTypes.PIE;
    case 'scatter':
      return ChartTypes.SCATTER;
    case 'line':
      return ChartTypes.LINE;
    default:
      logger.error(`Unsupported chart type requested: ${displayType}`);
      // Fallback to default chart type.
      return undefined;
  }
};

// Should throw error for ErrorBoundary to catch
Highcharts.error = function (code, stop, chart) {
  throw 'Highcharts error';
};

const VizErrorFallback = () => (
  <Text>Sorry, unable to interpret the response. Please try again with different query</Text>
);

interface ReferenceItemProps {
  reference: MarkovPanelReference;
}

const ReferenceItem = ({ reference }: ReferenceItemProps) => {
  const theme = useMarkovTheme();
  const referenceObj = toCamelCaseKeys(reference) as Citation;

  return (
    <Box>
      <LinkAnchor external href={reference.identifier.url} target="_blank">
        <Text variant="bodyShort03" color={theme.colors.blue[6]}>
          {`[${reference.title}]`}
        </Text>
      </LinkAnchor>
      <Horizontal>
        <Text variant="bodyShort03" color={theme.colors.gray[6]}>
          {`${referenceObj.rawCitationDetails['citationType']}: ${referenceObj.rawCitationDetails['citationTitle']}`}
        </Text>
      </Horizontal>
    </Box>
  );
};

export const getAnswerContentToCopy = (responseItem: DataSourceConversationModel) => {
  if (
    responseItem.answerType === AnswerTypes.Text &&
    responseItem.questionType === QuestionTypes.Text
  )
    return responseItem.answer;

  return null;
};

const ACTION_BUTTON_SIZE = 28;

export const useGetAnswerContent = (
  resourceId: string,
  responseItem: DataSourceConversationModel | undefined,
  addLocalQuestion: (query: string, questionType: QuestionTypes) => void,
  isAnswerEditAllowed: boolean,
  onAnswerEditClick: () => void,
  isExecuteDisabled?: boolean,
  prismClasses?: { prismCode: string },
) => {
  const { ref: actionButtonHorizontalRef, width: actionButtonHorizontalWidth } = useElementSize();

  if (!responseItem) {
    return (
      <Text variant="bodyLong02" bg="transparent">
        Oops, something did not work as expected. Please try again, and if the issue persists,
        contact support@markovml.com for help.
      </Text>
    );
  }

  if (responseItem.answerType === AnswerTypes.Text) {
    if (responseItem.questionType === QuestionTypes.Text2sql) {
      const handleExecuteSQL = () => {
        addLocalQuestion(responseItem.answer, QuestionTypes.Sql);
      };
      return (
        <Horizontal w="100%" position="apart" pos="relative" noWrap>
          <Prism
            w={`calc(100% - ${actionButtonHorizontalWidth + 8}px)`}
            language="sql"
            className={prismClasses ? prismClasses.prismCode : ''}
            noCopy
          >
            {responseItem.answer}
          </Prism>
          <Horizontal
            ref={actionButtonHorizontalRef}
            pos="absolute"
            spacing="xs"
            top={4}
            right={0}
            noWrap
          >
            <Center
              sx={theme => ({
                border: `1px solid ${theme.colors.gray[3]}`,
                borderRadius: theme.radius.xs,
              })}
              w={ACTION_BUTTON_SIZE}
              h={ACTION_BUTTON_SIZE}
            >
              <CopyToClipboardTooltip valueToCopy={responseItem.answer} isMarkdown={true} />
            </Center>
            {isAnswerEditAllowed ? (
              <Tooltip withArrow label="Edit SQL" position="top">
                <ActionIcon
                  w={ACTION_BUTTON_SIZE}
                  disabled={isExecuteDisabled}
                  sx={theme => ({
                    border: `1px solid ${theme.colors.gray[3]}`,
                  })}
                  onClick={onAnswerEditClick}
                >
                  <IconPencil size={16} />
                </ActionIcon>
              </Tooltip>
            ) : null}
            <Tooltip withArrow label="Execute SQL" position="top">
              <ActionIcon w={ACTION_BUTTON_SIZE} bg="gray.1" onClick={handleExecuteSQL}>
                <IconCircleCaretRightFilled size={20} />
              </ActionIcon>
            </Tooltip>
          </Horizontal>
        </Horizontal>
      );
    }

    return <MarkdownPreview>{responseItem.answer}</MarkdownPreview>;
  }

  if (responseItem.answerType === AnswerTypes.Dict) {
    const references = responseItem.answer?.references ?? [];
    return (
      <Vertical>
        <MarkdownPreview variant="bodyShort02" bg="transparent">
          {responseItem.answer.answer}
        </MarkdownPreview>
        <Horizontal position="right" w="100%">
          {/* TODO: Fix this to paste with citation */}
          <CopyToClipboardTooltip valueToCopy={responseItem.answer.answer} />
        </Horizontal>
        {references.length > 0 ? (
          <Horizontal>
            <IconQuote />
            <Text variant="subTitle02" bg="transparent">
              References
            </Text>
          </Horizontal>
        ) : null}
        {references.map((reference: MarkovPanelReference, index: number) => (
          <ReferenceItem
            key={responseItem.threadId + responseItem.conversationId + index}
            reference={reference}
          />
        ))}
      </Vertical>
    );
  }

  if (responseItem.answerType === AnswerTypes.Viz) {
    const config = overrideConfig(responseItem.answer);
    return (
      <ErrorBoundary fallback={VizErrorFallback}>
        <HighchartsReact
          highcharts={Highcharts}
          options={config}
          immutable
          containerProps={{
            className: 'highcharts-container',
          }}
        />
      </ErrorBoundary>
    );
  }

  if (responseItem.answerType === AnswerTypes.VizV2) {
    // TODO: Remove assertions once BE improves typing
    const rawData =
      (responseItem.answer.display_data as Record<string, unknown>[] | undefined) ?? [];
    const chartTitle = (responseItem.answer.display_metadata?.title as string | undefined) ?? '';
    const xAxisTitle = (responseItem.answer.display_metadata?.x_label as string | undefined) ?? '';
    const yAxisTitle = (responseItem.answer.display_metadata?.y_label as string | undefined) ?? '';
    const xColumns = responseItem.answer.display_metadata?.x_cols as string[] | undefined;
    const yColumns = responseItem.answer.display_metadata?.y_cols as string[] | undefined;
    const xColName = first(xColumns) ?? '';
    const yColName = first(yColumns) ?? '';
    const defaultViewMode =
      responseItem.answer.display_type === 'table'
        ? MultiChartViewMode.TABLE
        : MultiChartViewMode.CHART;
    const suggestedChartType = convertDisplayTypeToChartType(responseItem.answer.display_type);
    // Ensure chart type suggested by BE is actually supported
    const defaultChartType =
      suggestedChartType && CHART_OPTIONS.some(({ value }) => value === suggestedChartType)
        ? suggestedChartType
        : undefined;

    return (
      <MultiChartViz
        title={chartTitle}
        rawData={rawData}
        xColName={xColName}
        yColName={yColName}
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
        defaultViewMode={defaultViewMode}
        defaultChartType={defaultChartType}
      />
    );
  }

  if (responseItem.answerType === AnswerTypes.Table) {
    // Is this correct?
    const content: any = responseItem.answer;
    return <TableView content={content ?? []} />;
  }

  if (responseItem.answerType === AnswerTypes.ErrorSql) {
    return <AnswerErrorSQLContainer resourceId={resourceId} responseItem={responseItem} />;
  }
};
