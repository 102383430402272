import { Alert, Box, Horizontal, Text, UnstyledButton } from '@/shared/design-system/v2';
import {
  useGetNotificationsQuery,
  useMarkAllNotificationAsReadMutation,
  useMarkNotificationAsReadMutation,
} from '../../../queries/notifications/in-app-notifications';
import { InfoMessage } from '../../common/InfoMessage';
import { NotificationDrawer } from './NotificationDrawer';
import { NotificationLoading } from './NotificationLoading';

export interface NotificationsDrawerContainerProps {
  onClosePopover: (opened: boolean) => void;
}

export const NotificationsDrawerContainer = ({
  onClosePopover,
}: NotificationsDrawerContainerProps) => {
  const { isLoading, isError, data, fetchNextPage, hasNextPage } = useGetNotificationsQuery();
  const notifications = data?.pages.flatMap(page => page.response) || [];
  const { mutate } = useMarkNotificationAsReadMutation();
  const { mutate: markAllAsRead } = useMarkAllNotificationAsReadMutation();

  const onMarkAsRead = (recordIds: string[]) => {
    mutate(
      { recordIds, read: true },
      {
        onSuccess: () => {
          onClosePopover(false);
        },
      },
    );
  };

  const handleMarkAllAsRead = () => {
    markAllAsRead();
    onClosePopover(true);
  };

  if (isLoading) {
    return <NotificationLoading />;
  }

  if (isError) {
    return (
      <Box h={520}>
        <Alert color="red" title="Error">
          Error loading notifications
        </Alert>
      </Box>
    );
  }

  if (!notifications || !notifications.length) {
    return (
      <Box h={520}>
        <InfoMessage text="You don't have any notifications yet. 🎉" />
      </Box>
    );
  }

  return (
    <Box>
      <Horizontal position="apart" noWrap>
        <Text align="left" variant="subTitle02" px={8} py={4}>
          Notifications
        </Text>
        <UnstyledButton onClick={handleMarkAllAsRead}>
          <Text variant="textLink" color="blue.6" px={8} py={4}>
            Mark all as read
          </Text>
        </UnstyledButton>
      </Horizontal>
      <NotificationDrawer
        notifications={notifications}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage ?? false}
        onMarkAsRead={onMarkAsRead}
      />
    </Box>
  );
};
