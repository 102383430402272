import {
  WorkflowAppsDeployButtonClicked,
  WorkflowAppsDeployButtonClickedProperties,
  WorkflowAppsLiveAppButtonClicked,
  WorkflowAppsLiveAppButtonClickedProperties,
  WorkflowsAddOperatorClicked,
  WorkflowsAddOperatorClickedProperties,
  WorkflowsAddOperatorDropped,
  WorkflowsAddOperatorDroppedProperties,
  WorkflowsAppsDeployStep1,
  WorkflowsAppsDeployStep1Properties,
  WorkflowsAppsDeployStep2,
  WorkflowsAppsDeployStep2Properties,
  WorkflowsAppsExecuteRunButtonClicked,
  WorkflowsAppsExecuteRunButtonClickedProperties,
  WorkflowsAppsNextButtonClicked,
  WorkflowsAppsNextButtonClickedProperties,
  WorkflowsAppsSidePanelNewRunClicked,
  WorkflowsAppsSidePanelNewRunClickedProperties,
  WorkflowsAppsSidePanelRunsHistoryClicked,
  WorkflowsAppsSidePanelRunsHistoryClickedProperties,
  WorkflowsAppsStepperClicked,
  WorkflowsAppsStepperClickedProperties,
  WorkflowsCloneWorkflowClicked,
  WorkflowsCloneWorkflowClickedProperties,
  WorkflowsCreateBlankWorkflowClicked,
  WorkflowsCreateBlankWorkflowClickedProperties,
  WorkflowsCreateWorkflowFromTemplateClicked,
  WorkflowsCreateWorkflowFromTemplateClickedProperties,
  WorkflowsDebugRunClicked,
  WorkflowsDebugRunClickedProperties,
  WorkflowsFreeTrialModalTourClicked,
  WorkflowsFreeTrialModalTourClickedProperties,
  WorkflowsHeaderSectionButtonClicked,
  WorkflowsHeaderSectionButtonClickedProperties,
  WorkflowsLinkNodesWithEdge,
  WorkflowsLinkNodesWithEdgeProperties,
  WorkflowsNodeDeleteBtnClicked,
  WorkflowsNodeDeleteBtnClickedProperties,
  WorkflowsNodeEditBtnClicked,
  WorkflowsNodeEditBtnClickedProperties,
  WorkflowsNodeLogsClicked,
  WorkflowsNodeLogsClickedProperties,
  WorkflowsNodeOutputPreviewClicked,
  WorkflowsNodeOutputPreviewClickedProperties,
  WorkflowsOnboardingStep1,
  WorkflowsOnboardingStep2,
  WorkflowsOnboardingStep2Properties,
  WorkflowsOperatorExampleHover,
  WorkflowsOperatorExampleHoverProperties,
  WorkflowsOperatorNodeClicked,
  WorkflowsOperatorNodeClickedProperties,
  WorkflowsOperatorNodesListSearch,
  WorkflowsOperatorNodesListSearchProperties,
  WorkflowsOperatorNodesListTabClicked,
  WorkflowsOperatorNodesListTabClickedProperties,
  WorkflowsPublishWorkflowClicked,
  WorkflowsPublishWorkflowClickedProperties,
  WorkflowsRunPublishedWorkflowClicked,
  WorkflowsRunPublishedWorkflowClickedProperties,
  WorkflowsRunsNodeLogsModalClosed,
  WorkflowsRunsNodeLogsModalClosedProperties,
  WorkflowsRunsNodeLogsModalOpened,
  WorkflowsRunsNodeLogsModalOpenedProperties,
  WorkflowsRunsNodeLogsTabClicked,
  WorkflowsRunsNodeLogsTabClickedProperties,
  WorkflowsRunsNodePreviewTabClicked,
  WorkflowsRunsNodePreviewTabClickedProperties,
  WorkflowsRunsOperatorViewConfigClicked,
  WorkflowsRunsOperatorViewConfigClickedProperties,
  WorkflowsRunsRunClicked,
  WorkflowsRunsRunClickedProperties,
  WorkflowsRunsRunFlowClicked,
  WorkflowsRunsRunFlowClickedProperties,
  WorkflowsRunsRunModalClosed,
  WorkflowsRunsRunModalClosedProperties,
  WorkflowsRunsRunNumberCardClicked,
  WorkflowsRunsRunNumberCardClickedProperties,
  WorkflowsRunsRunsHistoryTabClicked,
  WorkflowsRunsRunsHistoryTabClickedProperties,
  WorkflowsRunsRunsTabitemClicked,
  WorkflowsRunsRunsTabitemClickedProperties,
  WorkflowsRunsScheduleButtonClicked,
  WorkflowsRunsScheduleButtonClickedProperties,
  WorkflowsRunsScheduleModalClosed,
  WorkflowsRunsScheduleModalClosedProperties,
  WorkflowsRunsScheduleModalOpened,
  WorkflowsRunsScheduleModalOpenedProperties,
  WorkflowsRunsUpcomingTabitemClicked,
  WorkflowsRunsUpcomingTabitemClickedProperties,
  WorkflowsViewLastDebugRunClicked,
  WorkflowsViewLastDebugRunClickedProperties,
} from '@/shared/ampli';

export const workflowEvents = {
  onboarding: {
    onboardingStep1: () => new WorkflowsOnboardingStep1(),
    onboardingStep2: (data: WorkflowsOnboardingStep2Properties) =>
      new WorkflowsOnboardingStep2(data),
    freeTrailModalButtonClicked: (data: WorkflowsFreeTrialModalTourClickedProperties) =>
      new WorkflowsFreeTrialModalTourClicked(data),
  },
  apps: {
    deploy: (data: WorkflowAppsDeployButtonClickedProperties) =>
      new WorkflowAppsDeployButtonClicked(data),
    liveApp: (data: WorkflowAppsLiveAppButtonClickedProperties) =>
      new WorkflowAppsLiveAppButtonClicked(data),
    deployStep1: (data: WorkflowsAppsDeployStep1Properties) => new WorkflowsAppsDeployStep1(data),
    deployStep2: (data: WorkflowsAppsDeployStep2Properties) => new WorkflowsAppsDeployStep2(data),
    sidePanelNewRun: (data: WorkflowsAppsSidePanelNewRunClickedProperties) =>
      new WorkflowsAppsSidePanelNewRunClicked(data),
    sidePanelRunsHistory: (data: WorkflowsAppsSidePanelRunsHistoryClickedProperties) =>
      new WorkflowsAppsSidePanelRunsHistoryClicked(data),
    nextButton: (data: WorkflowsAppsNextButtonClickedProperties) =>
      new WorkflowsAppsNextButtonClicked(data),
    executeButton: (data: WorkflowsAppsExecuteRunButtonClickedProperties) =>
      new WorkflowsAppsExecuteRunButtonClicked(data),
    stepperClicked: (data: WorkflowsAppsStepperClickedProperties) =>
      new WorkflowsAppsStepperClicked(data),
  },
  create: {
    blank: (data: WorkflowsCreateBlankWorkflowClickedProperties) =>
      new WorkflowsCreateBlankWorkflowClicked(data),
    fromTemplate: (data: WorkflowsCreateWorkflowFromTemplateClickedProperties) =>
      new WorkflowsCreateWorkflowFromTemplateClicked(data),
    clone: (data: WorkflowsCloneWorkflowClickedProperties) =>
      new WorkflowsCloneWorkflowClicked(data),
  },
  header: {
    sectionChange: (data: WorkflowsHeaderSectionButtonClickedProperties) =>
      new WorkflowsHeaderSectionButtonClicked(data),
  },
  dag: {
    searchOperators: (data: WorkflowsOperatorNodesListSearchProperties) =>
      new WorkflowsOperatorNodesListSearch(data),
    operatorsTabClicked: (data: WorkflowsOperatorNodesListTabClickedProperties) =>
      new WorkflowsOperatorNodesListTabClicked(data),
    addOperatorDrop: (data: WorkflowsAddOperatorDroppedProperties) =>
      new WorkflowsAddOperatorDropped(data),
    addOperatorClicked: (data: WorkflowsAddOperatorClickedProperties) =>
      new WorkflowsAddOperatorClicked(data),
    hoverOperatorExample: (data: WorkflowsOperatorExampleHoverProperties) =>
      new WorkflowsOperatorExampleHover(data),
    operatorNodeClicked: (data: WorkflowsOperatorNodeClickedProperties) =>
      new WorkflowsOperatorNodeClicked(data),
    editNodeClicked: (data: WorkflowsNodeEditBtnClickedProperties) =>
      new WorkflowsNodeEditBtnClicked(data),
    deleteNodeClicked: (data: WorkflowsNodeDeleteBtnClickedProperties) =>
      new WorkflowsNodeDeleteBtnClicked(data),
    linkNodesWithEdge: (data: WorkflowsLinkNodesWithEdgeProperties) =>
      new WorkflowsLinkNodesWithEdge(data),
  },
  runs: {
    debugRunClicked: (data: WorkflowsDebugRunClickedProperties) =>
      new WorkflowsDebugRunClicked(data),
    viewLastRunClicked: (data: WorkflowsViewLastDebugRunClickedProperties) =>
      new WorkflowsViewLastDebugRunClicked(data),
    nodePreviewClicked: (data: WorkflowsNodeOutputPreviewClickedProperties) =>
      new WorkflowsNodeOutputPreviewClicked(data),
    nodeLogsClicked: (data: WorkflowsNodeLogsClickedProperties) =>
      new WorkflowsNodeLogsClicked(data),
    runPublishedWorkflow: (data: WorkflowsRunPublishedWorkflowClickedProperties) =>
      new WorkflowsRunPublishedWorkflowClicked(data),
    workflowRunsNodePreviewTabClicked: (data: WorkflowsRunsNodePreviewTabClickedProperties) =>
      new WorkflowsRunsNodePreviewTabClicked(data),
    workflowRunsNodeLogsTabClicked: (data: WorkflowsRunsNodeLogsTabClickedProperties) =>
      new WorkflowsRunsNodeLogsTabClicked(data),
    workflowsRunsNodeLogsModalClosed: (data: WorkflowsRunsNodeLogsModalClosedProperties) =>
      new WorkflowsRunsNodeLogsModalClosed(data),
    WorkflowsRunsNodeLogsModalOpened: (data: WorkflowsRunsNodeLogsModalOpenedProperties) =>
      new WorkflowsRunsNodeLogsModalOpened(data),
    workflowRunsOperatorViewConfigClicked: (
      data: WorkflowsRunsOperatorViewConfigClickedProperties,
    ) => new WorkflowsRunsOperatorViewConfigClicked(data),
    workflowRunsRunClicked: (data: WorkflowsRunsRunClickedProperties) =>
      new WorkflowsRunsRunClicked(data),

    workflowRunsRunFlowClicked: (data: WorkflowsRunsRunFlowClickedProperties) =>
      new WorkflowsRunsRunFlowClicked(data),

    workflowRunsRunModalClosed: (data: WorkflowsRunsRunModalClosedProperties) =>
      new WorkflowsRunsRunModalClosed(data),

    workflowRunsRunNumberCardClicked: (data: WorkflowsRunsRunNumberCardClickedProperties) =>
      new WorkflowsRunsRunNumberCardClicked(data),

    workflowRunsRunsHistoryTabClicked: (data: WorkflowsRunsRunsHistoryTabClickedProperties) =>
      new WorkflowsRunsRunsHistoryTabClicked(data),

    workflowRunsRunsTabitemClicked: (data: WorkflowsRunsRunsTabitemClickedProperties) =>
      new WorkflowsRunsRunsTabitemClicked(data),

    workflowRunsScheduleButtonClicked: (data: WorkflowsRunsScheduleButtonClickedProperties) =>
      new WorkflowsRunsScheduleButtonClicked(data),

    workflowRunsScheduleModalClosed: (data: WorkflowsRunsScheduleModalClosedProperties) =>
      new WorkflowsRunsScheduleModalClosed(data),

    workflowRunsScheduleModalOpened: (data: WorkflowsRunsScheduleModalOpenedProperties) =>
      new WorkflowsRunsScheduleModalOpened(data),

    workflowRunsUpcomingTabitemClicked: (data: WorkflowsRunsUpcomingTabitemClickedProperties) =>
      new WorkflowsRunsUpcomingTabitemClicked(data),
  },
  publish: {
    publishClicked: (data: WorkflowsPublishWorkflowClickedProperties) =>
      new WorkflowsPublishWorkflowClicked(data),
  },
};
