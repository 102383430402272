import {
  APITokensRoutesApiFactory,
  InvitationApiFactory,
  PaymentRoutesApiFactory,
  UserManagementApiFactory,
  UserProfilesApiFactory,
} from '@/main/generated/api';
import { apiConfiguration } from './api';

export const userManagementApi = UserManagementApiFactory(apiConfiguration);

export const invitationApi = InvitationApiFactory(apiConfiguration);

export const userProfilesApi = UserProfilesApiFactory(apiConfiguration);

export const apiTokensRoutesApi = APITokensRoutesApiFactory(apiConfiguration);

export const paymentRoutesApi = PaymentRoutesApiFactory(apiConfiguration);
