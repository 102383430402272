import { IconInfoCircle } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { Alert, Center, Loader } from '@/shared/design-system/v2';
import { AnalysisStateNames } from '../../generated/api';
import { useDatasetAnalysisTasksQuery } from '../../queries/datasets/analysis/tasks';
import { DatasetAnalysisStatusPanel } from './DatasetAnalysisStatusPanel';

export interface DatasetAnalysisStatusPanelContainerProps {
  datasetId: string;
  showAnalysesErrors?: boolean;
}

export const DatasetAnalysisStatusPanelContainer = ({
  datasetId,
  showAnalysesErrors = false,
}: DatasetAnalysisStatusPanelContainerProps) => {
  const { isLoading, isError, data } = useDatasetAnalysisTasksQuery(datasetId);

  const [showErrors, setShowErrors] = useState(showAnalysesErrors);

  const tasks = useMemo(() => data?.tasks ?? [], [data]);

  useEffect(() => {
    const errorCount = tasks.filter(task => task.data?.state === AnalysisStateNames.Error).length;
    if (errorCount === tasks.length && !isLoading && !isError) {
      setShowErrors(true);
    }
  }, [tasks, isLoading, isError]);

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader text="Loading analysis status" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Alert m="lg" icon={<IconInfoCircle size={14} />} color="red">
        Failed to load analysis tasks
      </Alert>
    );
  }

  return (
    <DatasetAnalysisStatusPanel
      datasetId={datasetId}
      tasks={tasks}
      defaultFilter={showErrors ? AnalysisStateNames.Error : ''}
    />
  );
};
