import { Center, Loader } from '@/shared/design-system/v2';
import { useDataSourceCredentialList } from '../../../queries/datasets/registration';
import { PageEmptyState } from '../../common/EmptyState';
import { PageErrorScreen } from '../../common/ErrorState';
import { CredentialsTable } from './CredentialsTable';

export const CredentialListContainer = (): JSX.Element => {
  const { isLoading, isError, data: dataSourceCredentialList } = useDataSourceCredentialList();

  if (isLoading) {
    return (
      <Center h={200}>
        <Loader text="Loading credentials" />
      </Center>
    );
  }

  if (isError) {
    return (
      <PageErrorScreen
        title="Unable to load credentials"
        subtitle="If the issue persists, please contact support"
      />
    );
  }

  if (!dataSourceCredentialList || !dataSourceCredentialList.length) {
    return (
      <PageEmptyState
        iconName="credential"
        iconSize="lg"
        title="No credentials added yet"
        subtitle="Add new credential to be able to access your cloud provider"
      />
    );
  }

  return <CredentialsTable data={dataSourceCredentialList} />;
};
