import { ValueGetterParams } from '@ag-grid-community/core';
import { ColDef } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import {
  dateFilterParams,
  numberFilterParams,
  textFilterParams,
} from '@/shared/design-system/v2/core/data-display/table/filters/util';
import {
  ListFilterTypes,
  SupportedFilters,
  filterTypeToAgGridFilter,
  operatorToFilterType,
  supportedOperators,
} from '@/shared/lib/list';
import { AllowedFilters, ArtifactFilterState } from '../../../generated/api';
import { Dataset } from '../../../queries/datasets/list';
import { CreateDateRenderer } from '../../common/table/cell-renderers/CreateDateRenderer';
import { ActionsRenderer } from './cell-renderers/ActionsRenderer';
import { AnalysisRenderer } from './cell-renderers/AnalysisRenderer';
import { DataFamilyRenderer } from './cell-renderers/DataFamilyRenderer';
import { DataQualityRenderer } from './cell-renderers/DataQualityRenderer';
import { TagsRenderer } from './cell-renderers/TagsRenderer';
import { TitleRenderer } from './cell-renderers/TitleRenderer';
import { TypeRenderer } from './cell-renderers/TypeRenderer';
import { ViewTasksRenderer } from './cell-renderers/ViewTasksRenderer';
import { DataFamilyFilter } from './filters/data-family/DataFamilyFilter';
import { TagsFilter } from './filters/tags/TagsFilter';
import { TypeFilter } from './filters/type/TypeFilter';

export const allDatasetColumns: ColDef<Dataset>[] = [
  {
    colId: 'name',
    field: 'name',
    checkboxSelection: true,
    filter: false,
    sortable: false,
    filterParams: textFilterParams,
    cellRenderer: TitleRenderer,
    pinned: 'left' as const,
  },
  {
    colId: 'dataType',
    field: 'dataType',
    headerName: 'Type',
    sortable: false,
    cellRenderer: TypeRenderer,
    width: 216,
    filter: TypeFilter,
  },
  {
    colId: 'dataQualityScore',
    field: 'dataQualityScore',
    headerName: 'Data quality',
    filter: false,
    sortable: false,
    cellRenderer: DataQualityRenderer,
    filterParams: numberFilterParams,
    width: 160,
    filterValueGetter: (p: ValueGetterParams<Dataset>) => {
      if (!p.data?.dataQualityScore || p.data.dataQualityScore === -1) {
        return -1;
      }

      return p.data.dataQualityScore * 100;
    },
  },
  {
    colId: 'createDate',
    field: 'createDate',
    headerName: 'Registered',
    cellRenderer: CreateDateRenderer,
    filter: false,
    sortable: false,

    filterParams: dateFilterParams,
  },
  {
    colId: 'dataFamily',
    field: 'dataFamily',
    headerName: 'Family',
    sortable: false,
    cellRenderer: DataFamilyRenderer,
    filter: DataFamilyFilter,
    flex: 1,
    minWidth: 140,
  },
  {
    colId: 'tags',
    field: 'tags',
    headerName: 'Tags',
    filter: TagsFilter,
    sortable: false,
    cellRenderer: TagsRenderer,
    flex: 1,
    minWidth: 180,
  },
  {
    colId: 'analysis',
    field: 'analysis',
    headerName: 'Status',
    cellRenderer: AnalysisRenderer,
    width: 176,
    filter: false,
    sortable: false,
  },
  {
    sortable: false,
    filter: false,
    resizable: false,
    cellRenderer: ViewTasksRenderer,
    width: 80,
    pinned: 'right' as const,
  },
  {
    sortable: false,
    filter: false,
    resizable: false,
    cellRenderer: ActionsRenderer,
    cellRendererParams: {},
    width: 48,
    pinned: 'right' as const,
  },
];

// TODO:: This should be moved <to BE and come as fieldType in AllowedFilters
const fieldToFilterType: Record<string, ListFilterTypes> = {
  name: ListFilterTypes.Text,
  type: ListFilterTypes.Enum,
  dataType: ListFilterTypes.Number,
  createDate: ListFilterTypes.Date,
  dataQualityScore: ListFilterTypes.Number,
  dataFamilyId: ListFilterTypes.Relational,
  tags: ListFilterTypes.Relational,
};

const getFilterParamsForFilterType = (filterType: ListFilterTypes) => {
  if (filterType === ListFilterTypes.Text) {
    return textFilterParams;
  }

  if (filterType === ListFilterTypes.Number) {
    return numberFilterParams;
  }

  if (filterType === ListFilterTypes.Date) {
    return dateFilterParams;
  }

  return {};
};

export const getDatasetColumns = (
  state: ArtifactFilterState,
  config: AllowedFilters,
  columns: ColDef<Dataset>[],
): ColDef<Dataset>[] =>
  columns.map(col => {
    const isSortable = col.colId ? config.sortable.includes(col.colId) : false;
    const filter = col.colId
      ? config.filters.find(filter => filter.field === col.colId)
      : undefined;
    const fieldFilterType = filter ? fieldToFilterType[col.colId as string] : undefined;
    const cellRendererParams =
      col.cellRenderer === ActionsRenderer
        ? {
            ...col.cellRendererParams,
            state: state,
          }
        : undefined;

    if (col.colId && ['tags', 'dataType', 'dataFamily'].includes(col.colId)) {
      return col;
    }

    return {
      ...col,
      filter: fieldFilterType
        ? filterTypeToAgGridFilter[fieldFilterType as SupportedFilters]
        : false,
      filterParams:
        filter && fieldFilterType
          ? {
              ...getFilterParamsForFilterType(fieldFilterType),
              filterOptions: filter.allowedOperators
                .filter(allowedOperator => supportedOperators.includes(allowedOperator))
                .map(operator => operatorToFilterType[operator as supportedOperators]),
            }
          : undefined,
      cellRendererParams: cellRendererParams,
      sortable: isSortable,
    };
  });
