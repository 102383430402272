import first from 'lodash/first';
import { useEffect, useMemo, useState } from 'react';
import { Horizontal } from '@/shared/design-system/v2';
import { useAddChartsContext } from '../../../../../contexts/snippets/add-charts/AddChartsContext';
import { useModelClassRecordingsQuery } from '../../../../../queries/recordings';
import { PageErrorScreen } from '../../../../common/ErrorState';
import { EmptyRecording } from '../../../../recording/empty-recording/EmptyRecording';
import { ChartsTypesSideBar } from '../analysers/ChartsTypesSideBar';
import { EvaluationsCharts } from './EvaluationsCharts';

export const EvaluationsChartsContainer = () => {
  const { selectedEvaluationClassType } = useAddChartsContext();

  const { isLoading, isError, isFetching, data, fetchNextPage, hasNextPage } =
    useModelClassRecordingsQuery(selectedEvaluationClassType ?? '');

  const [selectedEvaluation, setSelectedEvaluation] = useState('');

  const evaluations = useMemo(() => data?.pages.flatMap(page => page) || [], [data]);

  const chartTypes = useMemo(
    () => ({
      Evaluations: evaluations.map(evl => ({ name: evl.name, id: evl.evaluationId })),
    }),
    [evaluations],
  );

  useEffect(() => {
    setSelectedEvaluation(prevSelected => prevSelected || (first(evaluations)?.evaluationId ?? ''));
  }, [evaluations]);

  if (isError) {
    return (
      <PageErrorScreen
        title="Unable to load evaluations"
        subtitle="There was an error retrieving evaluations for the current workspace. Try refreshing."
        iconName="evaluation"
      />
    );
  }

  const showEmptyScreen = !isLoading && !!selectedEvaluationClassType && !evaluations?.length;
  if (showEmptyScreen) {
    return <EmptyRecording />;
  }

  return (
    <Horizontal position="apart">
      <ChartsTypesSideBar
        isLoading={isLoading}
        charts={chartTypes}
        selectedChartId={selectedEvaluation}
        onChartClick={setSelectedEvaluation}
        isFetchingMore={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        width="40%"
      />
      {selectedEvaluation && <EvaluationsCharts evaluationId={selectedEvaluation} />}
    </Horizontal>
  );
};
