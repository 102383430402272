import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { invitationApi } from '@/shared/lib/api';
import { HTTPError } from '@/shared/lib/api/api';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  InvitationRequest,
  InviteUserToWorkspaceResponse,
  InviteUsersToWorkspaceResponse,
  RevokeUserInviteFromWorkspaceRequest,
  RevokeUserInviteFromWorkspaceResponse,
} from '../../generated/api';
import { INVITE, WORKSPACE } from '../queryConstants';

export const useUserPendingInvitesQuery = () => {
  const { userId } = useAppMetadata();

  return useQuery(
    [INVITE.PENDING_USER_INVITE, userId],
    () => invitationApi.getPendingInvitesForUserV1(userId),
    {
      refetchInterval: 60 * 1000,
      enabled: !!userId,
      select: data => data.data.invitations,
    },
  );
};

export const useAcceptInviteToWorkspaceMutation = () => {
  const queryClient = useQueryClient();
  const { userId } = useAppMetadata();
  return useMutation<AxiosResponse, AxiosError<HTTPError>, string>(
    (inviteId: string) => invitationApi.acceptInviteToWorkspaceV1(userId, inviteId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVITE.PENDING_USER_INVITE, userId]);
        queryClient.invalidateQueries([WORKSPACE.LIST_USER_WORKSPACE, userId]);
        queryClient.invalidateQueries([WORKSPACE.LIST_USER_WORKSPACE_WITH_MEMBER_DETAILS, userId]);
      },
    },
  );
};

export const useCreateInviteToWorkspaceMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<InviteUsersToWorkspaceResponse>,
    AxiosError<HTTPError>,
    InvitationRequest
  >(
    (invitationReq: InvitationRequest) =>
      invitationApi.createUserInviteToWorkspaceV1(workspaceId, invitationReq),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVITE.GET_WORKSPACE_INVITES, workspaceId]);
      },
    },
  );
};

export const useWorkspaceInviteQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    [INVITE.GET_WORKSPACE_INVITES, workspaceId],
    () => invitationApi.getWorkspaceInvitesV1(workspaceId),
    {
      staleTime: 0,
      select: data => data.data.invitations,
      enabled: !!workspaceId,
    },
  );
};

export const useWorkspaceInviteResendMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<InviteUserToWorkspaceResponse>, AxiosError<HTTPError>, string>(
    (inviteId: string) => invitationApi.resendUserInviteV1(inviteId, workspaceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVITE.GET_WORKSPACE_INVITES, workspaceId]);
      },
    },
  );
};

export const useWorkspaceInviteRevokeMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<RevokeUserInviteFromWorkspaceResponse>,
    AxiosError<HTTPError>,
    RevokeUserInviteFromWorkspaceRequest
  >(
    (invites: RevokeUserInviteFromWorkspaceRequest) =>
      invitationApi.revokeUserInviteFromWorkspaceV1(workspaceId, invites),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVITE.GET_WORKSPACE_INVITES, workspaceId]);
      },
    },
  );
};
