import { IconFlask2 } from '@tabler/icons-react';
import { Box, Horizontal, Text, Vertical, useMarkovTheme } from '@/shared/design-system/v2';
import { ThreadsItemProvider } from '../../../contexts/chat-with-data/ThreadsItemContext';
import { QuestionTypeProvider } from '../../../contexts/data-analytics/QuestionTypeContext';
import { QuestionTypes } from '../../../generated/api';
import { CustomAppChat } from '../custom-apps/CustomAppChat';
import { ResetConversation } from './ResetConversation';

export interface CustomAppChatPreviewProps {
  appId: string;
}

export const CustomAppChatPreview = ({ appId }: CustomAppChatPreviewProps) => {
  const theme = useMarkovTheme();
  return (
    <QuestionTypeProvider questionType={QuestionTypes.Text}>
      {/* Initial value of undefined is passed to start a new thread 
      every time the app builder interface is loaded */}
      <ThreadsItemProvider resourceId={appId}>
        <Vertical h="100%" pos="relative">
          <Horizontal
            position="apart"
            p="lg"
            sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[2]}` })}
          >
            <Horizontal>
              <IconFlask2 size={32} color={theme.colors.gray[6]} />
              <Text variant="heading04" color="gray.9">
                Playground
              </Text>
            </Horizontal>
            <ResetConversation appId={appId} />
          </Horizontal>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {/* PromptChangedOverlay is commented out because
            we need to implement the 'Don't show the message again' functionality.
            Without this, overlay appearing on every keystroke may annoy the user */}
            {/* <PromptChangedOverlayContainer prompt={prompt} appId={appId} /> */}
            <CustomAppChat appId={appId} />
          </Box>
        </Vertical>
      </ThreadsItemProvider>
    </QuestionTypeProvider>
  );
};
