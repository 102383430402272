import { Alert, Center } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import {
  Navigate,
  RouteProps,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { logger } from '@/shared/initializers/logging';
import { Loading } from '../components/common/Loading';
import { StorageType } from '../generated/api';
import { useIDPExchangeForOAuth } from '../queries/connectors/connector-oauth';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { useAbsoluteRoutes } from './hooks';

interface RedirectToExistingConnectorProps {
  workspaceId: string;
  connectorType?: StorageType;
}

export const RedirectToExistingConnector = ({ workspaceId }: RedirectToExistingConnectorProps) => {
  const navigate = useNavigate();
  const datasourceRoute = `/${workspaceId}/${getRoute(AppRoutes.DATA_SOURCES, {
    tab: 'connectors',
  })}`;

  useTimeout(() => navigate(datasourceRoute), 1000, {
    autoInvoke: true,
  });

  return (
    <Center w="100%" h="100%">
      <Alert color="blue">
        Account already connected. Redirecting to connectors page where you can add source from this
        account
      </Alert>
    </Center>
  );
};
export const RedirectToConnectorsTab = ({ workspaceId }: RedirectToExistingConnectorProps) => {
  const navigate = useNavigate();
  const datasourceRoute = `/${workspaceId}/${getRoute(AppRoutes.DATA_SOURCES, {
    tab: 'connectors',
  })}`;

  useTimeout(() => navigate(datasourceRoute), 1000, {
    autoInvoke: true,
  });
  return null;
};

export const AuthRedirect = () => {
  const { provider = '', storageType } = useParams();
  const [searchParams] = useSearchParams();
  const { getDataSourcesRoute } = useAbsoluteRoutes();
  const workspaceId = searchParams.get('state') ?? '';
  const code = searchParams.get('code') ?? '';

  if (
    !workspaceId ||
    !storageType ||
    !Object.values(StorageType).includes(storageType as StorageType)
  ) {
    logger.error('Workspace ID and Connector Type are required but not provided.');
  }

  const { data, isError, isLoading } = useIDPExchangeForOAuth(
    workspaceId,
    code,
    provider,
    storageType as StorageType,
  );

  if (isLoading) {
    return (
      <Center w="100%" h="100%">
        <Loading />
        Authenticating google account
      </Center>
    );
  }

  if (isError) {
    return (
      <Center w="100%" h="100%">
        <Alert color="red">There has been an error validating account credentials.</Alert>
      </Center>
    );
  }

  if (data.response?.connectorId) {
    if (data?.message?.code === 'EXISTING') {
      return <RedirectToExistingConnector workspaceId={workspaceId} />;
    }
    return <RedirectToConnectorsTab workspaceId={workspaceId} />;
  }
  return <Navigate to={getDataSourcesRoute()} />;
};

const oauthRoutes: RouteProps[] = [
  {
    // Currently all providers and connectors fit here, but it is possible we may have
    // to extend it
    path: '/:provider/:storageType',
    element: <AuthRedirect />,
  },
];

export const OAuthRouter = () => <Routes>{oauthRoutes.map(renderAuthRoute)}</Routes>;
