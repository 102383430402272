import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme } from '@/shared/design-system';
import { ModalsProvider, ThemeProvider } from '@/shared/design-system/v2';
import { addFlagProvider } from '@/shared/initializers/flag-provider';
import { queryClientConfig } from '@/shared/lib/query';
import './App.css';
import { ErrorBoundary, ErrorFallback } from './ErrorBoundary';
import { AppMetadataContextProvider } from './contexts/app-metadata/AppMetadata';
import { AuthProvider } from './contexts/auth-provider/Auth';
import { DataUploadProvider } from './contexts/data-upload/DataUpload';
import { AppRouter } from './router/AppRouter';

const queryClient = new QueryClient(queryClientConfig);

const MarkovMLApp = () => (
  <BrowserRouter>
    <ErrorBoundary fallback={ErrorFallback}>
      <AuthProvider>
        <AppMetadataContextProvider>
          <QueryClientProvider client={queryClient}>
            <DataUploadProvider>
              <ThemeProvider>
                <StyledThemeProvider theme={theme}>
                  <ModalsProvider modalProps={{ centered: true, padding: 24 }}>
                    <AppRouter />
                  </ModalsProvider>
                </StyledThemeProvider>
              </ThemeProvider>
            </DataUploadProvider>
            {import.meta.env.VITE_APP_MODE === 'debug' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </AppMetadataContextProvider>
      </AuthProvider>
    </ErrorBoundary>
  </BrowserRouter>
);

export const App = addFlagProvider(MarkovMLApp);
