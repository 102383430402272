import { useLocalStorage } from '@mantine/hooks';
import {
  AnswerTypes,
  DataResourceType,
  MKVLibraryStorageResourceModel,
  PostgresStorageResourceModel,
  QuestionTypes,
  SnowflakeStorageResourceModel,
  StorageType,
} from '../../generated/api';
import { ThreadProps } from '../common/chat-with-data/menu/panels/threads-panel/ThreadsItem';
import { StorageMetadataModel } from '../data-sources/cell-renderers/config';

export const supportedConnectorTypes = [
  StorageType.Postgresql,
  StorageType.Snowflake,
  StorageType.MarkovLibrary,
];

export const supportedResourceTypes = [
  DataResourceType.Csv,
  DataResourceType.Table,
  DataResourceType.Database,
  DataResourceType.Default,
];

export enum SuggestionType {
  TABLE = 'TABLE',
  COLUMN = 'COLUMN',
}

export interface ChatItem {
  id: string;
  question: string;
  questionType: QuestionTypes;
  answer: string;
  answerType: AnswerTypes;
  isLoading: boolean;
  timestamp: number;
}

export interface Thread {
  id: string;
  content: ChatItem[];
}

const KEY_PREFIX = '__markov__';

const getActiveThreadStorageKey = (resourceId: string) =>
  `${KEY_PREFIX}data_analytics_active_thread_history_${resourceId}`;

const getActiveDataPreviewTableKey = (resourceId: string) =>
  `${KEY_PREFIX}data_analytics_active_data_preview_table_history_${resourceId}`;

const getSelectedDataPreviewTablesKey = (resourceId: string) =>
  `${KEY_PREFIX}data_analytics_selected_data_preview_tables_history_${resourceId}`;

export const useDataAnalyticsActiveThreadStorage = (resourceId: string) =>
  useLocalStorage<ThreadProps | undefined>({
    key: getActiveThreadStorageKey(resourceId),
    defaultValue: undefined,
    getInitialValueInEffect: false,
    deserialize: value => (value === 'undefined' ? undefined : JSON.parse(value)),
  });

export const useDataAnalyticsActiveDataPreviewTableStorage = (resourceId: string) =>
  useLocalStorage<StorageMetadataModel | undefined>({
    key: getActiveDataPreviewTableKey(resourceId),
    defaultValue: undefined,
    getInitialValueInEffect: false,
    deserialize: value => (value === 'undefined' ? undefined : JSON.parse(value)),
  });

export const useDataAnalyticsSelectedDataPreviewTablesStorage = (resourceId: string) =>
  useLocalStorage<StorageMetadataModel[]>({
    key: getSelectedDataPreviewTablesKey(resourceId),
    defaultValue: [],
    getInitialValueInEffect: false,
  });

export const DATA_ANALYTICS_NAME = 'Data Insights';

interface LeafResourceSchemaTable {
  schema: string;
  table: string;
}

interface DataAnalyticsStorageConfig {
  getLeafResourceSchemaAndTable: (metadata: StorageMetadataModel) => LeafResourceSchemaTable;
}

export const dataAnalyticsStorageConfigMap: Partial<
  Record<StorageType, DataAnalyticsStorageConfig>
> = {
  [StorageType.Snowflake]: {
    getLeafResourceSchemaAndTable: (metadata): LeafResourceSchemaTable => {
      const { snowflakeSchema = '' } = metadata as SnowflakeStorageResourceModel;
      const { tableName = '' } = metadata as SnowflakeStorageResourceModel;
      return { schema: snowflakeSchema, table: tableName };
    },
  },
  [StorageType.Postgresql]: {
    getLeafResourceSchemaAndTable: (metadata): LeafResourceSchemaTable => {
      const { tableName = '' } = metadata as PostgresStorageResourceModel;
      return { schema: 'Public', table: tableName };
    },
  },
  [StorageType.MarkovLibrary]: {
    getLeafResourceSchemaAndTable: (metadata): LeafResourceSchemaTable => {
      const { filePath } = metadata as MKVLibraryStorageResourceModel;
      return { schema: '', table: filePath };
    },
  },
};
