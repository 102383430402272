import first from 'lodash/first';
import startCase from 'lodash/startCase';
import values from 'lodash/values';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Horizontal } from '@/shared/design-system/v2';
import { useAddChartsContext } from '../../../../../contexts/snippets/add-charts/AddChartsContext';
import { SegmentType } from '../../../../../generated/api';
import { useVariablesListQuery } from '../../../../../queries/datasets/statistics/variables';
import { DatasetChartSelectionStep, useSnippetDetail } from '../../context/SnippetContext';
import { useGetAllAnalysers } from '../hooks/dataset';
import { VIZ_NAMES } from '../hooks/util';
import { ChartsList } from './ChartsList';
import { ChartsTypesSideBar } from './ChartsTypesSideBar';

export enum ChartTypes {
  BASIC_INFO = 'BASIC INFO',
  DATA_PROFILE = 'DATA PROFILE',
  ANALYSIS = 'ANALYSIS',
}

export const SelectAnalysisContainer = () => {
  const { currSelectedDataset: datasetId, selectCurrDataset } = useAddChartsContext();
  const { selectedDatasets, addChartStep } = useSnippetDetail();
  const {
    segments = [],
    target,
    dataQualityScore,
  } = selectedDatasets?.filter(d => d.datasetId === datasetId)?.[0] ?? {};
  const [selectedChart, setChartType] = useState('');
  const [selectedSegment, setSelectedSegment] = useState(first(segments) ?? '');

  const { data } = useVariablesListQuery(datasetId, selectedSegment as SegmentType);
  const variablesList = useMemo(() => data?.response?.variables ?? [], [data]);
  const variables = variablesList.map(({ name, columnType }) => ({
    value: name,
    label: `${name} (${startCase(columnType)})`,
  }));
  const [selectedVariable, setSelectedVariable] = useState('');

  const isUnlabeledDataset = target === 'Unlabeled';
  const { allChartsTypes, chartsList, isLoading } = useGetAllAnalysers(
    selectedChart,
    selectedSegment as SegmentType,
    selectedVariable,
    isUnlabeledDataset,
    dataQualityScore,
  );

  const isAnalysisType = useMemo(
    () => allChartsTypes.ANALYSIS.some(type => type.id === selectedChart),
    [allChartsTypes.ANALYSIS, selectedChart],
  );

  const showSegments = useMemo(
    () =>
      ![
        VIZ_NAMES.DATASET_EMBEDDINGS,
        VIZ_NAMES.DATASET_CLASS_QUALITY_SCORE,
        VIZ_NAMES.DATASET_BASELINE_MODEL_STATS,
        VIZ_NAMES.DATASET_OVERLAPPING_CLASS_METRICS,
        VIZ_NAMES.DATASET_TOP_K_FEATURES,
      ].includes(selectedChart) && !isAnalysisType,
    [isAnalysisType, selectedChart],
  );

  const showVariableSelector = useMemo(
    () => selectedChart === VIZ_NAMES.DATASET_DATA_PROFILE_VARIABLE,
    [selectedChart],
  );

  const resetStates = useCallback(() => {
    setSelectedSegment(first(segments) ?? '');
  }, [segments]);

  useEffect(() => {
    setSelectedVariable(first(variablesList)?.name ?? '');
  }, [variablesList]);

  useEffect(() => {
    if (!isLoading) {
      setChartType(
        prevSelection =>
          prevSelection || (first(values(allChartsTypes).flatMap(types => types))?.id ?? ''),
      );
    }
  }, [allChartsTypes, isLoading]);

  useEffect(() => {
    if (addChartStep === DatasetChartSelectionStep.AnalyserSelection) {
      selectCurrDataset(first(selectedDatasets)?.datasetId ?? '');
    }
  }, [selectCurrDataset, selectedDatasets, addChartStep]);

  useEffect(() => {
    resetStates();
  }, [datasetId, resetStates]);

  return (
    <Horizontal position="apart">
      <ChartsTypesSideBar
        isLoading={isLoading}
        charts={allChartsTypes}
        selectedChartId={selectedChart}
        onChartClick={setChartType}
      />
      <ChartsList
        isLoading={isLoading}
        charts={chartsList}
        gridCols={isAnalysisType || showVariableSelector ? 3 : 1}
        segments={showSegments ? segments : []}
        selectedSegment={selectedSegment}
        setSelectedSegment={setSelectedSegment}
        variables={showVariableSelector ? variables : []}
        selectedVariable={selectedVariable}
        setSelectedVariable={setSelectedVariable}
      />
    </Horizontal>
  );
};
