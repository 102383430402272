import {
  Horizontal,
  Pagination,
  ScrollArea,
  SimpleGrid,
  Text,
  Vertical,
} from '@/shared/design-system/v2';
import { DEFAULT_PAGE_SIZE } from '@/shared/design-system/v2/core/data-display/table/util';
import { ArtifactStateType, DatasetOperationModel, WorkspaceMember } from '../../generated/api';
import { DataLabelingCard } from './DataLabelingCard';

interface DataLabelingListProps {
  state: ArtifactStateType;
  data: DatasetOperationModel[];
  totalRecords: number;
  workspaceMembers: WorkspaceMember[];
  pageNumber: number;
  handlePageNumberChange: (pageNumber: number) => void;
}

export const DataLabelingList = ({
  state,
  data,
  totalRecords,
  workspaceMembers,
  pageNumber,
  handlePageNumberChange,
}: DataLabelingListProps) => {
  const startRange = (pageNumber - 1) * DEFAULT_PAGE_SIZE + 1;
  const endRange = startRange + data.length - 1;
  const dataRangeText = `${startRange}${data.length > 1 ? `-${endRange}` : ''}`;

  return (
    <Vertical h="100%">
      <Horizontal position="right">
        <Horizontal>
          <Text variant="subTitle05" color="gray.6">
            Showing {dataRangeText} of {totalRecords}
          </Text>
          <Pagination
            value={pageNumber}
            onChange={handlePageNumberChange}
            total={Math.ceil(totalRecords / DEFAULT_PAGE_SIZE)}
          />
        </Horizontal>
      </Horizontal>
      <ScrollArea h="calc(100% - 52px)" offsetScrollbars>
        <SimpleGrid
          h="100%"
          cols={4}
          breakpoints={[
            { maxWidth: 'lg', cols: 3 },
            { maxWidth: 'md', cols: 2 },
            { maxWidth: 'xs', cols: 1 },
          ]}
        >
          {data.map(datum => (
            <DataLabelingCard
              key={datum.dataOperationId}
              state={state}
              data={datum}
              workspaceMembers={workspaceMembers}
            />
          ))}
        </SimpleGrid>
      </ScrollArea>
    </Vertical>
  );
};
