import { IconBrandAzure, IconInfoCircle } from '@tabler/icons-react';
import {
  Alert,
  Box,
  Horizontal,
  PasswordInput,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
  useForm,
} from '@/shared/design-system/v2';
import { CreateConnectorRequest, StorageType } from '../../../generated/api';
import { useCreateConnectorMutation } from '../../../queries/connectors';
import { CreateConnectorModalActions } from './CreateConnectorModalActions';

const useAzureBlobConnectorForm = () =>
  useForm({
    initialValues: {
      connectorName: '',
      StorageAccountName: '',
      accessKey: '',
    },
    validate: {
      connectorName: value => {
        if (!value.trim()) {
          return 'Connector name cannot be empty';
        }
        return null;
      },
      StorageAccountName: value => {
        if (!value.trim()) {
          return 'Storage Account cannot be empty';
        }
        return null;
      },
      accessKey: value => {
        if (!value.trim()) {
          return 'Access key cannot be empty';
        }
        return null;
      },
    },
    transformValues: values => ({
      connectorName: values.connectorName.trim(),
      StorageAccountName: values.StorageAccountName.trim(),
      accessKey: values.accessKey.trim(),
    }),
  });

interface AzureBlobConnectorModalProps {
  onClickBack?: () => void;
  onCreate?: (connectorId: string) => void;
  onClose?: () => void;
}

export const AzureBlobConnectorModal = ({
  onClickBack,
  onCreate,
  onClose = closeAllModals,
}: AzureBlobConnectorModalProps) => {
  const form = useAzureBlobConnectorForm();

  const { mutate: createConnector, isLoading } = useCreateConnectorMutation();

  const handleCreateConnector = () => {
    if (form.validate().hasErrors) {
      return;
    }

    const { connectorName, StorageAccountName, accessKey } = form.getTransformedValues();

    const createReq: CreateConnectorRequest = {
      name: connectorName,
      connectorType: StorageType.AzureBlob,
      credData: {
        accessKey,
        StorageAccountName,
      },
      connectorMetadata: {},
    };

    createConnector(createReq, {
      onError: () => {
        notifications.error('Unable to create connector');
      },
      onSuccess: res => {
        notifications.success('Connector created successfully');
        const connectorId = res.data.response?.connectorId ?? '';
        onCreate?.(connectorId);
        onClose();
      },
    });
  };

  return (
    <Vertical spacing="xl">
      <Box>
        <Alert color="yellow" icon={<IconInfoCircle />}>
          Access credentials will be encrypted and stored securely.
        </Alert>
      </Box>
      <Horizontal>
        <Text variant="subTitle03">Service:</Text>
        <Horizontal spacing="sm">
          <IconBrandAzure width={70} />
          <Text variant="subTitle03">Azure Blob</Text>
        </Horizontal>
      </Horizontal>
      <Vertical spacing="sm">
        <TextInput
          required
          ariaLabel="Connector name"
          label="Connector name"
          {...form.getInputProps('connectorName')}
        />
        <TextInput
          required
          ariaLabel="Storage Account Name"
          label="Storage Account Name"
          {...form.getInputProps('storageAccountName')}
        />
        <PasswordInput
          required
          aria-label="Access Key"
          label="Access Key"
          {...form.getInputProps('accessKey')}
        />
      </Vertical>

      <CreateConnectorModalActions
        onClickBack={onClickBack}
        onCreate={handleCreateConnector}
        isCreating={isLoading}
        onClose={onClose}
      />
    </Vertical>
  );
};
