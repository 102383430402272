import { ChipProps } from '@mantine/core';
import { Chip } from '@/shared/design-system/v2';
import { ClusterCounts } from '../../../../queries/datasets/clustering/clusterSelector';
import { useStyles } from './LabelChip.style';

interface LabelChipProps extends ChipProps {
  labelsCount: ClusterCounts[];
  labelColor: string;
}

export const LabelChip = ({ value, labelsCount, labelColor, ...restProps }: LabelChipProps) => {
  const { classes } = useStyles({
    label: value as string,
    labelsCount,
    labelColor,
  });

  return <Chip value={value} {...restProps} classNames={classes} />;
};
