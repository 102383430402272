import { Loader, Text, Vertical } from '@/shared/design-system/v2';
import { getIsTaskPending } from '@/shared/lib/analysis';
import { AnalysisStateNames, SegmentType } from '../../../generated/api';
import { useDatasetSummaryStats } from '../../../queries/datasets/statistics/variables';
import { EmptyState } from '../../common/EmptyState';
import { ErrorState } from '../../common/ErrorState';
import { DataInsightsOverview, DataSummaryType } from './DataInsightsOverview';

interface DataInsightsOverviewContainerProps {
  datasetId: string;
  segmentType: SegmentType;
  filterSummaryType?: DataSummaryType;
  hideHeader?: boolean;
}

export const DataInsightsOverviewContainer = ({
  datasetId,
  segmentType,
  filterSummaryType,
  hideHeader = false,
}: DataInsightsOverviewContainerProps) => {
  const datasetSummaryStatsQuery = useDatasetSummaryStats(datasetId, segmentType);

  if (datasetSummaryStatsQuery.isLoading) {
    return (
      <Vertical h={372} pt="150px" align="center">
        <Loader color="yellow.6" size="lg" />
        <Text>Loading feature statistics</Text>
      </Vertical>
    );
  }

  if (datasetSummaryStatsQuery.isError) {
    // Handle 404 error
    const notFound = datasetSummaryStatsQuery.error?.response?.status === 404;
    const errorMsg = notFound
      ? 'No statistics were found for this dataset'
      : 'There was an error retrieving dataset statistics';

    return <ErrorState compact title="" subtitle={errorMsg} />;
  }

  const analysisState = datasetSummaryStatsQuery.data?.analysisState;

  if (analysisState === AnalysisStateNames.Error) {
    return (
      <ErrorState compact title="" subtitle="There was an error while computing analysis results" />
    );
  }

  // Handle case when analyzer results aren't available yet
  if (getIsTaskPending(analysisState)) {
    return (
      <EmptyState
        compact
        title="Data summary analysis in progress"
        subtitle="Results will become available once the analysis is complete"
        iconName="dataset"
      />
    );
  }

  if (!datasetSummaryStatsQuery.data?.isResultAvailable) {
    return <ErrorState compact title="" subtitle="No statistics were found for this dataset" />;
  }

  const summaryData = datasetSummaryStatsQuery.data;

  return (
    <DataInsightsOverview
      summaryData={summaryData}
      filterSummaryType={filterSummaryType}
      hideHeader={hideHeader}
    />
  );
};
