import HighchartsReact from 'highcharts-react-official';
import startCase from 'lodash/startCase';
import { MutableRefObject } from 'react';
import {
  Box,
  EmptyCorrelations,
  Expand,
  Loader,
  MarkovStarsFull,
  VizErrorState,
} from '@/shared/design-system';
import { ActionIcon, Center } from '@/shared/design-system/v2';
import { ChartTypes } from '../../../../charts';
import { ChartData } from '../../../../charts/config/build';
import { ChartsProvider } from '../../../../charts/context/Context';
import { getColors } from '../../../../charts/utils/colors';
import { DatasetVariableStatsVisualization, SegmentType } from '../../../../generated/api';
import { useGetVariablesChartDataQuery } from '../../../../queries/datasets/statistics/variables';
import { VisualizationComponent } from '../../../analyser/visualization/Visualization';
import { DownloadVisualization } from '../../../analyser/visualization/actions/DownloadVisualization';
import { ErrorState } from '../../../common/ErrorState';
import { InfoMessage } from '../../../common/InfoMessage';
import { transformSeriesName } from '../../util';

interface VariablesChartsProps {
  datasetId: string;
  segmentType: SegmentType;
  variable: string;
  chart: DatasetVariableStatsVisualization;
  onClickExpand?: (explainCurrentWithAI?: boolean) => void;
  hideHeader?: boolean;
  expanded?: boolean;
  groupByTarget: boolean;
  chartRef: MutableRefObject<HighchartsReact.RefObject | null>;
}

const getText = (vizId: string) => {
  switch (vizId) {
    case 'pii_detection':
      return 'No PII detected';
    default:
      return 'No data found';
  }
};

const getTitle = (vizId: string) => {
  switch (vizId) {
    case 'pii_detection':
      return 'PII Detection';
    case 'kde_plot':
      return 'KDE Plot';
    case 'qq_plot':
      return 'Q-Q Plot';
    default:
      return startCase(vizId);
  }
};

export const getChartTitle = (vizId: string, variableName: string) =>
  `${getTitle(vizId)}: ${transformSeriesName(variableName)}`;

export const VariablesCharts = ({
  datasetId,
  segmentType,
  variable,
  chart,
  onClickExpand,
  hideHeader = false,
  expanded = false,
  groupByTarget,
  chartRef,
}: VariablesChartsProps) => {
  const {
    isError: isErrorChartData,
    isLoading: isLoadingChartData,
    data: chartData,
  } = useGetVariablesChartDataQuery(
    datasetId,
    segmentType,
    variable,
    chart.visualizationId,
    groupByTarget,
  );

  const header = (
    <VisualizationComponent.Header
      actions={
        !hideHeader ? (
          <>
            <DownloadVisualization
              fileName={getTitle(chart.visualizationId).replace(/ /g, '_') || 'chart'}
              isDownloadDisabled={
                isLoadingChartData ||
                isErrorChartData ||
                !chartData?.data?.series?.[0]?.data?.length
              }
            />
            {!!onClickExpand && (
              <ActionIcon
                color="gray.6"
                onClick={() => {
                  onClickExpand();
                }}
              >
                <Expand />
              </ActionIcon>
            )}
          </>
        ) : null
      }
    >
      {!hideHeader && (
        <Center ml="60px">
          <VisualizationComponent.Header.Title
            title={getChartTitle(chart.visualizationId, variable)}
            titleProps={{ variant: 'subTitle04', color: 'gray.8' }}
          />
        </Center>
      )}
    </VisualizationComponent.Header>
  );

  let content = null;
  if (isLoadingChartData) {
    content = (
      <Center h={280}>
        <Loader text="Loading data..." />
      </Center>
    );
  }

  if (isErrorChartData) {
    content = (
      <Box height={280} display="flex" flexDirection="column" alignItems="center" m="auto">
        <VizErrorState />
        <InfoMessage text="Error loading chart data" />
      </Box>
    );
  }

  if (!isLoadingChartData && !chartData?.data?.series?.[0]?.data?.length) {
    content = (
      <Box height={260} display="flex" flexDirection="column" alignItems="center" m="auto">
        <ErrorState
          compact
          icon={<EmptyCorrelations width={120} height={120} />}
          title=""
          subtitle={getText(chart.visualizationId)}
        />
      </Box>
    );
  } else if (chartData?.data) {
    content = (
      <VisualizationComponent.Chart
        colors={getColors(5)}
        data={chartData?.data as ChartData}
        type={
          groupByTarget && chart.visualizationId === 'frequency_bar_chart'
            ? ChartTypes.GROUPED_COLUMN
            : (chart.chartType.toUpperCase() as ChartTypes)
        }
        enableLegends={chart.visualizationId === 'pie_chart' && !expanded ? false : true}
        colorByPoint={false}
        yAxisOptions={{
          title:
            // KDE plot uses column chart but shows probability differential, not counts
            chart.visualizationId === 'kde_plot'
              ? 'Probability'
              : chart.chartType.toUpperCase() === 'COLUMN'
              ? 'Count'
              : '',
        }}
        dataLabelStyle={
          chart.visualizationId === 'kde_plot'
            ? {
                display: 'none',
              }
            : chart.visualizationId === 'frequency_bar_chart'
            ? {
                fontSize: '12px',
                fontWeight: 600,
              }
            : undefined
        }
      />
    );
  }

  const footer = onClickExpand && (
    <ActionIcon
      color="gray.6"
      onClick={() => onClickExpand(true)}
      disabled={
        isLoadingChartData || isErrorChartData || !chartData?.data?.series?.[0]?.data?.length
      }
      pos="absolute"
      bottom={8}
      right={8}
      size={20}
      aria-label="explain-with-ai"
    >
      <MarkovStarsFull />
    </ActionIcon>
  );

  return (
    <ChartsProvider hcRef={chartRef}>
      <VisualizationComponent
        cardProps={{
          shadow: !expanded ? 'md' : '',
          withBorder: !expanded,
          sx: {
            height: !expanded ? 'inherit' : '100%',
          },
        }}
      >
        {header}
        {content}
        {footer}
      </VisualizationComponent>
    </ChartsProvider>
  );
};
