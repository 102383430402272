import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useRef } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Horizontal,
  Popover,
  ScrollArea,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { ModelArtifactColumnSchemaResponse } from '../../../../generated/api';

interface ModelSamplesProps {
  samples: string[];
  modelInputSchema: ModelArtifactColumnSchemaResponse[];
  onSampleSelect: (text: string) => void;
  verticalLayout?: boolean;
}

export const ModelSamples = ({
  samples,
  modelInputSchema,
  onSampleSelect,
  verticalLayout = false,
}: ModelSamplesProps) => {
  const theme = useMarkovTheme();
  const viewport = useRef<HTMLDivElement>(null);

  const handleSampleClick = (text: string) => () => onSampleSelect(text);

  const featuresOrder = modelInputSchema
    .slice(0, 6)
    .map(({ featureName }) => featureName)
    .join(', ');

  const restFeatures = modelInputSchema.slice(6).map(({ featureName }) => featureName);

  const scroll = (dir: 'left' | 'right') => {
    viewport?.current?.scrollTo({
      left: viewport.current.scrollLeft + viewport.current.clientWidth * (dir === 'right' ? 1 : -1),
      behavior: 'smooth',
    });
  };

  const allSamples = (
    <Horizontal w="calc(100% - 132px)" noWrap>
      <ActionIcon
        size="lg"
        variant="filled"
        color="white.0"
        radius="xl"
        onClick={() => scroll('left')}
      >
        <IconChevronLeft color={theme.colors.gray[7]} size={18} />
      </ActionIcon>
      <ScrollArea viewportRef={viewport} scrollbarSize={0}>
        <Horizontal noWrap>
          {samples.map((text, idx) => (
            <Button
              key={idx}
              onClick={handleSampleClick(text)}
              maw={240}
              bg="white.0"
              variant="subtle"
              shape="rounded"
              sx={theme => ({ border: `1px solid ${theme.colors.gray[1]}` })}
            >
              <Text
                variant="small01"
                color="gray.8"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <Text span color="gray.6" variant="small01">
                  Sample {idx + 1}:{' '}
                </Text>
                {text}
              </Text>
            </Button>
          ))}
        </Horizontal>
      </ScrollArea>
      <ActionIcon
        size="lg"
        variant="filled"
        color="white.0"
        radius="xl"
        onClick={() => scroll('right')}
      >
        <IconChevronRight color={theme.colors.gray[7]} size={18} />
      </ActionIcon>
    </Horizontal>
  );

  return (
    <Vertical spacing="sm">
      {verticalLayout ? (
        <Vertical>
          <Text align="center" variant="subTitle04" color="gray.7" sx={{ whiteSpace: 'nowrap' }}>
            Try these samples:
          </Text>
          {allSamples}
        </Vertical>
      ) : (
        <Horizontal noWrap>
          <Text variant="subTitle04" color="gray.7" sx={{ whiteSpace: 'nowrap' }}>
            Try these samples:
          </Text>
          {allSamples}
        </Horizontal>
      )}
      {!verticalLayout && (
        <Horizontal noWrap w="100%" sx={{ overflow: 'hidden' }}>
          <Text variant="small01" color="gray.6" sx={{ whiteSpace: 'nowrap' }}>
            Columns in order:
          </Text>
          <Text variant="small02" color="gray.6" lineClamp={1}>
            {featuresOrder}
          </Text>
          {restFeatures.length > 0 && (
            <Popover position="right" withArrow>
              <Popover.Target>
                <Text span variant="bodyShort03" color="blue.6">
                  +{restFeatures.length} more
                </Text>
              </Popover.Target>
              <Popover.Dropdown>
                <Box style={{ maxHeight: '280px', overflow: 'auto' }}>
                  {restFeatures.map(feature => (
                    <Box key={feature}>
                      <Text variant="subTitle04" color="dark.7" lineClamp={1} title={feature}>
                        {feature}
                      </Text>
                    </Box>
                  ))}
                </Box>
              </Popover.Dropdown>
            </Popover>
          )}
        </Horizontal>
      )}
    </Vertical>
  );
};
