import noop from 'lodash/noop';
import { Form, FormSchema } from '@/main/components/common/form/Form';
import { OperatorModel } from '@/main/generated/api';
import { Box, Button, Horizontal } from '@/shared/design-system/v2';
import { Mode } from '../../utils';

interface NodeFormViewProps {
  workflowId: string;
  runId?: string;
  nodeId: string;
  prevNodeId?: string;
  operatorModel?: OperatorModel;
  data?: any;
  setData?: React.Dispatch<any>;
  isFormDirty?: boolean;
  setIsFormDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  initialState?: any;
  onFormSubmit?: (data: any) => void;
  onFormClose: () => void;
  viewOnly?: boolean;
  isFormSaving?: boolean;
  mode?: Mode;
  onResultsDownload?: () => void;
}

export const NodeFormView = ({
  workflowId,
  runId,
  nodeId,
  operatorModel,
  data,
  setData,
  isFormDirty,
  setIsFormDirty,
  initialState,
  onFormSubmit = noop,
  onFormClose,
  onResultsDownload,
  viewOnly,
  prevNodeId,
  isFormSaving,
  mode,
}: NodeFormViewProps) => (
  <Box sx={theme => ({ borderTop: `1px solid ${theme.colors.gray[2]}` })} px="24px" py="16px">
    {/* Position: relative needed for nested table view with absolute positioning */}
    <Box h="76vh" pos="relative">
      <Box h="calc(100% - 52px)">
        <Form
          schema={operatorModel?.parameters as FormSchema}
          data={data}
          setData={setData}
          isFormDirty={isFormDirty}
          setIsFormDirty={setIsFormDirty}
          initialState={initialState}
          onErrors={noop}
          viewOnly={viewOnly}
          operator={operatorModel}
          workflowId={workflowId}
          runId={runId}
          nodeId={nodeId}
          prevNodeId={prevNodeId ?? ''}
          isFormSaving={isFormSaving}
          mode={mode}
        />
      </Box>
      <Horizontal pt="lg" noWrap>
        {!viewOnly ? (
          <Button
            w="168px"
            variant="primary"
            loading={isFormSaving}
            disabled={isFormSaving || !isFormDirty}
            onClick={onFormSubmit}
          >
            Update results
          </Button>
        ) : null}
        <Button variant="subtle" color="gray.7" onClick={onFormClose}>
          Close
        </Button>
        {viewOnly && onResultsDownload ? (
          <Button onClick={onResultsDownload} ml="auto">
            Download results
          </Button>
        ) : null}
      </Horizontal>
    </Box>
  </Box>
);
