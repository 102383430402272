import { PropsWithChildren, createContext, useContext, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Loader } from '@/shared/design-system/Loader/Loader';
import { Alert, Box, IconAlertCircle, Vertical } from '@/shared/design-system/v2';
import { DataCategory, SegmentType, WorkflowMode } from '../../../generated/api';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useCreateDatasetWorkflowQuery } from '../../../queries/datasets/registration';
import { DatasetRegistrationStepV2 } from '../constants';
import { RegistrationUserIntent } from '../util';

export interface SegmentWiseFiles {
  segmentType: SegmentType;
  file: File;
}

export interface SegmentWiseFileUrls {
  segmentType: SegmentType;
  url: string;
}

export enum DataOrientation {
  UNSPLIT_DATA = 'UNSPLIT_DATA',
  UNLABELLED_DATA = 'UNLABELLED_DATA',
}

export enum DataUploadView {
  SIMPLE_UPLOAD = 'SIMPLE_UPLOAD',
  FULL_FEATURE_UPLOAD = 'FULL_FEATURE_UPLOAD',
}

interface DatasetRegistrationState {
  workflowId: string;

  step: DatasetRegistrationStepV2;
  setStep: (s: DatasetRegistrationStepV2) => void;

  dataUploadView: DataUploadView;
  setDataUploadView: (view: DataUploadView) => void;

  mode: WorkflowMode | null;
  setMode: (s: WorkflowMode) => void;

  isUnlabeled: boolean;
  setIsUnlabeled: (s: boolean) => void;

  dataCategory: DataCategory;
  setDataCategory: (dataCategory: DataCategory) => void;

  isRegistrationFormFilled: boolean;
  setIsRegistrationFormFilled: (s: boolean) => void;

  dataOrientation: DataOrientation;
  setDataOrientation: (dataOrientation: DataOrientation) => void;

  segmentFiles: SegmentWiseFiles[];
  setSegmentFiles: (segmentFileData: SegmentWiseFiles[]) => void;

  segmentFileUrls: SegmentWiseFileUrls[];
  setSegmentFileUrls: (segmentFileUrl: SegmentWiseFileUrls[]) => void;

  errorMessage?: string;
  setErrorMessage: (err: string) => void;

  shouldConfirmTarget: boolean;
  setShouldConfirmTarget: (value: boolean) => void;

  intent: RegistrationUserIntent | null;

  //These are used to determine steps through onboarding
  painPoint: string;
  setPainPoint: (s: string) => void;

  isOnboardingComplete: boolean;
  setIsOnboardingComplete: (s: boolean) => void;
}

export const DatasetRegistrationContext = createContext<DatasetRegistrationState | undefined>(
  undefined,
);

export const useDatasetRegistrationContext = () => {
  const context = useContext(DatasetRegistrationContext);
  if (!context) {
    throw new Error('Hook must be used within a DatasetRegistrationContextProvider');
  }
  return context;
};

export const DatasetRegistrationContextProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>) => {
  const queryParams = useQueryParams();
  const { current: workflowId } = useRef(uuid());
  const { isLoading, isError } = useCreateDatasetWorkflowQuery(workflowId);

  const [step, setStep] = useState(DatasetRegistrationStepV2.IMPORT_DATASET);
  const [mode, setMode] = useState<WorkflowMode | null>(null);

  const [dataUploadView, setDataUploadView] = useState<DataUploadView>(
    DataUploadView.SIMPLE_UPLOAD,
  );

  const [dataCategory, setDataCategory] = useState(DataCategory.Unknown);
  const [isUnlabeled, setIsUnlabeled] = useState(false);
  const [isRegistrationFormFilled, setIsRegistrationFormFilled] = useState(false);
  const [painPoint, setPainPoint] = useState('');
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);

  const [dataOrientation, setDataOrientation] = useState<DataOrientation>(
    DataOrientation.UNSPLIT_DATA,
  );

  const [segmentFiles, setSegmentFiles] = useState<SegmentWiseFiles[]>([]);
  const [segmentFileUrls, setSegmentFileUrls] = useState<SegmentWiseFileUrls[]>([]);

  const [shouldConfirmTarget, setShouldConfirmTarget] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const intent = queryParams.get('intent') as RegistrationUserIntent | null;

  if (isLoading) {
    return (
      <Box h="100%">
        <Loader text="Getting ready to add your dataset" />;
      </Box>
    );
  }

  if (isError) {
    return (
      <Vertical>
        <Alert icon={<IconAlertCircle size={20} />} color="red">
          Error creating session. Try reloading the page, or contact support if the problem
          persists.
        </Alert>
      </Vertical>
    );
  }

  const value = {
    workflowId,
    step,
    setStep,
    mode,
    setMode,
    dataUploadView,
    setDataUploadView,
    dataCategory,
    setDataCategory,
    isUnlabeled,
    setIsUnlabeled,
    isRegistrationFormFilled,
    setIsRegistrationFormFilled,
    intent,
    painPoint,
    setPainPoint,
    isOnboardingComplete,
    setIsOnboardingComplete,
    dataOrientation,
    setDataOrientation,
    segmentFiles,
    setSegmentFiles,
    segmentFileUrls,
    setSegmentFileUrls,
    errorMessage,
    setErrorMessage,
    shouldConfirmTarget,
    setShouldConfirmTarget,
  };

  return (
    <DatasetRegistrationContext.Provider value={value}>
      {children}
    </DatasetRegistrationContext.Provider>
  );
};
