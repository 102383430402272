import { Text, Vertical } from '@/shared/design-system/v2';

interface PricingPlansHeaderProps {
  title: string;
  subtitle: string;
}

export const PricingPlansHeader = ({ title, subtitle }: PricingPlansHeaderProps): JSX.Element => (
  <Vertical
    align="center"
    spacing={0}
    p="xl"
    sx={theme => ({
      background: 'linear-gradient(90deg, #FCF1E8 0%, #E6F5FE 100%)',
      borderRadius: theme.radius.sm,
    })}
  >
    <Text variant="heading02">{title}</Text>
    <Text variant="bodyShort01" color="gray.7">
      {subtitle}
    </Text>
  </Vertical>
);
