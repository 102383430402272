import { Badge, Text } from '@/shared/design-system/v2';

interface NumberBadgeProps {
  active?: boolean;
  number: number;
}

export const NumberBadge = ({ active = false, number }: NumberBadgeProps) => {
  if (!number) {
    return <></>;
  }
  return (
    <Badge
      sx={{ pointerEvents: 'none' }}
      p={6}
      variant={active ? 'filled' : 'light'}
      color={active ? 'blue' : 'dark.6'}
    >
      <Text variant="small02">{number}</Text>
    </Badge>
  );
};
