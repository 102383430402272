import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { getIsTaskPending } from '@/shared/lib/analysis';
import { datasetApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { AnalysisStateNames, AnalysisTask, GetAnalysisTasksResponse } from '../../../generated/api';
import { AnalyzerTaskletEnum } from '../../constants';
import { dataAnalysisTaskKeys, datasetKeys } from '../../queryConstants';
import { datasetClusterKeys } from '../clustering/cluster';

export const useDatasetAnalysisTasksQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    dataAnalysisTaskKeys.list(workspaceId, datasetId),
    () => datasetApi.workspaceGetAnalysisTasksResponseV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      refetchInterval: 15 * 1000,
      select: response => response.data.response,
    },
  );
};

// Creating a separate query for Topology task to see status
// otherwise refetchInterval causes issues
export const useDatasetTopologyTaskQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetAnalysisTasksResponse>, AxiosError, AnalysisTask | undefined>(
    dataAnalysisTaskKeys.listTopology(workspaceId, datasetId),
    () => datasetApi.workspaceGetAnalysisTasksResponseV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      select: response =>
        response.data.response.tasks.find(
          task => task.data?.analysisGroupId === AnalyzerTaskletEnum.TOPOGRAPHY,
        ),
      refetchInterval: data => {
        const state = data?.data?.state;
        if (state && getIsTaskPending(state)) {
          return 15 * 1000;
        }
        return false;
      },
    },
  );
};

export const useRerunAnalysisMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (taskletId: string) =>
      datasetApi.workspaceAnalysisTaskRetriggerV2(workspaceId, datasetId, taskletId),
    {
      onSuccess: () => {
        const clusteringStateKey = datasetClusterKeys.getClusterState(workspaceId, datasetId);
        queryClient.invalidateQueries(clusteringStateKey);
        queryClient.invalidateQueries(
          dataAnalysisTaskKeys.dataQualityStatus(workspaceId, datasetId),
        );
      },
    },
  );
};

export const useRerunAnalysisTaskMutation = (datasetId: string, taskId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    () => datasetApi.workspaceAnalysisTaskRetriggerV1(workspaceId, datasetId, taskId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(datasetKeys.list(workspaceId));
        queryClient.invalidateQueries(dataAnalysisTaskKeys.list(workspaceId, datasetId));
      },
    },
  );
};

export const useRerunFailedAnalysisTasksMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const taskListQueryKey = dataAnalysisTaskKeys.list(workspaceId, datasetId);
  const datasetListQueryKey = datasetKeys.list(workspaceId);

  const { mutate, isLoading, isError } = useMutation(
    () => datasetApi.workspaceFailedAnalysisTaskRetriggerV1(workspaceId, datasetId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(taskListQueryKey);

        const previousTasksData =
          queryClient.getQueryData<AxiosResponse<GetAnalysisTasksResponse>>(taskListQueryKey);

        if (previousTasksData) {
          const updatedTasks = previousTasksData.data.response.tasks.map(task => {
            if (task.data?.state === AnalysisStateNames.Error)
              task.data.state = AnalysisStateNames.InProgress;
            return task;
          });
          previousTasksData.data.response.tasks = updatedTasks;
          queryClient.setQueryData<AxiosResponse<GetAnalysisTasksResponse>>(
            taskListQueryKey,
            previousTasksData,
          );
        }
        return { previousTasksData };
      },
      onError: (
        _err,
        _data,
        context: { previousTasksData?: AxiosResponse<GetAnalysisTasksResponse> } | undefined,
      ) => {
        if (context?.previousTasksData) {
          queryClient.setQueryData(taskListQueryKey, context.previousTasksData);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(datasetListQueryKey);
      },
    },
  );

  return { isLoading, isError, rerunFailedTasks: mutate };
};
