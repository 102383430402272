import first from 'lodash/first';
import { useEffect, useState } from 'react';
import { logger } from '@/shared/initializers/logging';
import { parseCSV, parseCSVString } from '@/shared/lib/fileparse';
import { SegmentWiseFiles } from '../components/dataset-registration/context/DatasetRegistrationContext';
import { DatasetPreviewData, SegmentType } from '../generated/api';

interface useCloudDatasetPreviewParserProps {
  unParsedData?: DatasetPreviewData;
  delimiter: string;
}

interface useManualDatasetPreviewParserProps {
  segmentFiles?: SegmentWiseFiles[];
  delimiter: string;
}

type ResultSegmentData = {
  [key in SegmentType]: string[][];
};

export const datasetDefaultColumns = {
  [SegmentType.All]: [],
  [SegmentType.Test]: [],
  [SegmentType.Train]: [],
  [SegmentType.Unknown]: [],
  [SegmentType.Validate]: [],
};

export const useCloudDatasetPreviewParser = ({
  unParsedData,
  delimiter,
}: useCloudDatasetPreviewParserProps) => {
  const [isDataParsing, setIsDataParsing] = useState(true);
  const [parsedDataBySegment, setParsedDataBySegment] =
    useState<ResultSegmentData>(datasetDefaultColumns);
  const [isParsingError, setIsParsingError] = useState(false);

  useEffect(() => {
    if (unParsedData) {
      setIsDataParsing(true);
      setIsParsingError(false);

      Promise.all(
        unParsedData.segments.map(async (segment: SegmentType) => {
          const previewData = unParsedData.preview[segment].data;
          return await parseCSVString(previewData ?? [''], delimiter);
        }),
      )
        .then(results => {
          const parsedData = unParsedData.segments.reduce<ResultSegmentData>(
            (acc, segment: SegmentType, idx: number) => {
              acc[segment] = results[idx];
              return acc;
            },
            datasetDefaultColumns,
          );

          setParsedDataBySegment(parsedData);
          setIsParsingError(false);
        })
        .catch((err: any) => {
          setIsParsingError(true);
          logger.error(err);
        })
        .finally(() => {
          setIsDataParsing(false);
        });
    }
  }, [unParsedData, delimiter]);

  const columnNames = {} as Record<SegmentType, string[]>;
  const rows = {} as Record<SegmentType, string[][]>;

  if (parsedDataBySegment) {
    unParsedData?.segments.forEach((segment: SegmentType) => {
      columnNames[segment] = first(parsedDataBySegment[segment]) ?? [''];
      rows[segment] = parsedDataBySegment[segment].slice(1, parsedDataBySegment[segment].length);
    });
  }

  return {
    isParsing: isDataParsing,
    isParsingError,
    isParsingNotAvailable: !parsedDataBySegment,
    columns: columnNames,
    rows,
  };
};

export const useManualDatasetPreviewParser = ({
  segmentFiles,
  delimiter,
}: useManualDatasetPreviewParserProps) => {
  const [isDataParsing, setIsDataParsing] = useState(true);
  const [isParsingError, setIsParsingError] = useState(false);
  const [fileResultsBySegment, setFileResultsBySegment] = useState<ResultSegmentData>();

  useEffect(() => {
    if (segmentFiles) {
      setIsDataParsing(true);
      setIsParsingError(false);

      const parsedFilePromises = segmentFiles.map(
        async segmentFile => await parseCSV(segmentFile.file, delimiter ?? ''),
      );
      Promise.all(parsedFilePromises)
        .then((results: string[][][]) => {
          setFileResultsBySegment(
            segmentFiles.reduce((acc, val, idx) => {
              acc[val.segmentType] = results[idx];
              return acc;
            }, {} as ResultSegmentData),
          );
          setIsParsingError(false);
        })
        .catch((err: any) => {
          setIsParsingError(true);
          logger.error(err);
        })
        .finally(() => {
          setIsDataParsing(false);
        });
    }
  }, [segmentFiles, delimiter]);

  const columnNames = {} as Record<SegmentType, string[]>;
  const rowsData = {} as Record<SegmentType, string[][]>;

  if (fileResultsBySegment) {
    Object.entries(fileResultsBySegment).forEach(([key, val]) => {
      const currentFileResults = val;

      columnNames[key as SegmentType] = first(currentFileResults) ?? [];
      rowsData[key as SegmentType] = currentFileResults.slice(1, currentFileResults.length);
    });
  }

  return {
    isParsing: isDataParsing,
    isParsingError,
    columnNames,
    rowsData,
  };
};
