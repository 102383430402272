import { IconDotsVertical } from '@tabler/icons-react';
import { ActionIcon, Menu } from '@/shared/design-system/v2';
import { ArtifactStateType } from '../../generated/api';
import { useDataLabelingArchiveUnarchive } from './useDataLabelingArchiveUnarchive';
import { useDataLabelingEditNameModal } from './useDataLabelingEditNameModal';

interface DataLabelingActionsMenuProps {
  datasetId: string;
  dataLabelingId: string;
  currentName: string;
  state: ArtifactStateType;
}

export const DataLabelingActionsMenu = ({
  datasetId,
  dataLabelingId,
  currentName,
  state,
}: DataLabelingActionsMenuProps): JSX.Element => {
  const { openArchiveUnarchiveModal } = useDataLabelingArchiveUnarchive([dataLabelingId], state);

  const { open: openEditNameModal } = useDataLabelingEditNameModal(
    datasetId,
    dataLabelingId,
    currentName,
  );

  const archiveAction = {
    id: 'archive-data-labeling',
    label: state === ArtifactStateType.Active ? 'Archive' : 'Unarchive',
    handler: openArchiveUnarchiveModal,
  };

  const editNameAction = {
    id: 'edit-name-data-labeling',
    label: 'Edit name',
    handler: openEditNameModal,
  };

  const actions = [editNameAction, archiveAction];

  return (
    <Menu shadow="md" position="bottom-end" width={110} withinPortal>
      <Menu.Target>
        <ActionIcon bg="white.0">
          <IconDotsVertical size={20} color="#777" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {actions.map(action => (
          <Menu.Item key={action.id} py={12} onClick={action.handler}>
            {action.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
