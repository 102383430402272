import { useState } from 'react';
import {
  Button,
  Horizontal,
  Loader,
  Select,
  Text,
  Vertical,
  notifications,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import {
  useListSlackChannelsQuery,
  useUpdateSlackChannelMutation,
} from '../../../../queries/notifications/slack-notifications';

interface SlackConfigurationProps {
  slackChannelId: string;
  onClose: () => void;
}

const SLACK_CHANNEL_LABEL = 'Select slack channel';
const SLACK_CHANNEL_PLACEHOLDER = 'Click to select your slack channel';

export const SlackConfiguration = ({
  slackChannelId,
  onClose,
}: SlackConfigurationProps): JSX.Element => {
  const [channelId, setChannelId] = useState(slackChannelId);
  const { data, isLoading } = useListSlackChannelsQuery();
  const { mutate: selectChannel, isLoading: isSaving } = useUpdateSlackChannelMutation();

  const slackChannelOptions = data
    ? data.map(channel => ({
        value: channel.id,
        label: channel.name,
      }))
    : [];

  const chosenSlackChannel = slackChannelOptions.find(({ value }) => value === channelId);
  const channelName = chosenSlackChannel?.label || '';

  const handleSlackChannelSelection = (channelId: string) => {
    setChannelId(channelId);
  };

  const handleConfirm = () => {
    selectChannel(
      { channelId, channelName },
      {
        onSuccess: () => {
          onClose();
        },
        onError: err => {
          notifications.show({
            variant: 'error',
            message: 'Unable to change the slack channel. Please try again.',
          });
          logger.error(err);
        },
      },
    );
  };

  if (isSaving) {
    return (
      <Vertical align="center" justify="center">
        <Loader />
        <Text variant="bodyShort03">Saving slack channel configuration</Text>
      </Vertical>
    );
  }

  return (
    <Vertical>
      <Select
        ariaLabel={SLACK_CHANNEL_LABEL}
        label={SLACK_CHANNEL_LABEL}
        placeholder={SLACK_CHANNEL_PLACEHOLDER}
        options={slackChannelOptions}
        defaultValue={channelId}
        value={chosenSlackChannel?.value}
        onChange={handleSlackChannelSelection}
        searchable
        disabled={isLoading}
        loading={isLoading}
        withinPortal
      />
      <Horizontal mt="xl" position="right">
        <Button onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Horizontal>
    </Vertical>
  );
};
