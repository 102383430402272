import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import merge from 'lodash/merge';
import { logger } from '@/shared/initializers/logging';
import { experimentsApi, recordingsApi, taggedResourcesApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  ArtifactFilterState,
  ChartingResponse,
  ExpEditableFields,
  ExperimentWithTags,
  GetExperimentDetailsResponse,
  UpdateExperimentArtifactState,
} from '../../generated/api';
import { API_PAGE_SIZE } from '../constants';
import { getNextPageParamHandler } from '../util';
import { experimentKeys } from './queryKeys';

interface ExperimentsListQueryFilters {
  name?: string;
  projectId?: string;
  modelId?: string;
  datasetId?: string;
  userIds?: string[];
  excludeUserIds?: string[];
  recordIds?: string[];
  tagIds?: string[];
  state?: ArtifactFilterState;
}

export const useExperimentsListSnapshot = (userId?: string) => {
  const PAGINATION_START_INDEX = 0;
  const PAGINATION_END_INDEX = 5;

  const { workspaceId } = useAppMetadata();

  const ts = undefined;
  const filters: ExperimentsListQueryFilters = {
    userIds: userId ? [userId] : undefined,
  };

  return useQuery(
    experimentKeys.listPreview(workspaceId, userId),
    () =>
      recordingsApi.workspaceGetExperimentsListV1(
        workspaceId,
        PAGINATION_START_INDEX,
        PAGINATION_END_INDEX,
        ts,
        filters.datasetId,
        filters.modelId,
        filters.projectId,
        filters.name,
        filters.userIds,
        filters.excludeUserIds,
        filters.recordIds,
      ),
    {
      enabled: !!workspaceId,
      select: data => data.data.response,
    },
  );
};

export const useExperimentsListInfiniteQuery = (filters: ExperimentsListQueryFilters) => {
  const { workspaceId, userId } = useAppMetadata();

  return useInfiniteQuery(
    experimentKeys.list(workspaceId, userId, filters),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE;
      return taggedResourcesApi.getAllExperimentListWithTagsV1(
        workspaceId,
        start,
        end,
        lastTimestamp,
        filters.name,
        filters.datasetId,
        filters.modelId,
        filters.projectId,
        filters.userIds,
        filters.excludeUserIds,
        filters.recordIds,
        filters.tagIds,
        false,
        filters.state,
      );
    },
    {
      enabled: !!workspaceId,
      refetchInterval: 30 * 1000,
      getNextPageParam: getNextPageParamHandler(),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => {
          const experiments = page.data.response;
          const totalCount = page.data.numRecords || 0;
          return { experiments, totalCount };
        }),
      }),
    },
  );
};

export const useExperimentStateMutation = (
  experimentArtifactState: UpdateExperimentArtifactState,
  resetExperimentSelection: () => void,
) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    () => experimentsApi.updateArtifactStateExperimentsV1(workspaceId, experimentArtifactState),
    {
      onSuccess: () => {
        resetExperimentSelection();
        queryClient.invalidateQueries(experimentKeys.list(workspaceId, userId));
      },
    },
  );
};

// TODO: Now that projectId filter is supported, we can replace instances of this hook
export const useProjectExperimentsInfiniteQuery = (
  projectId: string,
  filters: ExperimentsListQueryFilters,
) => {
  const { workspaceId, userId } = useAppMetadata();

  return useInfiniteQuery(
    experimentKeys.listProjectExperiments(workspaceId, userId, projectId, filters),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE;
      return taggedResourcesApi.getProjectExperimentsListWithTagsV1(
        workspaceId,
        projectId,
        start,
        end,
        lastTimestamp,
        filters.name,
        filters.modelId,
        filters.userIds,
        filters.excludeUserIds,
        filters.recordIds,
        filters.tagIds,
      );
    },
    {
      enabled: Boolean(workspaceId && projectId),
      getNextPageParam: getNextPageParamHandler(),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
    },
  );
};

export const useExperimentDetailsQuery = (projectId: string, experimentId: string) => {
  const { workspaceId, userId } = useAppMetadata();

  return useQuery<
    AxiosResponse<GetExperimentDetailsResponse>,
    AxiosError,
    ExperimentWithTags | undefined
  >(
    experimentKeys.detail(workspaceId, userId, projectId, experimentId),
    () => taggedResourcesApi.getExperimentInfoWithTagsV1(workspaceId, projectId, experimentId),
    {
      select: res => res.data.response,
      // experimentId may be empty for models which don't have an associated experiment
      enabled: Boolean(workspaceId && experimentId),
    },
  );
};

export const useExperimentsPackageRequirementsQuery = (projectId: string, experimentId: string) => {
  const { workspaceId, userId } = useAppMetadata();

  return useQuery(
    experimentKeys.listPackageRequirements(workspaceId, userId, projectId, experimentId),
    () => experimentsApi.getExperimentPackageRequirementsV1(workspaceId, projectId, experimentId),
    {
      select: res => res.data.response,
      enabled: Boolean(workspaceId && experimentId),
    },
  );
};

export const useExperimentMetricsListQuery = (experimentId: string, shouldPoll = false) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery(
    experimentKeys.listMetrics(workspaceId, userId, experimentId),
    () => recordingsApi.workspaceGetExperimentKeysV1(workspaceId, experimentId),
    {
      enabled: Boolean(workspaceId && experimentId),
      select: res => res.data.metrics,
      // We poll for the metrics list, while the run status of the experiment is not FINISHED.
      refetchInterval: shouldPoll ? 15 * 1000 : false,
      // When we get a response, we need to also refetch the list of experiments (which gives run status),
      // as well as the data for each of the metrics for the particular experiment.
      onSuccess: () => {
        queryClient.invalidateQueries(experimentKeys.listPreview(workspaceId, userId));
        queryClient.invalidateQueries(
          experimentKeys.metricDetail(workspaceId, userId, experimentId),
        );
      },
    },
  );
};

export const useExperimentMetricDataQuery = (experimentId: string, metricKey: string) => {
  const { workspaceId, userId } = useAppMetadata();

  return useQuery(
    experimentKeys.metricDetail(workspaceId, userId, experimentId, metricKey),
    () => recordingsApi.workspaceExperimentMetricV1(workspaceId, experimentId, metricKey),
    {
      enabled: Boolean(workspaceId && metricKey),
      select: res => {
        const data: ChartingResponse = res.data.chartingResponse;
        return data;
      },
    },
  );
};

export const useEditExperimentMutation = (projectId: string, experimentId: string) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();

  const experimentDetailsQueryKey = experimentKeys.detail(
    workspaceId,
    userId,
    projectId,
    experimentId,
  );
  const experimentListsQueryKey = experimentKeys.lists();

  return useMutation(
    (updates: ExpEditableFields) =>
      experimentsApi.updateExperimentEditableFieldsV1(workspaceId, projectId, experimentId, {
        updates,
      }),
    {
      onMutate: async updates => {
        // optimistically update experiment details query data
        await queryClient.cancelQueries(experimentDetailsQueryKey);
        const previousData =
          queryClient.getQueryData<AxiosResponse<GetExperimentDetailsResponse>>(
            experimentDetailsQueryKey,
          );
        if (previousData) {
          const updatedExperimentData = merge({}, previousData, {
            data: { response: { ...updates } },
          });
          queryClient.setQueryData<AxiosResponse<GetExperimentDetailsResponse>>(
            experimentDetailsQueryKey,
            updatedExperimentData,
          );
        }
        return { previousData };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(experimentListsQueryKey);
      },
      onError: (
        err: AxiosError,
        _data,
        context: { previousData?: AxiosResponse<GetExperimentDetailsResponse> } | undefined,
      ) => {
        logger.error(err);
        if (context?.previousData) {
          queryClient.setQueryData(experimentDetailsQueryKey, context.previousData);
        }
      },
    },
  );
};
