import { IconInfoCircle } from '@tabler/icons-react';
import {
  Alert,
  Box,
  Horizontal,
  PasswordInput,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
  useForm,
} from '@/shared/design-system/v2';
import { CreateConnectorRequest, StorageType } from '../../../generated/api';
import { useCreateConnectorMutation } from '../../../queries/connectors';
import { getConnectorTypeDisplayName, getConnectorTypeIcon } from '../util';
import { CreateConnectorModalActions } from './CreateConnectorModalActions';

const useSnowflakeConnectorForm = () =>
  useForm({
    initialValues: {
      connectorName: '',
      accountId: '',
      username: '',
      password: '',
    },
    validate: {
      connectorName: value => {
        if (!value.trim()) {
          return 'Connector name cannot be empty';
        }
        return null;
      },
      accountId: value => {
        if (!value.trim()) {
          return 'Account ID cannot be empty';
        }
        return null;
      },
      username: value => {
        if (!value.trim()) {
          return 'Username cannot be empty';
        }
        return null;
      },
      password: value => {
        if (!value) {
          return 'Password cannot be empty';
        }
        return null;
      },
    },
    transformValues: values => ({
      connectorName: values.connectorName.trim(),
      accountId: values.accountId.trim(),
      username: values.username.trim(),
      // NOTE: Don't trim spaces from password
    }),
  });

interface SnowflakeConnectorModalProps {
  onClickBack?: () => void;
  onCreate?: (connectorId: string) => void;
  onClose?: () => void;
}

export const SnowflakeConnectorModal = ({
  onClickBack,
  onCreate,
  onClose = closeAllModals,
}: SnowflakeConnectorModalProps) => {
  const form = useSnowflakeConnectorForm();

  const { mutate: createConnector, isLoading } = useCreateConnectorMutation();

  const handleCreateConnector = () => {
    if (form.validate().hasErrors) {
      return;
    }

    const { connectorName, accountId, username } = form.getTransformedValues();
    const { password } = form.values;

    const createReq: CreateConnectorRequest = {
      name: connectorName,
      connectorType: StorageType.Snowflake,
      credData: {
        username,
        password,
      },
      connectorMetadata: {
        accountId,
      },
    };

    createConnector(createReq, {
      onError: () => {
        notifications.error('Unable to create connector');
      },
      onSuccess: res => {
        notifications.success('Connector created successfully');
        const connectorId = res.data.response?.connectorId ?? '';
        onCreate?.(connectorId);
        onClose();
      },
    });
  };

  const icon = getConnectorTypeIcon(StorageType.Snowflake);
  const connectorName = getConnectorTypeDisplayName(StorageType.Snowflake);

  return (
    <Vertical spacing="xl">
      <Box>
        <Alert color="yellow" icon={<IconInfoCircle />}>
          Access credentials will be encrypted and stored securely.
        </Alert>
      </Box>
      <Horizontal>
        <Text variant="subTitle03">Service:</Text>
        <Horizontal spacing="xs">
          {icon}
          <Text variant="subTitle03">{connectorName}</Text>
        </Horizontal>
      </Horizontal>
      <Vertical spacing="sm">
        <TextInput
          required
          ariaLabel="Connector name"
          label="Connector name"
          {...form.getInputProps('connectorName')}
        />
        <TextInput
          required
          ariaLabel="Account ID"
          label="Account ID"
          {...form.getInputProps('accountId')}
        />
        <TextInput
          required
          ariaLabel="Username"
          label="Username"
          {...form.getInputProps('username')}
        />
        <PasswordInput
          required
          aria-label="Password"
          label="Password"
          {...form.getInputProps('password')}
        />
      </Vertical>

      <CreateConnectorModalActions
        onClickBack={onClickBack}
        onCreate={handleCreateConnector}
        onClose={onClose}
        isCreating={isLoading}
      />
    </Vertical>
  );
};
