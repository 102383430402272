import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useRef, useState } from 'react';
import { Box, Center, Loader } from '../../..';
import { useSnippetDetail } from '../../../../../../apps/main/components/snippets/detail/context/SnippetContext';
import { useResizeMedia } from '../resizable-media/resizableMediaMenu.util';
import { IframeActions } from './IframeActions';
import { useIframeViewStyles } from './IframeView.style';
import { getParsedUrl } from './iframeView.util';

export const IframeView = ({
  editor,
  node,
  updateAttributes,
  deleteNode,
  getPos,
  selected,
}: NodeViewProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const resizableIframeRef = useRef<HTMLIFrameElement>(null);

  const { editing } = useSnippetDetail();

  const resizeHandlers = useResizeMedia('iframe', resizableIframeRef, updateAttributes);
  const { classes, cx } = useIframeViewStyles({ align: node.attrs.dataAlign });

  const onHideIframe = () => {
    const currentPosition = getPos();
    editor
      .chain()
      .focus()
      .insertContentAt(
        {
          from: currentPosition,
          to: currentPosition + 1,
        },
        {
          marks: [{ type: 'link', attrs: { href: node.attrs.src } }],
          text: node.attrs.src,
          type: 'text',
        },
      )
      .run();
  };

  const onLoad = () => {
    setIsLoading(false);
  };

  return (
    <NodeViewWrapper as="article" className={classes.iframeNodeView}>
      <Box
        className={cx(classes.iframeContainer, {
          [classes.selected]: selected,
        })}
      >
        {isLoading && (
          <Center
            pos="absolute"
            h="100%"
            w="100%"
            bg="gray.2"
            sx={theme => ({ borderRadius: theme.spacing.sm })}
          >
            <Loader variant="dots" />
          </Center>
        )}
        <iframe
          ref={resizableIframeRef}
          src={getParsedUrl(node.attrs.src)}
          height={node.attrs.height}
          width={node.attrs.width}
          allowFullScreen={node.attrs.allowfullscreen}
          onLoad={onLoad}
        />

        {!isLoading && editing && (
          <Box className={classes.resizeHandle} title="Resize" {...resizeHandlers} />
        )}

        {editing && (
          <IframeActions
            deleteNode={deleteNode}
            updateAttributes={updateAttributes}
            hideIframe={onHideIframe}
          />
        )}
      </Box>
    </NodeViewWrapper>
  );
};
