import { Alert, Center, Loader } from '@/shared/design-system/v2';
import { UpsertDAGRequest } from '../../../../generated/api';
import { useGetWorkflowDAGQuery } from '../../../../queries/workflows/dag';
import { useGetOperatorListQuery } from '../../../../queries/workflows/operators';
import { WorkflowViewer } from './WorkflowViewer';

interface WorkflowViewerContainerProps {
  workflowId: string;
  dag?: UpsertDAGRequest;
  isDagLoading?: boolean;
  renderNodeActions?: boolean;
}

export const WorkflowViewerContainer = ({
  workflowId,
  dag,
  isDagLoading,
  renderNodeActions,
}: WorkflowViewerContainerProps): JSX.Element => {
  const {
    isLoading: operatorListLoading,
    isError: operatorListError,
    data: operatorList,
  } = useGetOperatorListQuery();
  const { isLoading, isError, data } = useGetWorkflowDAGQuery(workflowId);

  if (isLoading || operatorListLoading || isDagLoading) {
    return (
      <Center h={400} w="100%">
        <Loader />
      </Center>
    );
  }

  if (isError || operatorListError || !data) {
    return (
      <Alert color="red">
        We have encountered an issue trying to load your workflow. Please refresh and if issue
        persist, please contact support
      </Alert>
    );
  }

  return (
    <WorkflowViewer
      dag={dag ?? data}
      operatorsList={operatorList ?? []}
      renderNodeActions={renderNodeActions}
    />
  );
};
