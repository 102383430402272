import {
  ControlProps,
  DispatchPropsOfMultiEnumControl,
  EnumOption,
  JsonSchema,
  JsonSchema4,
  OwnPropsOfEnum,
  RankedTester,
  and,
  hasType,
  rankWith,
  resolveSchema,
  schemaMatches,
  schemaSubPathMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsMultiEnumProps } from '@jsonforms/react';
import uniqBy from 'lodash/uniqBy';
import { useState } from 'react';
import { MultiSelect, Text } from '@/shared/design-system/v2';

export const MultiSelectControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  visible,
  enabled,
  data,
  schema,
  options = [],
  config,
  errors,
}: ControlProps & OwnPropsOfEnum & DispatchPropsOfMultiEnumControl) => {
  const [created, setCreated] = useState<EnumOption[]>([]);

  if (!visible) {
    return null;
  }

  const onChange = (value: string[]) => {
    handleChange(path, value);
  };

  const uiSchemaOptions = uischema.options;
  let dropdownOptions = uiSchemaOptions?.dropdownOptions ?? {};

  if (dropdownOptions.creatable) {
    const handleCreateInputChange = (query: string) => {
      const newItem = {
        label: query,
        value: query,
      };

      setCreated(prev => [...prev, newItem]);

      return newItem;
    };

    dropdownOptions = {
      ...dropdownOptions,

      getCreateLabel: (query: string) => `+ Create ${query}`,
      onCreate: handleCreateInputChange,
    };
  }
  const schemaItems = (schema.items as JsonSchema4) ?? {};

  const schemaEnums = Array.isArray(schemaItems.enum)
    ? schemaItems.enum.map(enumItem => ({
        label: enumItem,
        value: enumItem,
      }))
    : [];

  const formValues = Array.isArray(data)
    ? data.map((datum: string) => ({
        label: datum,
        value: datum,
      }))
    : [];
  const uniqData = uniqBy([...formValues, ...created, ...schemaEnums, ...options], 'value');

  const isViewOnlyForm = config.viewOnly;

  return (
    <MultiSelect
      ariaLabel={label}
      label={<Text variant="subTitle02">{label}</Text>}
      description={
        <Text variant="small02" color="gray.7" pb="sm">
          {description}
        </Text>
      }
      value={data}
      data={uniqData}
      onChange={onChange}
      defaultValue={schema.default}
      {...dropdownOptions}
      disabled={!isViewOnlyForm && !enabled}
      readOnly={isViewOnlyForm}
      error={config.isFormDirty ? errors : undefined}
    />
  );
};

const hasOneOfItems = (schema: JsonSchema): boolean =>
  schema.oneOf !== undefined &&
  schema.oneOf.length > 0 &&
  (schema.oneOf as JsonSchema[]).every((entry: JsonSchema) => entry.const !== undefined);

const hasStringItems = (schema: JsonSchema): boolean => schema.type === 'string';

export const multiSelectControlTester: RankedTester = rankWith(
  5,
  and(
    uiTypeIs('Control'),
    and(
      schemaMatches(schema => hasType(schema, 'array') && !Array.isArray(schema.items)),
      schemaSubPathMatches('items', (schema, rootSchema) => {
        const resolvedSchema = schema.$ref
          ? resolveSchema(rootSchema, schema.$ref, rootSchema)
          : schema;
        return hasOneOfItems(resolvedSchema) || hasStringItems(resolvedSchema);
      }),
    ),
  ),
);

export const MultiSelectControl = withJsonFormsMultiEnumProps(MultiSelectControlComponent);
