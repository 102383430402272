import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { StorageType } from '@/main/generated/api';
import { connectorsApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { connectorsKeys } from '../connectors';

const queryKeys = {
  idpExchange: (workspaceId: string, connectorType: StorageType, code: string) => [
    workspaceId,
    connectorType,
    code,
  ],
};

const getSourceForConnector = (connectorType: StorageType): string => {
  if ([StorageType.GoogleDrive, StorageType.GoogleAnalytics].includes(connectorType)) {
    return 'google';
  }
  switch (connectorType) {
    case StorageType.Notion:
      return 'notion';
    default:
      throw new Error(`Source not defined for connector type: ${connectorType}`);
  }
};

const OAuthScopes = new Map<StorageType, string>([
  [
    StorageType.GoogleDrive,
    'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/userinfo.email',
  ],
  [
    StorageType.GoogleAnalytics,
    'https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email',
  ],
  [StorageType.Notion, 'read:database read:content'],
]);

export const useGetConsentUrl = (connectorType: StorageType) => {
  const { workspaceId } = useAppMetadata();

  return useMutation(async () => {
    const scopes = OAuthScopes.get(connectorType);
    if (!scopes) {
      throw new Error(`Scopes not defined for connector type: ${connectorType}`);
    }

    const resp = await connectorsApi.consentUrlV1(
      workspaceId,
      getSourceForConnector(connectorType),
      scopes,
      connectorType,
    );
    return resp.data.url;
  });
};

export const useIDPExchangeForOAuth = (
  workspaceId: string,
  code: string,
  provider: string,
  connectorType: StorageType,
) => {
  const queryClient = useQueryClient();

  return useQuery(
    queryKeys.idpExchange(workspaceId, connectorType, code),
    () => {
      const redirectUri = `${window.origin}/oauth/${provider}/${connectorType}`;

      return connectorsApi.iDPExchangeAuthCodeV2(workspaceId, {
        authCode: code,
        connectorType,
        connectorName: '',
        redirectUri,
      });
    },
    {
      select: data => data.data,
      onSuccess: () => {
        queryClient.invalidateQueries(connectorsKeys.listAll(workspaceId));
      },
    },
  );
};
