import noop from 'lodash/noop';
import { ReactNode } from 'react';
import { Box, Card, Image, Text, Vertical, useHover } from '@/shared/design-system/v2';

export interface LaunchCardProps {
  title: string;
  description: string;
  illustrationSrc?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  hoverNode?: ReactNode;
}

export const LaunchCard = ({
  title,
  description,
  illustrationSrc,
  icon,
  onClick,
  hoverNode,
}: LaunchCardProps) => {
  const { hovered, ref } = useHover();
  const clickable = !!onClick;

  return (
    <Card
      withBorder
      radius="sm"
      h="100%"
      p={0}
      sx={theme => {
        const clickableStyle = clickable ? {} : {};

        return {
          ...clickableStyle,
        };
      }}
      onClick={onClick ?? noop}
      ref={ref}
    >
      {
        <Box pos="relative">
          {hovered ? hoverNode : null}
          {illustrationSrc && <Image src={illustrationSrc} />}
        </Box>
      }
      <Vertical spacing="lg" px="xxl" py="xl" align="start">
        {icon}
        <Vertical spacing="sm">
          <Text variant="subTitle02">{title}</Text>
          <Text variant="bodyShort02" color="gray.6">
            {description}
          </Text>
        </Vertical>
      </Vertical>
    </Card>
  );
};
