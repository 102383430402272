import {
  Box,
  Container,
  Panel,
  PanelGroup,
  PanelResizeHandle,
  createStyles,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { AppBuilder } from '../../../generated/api';
import { AppBuilderDetailsForm } from './AppBuilderDetailsForm';
import { APP_BUILDER_DETAILS_HEADER_HEIGHT } from './AppBuilderDetailsHeader';
import { CustomAppChatPreview } from './CustomAppChatPreview';

const useResizeHandleStyles = createStyles(theme => ({
  root: {
    width: 1,
    borderLeft: `1px solid ${theme.colors.gray[2]}`,

    '&[data-resize-handle-state=hover], &[data-resize-handle-state=drag]': {
      borderLeft: `2px solid ${theme.primaryColor}`,
      // Negative margin is used to prevent layout shift
      // when the width of the handle increases on hover
      marginLeft: -1,
    },
  },
}));

const PANEL_DEFAULT_WIDTH = 50;
const PANEL_MIN_WIDTH = 30;

interface AppBuilderDetailsFormWrapperProps {
  prompt: string;
  example: string;
  onPromptChange: (prompt: string) => void;
  onExampleChange: (example: string) => void;
  appDetails: AppBuilder;
  onFormValuesChange: (prompt: string, example: string) => void;
}

export const AppBuilderDetailsFormWrapper = ({
  appDetails,
  prompt,
  example,
  onPromptChange,
  onExampleChange,
  onFormValuesChange,
}: AppBuilderDetailsFormWrapperProps) => {
  const theme = useMarkovTheme();
  const { classes } = useResizeHandleStyles();
  const { appId } = appDetails ?? {};
  return (
    <Box h={`calc(100% - ${APP_BUILDER_DETAILS_HEADER_HEIGHT}px)`}>
      <Container h="100%" size="md">
        <PanelGroup
          direction="horizontal"
          style={{
            border: `1px solid ${theme.colors.gray[2]}`,
            borderRadius: 8,
            background: theme.colors.white[0],
            marginBlock: theme.spacing.lg,
          }}
        >
          <Panel
            defaultSize={PANEL_DEFAULT_WIDTH}
            minSize={PANEL_MIN_WIDTH}
            style={{ position: 'relative' }}
          >
            <AppBuilderDetailsForm
              prompt={prompt}
              example={example}
              onPromptChange={onPromptChange}
              onExampleChange={onExampleChange}
              appDetails={appDetails}
              onFormValuesChange={onFormValuesChange}
            />
          </Panel>
          <PanelResizeHandle className={classes.root} />
          <Panel
            defaultSize={PANEL_DEFAULT_WIDTH}
            minSize={PANEL_MIN_WIDTH}
            style={{ position: 'relative' }}
          >
            <CustomAppChatPreview appId={appId} />
          </Panel>
        </PanelGroup>
      </Container>
    </Box>
  );
};
