import { IconExclamationCircle } from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isNil from 'lodash/isNil';
import { PropsWithChildren } from 'react';
import {
  Box,
  Card,
  Center,
  Horizontal,
  Skeleton,
  Text,
  VirtualizedTable,
} from '@/shared/design-system/v2';
import { ChildrenRowProps } from '@/shared/design-system/v2/core/data-display/virtualized-table/VirtualizedTable';
import { ModelAppOutput } from '../use-model-app';
import { ModelExplainability } from './ModelExplainability';

interface ModelOutputBoxProps {
  modelOutputList: ModelAppOutput[];
  openFeaturesModal: (input: string, prediction?: string) => void;
  headerComponent?: JSX.Element | null;
  hideExplainability?: boolean;
  height?: string | number;
}

export const ROW_HEIGHT = 52;
export interface Row {
  width?: string;
  isLoading?: boolean;
}
export const Row = ({ width, isLoading = false, children }: PropsWithChildren<Row>) => (
  <Card radius={0} withBorder w={width} h={ROW_HEIGHT}>
    <Horizontal align="center" h="100%">
      {isLoading ? <Skeleton h="xl" w="70%" /> : children}
    </Horizontal>
  </Card>
);

export const ModelOutputBox = ({
  modelOutputList = [],
  openFeaturesModal,
  headerComponent = null,
  hideExplainability = false,
  height = 428,
}: ModelOutputBoxProps) => {
  const { featureModelAppExplainability } = useFlags();

  const handleViewFeature = (userInput: string, response?: string) => () =>
    openFeaturesModal(userInput, response);

  const showExplainability = featureModelAppExplainability && !hideExplainability;

  const tableContent = [
    {
      headerName: 'User Input',
      width: showExplainability ? '65%' : '80%',
      renderComponent: ({ userInput, response }: ModelAppOutput) => (
        <Text
          variant="bodyShort02"
          color="gray.8"
          lineClamp={1}
          sx={theme => ({
            cursor: 'pointer',
            ...theme.fn.hover({
              color: theme.colors.blue[6],
            }),
          })}
          onClick={handleViewFeature(userInput, response)}
        >
          {userInput}
        </Text>
      ),
    },
    {
      headerName: 'Response',
      width: '20%',
      renderComponent: ({ error, response, loading }: ModelAppOutput) => {
        if (loading) {
          return <Skeleton h="xl" w={60} />;
        }

        if (error || isNil(response)) {
          return (
            <Horizontal noWrap>
              <IconExclamationCircle color="red" />
              <Text variant="subTitle03" color="red.5" lineClamp={1}>
                {error}
              </Text>
            </Horizontal>
          );
        }

        return (
          <Text variant="subTitle03" color="gray.8" lineClamp={1}>
            {response}
          </Text>
        );
      },
    },
    {
      enabled: featureModelAppExplainability && !hideExplainability,
      headerName: 'Explainability',
      width: '15%',
      renderComponent: ({ explain, error, userInput, response }: ModelAppOutput) => (
        <ModelExplainability
          explain={explain}
          prediction={response}
          userInput={userInput}
          disabled={Boolean(error || !explain?.length)}
        />
      ),
    },
  ];

  return (
    <Card withBorder shadow="md" p={0}>
      {headerComponent}

      <Box p="sm">
        {/* Table Header */}
        <Horizontal noWrap spacing={0}>
          {tableContent.map(
            ({ enabled = true, headerName, width }) =>
              enabled && (
                <Row key={headerName} width={width}>
                  <Text truncate="end" variant="subTitle04" align="center" w="100%">
                    {headerName}
                  </Text>
                </Row>
              ),
          )}
        </Horizontal>

        {/* Table Rows */}
        <Box w="100%" h={height}>
          <VirtualizedTable itemCount={modelOutputList.length} itemSize={ROW_HEIGHT}>
            {({ index = 0, isScrolling }: ChildrenRowProps) => (
              <Horizontal noWrap spacing={0}>
                {tableContent.map(
                  ({ enabled = true, renderComponent, width }, idx) =>
                    enabled && (
                      <Row key={idx} width={width} isLoading={isScrolling}>
                        {renderComponent(modelOutputList[index])}
                      </Row>
                    ),
                )}
              </Horizontal>
            )}
          </VirtualizedTable>
          {!modelOutputList.length && (
            <Center h="100%">
              <Text variant="bodyShort02" color="gray.6">
                Waiting for input to summon data magic... 🚀✨
              </Text>
            </Center>
          )}
        </Box>
      </Box>
    </Card>
  );
};
