import { Node, mergeAttributes } from '@tiptap/core';
import { DIFF_TYPE } from '../../../../../../apps/main/components/common/constants';
import { highlightBackgroundMap, highlightBorderMap } from '../util';

// Define a new Node class for math content
export const MathNode = Node.create({
  name: 'math',

  group: 'inline',
  inline: true,

  atom: true,
  selectable: false,
  draggable: false,

  addAttributes() {
    return {
      'data-id': {
        default: '',
      },
      mathML: {
        default: '',
      },
      highlightType: {
        default: DIFF_TYPE.EQUAL,
      },
    };
  },

  parseHTML() {
    return [
      {
        // Specify how to parse this node from HTML
        // Match elements with the 'mkvMath' tag
        tag: 'mkvMath',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['mkvMath', mergeAttributes(HTMLAttributes)];
  },

  // Define the custom view for the node in the editor
  addNodeView() {
    return ({ node }) => {
      const dom = document.createElement('div');

      const highlightBorder = `2px solid ${highlightBorderMap[node.attrs.highlightType]}`;
      const highlightBackgroundColor = `${highlightBackgroundMap[node.attrs.highlightType]}`;

      const style =
        node.attrs.highlightType === DIFF_TYPE.EQUAL
          ? `display: inline-block;`
          : `display: inline-block; border: ${highlightBorder}; background-color: ${highlightBackgroundColor};`;

      dom.setAttribute('style', style);

      const mathContainer = document.createElement('div');
      mathContainer.innerHTML = node.attrs.mathML; // Set the MathML content inside the container

      dom.appendChild(mathContainer); // Append the MathML container to the dom element

      return {
        dom, // Return the dom as the node view
      };
    };
  },
});
