import xorBy from 'lodash/xorBy';
import { Filter } from '../../../../..';
import { TAGS_COLUMN_ID } from '../../../../../../../apps/main/components/common/table/Columns';
import { useTableContext } from '../../../../../../../apps/main/contexts/table/TableContext';
import { useTableFiltersContext } from '../../../../../../../apps/main/contexts/table/TableFilters';
import { TagMetadata } from '../../../../../../../apps/main/generated/api';
import { ActionIcon, Popover, useMarkovTheme } from '../../../../../v2';
import { useDisclosure, useListState } from '../../../../../v2/hooks';
import { TagsFilterMenu } from './TagsFilterMenu';

const TAGS_COLUMN_FILTER_WIDTH = 288;

interface TagsColumnFilterPopoverProps {
  defaultSelectedTags: TagMetadata[];
  onSave: (selectedTags: TagMetadata[]) => void;
}

const TagsColumnFilterPopover = ({ defaultSelectedTags, onSave }: TagsColumnFilterPopoverProps) => {
  const theme = useMarkovTheme();
  const [opened, { close: closeMenu, toggle }] = useDisclosure(false);
  const [selectedTags, handlers] = useListState<TagMetadata>(defaultSelectedTags);

  const handleClearSelection = () => {
    handlers.setState([]);
  };

  const handleClickTag = (tag: TagMetadata) => {
    const nextTags = xorBy(selectedTags, [tag], 'tagId');
    handlers.setState(nextTags);
  };

  const handleClose = () => {
    closeMenu();
  };

  const handleSave = () => {
    onSave(selectedTags);

    closeMenu();
  };

  const selectedTagIds = selectedTags.map(t => t.tagId);

  const iconColor = selectedTagIds.length > 0 ? theme.colors.blue[8] : theme.colors.gray[5];

  return (
    <Popover
      trapFocus
      opened={opened}
      onChange={toggle}
      onClose={handleClose}
      position="bottom"
      shadow="lg"
      width={TAGS_COLUMN_FILTER_WIDTH}
    >
      <Popover.Target>
        <ActionIcon size="xs" onClick={toggle}>
          <Filter fill={iconColor} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown p="xs">
        <TagsFilterMenu
          selectedTagIds={selectedTagIds}
          onClear={handleClearSelection}
          onClickTag={handleClickTag}
          onClose={handleClose}
          onSave={handleSave}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

// Deprecated version using old context
// TODO: Remove this once all tables with a tag column have been migrated
export const TagsColumnFilterDeprecated = () => {
  const deprecatedContext = useTableFiltersContext();
  const selectedTags: TagMetadata[] = deprecatedContext.selectedTags;

  const handleSave = (selectedTags: TagMetadata[]) => {
    deprecatedContext.setSelectedTags(selectedTags);
  };

  return <TagsColumnFilterPopover defaultSelectedTags={selectedTags} onSave={handleSave} />;
};

interface TagsFilterMenuContainerProps {
  defaultSelectedTags: TagMetadata[];
  onSave: (selectedTags: TagMetadata[]) => void;
  onClose: () => void;
}

export const TagsFilterMenuContainer = ({
  defaultSelectedTags,
  onSave,
  onClose,
}: TagsFilterMenuContainerProps) => {
  const [selectedTags, handlers] = useListState<TagMetadata>(defaultSelectedTags);

  const handleClearSelection = () => {
    handlers.setState([]);
  };

  const handleClickTag = (tag: TagMetadata) => {
    const nextTags = xorBy(selectedTags, [tag], 'tagId');
    handlers.setState(nextTags);
  };

  const handleClose = () => {
    onClose();
    // Reset state
    handlers.setState(defaultSelectedTags);
  };

  const handleSave = () => {
    onSave(selectedTags);
    onClose();
  };

  const selectedTagIds = selectedTags.map(t => t.tagId);

  return (
    <TagsFilterMenu
      selectedTagIds={selectedTagIds}
      onClear={handleClearSelection}
      onClickTag={handleClickTag}
      onClose={handleClose}
      onSave={handleSave}
    />
  );
};

export const TagsColumnFilter = () => {
  const theme = useMarkovTheme();
  const { filters, updateFilters } = useTableContext();
  const [opened, { close: closeMenu, toggle }] = useDisclosure(false);

  const handleSave = (selectedTags: TagMetadata[]) => {
    updateFilters({ [TAGS_COLUMN_ID]: selectedTags });
  };

  const handleClose = () => {
    closeMenu();
  };

  const selectedTags: TagMetadata[] = filters.tags ?? [];

  const iconColor =
    filters[TAGS_COLUMN_ID]?.length ?? 0 > 0 ? theme.colors.blue[8] : theme.colors.gray[5];

  return (
    <Popover
      trapFocus
      opened={opened}
      onChange={toggle}
      onClose={handleClose}
      position="bottom"
      shadow="lg"
      width={TAGS_COLUMN_FILTER_WIDTH}
    >
      <Popover.Target>
        <ActionIcon size="xs" onClick={toggle}>
          <Filter fill={iconColor} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown p="xs">
        <TagsFilterMenuContainer
          defaultSelectedTags={selectedTags}
          onClose={handleClose}
          onSave={handleSave}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
