import { AppShell } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { useMarkovTheme } from '@/shared/design-system/v2';
import { APP_HEADER_HEIGHT, AppHeader } from '../components/app-header/AppHeader';
import { SidebarV3 } from '../components/sidebar/sidebar-v3/Sidebar';
import { useIsMobileScreen, useNonEnterpriseSidebarWidth } from '../components/sidebar/util';

export const NonEnterprisePageLayout = (): JSX.Element => {
  const theme = useMarkovTheme();

  const sidebarWidth = useNonEnterpriseSidebarWidth();
  const isMobileScreen = useIsMobileScreen();

  return (
    <AppShell
      styles={{
        root: {
          height: '100%',
        },
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
          minHeight: `calc(100% - ${APP_HEADER_HEIGHT})`,
          paddingLeft: isMobileScreen ? 0 : sidebarWidth,
        },
        body: {
          height: '100%',
        },
      }}
      padding={0}
      navbar={<SidebarV3 />}
      navbarOffsetBreakpoint="sm"
      footer={<></>}
      header={<AppHeader />}
    >
      <Outlet />
    </AppShell>
  );
};
