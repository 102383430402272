import { IconArrowsShuffle, IconPlus } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Box, Button, Flex, Horizontal, ScrollArea, Select, Text } from '@/shared/design-system/v2';
import { getSortedSegmentTypes } from '@/shared/lib/adapters';
import { calculateTimeStampsInterval } from '@/shared/lib/time-format';
import { toPlural } from '@/shared/lib/ui';
import { unique } from '@/shared/lib/util';
import { AnalysisStateNames, AnalysisTask, SegmentType } from '../../generated/api';
import { useRerunFailedAnalysisTasksMutation } from '../../queries/datasets/analysis/tasks';
import { getSegmentText } from '../analyser/analyser.util';
import { useAnalyzersModal } from '../analyser/modal/RunAnalyzersModal';
import { InfoMessage } from '../common/InfoMessage';
import { isSplitSegmentType } from '../dataset-registration/util';
import { SegmentTaskListItem } from './SegmentTaskListItem';

// Type guard needed to type array with possibly-undefined elements
// see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
const isSegmentType = (segmentType: SegmentType | undefined): segmentType is SegmentType =>
  segmentType !== undefined;

const filterOptions = [
  {
    value: '',
    label: 'All',
  },
  {
    value: AnalysisStateNames.Error,
    label: 'Error',
  },
];

interface DatasetAnalysisStatusPanelProps {
  datasetId: string;
  tasks: AnalysisTask[];
  defaultFilter?: string;
}

export const DatasetAnalysisStatusPanel = ({
  tasks,
  datasetId,
  defaultFilter = '',
}: DatasetAnalysisStatusPanelProps): JSX.Element => {
  const [filter, setFilter] = useState(defaultFilter);
  const { rerunFailedTasks } = useRerunFailedAnalysisTasksMutation(datasetId);
  const { open: openAnalyzersModal } = useAnalyzersModal(datasetId);

  useEffect(() => {
    setFilter(defaultFilter);
    // Note: the dependency array here needs to reference both defaultFilter and datasetId
    // If a user changes the filter value from the default, closes the panel, and then inspects another
    // dataset with the same default filter, the filter should get updated to the default for the new
    // dataset. This way, the filter will be maintained for a single dataset but not across datasets.
  }, [defaultFilter, datasetId]);

  const retryAllFailed = () => {
    rerunFailedTasks();
  };

  const onSelectChange = (value: string) => {
    setFilter(value);
  };

  const filteredTasks = tasks.filter(task => filter === '' || task.data?.state === filter);

  const segmentTypes = unique(
    filteredTasks.map(task => task.data?.segmentType).filter(isSegmentType),
  );

  const sortedSegments = getSortedSegmentTypes(segmentTypes);
  const tasksList = sortedSegments.length ? (
    sortedSegments.flatMap(segmentType => {
      const segmentTasks = filteredTasks.filter(task => task.data?.segmentType === segmentType);
      return segmentTasks.map(({ data, message }, i) => {
        if (!data) {
          return null;
        }
        const { taskId, analysisName, state, startTime, endTime, runtimeEstimateSecs } = data;
        const actualRuntime = calculateTimeStampsInterval(startTime, endTime);
        const displayName = isSplitSegmentType(segmentType)
          ? `${analysisName} [${getSegmentText(segmentType)}]`
          : analysisName;

        return (
          <SegmentTaskListItem
            key={`${i}-${taskId}`}
            datasetId={datasetId}
            taskId={taskId}
            analysisName={displayName}
            state={state}
            actualRuntime={actualRuntime}
            estimatedRuntime={runtimeEstimateSecs}
            startTime={startTime}
            endTime={endTime}
            error={message?.description}
          />
        );
      });
    })
  ) : (
    <InfoMessage text="No tasks found" />
  );

  return (
    <Flex direction="column" h="calc(100vh-72px)" gap={0} pos="relative">
      <Horizontal px="lg" py="sm" position="apart">
        <Text variant="subTitle03" color="dark.6">
          {toPlural(tasks.length, 'Analysis', 'Analyses')}
        </Text>
        <Box sx={{ flex: '0 0 130px' }}>
          <Select
            ariaLabel="Tasks"
            hideLabel
            value={filter}
            options={filterOptions}
            onChange={onSelectChange}
          />
        </Box>
      </Horizontal>
      {filter === AnalysisStateNames.Error && sortedSegments.length > 0 && (
        <Horizontal position="right" mr="lg" p="xs">
          <Button
            variant="subtle"
            color="blue"
            onClick={retryAllFailed}
            leftIcon={<IconArrowsShuffle size={16} />}
            size="md"
          >
            Re-run all
          </Button>
        </Horizontal>
      )}
      <ScrollArea h="calc(100vh - 204px)">{tasksList}</ScrollArea>
      <Button
        variant="light"
        onClick={openAnalyzersModal}
        w="100%"
        h={64}
        leftIcon={<IconPlus size={16} />}
      >
        Run more analysis
      </Button>
    </Flex>
  );
};
