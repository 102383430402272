import { Code, CopyButton } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import snakeCase from 'lodash/snakeCase';
import { useState } from 'react';
import {
  Button,
  Horizontal,
  ScrollArea,
  Text,
  Vertical,
  notifications,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { TemplateModel } from '../../../../generated/api';
import { useExportAsJsonMutation } from '../../../../queries/workflows/operators';

export interface ExportWorkflowInputModalProps {
  workflowId: string;
}
const HEADING = 'Exporting the workflow';
const SUBHEADING =
  'You can export this workflow as a JSON template definition which can be saved in SF.';

const convertKeysToSnakeCase = (obj: any): any => {
  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = snakeCase(key);
      acc[snakeKey] = obj[key];
      return acc;
    }, {} as Record<string, any>);
  }
  return obj;
};

const ExportWorkflowAsJsonModal = ({ workflowId }: ExportWorkflowInputModalProps): JSX.Element => {
  const [data, setData] = useState<TemplateModel>();
  const { mutateAsync: exportWorkflowAsJson, isLoading: isExporting } = useExportAsJsonMutation();

  const handleConfirmExporting = async () => {
    try {
      const result = await exportWorkflowAsJson({ workflowIds: [workflowId] });
      const snakeCaseData = convertKeysToSnakeCase(result.data);
      setData(snakeCaseData);
      notifications.success('Exported selected workflow');
    } catch (error) {
      notifications.error('Unable to export selected workflow');
      logger.error(error);
    }
  };

  const handleCancel = () => {
    closeAllModals();
  };

  const jsonData = JSON.stringify(data, null, 2);

  return (
    <Vertical spacing="sm">
      <Text variant="bodyShort02">{SUBHEADING}</Text>

      {data ? (
        <ScrollArea h="50vh">
          <Code block sx={{ overflowY: 'auto' }}>
            {jsonData}
          </Code>
        </ScrollArea>
      ) : (
        <Text>Click Export to generate the JSON template for the workflow.</Text>
      )}

      <Horizontal w="100%" position="right" pt="xl">
        <Button onClick={handleCancel} variant="outline">
          Cancel
        </Button>
        {data ? (
          <CopyButton value={jsonData}>
            {({ copied, copy }) => (
              <Button variant="primary" color={copied ? 'teal' : 'blue'} onClick={copy}>
                {copied ? 'Copied' : 'Copy JSON'}
              </Button>
            )}
          </CopyButton>
        ) : (
          <Button variant="primary" onClick={handleConfirmExporting} disabled={isExporting}>
            {isExporting ? 'Exporting...' : 'Export'}
          </Button>
        )}
      </Horizontal>
    </Vertical>
  );
};

export const useWorkflowAsJsonExport = (workflowId: string) => {
  const openExportWorkflowAsJsonModal = () =>
    openModal({
      title: <Text variant="heading03">{HEADING}</Text>,
      size: 'xl',
      children: <ExportWorkflowAsJsonModal workflowId={workflowId} />,
    });

  return { openExportWorkflowAsJsonModal };
};
