import { VerticalList } from '@/shared/design-system/v2';
import { Info, MoreInfoListItem } from './MoreInfoListItem';

export interface MoreInfoListProps {
  infoList: Array<Info>;
}

export const MoreInfoList = ({ infoList }: MoreInfoListProps) => (
  <VerticalList spacing="xl" listStyleType="none">
    {infoList.map(info => (
      <VerticalList.Item
        key={info.title}
        sx={{
          '.mantine-List-itemWrapper': {
            width: '100%',
          },
        }}
      >
        <MoreInfoListItem info={info} />
      </VerticalList.Item>
    ))}
  </VerticalList>
);
