import { MantineSize } from '@mantine/styles';
import { IconDotsVertical } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { ActionIcon, Menu } from '@/shared/design-system/v2';
import { StyledClickableWrapper } from '../datasets-table/DatasetsTable.style';

interface TableAction {
  id: string;
  label: ReactNode;
  handler: () => void;
}

interface TableActionMenuProps {
  actions: TableAction[];
  disabled?: boolean;
  width?: MantineSize | number;
}

export const TableActionMenu = ({ disabled, actions, width }: TableActionMenuProps) => (
  <StyledClickableWrapper
    onClick={e => {
      e.stopPropagation();
      e.preventDefault();
    }}
  >
    <Menu shadow="md" position="bottom-end" width={width ?? 110} withinPortal>
      <Menu.Target>
        <ActionIcon
          // Removing the border to have same look and feel as the enabled state
          sx={{
            ':disabled': {
              background: 'transparent',
              border: 'none',
            },
          }}
          bg="white.0"
          disabled={disabled}
        >
          <IconDotsVertical
            aria-disabled={disabled}
            style={{ opacity: disabled ? 0.2 : 1 }}
            size={20}
            color="#777"
          />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {actions.map(action => (
          <Menu.Item key={action.id} py={12} onClick={action.handler}>
            {action.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  </StyledClickableWrapper>
);
