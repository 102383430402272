import React, { memo, useEffect } from 'react';
import { Loader } from '@/shared/design-system';
import { Alert, Box, Horizontal } from '@/shared/design-system/v2';
import { useDatasetClusterContext } from '../../../contexts/datasets/DatasetClusterContext';
import { AnalysisStateNames } from '../../../generated/api';
import { useDataQualityAnalysisStatusQuery } from '../../../queries/datasets/analysis/analysis';
import { useGetDatasetClusterFullQuery } from '../../../queries/datasets/clustering/cluster';
import { useDataSubsetsListQuery } from '../../../queries/datasets/clustering/subsets';
import { EmbeddingsClassesFilter } from './EmbeddingsClassesFilter';
import { EmbeddingsInfoCard } from './EmbeddingsInfoCard';
import { ChartActions } from './chart-actions/ChartActions';
import { MislabelledSelection } from './controls/MislabelledSelection';

const LazyScatter = React.lazy(() => import('../../../charts/providers/deck-gl/Scatter'));

interface EmbeddingsDataContainerProps {
  subsetId: string;
  width: number;
  height: number;
}

export const ScatterMemoized = memo(LazyScatter);

export const EmbeddingsDataContainer = ({
  subsetId,
  width,
  height,
}: EmbeddingsDataContainerProps) => {
  const { datasetId, embeddingId, setSelectedSubsetIds } = useDatasetClusterContext();
  const { isLoading, isError, data } = useGetDatasetClusterFullQuery(datasetId, embeddingId);
  const dataQualityStatusQuery = useDataQualityAnalysisStatusQuery(datasetId);
  const { data: subsets = [] } = useDataSubsetsListQuery(datasetId, Boolean(subsetId));

  useEffect(() => {
    if (subsetId) {
      setSelectedSubsetIds([subsetId]);
    }
  }, [setSelectedSubsetIds, subsetId]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Alert color="red">error</Alert>;
  }

  if (!data?.data) {
    return <Alert color="red">no data</Alert>;
  }

  const dataQualityStatus = dataQualityStatusQuery.data;
  const showMislabeledSelection = dataQualityStatus === AnalysisStateNames.Completed;

  return (
    <Box w="100%" h="100%">
      <Horizontal py="lg" px="sm" align="flex-start" sx={{ position: 'absolute', zIndex: 1 }}>
        <EmbeddingsInfoCard />
      </Horizontal>
      {!subsetId && (
        <Horizontal p="lg" sx={{ position: 'absolute', zIndex: 1, right: 24 }}>
          <EmbeddingsClassesFilter />
          {showMislabeledSelection && (
            <MislabelledSelection totalCount={data.metadata.mislabelledCount} />
          )}
        </Horizontal>
      )}
      <ScatterMemoized
        data={data.data}
        metadata={data.metadata}
        subsets={Boolean(subsetId) ? subsets : undefined}
        width={width}
        height={height}
      />
      <ChartActions hideSelectionBtns />
    </Box>
  );
};
