import { PropsWithChildren, ReactNode, forwardRef } from 'react';
import { Box, Horizontal, Text, Vertical } from '@/shared/design-system/v2';

interface HomeSectionProps {
  title: string;
  description: string;
  action: ReactNode;
  headerRef: (element: HTMLDivElement | null) => void;
}

export const HomeSection = forwardRef<HTMLDivElement, PropsWithChildren<HomeSectionProps>>(
  ({ title, description, action, children, headerRef }, ref) => (
    <Vertical spacing="24px" ref={ref}>
      <Horizontal position="apart" ref={headerRef}>
        <Box>
          <Text variant="heading04" color="dark.4">
            {title}
          </Text>
          <Text variant="bodyShort02" color="gray.7">
            {description}
          </Text>
        </Box>
        {action}
      </Horizontal>
      {children}
    </Vertical>
  ),
);

HomeSection.displayName = 'HomeSection';
