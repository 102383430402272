import { Avatar, Horizontal, Prism, Text } from '@/shared/design-system/v2';
import { DataSourceConversationModel, QuestionTypes } from '../../../../../generated/api';
import { useUserInfo } from '../../../../../queries/user';

export interface QuestionViewProps {
  conversation: DataSourceConversationModel;
}

export const QuestionView = ({ conversation }: QuestionViewProps) => {
  const { data } = useUserInfo();
  const isSql = conversation.questionType === QuestionTypes.Sql;

  return (
    <Horizontal maw="100%" align="start" noWrap>
      <Avatar src={data?.user.avatar} size="32px">
        <Text variant="subTitle05">{data?.user.name}</Text>
      </Avatar>
      <Text
        w="100%"
        variant="bodyLong02"
        bg="gray.1"
        py={isSql ? 'none' : 'sm'} // Prism adds some padding, hence in that case padding, it should be none.
        px={isSql ? 'none' : 'md'}
        sx={{ borderRadius: '8px' }}
      >
        {isSql ? (
          <Prism
            language="sql"
            noCopy={true}
            styles={{
              code: {
                backgroundColor: `transparent !important`,
              },
            }}
          >
            {conversation.question}
          </Prism>
        ) : (
          conversation.question
        )}
      </Text>
    </Horizontal>
  );
};
