import { useNavigate } from 'react-router-dom';
import { Button, Center, Overlay } from '@/shared/design-system/v2';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import {
  InsightIntent,
  RegistrationUserIntent,
  intentDetailsMap,
} from '../../../dataset-registration/util';
import { LaunchCard, LaunchCardProps } from '../../studio-home/LaunchCard';
import { CardGrid } from '../CardGrid';

export const featuredIntents: InsightIntent[] = [
  RegistrationUserIntent.BASIC_UNDERSTANDING,
  // Note: Hiding basic text analysis for now
  // RegistrationUserIntent.BASIC_TEXT,
  RegistrationUserIntent.TOPIC_MODELING,
  RegistrationUserIntent.CLUSTERING,
  RegistrationUserIntent.SENTIMENT_ANALYSIS,
  RegistrationUserIntent.PROFANITY_ANALYSIS,
  RegistrationUserIntent.NER,
  RegistrationUserIntent.LABEL_QUALITY,
  RegistrationUserIntent.KEYWORD_EXTRACTION,
];

const useItems = () => {
  const navigate = useNavigate();
  const { getDatasetRegistrationRoute } = useAbsoluteRoutes();

  const DATA_INSIGHTS_ITEMS: LaunchCardProps[] = featuredIntents.map(intent => {
    const details = intentDetailsMap[intent];
    return {
      ...details,
      onClick: () => {
        navigate(getDatasetRegistrationRoute(intent));
      },
    };
  });

  return DATA_INSIGHTS_ITEMS;
};

export const DataInsightsSection = (): JSX.Element => {
  const items = useItems();

  return (
    <CardGrid spacing={24}>
      {items.map(item => (
        <LaunchCard
          key={item.title}
          {...item}
          hoverNode={
            <Overlay color="transparent" opacity={0}>
              <Center w="100%" h="100%">
                <Button
                  variant="primary"
                  bg="dark.6"
                  shape="rounded"
                  sx={theme => ({
                    '&:hover': {
                      backgroundColor: theme.colors.dark[6],
                    },
                  })}
                >
                  Analyze
                </Button>
              </Center>
            </Overlay>
          }
        />
      ))}
    </CardGrid>
  );
};
