import { Event, captureException, captureMessage, withScope } from '@sentry/react';
import { isLocal } from '@/shared/lib/env.util';

export const logger = {
  error: (err: Error | unknown) => {
    if (isLocal()) {
      // eslint-disable-next-line no-console
      console['error'](err);
      return;
    }
    captureException(err);
  },
  warn: (err: Error | unknown) => {
    if (isLocal()) {
      // eslint-disable-next-line no-console
      console['warn'](err);
      return;
    }
    withScope(scope => {
      scope.setLevel('warning');
      captureException(err);
    });
  },
  info: (event: Event) => {
    if (isLocal()) {
      // default to logging in console for local env
      // eslint-disable-next-line no-console
      console['log'](event.message, event.extra);
      return;
    }
    withScope(scope => {
      scope.setLevel('info');
      captureMessage(event.message ?? '', event.extra);
    });
  },
};
