import first from 'lodash/first';
import { Navigate } from 'react-router-dom';
import { FullPageLoader } from '@/shared/design-system';
import { useQueryParams } from '../hooks/useQueryParams';
import { useWorkspaceList } from '../queries/userHome';
import { AppRoutes, getRoute } from './constants';

interface RouteRedirectionProps {
  route: string;
}

export const RouteRedirection = ({ route }: RouteRedirectionProps): JSX.Element => {
  const { isLoading, data } = useWorkspaceList();
  const query = useQueryParams();
  const inviteWorkspaceId = query.get('workspaceId');
  const templateId = query.get('templateId');
  const onboardComplete = query.get('onboardComplete') === 'true'; // Check for onboardComplete param

  if (isLoading) {
    return <FullPageLoader text="Loading your details..." />;
  }

  const workspaceId = inviteWorkspaceId ?? first(data)?.workspaceId;

  let targetRoute = `/${workspaceId}/${route}`;

  if (onboardComplete) {
    targetRoute += '?onboardComplete=true';
  }

  if (templateId) {
    targetRoute += onboardComplete ? `&templateId=${templateId}` : `?templateId=${templateId}`;
  }

  return workspaceId ? (
    <Navigate to={targetRoute} />
  ) : (
    <Navigate to={getRoute(AppRoutes.BASE_ROUTE)} />
  );
};
