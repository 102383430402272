import {
  ControlProps,
  RankedTester,
  and,
  hasType,
  optionIs,
  rankWith,
  schemaMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Text, TextArea, useInputState } from '@/shared/design-system/v2';

const TextAreaControlComponent = ({
  label,
  description,
  handleChange,
  path,
  required,
  uischema,
  data,
  visible,
  enabled,
  config,
  errors,
}: ControlProps) => {
  const [val, setVal] = useInputState(data);
  const minRows = uischema.options?.minRows ?? 3;
  const maxRows = uischema.options?.maxRows ?? 9;

  if (!visible) {
    return null;
  }

  const onChange = (val: string) => {
    setVal(val);
    handleChange(path, val);
  };

  const isViewOnlyForm = config.viewOnly;

  return (
    <TextArea
      autosize
      ariaLabel={label ?? ' '}
      value={val}
      onChange={onChange}
      label={
        <Text span variant="subTitle02">
          {label}
        </Text>
      }
      description={
        <Text span variant="small02" color="gray.7" pb="sm">
          {description}
        </Text>
      }
      minRows={minRows}
      maxRows={maxRows}
      required={required}
      placeholder={uischema.options?.placeholder}
      disabled={!isViewOnlyForm && !enabled}
      readOnly={isViewOnlyForm}
      error={config.isFormDirty ? errors : undefined}
    />
  );
};

export const textAreaControlTester: RankedTester = rankWith(
  2,
  and(
    uiTypeIs('Control'),
    optionIs('multipleLines', true),
    schemaMatches(schema => hasType(schema, 'string')),
  ),
);

export const TextAreaControl = withJsonFormsControlProps(TextAreaControlComponent);
