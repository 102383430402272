import { IconInfoCircle } from '@tabler/icons-react';
import { AxiosPromise } from 'axios';
import { Loader } from '@/shared/design-system';
import { Alert, closeAllModals, notifications } from '@/shared/design-system/v2';
import { ModelWithAppMetadata } from '../../../../generated/api';
import { AnalyzerTaskletEnum } from '../../../../queries/constants';
import { useTriggerAnalysisMutation } from '../../../../queries/datasets/analysis/analysis';
import { useDatasetDetailsQuery } from '../../../../queries/datasets/list';
import {
  useGenerateModelAppFromDataset,
  useModelAppDetailsQuery,
} from '../../../../queries/model-apps/model-apps';
import { GenerateAppModal } from './GenerateAppModal';

interface GenerateAppFromDatasetContainerProps {
  datasetId: string;
}

export const GenerateAppFromDatasetContainer = ({
  datasetId,
}: GenerateAppFromDatasetContainerProps): JSX.Element => {
  const { data: datasetDetails, isLoading: isDatasetDetailsLoading } =
    useDatasetDetailsQuery(datasetId);

  const {
    data: modelAppDetails,
    isLoading: isModelAppDetailsLoading,
    error: modelAppDetailsError,
  } = useModelAppDetailsQuery({
    datasetId,
  });

  const { mutateAsync: generateApp, isLoading: isGeneratingApp } =
    useGenerateModelAppFromDataset(datasetId);

  const { mutateAsync: triggerTasklet, isLoading: isBaselineAnalysisLoading } =
    useTriggerAnalysisMutation(datasetId);

  if (isModelAppDetailsLoading || isDatasetDetailsLoading) {
    return <Loader text="Loading model details..." />;
  }

  if (modelAppDetailsError || !modelAppDetails) {
    return (
      <Alert
        color="red"
        icon={<IconInfoCircle size={14} />}
        title="Unable to load model details to be able to create an app."
      >
        Please try after sometime. If the issue persists, please contact Support
      </Alert>
    );
  }

  const generateModelApp = ({ appName, duration }: { appName: string; duration: number }) => {
    const promises = [
      generateApp({ appName, durationInMins: duration, datasetId }).catch(error => {
        const errorMsg = error.response.data.detail ?? 'Not able to generate the app';
        notifications.show({ variant: 'error', message: errorMsg });
      }),
    ] as AxiosPromise[];

    promises.push(triggerTasklet([AnalyzerTaskletEnum.BASELINE_MODEL]));

    Promise.all(promises).finally(() => {
      closeAllModals();
    });
  };

  const isAppGenerating = isGeneratingApp || isBaselineAnalysisLoading;
  const appDetails: ModelWithAppMetadata = {
    ...modelAppDetails,
    datasetId,
    modelAppName: modelAppDetails.modelAppName || `${datasetDetails?.name}-model-app`,
  };

  return (
    <GenerateAppModal
      modelAppDetails={appDetails}
      generateModelApp={generateModelApp}
      isAppGenerating={isAppGenerating}
    />
  );
};
