import { createStyles } from '@/shared/design-system/v2';

export const useSettingContainerStyles = createStyles(theme => {
  const borderColor = theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3];
  const backgroundColor =
    theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.white[0];

  return {
    tabListContainer: {
      flex: '0 0 240px',
      border: '1px solid',
      borderColor: borderColor,
      background: backgroundColor,
      padding: theme.spacing.xs,
      borderRadius: theme.radius.xs,
      height: 'max-content',
      marginRight: theme.spacing.lg,
    },
    tabContainer: {
      display: 'flex',
      justifyContent: 'start',
      overflowX: 'auto',
      padding: '8px 8px 12px',
      width: '100%',
      margin: 0,
      ':not(:last-child)': {
        borderBottom: '1px solid',
        borderColor: borderColor,
        borderRadius: 0,
      },
    },
    panelPageContainer: {
      width: '85%',
      margin: '0 auto',
      height: '100%',
      border: '1px solid',
      borderColor: borderColor,
      background: backgroundColor,
      color: theme.colorScheme === 'dark' ? theme.colors.white[0] : theme.colors.dark[5],
      borderRadius: theme.radius.xs,
    },
  };
});
