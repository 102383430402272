import { PropsWithChildren } from 'react';
import { Text, TextProps, Vertical } from '@/shared/design-system/v2';
import { useDatasetAnalysisDrawerContext } from '../../contexts/datasets/DatasetAnalysisDrawerContext';
import { DEFAULT_ROW_HEIGHT } from '../datasets-table/v2/cell-renderers/util';

interface DatasetAnalysisPanelLinkProps extends TextProps {
  datasetId: string;
  datasetName: string;
  showErrors?: boolean;
}

export const DatasetAnalysisPanelLink = ({
  children,
  datasetId,
  datasetName,
  showErrors = false,
  ...restProps
}: PropsWithChildren<DatasetAnalysisPanelLinkProps>): JSX.Element => {
  const { open: openDrawer } = useDatasetAnalysisDrawerContext();

  const handleOuterClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleOpenDrawer = () => {
    openDrawer({ datasetId, datasetName, showErrors });
  };

  return (
    <Vertical onClick={handleOuterClick} sx={{ cursor: 'pointer' }}>
      <Text
        variant="textLink"
        color="dark.2"
        onClick={handleOpenDrawer}
        {...restProps}
        sx={{ lineHeight: `${DEFAULT_ROW_HEIGHT}px` }}
      >
        {children}
      </Text>
    </Vertical>
  );
};
