import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { ampli } from '@/shared/ampli';
import { Box, Container, Horizontal, Tabs } from '@/shared/design-system/v2';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';

export enum DatasetDetailsTab {
  DETAILS = 'details',
  DATA_PROFILE = 'data_profile',
  ANALYSIS = 'analysis',
  EMBEDDINGS = 'embeddings',
  TOPOGRAPHY = 'topography',
  DATA_OPERATIONS = 'operations',
  APPS = 'apps',
}

enum AnalyticsEnum {
  DETAILS = 'datasetsDetailsTabClicked',
  DATA_PROFILE = 'datasetsDetailsDataprofileTabClicked',
  BASIC_ANALYSIS = 'datasetsDetailsBasicanalysisTabClicked',
  EMBEDDINGS = 'datasetsDetailsEmbeddingsTabClicked',
  DATA_OPERATIONS = 'datasetsDetailsWorkflowsTabClicked',
  APPS = 'datasetsDetailsAppsTabClicked',
}

const AnalyticsEnumMap = {
  [DatasetDetailsTab.DETAILS]: AnalyticsEnum.DETAILS,
  [DatasetDetailsTab.DATA_PROFILE]: AnalyticsEnum.DATA_PROFILE,
  [DatasetDetailsTab.ANALYSIS]: AnalyticsEnum.BASIC_ANALYSIS,
  [DatasetDetailsTab.EMBEDDINGS]: AnalyticsEnum.EMBEDDINGS,
  // TODO: Update TOPOGRAPHY analytics key
  [DatasetDetailsTab.TOPOGRAPHY]: AnalyticsEnum.EMBEDDINGS,
  [DatasetDetailsTab.DATA_OPERATIONS]: AnalyticsEnum.DATA_OPERATIONS,
  [DatasetDetailsTab.APPS]: AnalyticsEnum.APPS,
};

interface TabDetails {
  id: DatasetDetailsTab;
  label: string;
  enabled?: boolean;
}

interface DatasetDetailsTabsProps {
  datasetId: string;
  activeTab: DatasetDetailsTab;
  setActiveTab: (tabId: DatasetDetailsTab) => void;
}

export const DatasetDetailsTabs = ({
  activeTab,
  setActiveTab,
  datasetId,
}: DatasetDetailsTabsProps) => {
  const { workspaceId } = useAppMetadata();
  const { featureMizzen, featureDatasetTopography } = useFlags();

  const TABS: TabDetails[] = useMemo(
    () => [
      {
        id: DatasetDetailsTab.DETAILS,
        label: 'Details',
      },
      {
        id: DatasetDetailsTab.DATA_PROFILE,
        label: 'Data Profile',
      },
      {
        id: DatasetDetailsTab.ANALYSIS,
        label: 'Analysis Summary',
      },
      {
        id: DatasetDetailsTab.EMBEDDINGS,
        label: 'Embeddings',
      },
      {
        id: DatasetDetailsTab.TOPOGRAPHY,
        label: 'Topography',
        enabled: featureDatasetTopography,
      },
      {
        id: DatasetDetailsTab.DATA_OPERATIONS,
        label: 'Data Operations',
      },
      {
        id: DatasetDetailsTab.APPS,
        label: 'Apps',
        enabled: featureMizzen,
      },
    ],
    [featureMizzen, featureDatasetTopography],
  );

  const handleChangeTab = (tabId: DatasetDetailsTab) => {
    const analyticsKey = AnalyticsEnumMap[tabId];
    ampli[analyticsKey]({ workspaceId, datasets_id: datasetId });
    setActiveTab(tabId);
  };

  return (
    <Box
      sx={theme => ({
        backgroundColor:
          theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.white[0],
        position: 'sticky',
        top: 48,
        zIndex: 2,
        overflowX: 'auto',
        flexShrink: 0,
      })}
    >
      <Tabs value={activeTab} onTabChange={handleChangeTab}>
        <Tabs.List>
          <Container size="lg" mx={0} px={32}>
            <Horizontal noWrap spacing={0}>
              {TABS.filter(tab => tab?.enabled ?? true).map(({ id: tabId, label }) => (
                <Tabs.Tab key={tabId} size="sm" value={tabId}>
                  {label}
                </Tabs.Tab>
              ))}
            </Horizontal>
          </Container>
        </Tabs.List>
      </Tabs>
    </Box>
  );
};
