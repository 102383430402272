import css from '@styled-system/css';
import styled from 'styled-components';
import { Box } from '@/shared/design-system';

export const navbarHeight = '52px';

export const StyledHeaderContainer = styled.header(
  css({
    height: navbarHeight,
    px: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bg: '#192638',
  }),
);

export const StyledNavContainer = styled.nav({
  display: 'flex',
  alignItems: 'center',
});

export const StyledIconContainer = styled(Box)(
  css({
    cursor: 'pointer',
    pr: '16px',
    mr: '16px',
    borderRight: 'thinSolid',
    borderColor: 'text.shade4',
  }),
);

export const LogoContainer = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});
