import { Button, Menu, Text, Vertical } from '@/shared/design-system/v2';
import { DataOperationType } from '../../generated/api';
import { useDatasetSelectionModal } from './dataset-selection/useDatasetSelectionModal';

export const dataLabelingItems = [
  {
    id: DataOperationType.ManualRelabeling,
    title: 'Manual Labeling',
    description:
      'Generate a new dataset by correcting mislabeled data or assigning labels to your unlabeled data.',
  },
  {
    id: DataOperationType.RuleBasedRelabeling,
    title: 'Rule-based Labeling',
    description: 'Generate a new dataset by defining rules for automated data labeling.',
  },
  {
    id: DataOperationType.SubsetRelabeling,
    title: 'Auto-Labeling',
    description:
      'Auto-generate labels for your dataset based on the labels for a given subset of data.',
  },
];

export const StartLabelingMenu = () => {
  const { open: openDatasetSelectionModal } = useDatasetSelectionModal();

  const handleMenuItemClick = (type: DataOperationType) => {
    openDatasetSelectionModal(type);
  };

  return (
    <Menu shadow="xl" width={360} position="bottom-end" withinPortal>
      <Menu.Target>
        <Button variant="primary">Start data labeling</Button>
      </Menu.Target>
      <Menu.Dropdown px="md">
        <Vertical spacing="sm">
          {dataLabelingItems.map(props => (
            <Menu.Item key={props.id} onClick={() => handleMenuItemClick(props.id)}>
              <Vertical spacing="xs">
                <Text variant="subTitle03" color="gray.7">
                  {props.title}
                </Text>
                <Text variant="small03" color="gray.6">
                  {props.description}
                </Text>
              </Vertical>
            </Menu.Item>
          ))}
        </Vertical>
      </Menu.Dropdown>
    </Menu>
  );
};
