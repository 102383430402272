import { Button, Center, Text, Vertical } from '@/shared/design-system/v2';
import { mailToSupport } from '../../util';

interface DagNodeSchemaErrorProps {
  workflowId: string;
}

export const DagNodeSchemaError = ({ workflowId }: DagNodeSchemaErrorProps): JSX.Element => {
  const handleContactUsClick = () => {
    window.location.href = mailToSupport(
      `Issue while fetching data preview in workflow - ${workflowId}`,
    );
  };

  return (
    <Center w="100%" h="100%">
      <Vertical spacing="sx" align="center">
        <Text variant="subTitle02" color="gray.7">
          Something went wrong
        </Text>
        <Text variant="small01" color="gray.6">
          Try reloading the page, or reach out to us if the problem keeps happening.
        </Text>
        <Button variant="outline" onClick={handleContactUsClick} size="xs" mt="md">
          Contact us
        </Button>
      </Vertical>
    </Center>
  );
};
