import { createStyles } from '@/shared/design-system/v2';

export const useNotificationStyle = createStyles(theme => ({
  notificationItem: {
    '&': {
      height: '73px',
      width: '364px',
      padding: '16px',
      gap: '8px',
      cursor: 'pointer',
    },
    '& + &': {
      borderTop: `1px solid ${theme.colors.white[1]}`,
    },
    a: {
      color: 'inherit',
    },
    svg: {
      path: {
        fill: theme.colors.dark[0],
      },
    },
    ':hover': {
      backgroundColor: theme.colors.blue[0],

      a: {
        textDecoration: 'none',
        color: theme.colors.blue[6],
      },
    },
  },
  unread: {
    backgroundColor: theme.colors.yellow[0],
    ':hover': {
      backgroundColor: theme.colors.blue[0],

      a: {
        textDecoration: 'none',
        color: theme.colors.blue[6],
      },
    },
    a: {
      color: 'inherit',
    },
  },
}));
