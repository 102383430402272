import { IconChevronDown } from '@tabler/icons-react';
import {
  Badge,
  Horizontal,
  Popover,
  ScrollArea,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { useDatasetClusterContext } from '../../../contexts/datasets/DatasetClusterContext';
import { ClassesFilter } from './action-panel/class-filter/ClassesFilter';

export const EmbeddingsClassesFilter = () => {
  const { selectedClasses } = useDatasetClusterContext();
  const theme = useMarkovTheme();

  const isClassSelected = selectedClasses.length > 0;

  return (
    <Popover>
      <Popover.Target>
        <Badge
          p="md"
          variant={isClassSelected ? 'filled' : 'outline'}
          color={isClassSelected ? 'orange' : 'gray.4'}
          bg={isClassSelected ? 'orange' : 'gray.0'}
          sx={{ cursor: 'pointer' }}
        >
          <Horizontal spacing="xs">
            <Text variant="small01" color={isClassSelected ? 'white' : 'gray.8'}>
              Classes {isClassSelected ? `(${selectedClasses.length})` : ''}
            </Text>
            <IconChevronDown color={isClassSelected ? theme.white : theme.colors.gray[5]} />
          </Horizontal>
        </Badge>
      </Popover.Target>
      <Popover.Dropdown>
        <ScrollArea.Autosize maw={360} mah={240}>
          <Vertical>
            <ClassesFilter />
          </Vertical>
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
};
