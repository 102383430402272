import {
  DefaultApiFactory,
  ModelRegistryRoutesApiFactory,
  TaggedResourcesApiFactory,
  UserCommentsRoutesApiFactory,
} from '@/main/generated/api';
import { apiConfiguration } from './api';

export const taggedResourcesApi = TaggedResourcesApiFactory(apiConfiguration);

export const mumbaiApi = DefaultApiFactory(apiConfiguration);

export const userCommentsApi = UserCommentsRoutesApiFactory(apiConfiguration);

export const modelRegistryApi = ModelRegistryRoutesApiFactory(apiConfiguration);
