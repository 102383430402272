import { useEffect } from 'react';
import { useListState } from '@/shared/design-system/v2';
import { AsyncDropDownResources, AsyncResourcePair } from '../../../../generated/api';
import { getSelectorBasedOnResource } from '../factory';
import { ModelSelectionProps } from './ModelSelection';

const createOption = (query: string): AsyncResourcePair => ({
  value: query,
  label: query,
});

interface ResourceSelectProps extends Omit<ModelSelectionProps, 'options'> {
  resourceKey: AsyncDropDownResources;
  resources: AsyncResourcePair[];
}

export const ResourceSelect = ({
  resourceKey,
  resources,
  value,
  creatable,
  errorWithCode,
  ...restProps
}: ResourceSelectProps): JSX.Element => {
  // State for resource options needed to enable creating new options in UI
  const [resourceOptions, { setState: setOptions, append: addOption }] =
    useListState<AsyncResourcePair>([]);

  // Update options when resources data becomes available, preserving any custom value
  useEffect(() => {
    const isCustomValue = !!value && !resources.some(option => option.value === value);
    const nextOptions = isCustomValue ? [...resources, createOption(value)] : resources;
    setOptions(nextOptions);
  }, [resources]);

  const handleResourceCreate = (query: string | string) => {
    const resourceOption = createOption(query);
    addOption(resourceOption);
    return resourceOption;
  };

  const SelectComponent = getSelectorBasedOnResource(resourceKey);
  const isModelSelect = resourceKey === AsyncDropDownResources.WorkflowModels;

  const modelSelectProps = {
    errorWithCode,
  };

  const creatableProps = {
    creatable,
    getCreateLabel: (query: string) => `+ Create ${query}`,
    onCreate: handleResourceCreate,
  };

  return (
    <SelectComponent
      withinPortal
      options={resourceOptions}
      value={value}
      sx={{ flexGrow: 1 }}
      {...(isModelSelect ? modelSelectProps : {})}
      {...(creatable ? creatableProps : {})}
      {...restProps}
    />
  );
};
