import {
  Attributes,
  CommandProps,
  InputRule,
  Mark,
  PasteRule,
  getMarkAttributes,
  markInputRule,
  markPasteRule,
  mergeAttributes,
} from '@tiptap/core';
import { ParseRule } from '@tiptap/pm/model';
import { ulid } from 'ulid';
import { mergeAndStringifyStyles } from '../../utils/style-utils';
import { styleConfigurations } from './style-configurations';

export function sanitizeStyleAttr(styleAttr: string) {
  // Define a regular expression to match potentially harmful CSS code
  const regex = /expression|javascript|behaviour|vbscript|data:/gi;

  // Remove any potentially harmful code from the style attribute
  const sanitizedStyleAttr = styleAttr.replace(regex, '');

  // Return the sanitized style attribute
  return sanitizedStyleAttr;
}

/**
 * TextStyle Mark Extension
 *
 * This extension defines a Mark for text styling in a rich text editor built on ProseMirror.
 * It allows users to add various styles (like bold, italic, underline, etc.) to text
 * and handles commands, parsing, rendering, and shortcuts accordingly.
 *
 * Key Features:
 * - Adds attributes dynamically based on a provided configuration (`styleConfigurations`).
 * - Parses HTML to identify and add styles to `span` elements.
 * - Renders HTML by merging new and existing styles, ensuring styles are preserved.
 * - Defines commands for toggling, setting, and unsetting various text styles.
 * - Supports keyboard shortcuts, input rules, and paste rules for ease of use.
 * - Leverages the configuration to manage multiple text styles dynamically.
 *
 * Example:
 * - If the initial HTML is `<span style="font-weight: bold;">text</span>`, the output could be `<span bold="true" style="font-weight: bold;">text</span>`.
 * This attribute is used to determine whether the `bold` mark is active.
 */
export const TextStyle = Mark.create({
  name: 'textStyle',

  addAttributes() {
    const attributes: Attributes = {
      'data-id': {
        default: ulid(),
      },
      class: {
        default: null,
      },
      style: {
        default: null,
      },
    };

    // Define attributes dynamically based on styleConfigurations
    // This is to identify active marks
    for (const [key, config] of Object.entries(styleConfigurations)) {
      attributes[key] = {
        default: null,
        parseHTML: config.parseHTML,
      };
    }

    return attributes;
  },

  parseHTML() {
    const htmlTagConfigMapping: Record<string, string> = {};

    Object.entries(styleConfigurations).forEach(([key, config]) => {
      config.allowedTags?.forEach(tag => {
        htmlTagConfigMapping[tag] = key;
      });
    });

    const rules: ParseRule[] = [
      {
        tag: 'span',
      },
      {
        tag: Object.keys(htmlTagConfigMapping).join(','),
        getAttrs: (node: HTMLElement) => {
          const nodeAttrs: Record<string, boolean> = {};

          let element: HTMLElement | null = node;

          // Start with the given node and traverse up its parent hierarchy
          // Traverse as long as the element exists and is in the htmlTagConfigMapping
          while (element && element.tagName.toLowerCase() in htmlTagConfigMapping) {
            const key = htmlTagConfigMapping[
              element.tagName.toLowerCase()
            ] as keyof typeof styleConfigurations;

            const config = styleConfigurations[
              key
            ] as typeof styleConfigurations[keyof typeof styleConfigurations];

            // If the element passes the parseHTML check for the current configuration
            if (config.parseHTML(element)) {
              nodeAttrs[key] = true;
            }

            element = element.parentElement;
          }

          return Object.keys(nodeAttrs).length > 0 ? nodeAttrs : false;
        },
      },
    ];

    return rules;
  },

  // Render HTML, preserving existing styles and adding new ones
  // Here we are adding/modifying styles according to the changed attributes
  renderHTML({ HTMLAttributes }) {
    const newStyles: Record<string, string> = {};

    for (const [key, attrValue] of Object.entries(HTMLAttributes)) {
      const config = styleConfigurations[key];
      // Get the css value from the config using attribute value
      if (config && attrValue !== null && attrValue !== undefined) {
        newStyles[config.cssProperty] = config.toCSS(attrValue);
      }
    }

    const styleString = mergeAndStringifyStyles(HTMLAttributes.style, newStyles);
    return ['span', mergeAttributes({ ...HTMLAttributes, style: styleString }), 0];
  },

  // Dynamically Generate Commands Based on Style Configurations
  addCommands() {
    const commands: Record<string, (value?: string) => (props: CommandProps) => boolean> = {
      setTextStyleClass:
        (className?: string) =>
        ({ commands, state }) => {
          const attrs = { ...getMarkAttributes(state, this.name), class: className };
          return commands.setMark(this.name, attrs);
        },
    };

    for (const [key, config] of Object.entries(styleConfigurations)) {
      // Toggle Commands
      if (config.commands.toggle) {
        commands[config.commands.toggle] =
          () =>
          ({ commands }) => {
            const isActive = this.editor.isActive(this.name, {
              [key]: true,
            });

            if (isActive) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return commands[config.commands.unset]?.();
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return commands[config.commands.set]?.();
          };
      }

      // Set Commands
      if (config.commands.set) {
        commands[config.commands.set] =
          (value?: string) =>
          ({ commands, state }) => {
            const attrs = { ...getMarkAttributes(state, this.name), [key]: value ?? true };
            return commands.setMark(this.name, attrs);
          };
      }

      // Unset Commands
      if (config.commands.unset) {
        commands[config.commands.unset] =
          () =>
          ({ commands, state }) => {
            const attrs = { ...getMarkAttributes(state, this.name), [key]: false };
            return commands.setMark(this.name, attrs);
          };
      }
    }

    return commands;
  },

  // Dynamically Assign Keyboard Shortcuts Based on Style Configurations
  addKeyboardShortcuts() {
    const shortcuts: { [key: string]: () => boolean } = {};

    for (const config of Object.values(styleConfigurations)) {
      config.keyboardShortcuts?.forEach(shortcut => {
        shortcuts[shortcut] = () => {
          // Add keyboard shortcut only if toggle command exists
          if (config.commands.toggle) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.editor.commands[config.commands.toggle]?.();
          }
          return false;
        };
      });
    }

    return shortcuts;
  },

  // Dynamically Create Input Rules Based on Style Configurations
  addInputRules() {
    const inputRules: InputRule[] = [];

    for (const [key, config] of Object.entries(styleConfigurations)) {
      if (config.inputRule) {
        config.inputRule.forEach(pattern => {
          inputRules.push(
            markInputRule({
              find: pattern,
              type: this.type,
              getAttributes: () => ({
                [key]: true,
              }),
            }),
          );
        });
      }
    }

    return inputRules;
  },

  // Dynamically Create Paste Rules Based on Style Configurations
  addPasteRules() {
    const pasteRules: PasteRule[] = [];

    for (const [key, config] of Object.entries(styleConfigurations)) {
      if (config.pasteRule) {
        config.pasteRule.forEach(pattern => {
          pasteRules.push(
            markPasteRule({
              find: pattern,
              type: this.type,
              getAttributes: () => ({
                [key]: true,
              }),
            }),
          );
        });
      }
    }

    return pasteRules;
  },
});
