import { FullPageLoader, Loader } from '@/shared/design-system';
import { Box } from '@/shared/design-system/v2';
import { notifications } from '@/shared/design-system/v2/core/overlays/notification';
import {
  useAcceptInviteToWorkspaceMutation,
  useUserPendingInvitesQuery,
} from '../../../../queries/account/invite';
import { Invitation } from './Invitation';
import { useInvitationStyles } from './Invitation.style';

export const InvitationContainer = (): JSX.Element => {
  const { classes } = useInvitationStyles();
  const { isLoading, error, data } = useUserPendingInvitesQuery();
  const {
    isLoading: isAcceptingInviteLoading,
    error: errorAcceptingInvite,
    reset,
    mutate,
  } = useAcceptInviteToWorkspaceMutation();

  if (isLoading) {
    return (
      <Loader text="Fetching any pending invites..." height={32} width={32} borderWidth="4px" />
    );
  }

  if (isAcceptingInviteLoading) {
    return <FullPageLoader text="Accepting your invite..." />;
  }

  if (errorAcceptingInvite) {
    reset();
    notifications.show({
      variant: 'error',
      message:
        errorAcceptingInvite.response?.data.detail ??
        'Error accepting invite. Please try again or contact Support!',
    });
  }

  if (error) {
    notifications.show({ variant: 'error', message: 'Unable to load your invites' });
  }

  if (!data || !data.length) {
    return <></>;
  }

  return (
    <Box className={classes.container}>
      <Invitation invitations={data} onAcceptInvitation={mutate} />
    </Box>
  );
};
