import { AxiosError, AxiosResponse } from 'axios';
import { datasetApi } from '@/shared/lib/api';
import { transformSeriesName } from '../../../components/dataset-details/util';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  DatasetCorrelationInfo,
  DatasetCorrelationInfoResponse,
  DatasetCorrelationInfoTypes,
  DatasetCorrelationInfoTypesResponse,
  SegmentType,
} from '../../../generated/api';
import { useChartQuery } from '../../charts-query-wrapper';
import { AnalysesResponseSelector, analysesResponseSelector } from '../../util';

const queryKeys = {
  getCorrelationTypes: (
    datasetId: string,
    workspaceId: string,
    userId: string,
    segment: SegmentType,
  ) => ['get-correlation-types', datasetId, workspaceId, userId, segment],
  getCorrelationData: (
    datasetId: string,
    workspaceId: string,
    userId: string,
    segment: string,
    correlationType: string,
  ) => ['get-correlation-data', datasetId, workspaceId, userId, segment, correlationType],
};

export const useGetCorrelationTypesQuery = (datasetId: string, segment: SegmentType) => {
  const { workspaceId, userId = '' } = useAppMetadata();

  return useChartQuery<
    AxiosResponse<DatasetCorrelationInfoTypesResponse>,
    unknown,
    AnalysesResponseSelector<DatasetCorrelationInfoTypes>
  >(
    queryKeys.getCorrelationTypes(datasetId, workspaceId, userId, segment),
    () => datasetApi.workspaceGetDatasetCorrelationInfoTypesV1(workspaceId, datasetId, segment),
    {
      enabled: Boolean(datasetId && segment),
      select: analysesResponseSelector,
      publicChartFn: res => ({ data: res.data.correlationType }),
    },
  );
};

export const useGetCorrelationDataQuery = (
  datasetId: string,
  segment: SegmentType,
  correlationType: string,
) => {
  const { workspaceId, userId = '' } = useAppMetadata();

  return useChartQuery<
    AxiosResponse<DatasetCorrelationInfoResponse>,
    AxiosError,
    DatasetCorrelationInfo | undefined
  >(
    queryKeys.getCorrelationData(datasetId, workspaceId, userId, segment, correlationType),
    () =>
      datasetApi.workspaceGetDatasetCorrelationInfoV1(
        workspaceId,
        datasetId,
        segment,
        correlationType,
      ),
    {
      // FIXME:: There is an issue with the query cache as it is overriding the cache for different variables
      // After switching segments multiple times, both the segment data becomes same.
      cacheTime: 0,
      enabled: Boolean(workspaceId && userId && correlationType),
      select: res => {
        const response = res.data.response;
        const transformedData = response?.data?.xCategories
          ? {
              xCategories: response.data.xCategories.map(xCategory =>
                transformSeriesName(xCategory),
              ),
              yCategories: response.data.xCategories.map(yCategory =>
                transformSeriesName(yCategory),
              ),
              // Need to disable turbo threshold to render correlation heatmap with many cols.
              // See discussion here: https://www.highcharts.com/forum/viewtopic.php?t=36285
              series: response.data.series?.map(serie => ({
                ...serie,
                turboThreshold: 0,
              })),
            }
          : {};

        return {
          ...response,
          data: {
            ...response?.data,
            ...transformedData,
          },
        };
      },
      publicChartFn: res => {
        const index = res?.data?.correlationType?.response?.analyzedCorrelations.findIndex(
          (type: string) => type === correlationType,
        );

        return { data: res.data.correlationList[index] };
      },
    },
  );
};
