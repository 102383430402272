import { useState } from 'react';
import { marked } from '@/shared/design-system/v2/utils';

export interface UseCopyToClipboardOptions {
  /**
   * timeout in milliseconds
   */
  timeout: number;
}

export function useCopyMarkdown({ timeout = 2000 }: UseCopyToClipboardOptions) {
  const [copied, setCopied] = useState(false);

  const fallbackCopy = (htmlText: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlText;
    tempDiv.style.position = 'fixed';
    tempDiv.style.opacity = '0';
    tempDiv.style.pointerEvents = 'none';
    document.body.appendChild(tempDiv);

    const range = document.createRange();
    range.selectNodeContents(tempDiv);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    try {
      document.execCommand('copy');
      setCopied(true);
      setTimeout(() => setCopied(false), timeout);
    } catch (err) {
      setCopied(false);
    } finally {
      selection?.removeAllRanges();
      document.body.removeChild(tempDiv);
    }
  };

  const copy = async (markdownText: string) => {
    const htmlText = marked(markdownText, { async: false });
    try {
      const clipboardItems = [
        new ClipboardItem({
          'text/plain': new Blob([markdownText], { type: 'text/plain' }),
          'text/html': new Blob([htmlText], { type: 'text/html' }),
        }),
      ];

      await navigator.clipboard.write(clipboardItems);
      setCopied(true);
      setTimeout(() => setCopied(false), timeout);
    } catch (err) {
      fallbackCopy(htmlText);
    }
  };

  return { copied, copy };
}
