import { useCallback, useState } from 'react';
import { useListState } from '@/shared/design-system/v2';

export const useAnalyzerSelection = (initialSelection: string[] = []) => {
  const [selectedTaskletIds, handlers] = useListState<string>(initialSelection);
  const [savedSelectionId, setSavedSelectionId] = useState<string | null>(null);

  const getIsSelected = useCallback(
    (taskletId: string) => selectedTaskletIds.includes(taskletId),
    [selectedTaskletIds],
  );

  const selectTasklet = useCallback(
    (isSelected: boolean, taskletId: string) => {
      if (isSelected) {
        handlers.append(taskletId);
      } else {
        handlers.filter(id => id !== taskletId);
      }
      // Unset any saved selection when user customizes selection
      if (savedSelectionId) {
        setSavedSelectionId(null);
      }
    },
    [handlers, savedSelectionId],
  );

  const resetSelection = useCallback(() => {
    handlers.setState([]);
    if (savedSelectionId) {
      setSavedSelectionId(null);
    }
  }, [handlers, savedSelectionId]);

  const changeAnalyzerSelection = useCallback(
    (selectionId: string, taskletIds: string[]) => {
      setSavedSelectionId(selectionId);
      handlers.setState(taskletIds);
    },
    [handlers],
  );

  return {
    selectedTaskletIds,
    setSelectedTaskletIds: handlers.setState,
    savedSelectionId,
    getIsSelected,
    selectTasklet,
    resetSelection,
    changeAnalyzerSelection,
  };
};
