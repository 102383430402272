import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { useDisclosure } from '@/shared/design-system/v2';
import { DataAnalysisDrawer } from '../../components/dataset-analysis-status/DataAnalysisDrawer';

interface DatasetDrawerProps {
  datasetId: string;
  datasetName: string;
  showErrors?: boolean;
}

interface DatasetAnalysisDrawerContextState {
  opened: boolean;
  open: (props: DatasetDrawerProps) => void;
  close: () => void;
}

const DatasetAnalysisDrawerContext = createContext<DatasetAnalysisDrawerContextState | null>(null);

export const DatasetAnalysisDrawerContextProvider = ({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element => {
  const [opened, { open: openDrawer, close }] = useDisclosure();
  const [datasetId, setDatasetId] = useState('');
  const [datasetName, setDatasetName] = useState('');
  const [showErrors, setShowErrors] = useState(false);

  const open = (props: DatasetDrawerProps) => {
    openDrawer();
    setDatasetId(props.datasetId);
    setDatasetName(props.datasetName);
    setShowErrors(props.showErrors ?? false);
  };

  const value = { opened, open, close };

  return (
    <DatasetAnalysisDrawerContext.Provider value={value}>
      {children}
      <DataAnalysisDrawer
        datasetId={datasetId}
        datasetName={datasetName}
        opened={opened}
        onClose={close}
        showErrors={showErrors}
      />
    </DatasetAnalysisDrawerContext.Provider>
  );
};

export const useDatasetAnalysisDrawerContext = () => {
  const context = useContext(DatasetAnalysisDrawerContext);
  if (!context) {
    throw new Error(
      'useDatasetAnalysisDrawerContext should only be used inside DatasetAnalysisDrawerContextProvider',
    );
  }
  return context;
};
