import { ScrollArea } from '@mantine/core';
import { IconAlertCircleFilled } from '@tabler/icons-react';
import first from 'lodash/first';
import get from 'lodash/get';
import { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Horizontal,
  Popover,
  Select,
  SelectItem,
  SelectProps,
  Text,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { ErrorObject } from '../../../../generated/api';
import { ModelSchemaPreview, ModelSelectItem } from '../items/ModelSelectItem';

interface SchemaMismatchErrorProps {
  errorText?: string;
}

export const SchemaMismatchError = ({ errorText }: SchemaMismatchErrorProps) => {
  if (!errorText) {
    return <></>;
  }
  const columnsStr = errorText
    .replace('Following columns needed for the pre-trained model are missing: ', '')
    .slice(1, -1);

  // Use a regex to correctly split on commas outside of quotes and handle escaped quotes
  const matchedCols = columnsStr.match(/'(?:\\'|[^'])*'|"(?:\\"|[^"])*"/g);
  const columns = matchedCols
    ? matchedCols.map(col => col.slice(1, -1).replace(/\\"|\\'/g, match => match[1]))
    : [];

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ScrollArea.Autosize mah={200} maw={340} miw={200}>
      {columns.map(col => (
        <Text key={col} truncate>
          {col}
        </Text>
      ))}
    </ScrollArea.Autosize>
  );
};

export interface ModelSelectionProps extends SelectProps {
  errorWithCode?: ErrorObject[];
}

export const ModelSelection = ({
  loading,
  options,
  onChange,
  value,
  error,
  errorWithCode,
  ...restProps
}: ModelSelectionProps) => {
  const theme = useMarkovTheme();
  const [selectedVal, setSelectedVal] = useState(value);

  const selectedResource = (options as SelectItem[]).find((opt: SelectItem) => opt.value === value);
  const itemSchema = get(selectedResource, 'detail.artifact_metadata.schema', []) as any[];

  const showSchemaBtn = Boolean(value && itemSchema.length);

  const handleChange = (val: string | null) => {
    setSelectedVal(val);
    onChange ? onChange(val) : undefined;
  };

  return (
    <Box w="100%">
      <Select
        options={loading ? [] : options}
        withinPortal
        onChange={handleChange}
        value={selectedVal as string}
        error={error ? 'No model selected' : undefined}
        itemComponent={ModelSelectItem}
        sx={{ width: '100%' }}
        {...restProps}
      />
      <Horizontal w="100%">
        {showSchemaBtn ? (
          <Popover>
            <Popover.Target>
              <Horizontal>
                <Button>Show schema</Button>
                <Text>+{itemSchema.length} Features</Text>
              </Horizontal>
            </Popover.Target>
            <Popover.Dropdown>
              <ModelSchemaPreview schema={itemSchema} />
            </Popover.Dropdown>
          </Popover>
        ) : null}
      </Horizontal>
      {Boolean(value && !error) && errorWithCode && errorWithCode.length > 0 ? (
        <Alert color="yellow" mt="lg">
          <Horizontal>
            <IconAlertCircleFilled color={theme.colors.yellow[7]} />
            <Text color="yellow" variant="overline">
              Note
            </Text>
          </Horizontal>
          <Text variant="subTitle05" color="gray.8">
            The input data does not match the columns required by the selected model to make
            predictions. To proceed, you can either select a different model that matches the data
            you have, or choose another data source that includes the required columns for the model
            to predict properly.
          </Text>
          <Popover>
            <Popover.Target>
              <Button>View missing columns</Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Text variant="subTitle05" color="gray.8">
                <SchemaMismatchError errorText={first(errorWithCode)?.errorText} />
                {}
              </Text>
            </Popover.Dropdown>
          </Popover>
        </Alert>
      ) : null}
    </Box>
  );
};
