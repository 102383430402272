import { ReactNode, useState } from 'react';
import { Box, Button, Horizontal, Vertical, useDisclosure } from '@/shared/design-system/v2';
import { StorageType } from '../../../../generated/api';
import { ResourceActionsParams } from '../../connector-details/actions/ResourceActions';
import { CreateConnectorWithTypeModal } from '../CreateConnectorWithTypeModal';
import { ConnectorResourceExplorer } from './ConnectorResourceExplorer';
import { ConnectorSelectContainer } from './ConnectorSelect.container';

const HEADER_HEIGHT = 72;

interface ConnectorResourceSelectionProps {
  onSelectResource?: (resourceId: string) => void;
  connectorType?: StorageType;
  availableConnectorTypes?: StorageType[];
  getResourceActions?: (params: ResourceActionsParams) => ReactNode;
  leafSelectable?: boolean;
}

export const ConnectorResourceSelection = ({
  onSelectResource,
  connectorType,
  availableConnectorTypes,
  getResourceActions,
  leafSelectable,
}: ConnectorResourceSelectionProps): JSX.Element => {
  const [selectedConnectorId, setSelectedConnectorId] = useState<string | null>(null);
  const [opened, { open: openCreateConnectorModal, close }] = useDisclosure();

  const handleCreate = (connectorId: string) => {
    setSelectedConnectorId(connectorId);
  };

  return (
    <>
      <CreateConnectorWithTypeModal
        opened={opened}
        onClose={close}
        onCreate={handleCreate}
        connectorType={connectorType}
        availableConnectorTypes={availableConnectorTypes}
      />
      <Vertical h="100%">
        <Horizontal align="end">
          <Box sx={{ flexGrow: 1 }}>
            <ConnectorSelectContainer
              value={selectedConnectorId}
              onChange={setSelectedConnectorId}
              connectorTypes={connectorType ? [connectorType] : availableConnectorTypes}
            />
          </Box>
          <Button variant="primary" onClick={openCreateConnectorModal}>
            + Add Connector
          </Button>
        </Horizontal>
        <Box h={`calc(100% - ${HEADER_HEIGHT}px)`}>
          <ConnectorResourceExplorer
            connectorId={selectedConnectorId}
            onSelectResource={onSelectResource}
            getResourceActions={getResourceActions}
            leafSelectable={leafSelectable}
          />
        </Box>
      </Vertical>
    </>
  );
};
