import { Center, Text } from '@/shared/design-system/v2';

interface InfoMessageProps {
  text: string;
  height?: string;
}

export const InfoMessage = ({ height = '100px', text }: InfoMessageProps) => (
  <Center sx={{ height: height }}>
    <Text variant="bodyShort02" fs="italic" align="center">
      {text}
    </Text>
  </Center>
);
