import { MantineNumberSize } from '@mantine/styles';
import { PropsWithChildren } from 'react';
import { Box, Card, Horizontal, Text } from '@/shared/design-system/v2';

interface ProfilingTableWidgetProps {
  title: string;
  height?: MantineNumberSize;
  showBorder?: boolean;
}

export const PADDING = 16;
export const BORDER = 1;
export const HEADER_HEIGHT = 22;

export const ProfilingTableWidget = ({
  title,
  children,
  height = '228px',
  showBorder = true,
}: PropsWithChildren<ProfilingTableWidgetProps>): JSX.Element => (
  <Card withBorder={showBorder} p={0}>
    <Horizontal
      p={`${PADDING}px`}
      sx={theme => ({ borderBottom: `${BORDER}px solid ${theme.fn.themeColor('white.2')}` })}
    >
      <Text variant="subTitle03" color="dark.7">
        {title}
      </Text>
    </Horizontal>
    <Box p="12px 0 12px 12px" h={height}>
      {children}
    </Box>
  </Card>
);
