import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import { useMemo } from 'react';
import { Badge, Box, Horizontal, SimpleGrid, Text, Vertical } from '@/shared/design-system/v2';
import { Insight } from '../../../generated/api';
import { InfoMessage } from '../../common/InfoMessage';
import { replaceSeriesName } from '../util';

// Needed to parse the insight message that BE sends, which is generated from a 3rd party tool

interface InsightsTableRow {
  message: string;
  insightType: string;
}

// TODO: @Kris this needs to be revisited. Multi-word columns are causing problems with current parsing.
// Will likely need BE to modify the response. Also, Chip should not be used for column names.
// const { colName, rest } = parseInsightMessage(message);
// if (!colName) {
//   return (
//     <Text variant="subTitle04" color="dark.5" lineClamp={1} title={rest}>
//       {rest}
//     </Text>
//   );
// }

// return (
//   <Horizontal spacing={4} noWrap>
//     <Text span variant="subTitle04" color="dark.7" sx={{ flexShrink: 0 }}>
//       {colName}
//     </Text>
//     <Text span variant="subTitle04" color="dark.5" lineClamp={1} title={rest}>
//       {rest}
//     </Text>
//   </Horizontal>
// );

const getRows = (insights: Insight[]) =>
  sortBy(insights, 'insightIdentifier').map(insight => ({
    message: replaceSeriesName(insight.msg),
    insightType: startCase(insight.insightIdentifier),
  }));

interface DataInsightsTableProps {
  insights: Insight[];
  oneColumn?: boolean;
}

export const DataInsightsTable = ({ insights, oneColumn }: DataInsightsTableProps): JSX.Element => {
  const rows = useMemo(() => getRows(insights), [insights]);

  if (!insights.length) {
    return (
      <Box>
        <InfoMessage text="No insights available" />
      </Box>
    );
  }

  const chunkSize = oneColumn ? rows.length : 5;

  const rowSubsets = Array(Math.ceil(rows.length / chunkSize))
    .fill(null)
    .map((_, index) => rows.slice(index * chunkSize, index * chunkSize + chunkSize));

  const minWidth = 500 * rowSubsets.length;

  return (
    <SimpleGrid miw={minWidth} w="100%" cols={rowSubsets.length} spacing={24}>
      {rowSubsets.map((rowSubset, index) => (
        <Vertical key={index}>
          {rowSubset.map((element, idx) => (
            <Box
              pt="sm"
              key={idx}
              w="100%"
              h="44px"
              sx={theme => ({ borderBottom: `1px solid ${theme.fn.themeColor('gray.2')}` })}
            >
              <Horizontal noWrap>
                <Badge variant="outline" color="green">
                  <Text variant="small01" tt="capitalize" color="green">
                    {element.insightType}
                  </Text>
                </Badge>
                <Text variant="small02" color="dark.5" lineClamp={1} title={element.message} mt={2}>
                  {element.message}
                </Text>
              </Horizontal>
            </Box>
          ))}
        </Vertical>
      ))}
    </SimpleGrid>
  );
};
