import { MarkerType } from 'reactflow';
import { Flow } from '@/shared/design-system/v2';
import { grays } from '@/shared/design-system/v2/theme/colors/colorList';
import { humaniseCronString } from '@/shared/lib/cron-lib';
import { humaniseTimeDuration } from '@/shared/lib/time-format';
import {
  Edge,
  Node,
  Workflow,
  WorkflowScheduleStatus,
  WorkflowScheduleType,
} from '../../generated/api';
import { WorkflowEdge, WorkflowNode } from './nodes/utils';

export const defaultNodeProps: Partial<WorkflowNode> = {
  sourcePosition: Flow.Position.Right,
  targetPosition: Flow.Position.Left,
};

export const defaultEdgeProps: Partial<WorkflowEdge> = {
  type: 'step',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: grays.gray6,
  },
  style: {
    strokeWidth: 2,
    stroke: grays.gray6,
  },
};

export const topologicalSort = (edges: Edge[], nodes: Node[]): string[] => {
  const inDegree: Record<string, number> = {};
  const adjList: Record<string, string[]> = {};

  for (const node of nodes) {
    inDegree[node.id] = 0;
    adjList[node.id] = [];
  }

  for (const edge of edges) {
    const edgeSrc = edge.source;
    const edgeTarget = edge.target;
    adjList[edgeSrc].push(edgeTarget);
    inDegree[edgeTarget] = (inDegree[edgeTarget] || 0) + 1;
  }

  const queue: string[] = nodes.filter(node => inDegree[node.id] === 0).map(node => node.id);
  const sortedList: string[] = [];

  while (queue.length > 0) {
    const vertex = queue.shift()!;
    sortedList.push(vertex);
    for (const neighbor of adjList[vertex]) {
      inDegree[neighbor] -= 1;
      if (inDegree[neighbor] === 0) {
        queue.push(neighbor);
      }
    }
  }

  if (sortedList.length !== nodes.length) {
    throw new Error('The DAG has a cycle, which is not permitted');
  }

  return sortedList;
};

export const findNodesWithNoIncomingEdges = (edges: Edge[]) => {
  const sources = new Set(edges.map(edge => edge.source));
  const targets = new Set(edges.map(edge => edge.target));

  const nodesWithNoIncomingEdges = [...sources].filter(node => !targets.has(node));

  return nodesWithNoIncomingEdges;
};

export const getScheduleBadgeColor = (status?: WorkflowScheduleStatus) => {
  switch (status) {
    case WorkflowScheduleStatus.Scheduled:
      return 'skyBlue';
    case WorkflowScheduleStatus.Paused:
      return 'yellow';
    default:
      return 'gray';
  }
};

export const getScheduleStatusText = (status?: WorkflowScheduleStatus) => {
  if (status === WorkflowScheduleStatus.Scheduled) {
    return 'Scheduled';
  }
  if (status === WorkflowScheduleStatus.Paused) {
    return 'Paused';
  }
  return 'Unscheduled';
};

export const getScheduleDescription = (workflow: Workflow) => {
  if (!workflow.workflowScheduleDetails) {
    return 'No schedule details available';
  }

  const {
    scheduleType,
    interval = 0,
    frequency = '',
    endDate = '',
  } = workflow.workflowScheduleDetails;

  const frequencyDescriptionMap: Record<WorkflowScheduleType, string> = {
    [WorkflowScheduleType.Cron]: humaniseCronString(frequency),
    [WorkflowScheduleType.Periodic]: `every ${humaniseTimeDuration(interval, 'seconds')}`,
  };
  const frequencyText = frequencyDescriptionMap[scheduleType];
  const endText = new Date(endDate).toDateString();

  return `This workflow runs ${frequencyText} until ${endText}`;
};
