import css from '@styled-system/css';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { getInterpolatedColor } from '@/shared/lib/ui';
import Box from '../Box';
import { Loader, LoaderProps } from './Loader';

export const PageWrapper = styled(Box)(
  css({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1002,
    width: '100%',
    height: '100%',
  }),
);

export const LoadingWrapper = styled(Box)(
  css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    pointerEvents: 'none',
    bg: getInterpolatedColor('#FFFFFF', 0.8),
    boxShadow: '0px 0px 28px rgba(0, 0, 0, 0.12)',
  }),
);

export const SpinnerWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FullPageLoader = (props: LoaderProps) =>
  createPortal(
    <PageWrapper>
      <LoadingWrapper>
        <SpinnerWrapper>
          <Loader {...props} />
        </SpinnerWrapper>
      </LoadingWrapper>
    </PageWrapper>,
    document.getElementById('loader-root') as HTMLElement,
  );
