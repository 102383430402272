import { IconMinus, IconPlus } from '@tabler/icons-react';
import { ActionIcon, Button, Tooltip } from '@/shared/design-system/v2';
import { useViewStateContext } from '../../../../contexts/datasets/embeddings/ViewStateContext';
import { useChartActions } from './ChartActions.style';

export const ZoomControls = (): JSX.Element => {
  const { viewState, setViewState } = useViewStateContext();
  const { classes } = useChartActions();

  const handleZoomIn = () => {
    if (!viewState?.zoom || viewState?.zoom >= (viewState.maxZoom ?? 20)) {
      return;
    }

    setViewState({
      ...viewState,
      zoom: (viewState?.zoom ?? 5) + 1,
    });
  };

  const handleZoomOut = () => {
    if (!viewState?.zoom || viewState?.zoom >= (viewState.maxZoom ?? 5)) {
      return;
    }

    setViewState({
      ...viewState,
      zoom: (viewState?.zoom ?? 10) - 1,
    });
  };

  return (
    <Button.Group
      bg="dark.8"
      sx={theme => ({
        borderRadius: theme.radius.sm,
        border: '1px solid',
        borderColor: theme.fn.themeColor('white.3'),
        gap: '4px',
      })}
    >
      <Tooltip label="Zoom In" withArrow withinPortal>
        <ActionIcon
          onClick={handleZoomIn}
          title="Zoom in"
          color="white.0"
          className={classes.actionIcon}
        >
          <IconPlus />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Zoom Out" withArrow withinPortal>
        <ActionIcon
          onClick={handleZoomOut}
          title="Zoom out"
          color="white.0"
          className={classes.actionIcon}
        >
          <IconMinus />
        </ActionIcon>
      </Tooltip>
    </Button.Group>
  );
};
