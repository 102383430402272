import { Skeleton } from '@mantine/core';
import startCase from 'lodash/startCase';
import { Text, Vertical } from '@/shared/design-system/v2';
import { ICellRendererParams } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { DataCategory } from '../../../../generated/api';
import { Dataset } from '../../../../queries/datasets/list';
import { DEFAULT_ROW_HEIGHT } from './util';

export const TypeRenderer = ({ value, data }: ICellRendererParams<Dataset, DataCategory>) => {
  if (!data) {
    return (
      <Vertical justify="center" h={DEFAULT_ROW_HEIGHT}>
        <Skeleton width={80} height={30} />
      </Vertical>
    );
  }

  return (
    <Vertical pr="md" spacing="0px" justify="center" h={DEFAULT_ROW_HEIGHT}>
      <Text variant="subTitle04" color="gray.7">
        {value ? startCase(value) : '--'}
      </Text>
    </Vertical>
  );
};
