import { CitationStyleGuideType } from '../../../../../../apps/main/generated/api';

const DEFAULT_CITATION_STYLE = CitationStyleGuideType.Cmos;

export const getAppliedCitationStyle = (defaultCitationStyle = DEFAULT_CITATION_STYLE) =>
  defaultCitationStyle === CitationStyleGuideType.None
    ? DEFAULT_CITATION_STYLE
    : defaultCitationStyle; // Defaults the reference style to DEFAULT_CITATION_STYLE if not found

// We need to use this if there is exact match of types while pasting the content from paste-content extension. If a user copies and tries to paste a normal JSON object, it should return false.
export function tryParseJSONObject(
  jsonString?: string,
  validateFn?: (obj: any) => boolean,
): boolean {
  try {
    if (jsonString) {
      const o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        // If a validation function is provided, use it
        if (validateFn) {
          return validateFn(o);
        }
        return true; // No validation function, just return true
      }
    }
  } catch (e) {}

  return false;
}
