import { Info } from '@/shared/design-system';
import {
  ActionIcon,
  Box,
  Horizontal,
  Progress,
  ScrollArea,
  Text,
  Tooltip,
  Vertical,
} from '@/shared/design-system/v2';
import { DataQualityClassMetric } from '../../../../generated/api';
import { VisualizationComponent } from '../../../analyser/visualization/Visualization';

const CLASS_QUALITY_SCORE_DESCRIPTION =
  'This score indicates the probable accuracy of the labels assigned to every instance within a specific class.';

const getProgressSections = (element: DataQualityClassMetric) => {
  const scorePercentage = element.score * 100;
  const complement = 100 - scorePercentage;

  return [
    {
      value: scorePercentage,
      color: 'green.5',
      label: `${scorePercentage.toFixed(1)}%`,
      tooltip: `High label quality: ${scorePercentage.toFixed(2)}%`,
    },
    {
      value: complement,
      color: 'red.4',
      label: `${complement.toFixed(1)}%`,
      tooltip: `Possibly mislabeled: ${complement.toFixed(2)}%`,
    },
  ];
};

interface ClassQualityScoreProps {
  classDataQualityData: DataQualityClassMetric[];
}

export const ClassQualityScore = ({ classDataQualityData }: ClassQualityScoreProps) => (
  <VisualizationComponent
    cardProps={{
      shadow: '',
      p: '0px',
    }}
  >
    <Horizontal spacing="xs">
      <VisualizationComponent.Header.Title
        title="Quality score by class"
        titleProps={{ variant: 'subTitle03', color: 'gray.8', py: '24px', pl: '24px' }}
      />
      <Tooltip label={CLASS_QUALITY_SCORE_DESCRIPTION} withArrow withinPortal multiline maw={325}>
        <ActionIcon variant="transparent">
          <Info />
        </ActionIcon>
      </Tooltip>
    </Horizontal>
    <ScrollArea.Autosize mah="316px">
      <Vertical px="24px" pb="lg">
        <Box
          w="100%"
          h="36px"
          sx={theme => ({ borderBottom: `1px solid ${theme.fn.themeColor('gray.2')}` })}
        >
          <Horizontal position="apart" noWrap>
            <Text
              variant="subTitle05"
              color="gray.6"
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              Class name
            </Text>
            <Text
              variant="subTitle05"
              color="gray.6"
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              Quality score
            </Text>
          </Horizontal>
        </Box>
        {classDataQualityData.map((element, idx) => (
          <Box
            pt="sm"
            key={idx}
            w="100%"
            h="44px"
            sx={theme => ({ borderBottom: `1px solid ${theme.fn.themeColor('gray.2')}` })}
          >
            <Horizontal position="apart" noWrap>
              <Text variant="small01" color="gray.7">
                {element.name}
              </Text>
              <Box w={240}>
                <Progress radius="xl" size={20} sections={getProgressSections(element)} />
              </Box>
            </Horizontal>
          </Box>
        ))}
      </Vertical>
    </ScrollArea.Autosize>
  </VisualizationComponent>
);
