import { Badge, Horizontal, Text, Tooltip, Vertical } from '@/shared/design-system/v2';
import { WorkflowRun } from '../../../../generated/api';
import { useGetWorkflowDAGQuery } from '../../../../queries/workflows/dag';
import { useDebugRunStatusQuery } from '../../../../queries/workflows/debug';
import { getWorkflowRunItemDetails } from './util';

interface WorkflowRunDetailsOverviewProps {
  run: WorkflowRun;
  withExecutionTypeLabel: boolean;
  activeFlowBadge?: boolean;
}

export const WorkflowRunDetailsOverview = ({
  run,
  withExecutionTypeLabel,
  activeFlowBadge,
}: WorkflowRunDetailsOverviewProps) => {
  const { data } = useGetWorkflowDAGQuery(run.workflowId);
  const { data: runStatus } = useDebugRunStatusQuery(run.workflowId, run.workflowRunId);

  const {
    title,
    executionType,
    info = '',
    subtitle = '',
    subtitleColor = 'gray.8',
  } = getWorkflowRunItemDetails(run);

  const executionTypeLabel = (
    <Text variant="small02" color="gray.6">
      {executionType}
    </Text>
  );

  return (
    <Vertical w="100%" spacing={0}>
      <Horizontal noWrap spacing="sm" position="apart">
        <Horizontal noWrap spacing="sm">
          <Text variant="subTitle02" color="gray.9">
            {title}
          </Text>
          {run.debugRun ? (
            <Badge variant="light" bg="yellow.0" color="yellow.7" size="small" px="sm">
              <Text variant="small03" color="yellow.7" tt="capitalize">
                Sample run
              </Text>
            </Badge>
          ) : null}
        </Horizontal>
        {activeFlowBadge && runStatus?.dagHash === data?.dagHash ? (
          <Tooltip
            withArrow
            label="This run used the workflow active in the builder"
            withinPortal
            position="right-start"
          >
            <Badge variant="dot" bg="gray.0" color="green.5" px="sm">
              <Text variant="small03" color="gray.7" tt="capitalize">
                Active
              </Text>
            </Badge>
          </Tooltip>
        ) : null}
      </Horizontal>
      <Horizontal w="100%" spacing="xxl" position="apart" align="end" noWrap>
        <Horizontal noWrap spacing="xs" align="end">
          <Text variant="small02" color={subtitleColor}>
            {subtitle}
          </Text>
          {withExecutionTypeLabel && (
            <>
              {subtitle ? (
                <Text variant="subTitle01">
                  {/* Centered dot separator */}
                  {'\u00B7'}
                </Text>
              ) : null}
              {executionTypeLabel}
            </>
          )}
        </Horizontal>
        {info}
      </Horizontal>
    </Vertical>
  );
};
