import {
  CorrelationIllustration,
  DataProfileIllustration,
  LineChartIllustration,
  TableIllustration,
} from '@/shared/design-system';
import { VisualizationType } from '@/shared/design-system/v2/rich-text-editor/extensions/draggable-block/chart-grid/factory/util';
import { useConfusionMatrixQuery } from '../../../../../../queries/confusionMatrix';
import { useCustomMetricsQuery } from '../../../../../../queries/customMetrics';
import { useDisagreementsInfiniteQuery } from '../../../../../../queries/disagreements';
import { useClassDistributionsQuery } from '../../../../../../queries/evaluations/distributions';
import { useEvaluationMetricsAvailability } from '../../../../../../queries/evaluations/evaluations';
import { useEvalClassLevelMetricsQuery } from '../../../../../../queries/metrics';
import { VIZ_NAMES, getChartsData } from '../util';

export const useGetEvaluationsCharts = (evaluationId: string) => {
  const evaluationCharts = [];

  // ROC and PR Curve
  const { data: metricsAvailability, isLoading: isFetchingROCAndPRCurve } =
    useEvaluationMetricsAvailability([evaluationId]);

  if (metricsAvailability?.isRocAndPrCurveAvailable) {
    evaluationCharts.push(
      getChartsData({
        visualizationType: VisualizationType.EVALUATION_ROC_PR_CURVE,
        title: VIZ_NAMES.EVALUATION_ROC_PR_CURVE,
        chart: <LineChartIllustration />,
        visualizationParameters: { evaluationId },
      }),
    );
  }

  // Class Level Metrics
  const { isLoading: isFetchingClassLevelMetrics, data: classLevelMetrics } =
    useEvalClassLevelMetricsQuery(evaluationId);

  if (classLevelMetrics) {
    evaluationCharts.push(
      getChartsData({
        visualizationType: VisualizationType.EVALUATION_CLASS_LEVEL_METRICS,
        title: VIZ_NAMES.EVALUATION_CLASS_LEVEL_METRICS,
        chart: <TableIllustration />,
        visualizationParameters: { evaluationId },
      }),
    );
  }

  // Class Distribution
  const { isLoading: isFetchingClassDistribution, data: classDistribution } =
    useClassDistributionsQuery([evaluationId]);

  if (classDistribution) {
    evaluationCharts.push(
      getChartsData({
        visualizationType: VisualizationType.EVALUATION_CLASS_DISTRIBUTION,
        title: VIZ_NAMES.EVALUATION_CLASS_DISTRIBUTION,
        chart: <DataProfileIllustration />,
        visualizationParameters: { evaluationId },
      }),
    );
  }

  // Custom Metrics
  const { isLoading: isFetchingCustomMetrics, data: customMetrics } =
    useCustomMetricsQuery(evaluationId);

  if (Boolean(customMetrics?.length)) {
    evaluationCharts.push(
      getChartsData({
        visualizationType: VisualizationType.EVALUATION_CUSTOM_METRICS,
        title: VIZ_NAMES.EVALUATION_CUSTOM_METRICS,
        chart: <DataProfileIllustration />,
        visualizationParameters: { evaluationId },
      }),
    );
  }

  // Confusion Matrix
  const { isLoading: isFetchingConfusionMatrix, data: confusionMatrix } =
    useConfusionMatrixQuery(evaluationId);

  if (Boolean(confusionMatrix?.data)) {
    evaluationCharts.push(
      getChartsData({
        visualizationType: VisualizationType.EVALUATION_CONFUSION_MATRIX,
        title: VIZ_NAMES.EVALUATION_CONFUSION_MATRIX,
        chart: <CorrelationIllustration />,
        visualizationParameters: { evaluationId },
      }),
    );
  }

  // Model Disagreement
  const { isLoading: isFetchingModelDisagreement, data: modelDisagreement } =
    useDisagreementsInfiniteQuery(evaluationId);

  if (Boolean(modelDisagreement?.pages?.length)) {
    evaluationCharts.push(
      getChartsData({
        visualizationType: VisualizationType.EVALUATION_MODEL_DISAGREEMENTS,
        title: VIZ_NAMES.EVALUATION_MODEL_DISAGREEMENTS,
        chart: <TableIllustration />,
        visualizationParameters: { evaluationId },
      }),
    );
  }

  return {
    evaluationCharts,
    isLoading:
      isFetchingROCAndPRCurve ||
      isFetchingClassLevelMetrics ||
      isFetchingClassDistribution ||
      isFetchingCustomMetrics ||
      isFetchingConfusionMatrix ||
      isFetchingModelDisagreement,
  };
};
