import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme } from '@/shared/design-system';
import { loadAnalyticsModule } from '@/shared/initializers/analytics';
import { initChecksum } from '@/shared/initializers/checksum';
import { initializeSentry } from '@/shared/initializers/sentry';
import { initUserflow } from '@/shared/initializers/userflow';
import { isDevelopment } from '@/shared/lib/env.util';
import { Notifications, ThemeProvider } from './../../shared/design-system/v2';
import { App } from './App';
import './index.css';

// As of now, we don't want to use checksum in other envs
if (isDevelopment()) {
  initChecksum();
}

const sentryDsn = import.meta.env.VITE_APP_SENTRY_DSN as string;
initializeSentry(sentryDsn);

loadAnalyticsModule();

const rootContainer = document.getElementById('root');

if (rootContainer) {
  const root = createRoot(rootContainer);

  root.render(
    <React.StrictMode>
      <ThemeProvider>
        <StyledThemeProvider theme={theme}>
          <Notifications />
          <App />
        </StyledThemeProvider>
      </ThemeProvider>
    </React.StrictMode>,
  );

  initUserflow();
}
