import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { MODAL_LEVEL_2_Z_INDEX, Modal, Text } from '@/shared/design-system/v2';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { PaymentSubscriptionType } from '../../../generated/api';
import { paymentAndSubscriptionKeys } from '../../../queries/account/payment-and-subscription';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { TabComponentsType } from '../../settings/Settings';
import { showSubscriptionUpdateSuccessNotification } from '../util';
import { SubscriptionPlanCheckoutForm } from './SubscriptionPlanCheckoutForm';

interface PaidPlanCheckoutModalProps {
  opened: boolean;
  onClose: () => void;
  subscriptionType: PaymentSubscriptionType;
}

export const PaidPlanCheckoutModal = ({
  opened,
  onClose,
  subscriptionType,
}: PaidPlanCheckoutModalProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { getSettingsRoute } = useAbsoluteRoutes();

  const handleCompleteCheckout = () => {
    queryClient.invalidateQueries({
      queryKey: paymentAndSubscriptionKeys.getCurrentSubscription(workspaceId),
    });
    showSubscriptionUpdateSuccessNotification();
    navigate(getSettingsRoute(TabComponentsType.ABOUT));
  };

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={<Text variant="subTitle01">Upgrade plan</Text>}
      size="80vw"
      zIndex={MODAL_LEVEL_2_Z_INDEX}
    >
      <SubscriptionPlanCheckoutForm
        subscriptionType={subscriptionType}
        onComplete={handleCompleteCheckout}
      />
    </Modal>
  );
};
