import { IconCheck } from '@tabler/icons-react';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Center,
  Horizontal,
  Loader,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
  openModal,
  useInputState,
} from '@/shared/design-system/v2';
import { toPlural } from '@/shared/lib/ui';
import { DataOperationType, DatasetSamplingStrategy } from '../../../generated/api';
import { useCloudDatasetPreviewParser } from '../../../hooks/useDatasetPreviewParser';
import { useCreateDataOperationMutation } from '../../../queries/datasets/analysis/data-workflows';
import {
  useDatasetDetailsQuery,
  useRegisteredDatasetPreviewQuery,
} from '../../../queries/datasets/list';
import { useAbsoluteRoutes } from '../../../router/hooks';
import {
  PaginatedColumnsTable,
  useColumnColors,
} from '../../paginated-columns-table/PaginatedColumnsTable';

const PREVIEW_TABLE_HEIGHT = 436;

enum SETUP_STEPS {
  SELECT_FEATURES = 'SELECT_FEATURES',
  CONFIRM_TARGET = 'CONFIRM_TARGET',
}

interface RelabelingDataPreviewTableContainerProps {
  datasetId: string;
  step: SETUP_STEPS;
  targetName: string;
  selectedFeatures: string[];
  setSelectedFeatures: (features: string[]) => void;
}

export const RelabelingDataPreviewTableContainer = ({
  datasetId,
  step,
  targetName,
  selectedFeatures,
  setSelectedFeatures,
}: RelabelingDataPreviewTableContainerProps): JSX.Element => {
  const columnColors = useColumnColors();
  const datasetPreviewQuery = useRegisteredDatasetPreviewQuery(datasetId);
  const {
    isParsing,
    isParsingError,
    columns: columnNames,
    rows: rowsData,
  } = useCloudDatasetPreviewParser({
    unParsedData: datasetPreviewQuery.data,
    delimiter: datasetPreviewQuery.data?.delimiter ?? '',
  });

  if (datasetPreviewQuery.isLoading || isParsing) {
    return (
      <Center h={PREVIEW_TABLE_HEIGHT}>
        <Loader />
      </Center>
    );
  }

  if (datasetPreviewQuery.isError || isParsingError) {
    return (
      <Alert color="red">Error encountered while fetching data preview. Try refreshing.</Alert>
    );
  }

  const columns = first(Object.values(columnNames)) ?? [];
  // Showing data from first segment only
  const rows = first(Object.values(rowsData)) ?? [];

  const onColumnCheckedChange = (columnId: string, value: boolean) => {
    if (value) {
      setSelectedFeatures([...selectedFeatures, columnId]);
    } else {
      setSelectedFeatures(selectedFeatures.filter(feature => feature !== columnId));
    }
  };

  const getIsColumnChecked = (columnId: string) => selectedFeatures.includes(columnId);

  const getIsColumnDisabled = (columnId: string) =>
    step === SETUP_STEPS.CONFIRM_TARGET || columnId === targetName;

  const getColumnColor = (columnId: string) =>
    selectedFeatures.includes(columnId)
      ? columnColors.feature
      : columnId === targetName
      ? columnColors.target
      : columnColors.default;

  const getIsPageHighlighted = (start: number, end: number) =>
    selectedFeatures.some(column => {
      const featureColPos = columns.indexOf(column) + 1;
      return start <= featureColPos && featureColPos <= end;
    });

  return (
    <PaginatedColumnsTable
      columns={columns}
      rows={rows}
      height={PREVIEW_TABLE_HEIGHT}
      onColumnCheckedChange={onColumnCheckedChange}
      getIsColumnChecked={getIsColumnChecked}
      getIsColumnDisabled={getIsColumnDisabled}
      getIsPageHighlighted={getIsPageHighlighted}
      getColumnColor={getColumnColor}
    />
  );
};

interface RuleBasedRelabelingModalContentProps {
  datasetId: string;
}

export const RuleBasedRelabelingModalContent = ({
  datasetId,
}: RuleBasedRelabelingModalContentProps): JSX.Element => {
  const navigate = useNavigate();
  const { getRuleBasedRelabelingRoute } = useAbsoluteRoutes();

  const datasetDetailsQuery = useDatasetDetailsQuery(datasetId);
  const { mutate: createDataOperation } = useCreateDataOperationMutation(datasetId);

  const [step, setStep] = useState(SETUP_STEPS.SELECT_FEATURES);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  // Initialize new target name based on existing target
  const currTarget = datasetDetailsQuery.data?.yColName ?? '';
  const [newTargetName, setNewTargetName] = useInputState(currTarget ? `${currTarget}_New` : '');

  const handleClose = () => {
    closeAllModals();
  };

  const handleBackStep = () => {
    setStep(SETUP_STEPS.SELECT_FEATURES);
  };

  const handleProceedNextStep = () => {
    setStep(SETUP_STEPS.CONFIRM_TARGET);
  };

  const handleInitiateDataOperation = () => {
    createDataOperation(
      {
        dataOperationType: DataOperationType.RuleBasedRelabeling,
        details: {
          newTargetName,
          featureColumns: selectedFeatures,
          // Hard-coding this for now; later we'll let user configure this
          samplingStrategy: DatasetSamplingStrategy.Sequential,
        },
      },
      {
        onError: () => {
          notifications.error('Error creating new operation');
        },
        onSuccess: res => {
          const operationId = res.data.dataOperationId;
          const route = getRuleBasedRelabelingRoute(datasetId, operationId);
          navigate(route);
          closeAllModals();
        },
      },
    );
  };

  const handleClearColumns = () => {
    setSelectedFeatures([]);
  };

  return (
    <Vertical spacing="sm">
      <Horizontal position="apart">
        {step === SETUP_STEPS.CONFIRM_TARGET ? (
          <Box w={300}>
            <TextInput
              autoFocus
              ariaLabel="New Target"
              label="New Target Name"
              value={newTargetName}
              onChange={setNewTargetName}
            />
          </Box>
        ) : (
          <Box my="sm">
            <Text variant="subTitle03" color="gray.7">
              Target column: {currTarget}
            </Text>
          </Box>
        )}

        {selectedFeatures.length > 0 && (
          <Horizontal bg="seaGreen.0" px="lg">
            <IconCheck />
            <Text variant="small01" color="gray.8">
              {toPlural(selectedFeatures.length, 'feature')} selected
            </Text>
            {step === SETUP_STEPS.SELECT_FEATURES && (
              <Button size="xs" onClick={handleClearColumns}>
                Clear
              </Button>
            )}
          </Horizontal>
        )}
      </Horizontal>
      <RelabelingDataPreviewTableContainer
        datasetId={datasetId}
        step={step}
        targetName={currTarget}
        selectedFeatures={selectedFeatures}
        setSelectedFeatures={setSelectedFeatures}
      />
      <Horizontal position="right" mt="sm">
        {step === SETUP_STEPS.SELECT_FEATURES && (
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="primary"
              onClick={handleProceedNextStep}
              disabled={isEmpty(selectedFeatures)}
            >
              Next
            </Button>
          </>
        )}
        {step === SETUP_STEPS.CONFIRM_TARGET && (
          <>
            <Button onClick={handleBackStep}>Back</Button>
            <Button
              variant="primary"
              onClick={handleInitiateDataOperation}
              disabled={isEmpty(newTargetName)}
            >
              Begin
            </Button>
          </>
        )}
      </Horizontal>
    </Vertical>
  );
};

export const useRuleBasedRelabelingModal = () => {
  const open = (datasetId: string) =>
    openModal({
      title: (
        <Box>
          <Text variant="subTitle01">Confirm feature columns</Text>
          <Text variant="bodyShort02" color="gray.6">
            Select columns required to validate the assigned labels in the dataset
          </Text>
        </Box>
      ),
      size: '95vw',
      children: <RuleBasedRelabelingModalContent datasetId={datasetId} />,
    });

  return { open };
};
