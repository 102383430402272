import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { notifications, useMarkovTheme } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { UserflowEvent, useUserflowJSExecutor } from '@/shared/initializers/userflow';
import { openWorkspaceInviteModal } from '../../components/user-invitation/useOpenInviteModal';
import { useLayoutContext } from '../../contexts/layout/LayoutContext';
import { useAbsoluteRoutes } from '../../router/hooks';

// Hook to call so that pages can decide whether to show sidebar or not
export const useShowSideBar = (visible: boolean) => {
  const theme = useMarkovTheme();
  const { setSidebarExpanded } = useLayoutContext();

  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  useEffect(() => {
    // On mobile screens, we do not want the sidebar to expand by default
    if (isMobileScreen) {
      setSidebarExpanded(false);
      return;
    }
    setSidebarExpanded(visible);
  }, [isMobileScreen]);
};

export const WorkspaceShell = (): JSX.Element => {
  const navigate = useNavigate();
  const { getDatasetRegistrationRoute, getWorkspaceRoute } = useAbsoluteRoutes();

  useUserflowJSExecutor((evt: UserflowEvent) => {
    // Execute userflow events
    // Strings are defined inside userflow.com
    // Checklist - (Getting started with MarkovML)
    if (evt.name === 'OPEN_DATASET_REGISTRATION') {
      navigate(getDatasetRegistrationRoute());
      return;
    }

    if (evt.name === 'INVITE_FRIEND_MODAL') {
      openWorkspaceInviteModal();
      return;
    }

    // Initially defined inside (Monthly Product Updates)
    // in Userflow
    if (evt.name === 'WORKSPACE_PREPEND_LINK') {
      if (!evt.url) {
        logger.error(
          'Url does not exist in Webflow for WORKSPACE_PREPEND_LINK. Please fix in webflow',
        );
        notifications.show({
          variant: 'error',
          message: `Seems like there is some technical issue. We will get it fixed soon. Our engineers have been notified.`,
        });
        return;
      }
      const baseWorkspaceUrl = getWorkspaceRoute();
      navigate(`${baseWorkspaceUrl}${evt.url}`);
      return;
    }
  });

  return <Outlet />;
};
