import { Box, SimpleGrid } from '@/shared/design-system/v2';
import { FeatureCard, FeatureCardProps } from './FeatureCard';

export interface FeatureSectionProps {
  features: FeatureCardProps[];
}

export const FeatureSection = ({ features }: FeatureSectionProps) => (
  <Box>
    <SimpleGrid cols={3} py="xl" spacing={40}>
      {features.map((feature, index) => (
        <FeatureCard key={index} {...feature} />
      ))}
    </SimpleGrid>
  </Box>
);
