import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import noop from 'lodash/noop';
import { workflowApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  BaseAPIFilter,
  CloneWorkflowRequest,
  DeleteWorkflowsRequest,
  ListWorkflowsResponse,
  Operator,
  SortOrder,
  UpdateWorkflowArtifactStateRequest,
  Workflow,
  WorkflowRunStatus,
} from '../../../generated/api';

export const workflowsQueryKeys = {
  all: ['workflows'] as const,
  list: (
    workspaceId: string,
    filters?: object[],
    start?: number,
    end?: number,
    sortKey?: string,
    sortOrder?: SortOrder,
    searchQuery?: string,
  ) =>
    [
      ...workflowsQueryKeys.all,
      'list',
      workspaceId,
      start,
      end,
      sortKey,
      sortOrder,
      filters,
      searchQuery,
    ].filter(x => Boolean(x)),
  listForRuns: (
    workspaceId: string,
    workflowId: string,
    start?: number,
    filters?: BaseAPIFilter[],
  ) => [...workflowsQueryKeys.all, 'list-runs', workspaceId, workflowId, start, filters] as const,
  listRunningRuns: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'list-running-runs', workspaceId, workflowId] as const,
  listAllRuns: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'list-runs', workspaceId, workflowId] as const,
  detail: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'detail', workspaceId, workflowId] as const,
  dag: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'get-workflow-dag', workspaceId, workflowId] as const,
  runsDetail: (workspaceId: string, workflowId: string, workflowRunId: string) => [
    ...workflowsQueryKeys.all,
    'runs-detail',
    workspaceId,
    workflowId,
    workflowRunId,
  ],
  runsCreditDetail: (workspaceId: string, workflowId: string, workflowRunId: string) => [
    ...workflowsQueryKeys.all,
    'runs-credit-detail',
    workspaceId,
    workflowId,
    workflowRunId,
  ],
  emailTrigger: (workspaceId: string, workflowId: string) =>
    [...workflowsQueryKeys.all, 'email-trigger', workspaceId, workflowId] as const,
};

export const useGetWorkflowsQuery = ({
  filters = [],
  sortKey = 'createDate',
  sortOrder = SortOrder.Desc,
  start = 0,
  limit = 20,
  searchQuery = '',
}: {
  filters?: BaseAPIFilter[];
  sortKey?: string;
  sortOrder?: SortOrder;
  start?: number;
  limit?: number;
  searchQuery?: string;
}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.list(workspaceId, filters, start, limit, sortKey, sortOrder, searchQuery),
    // Implement Filters
    () =>
      workflowApi.listWorkflowsV1(
        workspaceId,
        {
          start,
          limit,
          sortKey,
          sortOrder,
          filters,
        },
        searchQuery,
      ),
    {
      select: res => {
        const response = res.data.response;
        const transformedData = response.map(item => ({
          ...item,
          name: item.name.length === 0 ? 'Untitled' : item.name,
        }));
        return {
          ...res.data,
          response: transformedData,
        };
      },
      staleTime: 30 * 1000,
      keepPreviousData: true,
    },
  );
};

export const useWorkflowStateUpdateMutation = (onWorkflowUpdateSuccess: () => void = noop) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UpdateWorkflowArtifactStateRequest) =>
      workflowApi.workflowStateChangeV1(workspaceId, req),
    {
      onMutate: (variables: UpdateWorkflowArtifactStateRequest) => {
        const workflowsQueriesData = queryClient.getQueriesData<
          AxiosResponse<ListWorkflowsResponse>
        >(workflowsQueryKeys.list(workspaceId));

        if (workflowsQueriesData) {
          workflowsQueriesData.forEach(([key, queryData]) => {
            if (queryData?.data.response) {
              queryClient.setQueryData(key, {
                ...queryData,
                data: {
                  ...queryData.data,
                  response: queryData.data.response.map(workflow => {
                    if (
                      workflow.workflowId &&
                      variables.workflowIds.includes(workflow.workflowId)
                    ) {
                      return {
                        ...workflow,
                        artifactState: {
                          ...workflow.artifactState,
                          state: variables.artifactState,
                        },
                      };
                    }

                    return workflow;
                  }),
                },
              });
            }
          });
        }
      },
      onSuccess: (data, variables) => {
        onWorkflowUpdateSuccess();
        queryClient.invalidateQueries({ queryKey: workflowsQueryKeys.list(workspaceId) });
        variables.workflowIds.forEach(workflowId => {
          queryClient.invalidateQueries({
            queryKey: workflowsQueryKeys.detail(workspaceId, workflowId),
          });
        });
      },
    },
  );
};

export const useWorkflowDeleteMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: DeleteWorkflowsRequest) => workflowApi.deleteWorkflowsV1(workspaceId, req),
    {
      onMutate: (variables: DeleteWorkflowsRequest) => {
        // Get all the queries for workflow list. If filters are applied, multiple will be cached
        const listQueries = queryClient.getQueriesData<AxiosResponse<ListWorkflowsResponse>>({
          queryKey: workflowsQueryKeys.list(workspaceId),
        });

        if (listQueries) {
          listQueries.forEach(([key, queryData]) => {
            if (queryData?.data?.response) {
              queryClient.setQueryData(key, {
                ...queryData,
                data: {
                  ...queryData.data,
                  response: queryData.data.response.filter(
                    (workflow: Workflow) =>
                      workflow.workflowId && !variables.workflowIds.includes(workflow.workflowId),
                  ),
                },
              });
            }
          });
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.list(workspaceId),
        });
      },
    },
  );
};

export const useGetWorkflowRunsQuery = ({
  workflowId = '',
  filters = [],
  sortKey = 'startDate',
  sortOrder = SortOrder.Desc,
  start = 0,
  limit = 20,
}: {
  workflowId: string;
  filters?: BaseAPIFilter[];
  sortKey?: string;
  sortOrder?: SortOrder;
  start?: number;
  limit?: number;
}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.listForRuns(workspaceId, workflowId, start, filters),
    () =>
      workflowApi.listWorkflowRunsV1(workspaceId, workflowId, {
        start,
        limit,
        sortKey,
        sortOrder,
        filters,
      }),
    {
      // Narrow type of nodeWiseOutput from `object`
      select: res => ({
        ...res.data,
        response: res.data.response.map(run => ({
          ...run,
          nodeWiseOutput: run.nodeWiseOutput,
        })),
      }),
      keepPreviousData: true,
    },
  );
};

export const useGetWorkflowRunningRunsQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  const runningStatusFilter: BaseAPIFilter = {
    field: 'status',
    operator: Operator.Eq,
    value: WorkflowRunStatus.Running,
  };

  return useQuery(
    workflowsQueryKeys.listRunningRuns(workspaceId, workflowId),
    () =>
      workflowApi.listWorkflowRunsV1(workspaceId, workflowId, {
        start: 0,
        limit: 10,
        sortKey: 'createDate',
        sortOrder: SortOrder.Desc,
        filters: [runningStatusFilter],
      }),
    {
      staleTime: 0,
      select: res => res.data,
    },
  );
};

export const useWorkflowCloneMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (workflowId: CloneWorkflowRequest) => workflowApi.cloneWorkflowsV1(workspaceId, workflowId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: workflowsQueryKeys.all });
      },
    },
  );
};

export const useWorkflowScheduleDeleteMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => workflowApi.deleteScheduledWorkflowV1(workspaceId, workflowId), {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: workflowsQueryKeys.all,
      }),
  });
};

export const useTriggerEmailWorkflowRunQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.emailTrigger(workspaceId, workflowId),
    () => workflowApi.getTriggerEmailV1(workspaceId, workflowId),
    {
      select: data => data.data,
    },
  );
};
