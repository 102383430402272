import chunk from 'lodash/chunk';
import constant from 'lodash/constant';
import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/design-system';
import {
  Box,
  Checkbox,
  Horizontal,
  Text,
  Tooltip,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { ColumnAutocomplete } from './ColumnAutocomplete';
import { ColumnPagination } from './ColumnPagination';
import { useStyles } from './PaginatedColumnsTable.style';

export const useColumnColors = () => {
  const theme = useMarkovTheme();
  const colors = {
    feature: theme.colors.blue[0],
    target: theme.colors.yellow[0],
    default: theme.colors.white[0],
  };
  return colors;
};

interface PaginatedColumnsTableProps {
  pageSize?: number;
  columns: string[];
  rows: string[][];
  height?: number | string;
  hideCheckbox?: boolean;
  showSearch?: boolean;
  onColumnCheckedChange: (columnId: string, value: boolean) => void;
  getIsColumnDisabled?: (columnId: string) => boolean;
  getIsColumnChecked: (columnId: string) => boolean;
  getColumnColor?: (columnId: string) => string;
  getIsPageHighlighted: (pageStart: number, pageEnd: number) => boolean;
}

export const PaginatedColumnsTable = ({
  pageSize = 7,
  columns,
  rows,
  height,
  hideCheckbox = false,
  showSearch = true,
  onColumnCheckedChange,
  getIsColumnDisabled = constant(false),
  getIsColumnChecked,
  getColumnColor = constant('white'),
  getIsPageHighlighted,
}: PaginatedColumnsTableProps): JSX.Element => {
  const { classes } = useStyles({ height });

  // Page state is 0-indexed (for parsing data)
  const [currentPage, setCurrentPage] = useState(0);

  const chunkedColumns = chunk(columns, pageSize);
  const currentColumns = chunkedColumns[currentPage] ?? [];

  const currentData = rows.map(row => {
    const start = pageSize * currentPage;
    return row.slice(start, start + pageSize);
  });

  const handleAutocompleteItemClick = (column: string) => {
    // set column as checked
    if (!getIsColumnChecked(column)) {
      onColumnCheckedChange(column, true);
    }
    // set the column's page as the current page
    const pageIndex = chunkedColumns.findIndex(chunk => chunk.includes(column));
    setCurrentPage(pageIndex);
  };

  const numColumns = columns.length;
  const showHeader = numColumns > pageSize;

  return (
    <Vertical spacing="lg">
      {showHeader && (
        <Horizontal position={showSearch ? 'apart' : 'right'}>
          {showSearch && (
            <ColumnAutocomplete columns={columns} onSelectColumn={handleAutocompleteItemClick} />
          )}
          <ColumnPagination
            numColumns={numColumns}
            pageSize={pageSize}
            currentPage={currentPage}
            onSetPage={setCurrentPage}
            getIsPageHighlighted={getIsPageHighlighted}
          />
        </Horizontal>
      )}
      <Box className={classes.wrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow key="table-header-row">
              {currentColumns.map((column, i) => (
                <TableHeader
                  key={`${column}-${i}`}
                  className={classes.header}
                  style={{
                    backgroundColor: getColumnColor(column),
                  }}
                >
                  <Horizontal noWrap>
                    {!hideCheckbox && (
                      <Checkbox
                        checked={getIsColumnChecked(column)}
                        disabled={getIsColumnDisabled(column)}
                        onChange={e => onColumnCheckedChange(column, e.target.checked)}
                      />
                    )}
                    <Tooltip label={column} withArrow withinPortal>
                      <Text variant="subTitle03" lineClamp={1}>
                        {column}
                      </Text>
                    </Tooltip>
                  </Horizontal>
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((row, i) => (
              <TableRow key={i}>
                {row.map((cellValue, j) => (
                  <TableCell
                    key={j}
                    className={classes.cell}
                    style={{
                      backgroundColor: getColumnColor(currentColumns[j]),
                    }}
                  >
                    {cellValue}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Vertical>
  );
};
