import { IconMoonFilled, IconSunFilled } from '@tabler/icons-react';
import { ActionIcon, useMarkovColorScheme } from '@/shared/design-system/v2';
import { AppHeaderElement } from './AppHeaderElement';

export const ColorSchemeToggler = ({ ...rest }): JSX.Element => {
  const { colorScheme, toggleColorScheme } = useMarkovColorScheme();
  const isDarkTheme = colorScheme === 'dark';

  const tooltipText = `Toggle to ${isDarkTheme ? 'light' : 'dark'} theme`;

  return (
    <AppHeaderElement label={tooltipText} {...rest}>
      <ActionIcon
        variant="subtle"
        color={isDarkTheme ? 'yellow' : 'blue'}
        onClick={() => toggleColorScheme()}
        title="Toggle color scheme (Experimental)"
      >
        {isDarkTheme ? <IconSunFilled size={18} /> : <IconMoonFilled size={18} />}
      </ActionIcon>
    </AppHeaderElement>
  );
};
