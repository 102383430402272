import isUndefined from 'lodash/isUndefined';
import range from 'lodash/range';
import { darks } from '@/shared/design-system/v2/theme/colors/colorList';
import { formatNumberLocaleString } from '@/shared/lib/ui';
import { ScatterData } from '../../../charts/providers/deck-gl/layers.util';
import { getColors } from '../../../charts/utils/colors';
import { DataCategory } from '../../../generated/api';
import { ClusterCounts } from '../../../queries/datasets/clustering/clusterSelector';
import { transformSeriesName } from '../util';

export enum PanelState {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  OPEN_EXPANDED = 'OPEN_EXPANDED',
}

export enum PanelMode {
  ACTIONS = 'ACTIONS',
  RAW_DATA = 'RAW_DATA',
  SIMILARITY_DATA = 'SIMILARITY_DATA',
  SUBSETS_LIST = 'SUBSETS_LIST',
  SUBSET_DETAIL = 'SUBSET_DETAIL',
  CHAT_WITH_SUBSET_OF_DATA = 'CHAT_WITH_SUBSET_OF_DATA',
}

// Lowercase strings since these are used as query params
export enum PanelTab {
  EXPLORE = 'explore',
  VIEW_SUBSETS = 'view-subsets',
}

const PANEL_ACTIONS_WIDTH = 324;
const SUBSETS_LIST_WIDTH = 324;
const RAW_DATA_TABLE_WIDTH = 864;
const SIMILARITY_DATA_TABLE_WIDTH = 713;
const SUBSET_DETAIL_WIDTH = 876;
const CHAT_WITH_SUBSET_OF_DATA_WIDTH = 864;

export const panelWidthByMode = {
  [PanelMode.ACTIONS]: {
    fullWidth: PANEL_ACTIONS_WIDTH,
  },
  [PanelMode.RAW_DATA]: {
    fullWidth: RAW_DATA_TABLE_WIDTH,
  },
  [PanelMode.SIMILARITY_DATA]: {
    fullWidth: SIMILARITY_DATA_TABLE_WIDTH,
  },
  [PanelMode.SUBSETS_LIST]: {
    fullWidth: SUBSETS_LIST_WIDTH,
  },
  [PanelMode.SUBSET_DETAIL]: {
    fullWidth: SUBSET_DETAIL_WIDTH,
  },
  [PanelMode.CHAT_WITH_SUBSET_OF_DATA]: {
    fullWidth: CHAT_WITH_SUBSET_OF_DATA_WIDTH,
  },
};

export const PANEL_MINIMIZED_WIDTH = 36;

const NUM_COLORS = 20;
const chartColors = getColors(NUM_COLORS);
const COLOR_PAIRS = range(0, NUM_COLORS, 2).map(n => [chartColors[n], chartColors[n + 1]]);

export const isEmbeddingsSupported = (dataCategory: DataCategory) =>
  [
    DataCategory.Text,
    DataCategory.MixedOrCategorical,
    DataCategory.MixedCatUnlabeledTarget,
    DataCategory.Numerical,
  ].includes(dataCategory);

export enum PointState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DIMMED = 'DIMMED',
}

export const getInactiveLabelName = (name: string) => `${name}__inactive`;

export const getLabelColor = (label: string, labels: ClusterCounts[], state: PointState) => {
  if (state === PointState.DIMMED) {
    return darks.dark0;
  }

  const labelIdx = labels.findIndex(l => l.name === label);
  const labelColors = COLOR_PAIRS[labelIdx % COLOR_PAIRS.length];
  const color = labelColors?.[state === PointState.ACTIVE ? 0 : 1];

  if (!color) {
    throw new Error(`Label color was not found at label index ${labelIdx}`);
  }

  return color;
};

export const getFilteredData = (
  data: ScatterData[],
  {
    selectedPoints,
    selectedClasses,
    selectedSegments,
    selectedClusters,
    selectedOutliers,
    showMislabelledData,
  }: {
    selectedPoints?: ScatterData[];
    selectedClasses?: string[];
    selectedSegments?: string[];
    selectedClusters?: string[];
    selectedOutliers?: string[];
    showMislabelledData?: boolean;
  },
) => {
  const hasSelectedPointsFilter = selectedPoints && selectedPoints.length > 0;
  const hasSelectedClassesFilter = selectedClasses && selectedClasses.length > 0;
  const hasSelectedClustersFilter = selectedClusters && selectedClusters.length > 0;
  const hasSelectedOutliersFilter = selectedOutliers && selectedOutliers.length > 0;
  const hasSelectedSegmentsFilter = selectedSegments && selectedSegments.length > 0;

  const hasNonPointFilter =
    hasSelectedClassesFilter ||
    hasSelectedClustersFilter ||
    hasSelectedOutliersFilter ||
    hasSelectedSegmentsFilter ||
    !isUndefined(showMislabelledData);

  if (!hasNonPointFilter) {
    return hasSelectedPointsFilter ? selectedPoints : data;
  }

  const filterPoint = (point: ScatterData) => {
    const isPointInSelectedClasses = hasSelectedClassesFilter
      ? selectedClasses.includes(point.metadata.label)
      : true;
    const isPointInSelectedClusters = hasSelectedClustersFilter
      ? selectedClusters.includes(point.metadata.cluster)
      : true;
    const isPointInSelectedOutliers = hasSelectedOutliersFilter
      ? selectedOutliers.includes(point.metadata.outlier)
      : true;
    const isPointInSelectedSegments = hasSelectedSegmentsFilter
      ? selectedSegments.includes(point.metadata.segment)
      : true;
    const isPointInMislabelled =
      showMislabelledData === undefined
        ? true
        : showMislabelledData === point.metadata.isMislabelled;

    return (
      isPointInSelectedClasses &&
      isPointInSelectedClusters &&
      isPointInSelectedOutliers &&
      isPointInSelectedSegments &&
      isPointInMislabelled
    );
  };

  if (!hasSelectedPointsFilter) {
    return data.filter(filterPoint);
  }

  return selectedPoints.filter(filterPoint);
};

// While selecting multiple regions of points, a single point may be included in more than one region.
// This function gets unique points, keeping points in the first region they appeared in.
export const getUniqueLassoSelectionPoints = (lassoSelection: ScatterData[][]): ScatterData[][] => {
  const seenPointIds = new Set();
  const uniqueLassoSelection = [];

  for (const region of lassoSelection) {
    const uniqueRegion = [];
    for (const point of region) {
      const isNewPoint = !seenPointIds.has(point.metadata.recordId);
      if (isNewPoint) {
        seenPointIds.add(point.metadata.recordId);
        uniqueRegion.push(point);
      }
    }
    uniqueLassoSelection.push(uniqueRegion);
  }

  return uniqueLassoSelection;
};

export const getFilterItemLabel = (name: string, count: number): string => {
  let label = transformSeriesName(name);
  if (count > 0) {
    const displayedCount = formatNumberLocaleString(count);
    label += ` (${displayedCount})`;
  }
  return label;
};
