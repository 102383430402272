import { Alert, Skeleton, Vertical } from '@/shared/design-system/v2';
import { SnippetState, UserDetails } from '../../../generated/api';
import { useGetPublicSnippetQuery } from '../../../queries/snippets/snippets';
import { SnippetContextProvider } from '../detail/context/SnippetContext';
import { PublicSnippetEditor } from './PublicSnippetEditor';

// TODO: Replace this with type from BE
export interface PublicSnippetModel {
  content: string;
  title?: string;
  mentions?: UserDetails[];
}

interface PublicSnippetProps {
  snippetId: string;
}

export const PublicSnippetContainer = ({ snippetId }: PublicSnippetProps) => {
  const { data: snippet, isLoading } = useGetPublicSnippetQuery(snippetId);

  if (isLoading) {
    return (
      <Vertical mt={48} ml={224}>
        <Skeleton width={300} height={64} />
        <Skeleton mt={32} width={700} height={14} />
        <Skeleton width={800} height={14} />
        <Skeleton width={250} height={250} />
      </Vertical>
    );
  }

  if (!snippet) {
    return (
      <Alert color="warning">
        Snippet loading failed. Please ensure that you have the necessary access permissions for
        this snippet. <br />
        You can try refreshing the page, and if the issue persists, please reach out to our support
        team for assistance.
      </Alert>
    );
  }

  const snippetData = {
    ...snippet,
    snippetId,
    state: SnippetState.Publish,
    createDate: '',
    ownerId: '',
    versionId: '',
  };

  return (
    <SnippetContextProvider snippet={snippetData} activeUserIds={[]} isPublicSnippet>
      <PublicSnippetEditor snippet={snippet} />
    </SnippetContextProvider>
  );
};
