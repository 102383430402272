import { ICellRendererParams } from '@ag-grid-community/core';
import { Vertical } from '@/shared/design-system/v2';
import { isAirbyteStorageType } from '../../connectors/util';
import {
  DEFAULT_ROW_HEIGHT,
  DEFAULT_TABLE_MENU_WIDTH,
} from '../../datasets-table/v2/cell-renderers/util';
import { TableActionMenu } from '../../table-action-menu/TableActionMenu';
import { AirbyteActionsRenderer } from './AirbyteActionsRenderer';

interface ActionsRendererProps extends ICellRendererParams {
  data: any;
}

export const ActionsRenderer = (props: ActionsRendererProps) => {
  const { data } = props;

  if (isAirbyteStorageType(data?.connectorType)) {
    return <AirbyteActionsRenderer {...props} />;
  }

  return (
    // Adding vertical to have same look and feel as the enabled state
    <Vertical justify="center" h={DEFAULT_ROW_HEIGHT}>
      <TableActionMenu actions={[]} width={DEFAULT_TABLE_MENU_WIDTH} disabled={true} />
    </Vertical>
  );
};
