import { IconCheck } from '@tabler/icons-react';
import { PropsWithChildren } from 'react';
import {
  Badge,
  Button,
  Card,
  Horizontal,
  Image,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { PaymentSubscriptionType } from '../../../generated/api';
import { getPaymentSubscriptionProductType, subscriptionPlanDetails } from '../util';
import { BillingFrequency, NonEnterpriseSubscriptionType, getMonthlyCostUsd } from './constants';

const FeatureItem = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const theme = useMarkovTheme();

  return (
    <Horizontal noWrap spacing="sm">
      <IconCheck color={theme.colors.blue[6]} />
      <Text variant="bodyShort02" color="gray.7">
        {children}
      </Text>
    </Horizontal>
  );
};

interface PricingPlanDetailsProps {
  planType: PaymentSubscriptionType;
  frequency: BillingFrequency;
  onSelect: () => void;
  isActive?: boolean;
}

export const PricingPlanDetails = ({
  planType,
  frequency,
  onSelect,
  isActive = false,
}: PricingPlanDetailsProps) => {
  const { workspaceId } = useAppMetadata();
  const subscriptionProductType = getPaymentSubscriptionProductType(planType);
  const { planName, iconUrl, description, features } =
    subscriptionPlanDetails[subscriptionProductType];

  const isEnterprise = planType === PaymentSubscriptionType.EnterprisePlan;
  const buttonLabel = isEnterprise ? 'Contact us' : 'Choose plan';
  const priceDetail = isEnterprise
    ? 'Custom Quote'
    : `$${getMonthlyCostUsd(
        subscriptionProductType as NonEnterpriseSubscriptionType,
        frequency,
      )}/month`;

  const handleButtonClick = () => {
    onSelect();
  };

  return (
    <Card withBorder p="xxl" radius="lg">
      <Vertical spacing="xxl">
        <Vertical>
          <Horizontal noWrap>
            <Image src={iconUrl} width={24} />
            {/* padding-bottom needed for text alignment */}
            <Text variant="heading02" pb="xs">
              {planName}
            </Text>
            {isActive && (
              <Badge variant="outline" color="green">
                Active
              </Badge>
            )}
          </Horizontal>
          <Text variant="bodyShort02" color="gray.7" mih={72}>
            {description}
          </Text>
          <Text variant="heading04">{priceDetail}</Text>
        </Vertical>
        <Button
          variant="primary"
          size="md"
          color="gray.9"
          onClick={handleButtonClick}
          disabled={isActive}
        >
          {buttonLabel}
        </Button>
        <Vertical spacing="lg">
          <Text variant="subTitle03" color="gray.6">
            {planName} plan includes:
          </Text>
          <Vertical>
            {features.map((feature, i) => (
              // index as key since order of features is stable
              <FeatureItem key={i}>{feature}</FeatureItem>
            ))}
          </Vertical>
        </Vertical>
      </Vertical>
    </Card>
  );
};
