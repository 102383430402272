import { Box, Divider, Grid, Text } from '@/shared/design-system/v2';
import { capitalize } from '@/shared/lib/ui';
import { StorageType } from '../../../generated/api';

interface ConfirmCredentialProps {
  accessKey: string;
  accessSecret: string;
  credentialName: string;
  credentialDescription: string;
  datastore: StorageType;
}

interface LabelTextProps {
  label: string;
  text: string;
}

const LabelText = ({ label, text }: LabelTextProps): JSX.Element => (
  <Grid pb={24}>
    <Grid.Col xs={6}>
      <Text variant="subTitle03">{label}:</Text>
    </Grid.Col>
    <Grid.Col xs={14}>
      <Text variant="bodyShort02" color="dark.6" lineClamp={1} title={text}>
        {text || 'None'}
      </Text>
    </Grid.Col>
  </Grid>
);

export const ConfirmCredentials = ({
  accessKey,
  accessSecret,
  credentialName,
  credentialDescription,
  datastore,
}: ConfirmCredentialProps): JSX.Element => (
  <Box>
    <LabelText label="Credential name" text={credentialName} />
    <LabelText label="Datastore" text={capitalize(datastore)} />
    <LabelText label="Access key" text={accessKey} />
    <LabelText label="Access secret" text={accessSecret} />
    <LabelText label="Description" text={credentialDescription} />

    <Divider color="#EAEDF2" style={{ marginBlock: '32px 16px' }} />
  </Box>
);
