import { PropsWithChildren, useRef } from 'react';
import { Box, Button, Center, Loader } from '@/shared/design-system/v2';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

interface InfiniteListWrapperProps {
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
}

export const InfiniteListWrapper = ({
  children,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: PropsWithChildren<InfiniteListWrapperProps>): JSX.Element => {
  const listEndRef = useRef(null);

  useIntersectionObserver({
    enabled: hasNextPage,
    target: listEndRef,
    onIntersect: fetchNextPage,
  });

  return (
    <>
      {children}
      {hasNextPage && (
        <Box ref={listEndRef} sx={{ height: '50px', position: 'relative' }}>
          {isFetchingNextPage ? (
            <Loader />
          ) : (
            <Center>
              <Button onClick={fetchNextPage}>Load More</Button>
            </Center>
          )}
        </Box>
      )}
    </>
  );
};
