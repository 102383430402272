import { CellProps } from 'react-table';
import { DataTable } from '@/shared/design-system';
import { Box, Horizontal, Text, Vertical } from '@/shared/design-system/v2';
import { getDateFromTimeStamp } from '@/shared/lib/time-format';
import { ConnectorModel, StorageType } from '../../../generated/api';
import { CopyToClipboardTooltip } from '../../common/CopyToClipboardTooltip';
import { getConnectorTypeDisplayName, getConnectorTypeIcon } from '../../connectors/util';

const COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 180,
    disableFilters: true,
    Cell: ({ value: name, row }: CellProps<ConnectorModel, string>) => {
      const connectorId = row.original.connectorId;
      return (
        <Vertical spacing={0} h="100%" justify="center">
          <Text variant="bodyShort03" color="gray.8">
            {name}
          </Text>
          <Horizontal spacing="sm">
            <Text variant="small01" color="gray.6">
              {connectorId}
            </Text>
            <CopyToClipboardTooltip valueToCopy={connectorId} />
          </Horizontal>
        </Vertical>
      );
    },
  },
  {
    Header: 'Created on',
    accessor: 'createDate',
    width: 100,
    disableFilters: true,
    Cell: ({ value }: CellProps<ConnectorModel, string>) => {
      const date = getDateFromTimeStamp(value);
      return <Text variant="bodyShort03">{date}</Text>;
    },
  },
  {
    Header: 'Type',
    accessor: 'connectorType',
    width: 100,
    disableFilters: true,
    Cell: ({ value: connectorType }: CellProps<ConnectorModel, StorageType>) => (
      <Horizontal spacing="xs" h="100%">
        {getConnectorTypeIcon(connectorType)}
        <Text variant="bodyShort03">{getConnectorTypeDisplayName(connectorType)}</Text>
      </Horizontal>
    ),
  },
];

interface ConnectorsListProps {
  connectors: ConnectorModel[];
}

export const ConnectorsList = ({ connectors }: ConnectorsListProps) => {
  const data = connectors.map(item => ({ ...item }));

  return (
    <Box
      h="100%"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <DataTable
        dataTestId="table-settings_connectors_list"
        data={data}
        columns={COLUMNS}
        height="100%"
      />
    </Box>
  );
};
