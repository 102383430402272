import { Navigate } from 'react-router-dom';
import { Login } from '@/main/components/app-in-iframe/Login';
import { useAuth } from '@/main/contexts/auth-provider/Auth';
import { useQueryParams } from '@/main/hooks/useQueryParams';
import { FullPageLoader } from '@/shared/design-system';

export const IframeHomePage = () => {
  const { loginWithPopup, isAuthenticated, isLoading } = useAuth();

  const queryParams = useQueryParams();
  const redirectRoute = queryParams.get('redirectTo') ?? '/';

  if (isLoading) return <FullPageLoader text="Authenticating" />;

  if (isAuthenticated) return <Navigate to={redirectRoute} replace />;

  return <Login onLogin={() => loginWithPopup()} />;
};
