import first from 'lodash/first';
import { useEffect, useMemo, useState } from 'react';
import { Center, Horizontal } from '@/shared/design-system/v2';
import { useExperimentsListInfiniteQuery } from '../../../../../queries/experiments/experiments';
import { PageErrorScreen } from '../../../../common/ErrorState';
import { EmptyRecording } from '../../../../recording/empty-recording/EmptyRecording';
import { ChartsTypesSideBar } from '../analysers/ChartsTypesSideBar';
import { ExperimentsCharts } from './ExperimentsCharts';

export const ExperimentsChartsContainer = () => {
  const { isLoading, isError, isFetching, data, fetchNextPage, hasNextPage } =
    useExperimentsListInfiniteQuery({ tagIds: [] });

  const [selectedExperiment, setSelectedExperiment] = useState('');

  const experiments = useMemo(() => data?.pages.flatMap(page => page.experiments) || [], [data]);

  const chartTypes = useMemo(
    () => ({
      Experiments: experiments.map(exp => ({ name: exp.name, id: exp.experimentId })),
    }),
    [experiments],
  );

  useEffect(() => {
    setSelectedExperiment(prevSelected => prevSelected || (first(experiments)?.experimentId ?? ''));
  }, [experiments]);

  if (isError) {
    return (
      <PageErrorScreen
        title="Unable to load experiments"
        subtitle="There was an error retrieving experiments for the current workspace. Try refreshing."
        iconName="experiment"
      />
    );
  }

  if (!isLoading && !experiments?.length) {
    return (
      <Center>
        <EmptyRecording />
      </Center>
    );
  }

  return (
    <Horizontal position="apart">
      <ChartsTypesSideBar
        isLoading={isLoading}
        charts={chartTypes}
        selectedChartId={selectedExperiment}
        onChartClick={setSelectedExperiment}
        isFetchingMore={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        width="40%"
      />
      {selectedExperiment && <ExperimentsCharts experimentId={selectedExperiment} />}
    </Horizontal>
  );
};
