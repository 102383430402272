import { Box, Button, Select, Vertical } from '@/shared/design-system/v2';
import { interleaveComponent } from '@/shared/lib/util-react';
import { Condition, ExpressionType, LogicalOperator } from '../../../../generated/api';
import { ConditionBlock } from './ConditionBlock';
import { LOGICAL_OPERATORS } from './constants';

const OPERATOR_SELECT_WIDTH = 90;

interface CompoundConditionProps {
  editable: boolean;
  condition: Condition;
  conditionIndex: number;
  columnNames: string[];
  getChangeColumnHandler: (
    conditionIndex: number,
    expressionIndex: number,
  ) => (column: string) => void;
  getChangeExpressionTypeHandler: (
    conditionIndex: number,
    expressionIndex: number,
  ) => (expressionType: ExpressionType) => void;
  getChangeValueHandler: (
    conditionIndex: number,
    expressionIndex: number,
  ) => (value: string) => void;
  getChangeConditionOperator: (conditionIndex: number) => (operator: LogicalOperator) => void;
  getDeleteExpressionHandler: (conditionIndex: number, expressionIndex: number) => () => void;
  getAddExpression: (conditionIndex: number) => () => void;
}

export const CompoundCondition = ({
  editable,
  condition,
  conditionIndex,
  columnNames,
  getChangeColumnHandler,
  getChangeExpressionTypeHandler,
  getChangeValueHandler,
  getDeleteExpressionHandler,
  getAddExpression,
  getChangeConditionOperator,
}: CompoundConditionProps): JSX.Element => (
  <Vertical spacing="xl" bg="gray.0" p="lg">
    {interleaveComponent(
      condition.expressions.map((expression, j) => (
        <Box key={`condition-${conditionIndex}-exp-${j}`} pos="relative">
          <ConditionBlock
            editable={editable}
            columns={columnNames}
            column={expression.column}
            onChangeColumn={getChangeColumnHandler(conditionIndex, j)}
            expressionType={expression.type}
            onChangeExpressionType={getChangeExpressionTypeHandler(conditionIndex, j)}
            value={expression.value?.toString() ?? ''}
            onChangeValue={getChangeValueHandler(conditionIndex, j)}
            onDelete={
              condition.expressions.length === 1
                ? undefined
                : getDeleteExpressionHandler(conditionIndex, j)
            }
          />
          {j === condition.expressions.length - 1 && (
            <Box mt="lg">
              <Button
                variant="outline"
                onClick={getAddExpression(conditionIndex)}
                disabled={!editable}
              >
                +Add condition
              </Button>
            </Box>
          )}
        </Box>
      )),
      () => (
        <Select
          disabled={!editable}
          ariaLabel="Logical operator"
          hideLabel
          w={OPERATOR_SELECT_WIDTH}
          options={LOGICAL_OPERATORS}
          value={condition.operator}
          onChange={getChangeConditionOperator(conditionIndex)}
        />
      ),
    )}
  </Vertical>
);
