import {
  ArrayLayoutProps,
  RankedTester,
  composePaths,
  createDefaultValue,
  findUISchema,
  isObjectArrayControl,
  rankWith,
} from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import { IconCirclePlus, IconTrash } from '@tabler/icons-react';
import noop from 'lodash/noop';
import range from 'lodash/range';
import { useEffect, useMemo, useState } from 'react';
import { ulid } from 'ulid';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Flex,
  Horizontal,
  Text,
  Vertical,
} from '@/shared/design-system/v2';
import { getControlWithoutLabel } from '../util';

interface EmptyListProps {
  errors?: string;
}

const EmptyList = ({ errors }: EmptyListProps) => {
  if (errors) {
    return (
      <Text color="red" variant="small03">
        {errors}
      </Text>
    );
  }

  return <Text variant="small03">Add new item to input</Text>;
};

export const ListObjectComponent = ({
  label,
  description,
  required,

  enabled,
  visible,

  uischema,
  uischemas = [],
  schema,
  rootSchema,
  renderers,
  cells,

  errors,
  data,
  path,

  addItem,
  removeItems = (r: string, n: number[]) => noop,
  moveUp,
  moveDown,

  config,
}: ArrayLayoutProps) => {
  const [keyPrefix, setKeyPrefix] = useState(ulid());

  // Any time array length changes, reset prefix for array item keys
  useEffect(() => {
    setKeyPrefix(ulid());
  }, [data]);

  const foundUISchema = useMemo(
    () =>
      findUISchema(
        uischemas,
        schema,
        uischema.scope,
        path,
        'HorizontalLayout',
        uischema,
        rootSchema,
      ),
    [uischemas, schema, uischema.scope, path, uischema, rootSchema],
  );

  if (!visible) {
    return null;
  }

  const handleAddItem = () => {
    addItem(path, createDefaultValue(schema, rootSchema))();
  };

  const children = range(data);

  const isViewOnlyForm = config.viewOnly;
  const isEditable = enabled && !isViewOnlyForm!;

  return (
    <Vertical>
      <Horizontal position="apart" noWrap>
        <Box>
          <Flex>
            <Text variant="subTitle03">{label}</Text>
            {required && <Text color="red">&nbsp;*</Text>}
          </Flex>
          <Text span variant="small02" color="gray.7">
            {description}
          </Text>
        </Box>
      </Horizontal>
      {children.map((d, idx) => {
        const childPath = composePaths(path, `${idx}`);
        const applicableUISchema = foundUISchema;
        const uischemaWithoutLabel = getControlWithoutLabel(uischema.scope);

        return (
          <Vertical key={`${keyPrefix}-${childPath}`} spacing="sm" bg="gray.1" px="lg" py="sm">
            <Box sx={{ flexGrow: 1 }}>
              <JsonFormsDispatch
                renderers={renderers}
                cells={cells}
                visible={visible}
                schema={schema}
                uischema={foundUISchema}
                path={childPath}
              />
            </Box>
            <Horizontal position="right">
              <ActionIcon onClick={removeItems(path, [idx])}>
                <IconTrash />
              </ActionIcon>
            </Horizontal>
          </Vertical>
        );
      })}
      {isEditable && (
        <Center>
          <Button variant="subtle" onClick={handleAddItem} leftIcon={<IconCirclePlus size={20} />}>
            Add New
          </Button>
        </Center>
      )}
      {children.length === 0 && <EmptyList errors={config.isFormDirty ? errors : undefined} />}
    </Vertical>
  );
};

export const listObjectControlTester: RankedTester = rankWith(5, isObjectArrayControl);

export const ListObjectControl = withJsonFormsArrayLayoutProps(ListObjectComponent);
