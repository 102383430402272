import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { DataTable } from '@/shared/design-system';
import { Text } from '@/shared/design-system/v2';
import { EvaluationsDisagreementData, RecordMetaData } from '../../generated/api';
import { MetadataBox } from '../metadata/MetadataBox';

const TABLE_COLUMNS = [
  {
    Header: 'Record ID',
    accessor: 'urid',
    width: 150,
    Cell: ({ value: urid }: CellProps<RecordMetaData, string>) => (
      <Text variant="small01" color="dark.4" lineClamp={1} title={urid}>
        {urid}
      </Text>
    ),
  },
  {
    Header: 'Ground Truth',
    accessor: 'groundTruth',
    width: 150,
  },
  {
    Header: 'Model Prediction',
    accessor: 'predictions.0.inferred',
    width: 150,
  },
  {
    Header: 'Metadata',
    accessor: 'predictions.0.metadata',
    width: 100,
    disableFilters: true,
    Cell: ({ value: metadata }: { value: RecordMetaData[] }) => <MetadataBox metadata={metadata} />,
  },
];

interface DisagreementTableProps {
  disagreements?: EvaluationsDisagreementData[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

export const DisagreementTable = ({
  disagreements = [],
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}: DisagreementTableProps): JSX.Element => {
  const columns = useMemo(() => TABLE_COLUMNS, []);

  const data = disagreements.map(dis => ({ ...dis }));

  return (
    <DataTable
      dataTestId="disagreement-table"
      data={data}
      columns={columns}
      height="440px"
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};
