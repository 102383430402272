import { useNavigate } from 'react-router-dom';
import { Horizontal, Text } from '@/shared/design-system/v2';
import { DataOperationType, DatasetOperationModel } from '../../generated/api';
import { useAbsoluteRoutes } from '../../router/hooks';
import { RelabelingOperationType } from './util';

const useRelabelingRoute = (operation: DatasetOperationModel) => {
  const { datasetId, dataOperationId: dataLabelingId } = operation;
  const { getManualRelabelingRoute, getRuleBasedRelabelingRoute, getSubsetRelabelingRoute } =
    useAbsoluteRoutes();

  const relabelingRoutesMap: Record<RelabelingOperationType, string> = {
    [DataOperationType.ManualRelabeling]: getManualRelabelingRoute(datasetId, dataLabelingId),
    [DataOperationType.RuleBasedRelabeling]: getRuleBasedRelabelingRoute(datasetId, dataLabelingId),
    [DataOperationType.SubsetRelabeling]: getSubsetRelabelingRoute(datasetId, dataLabelingId),
  };

  const route = relabelingRoutesMap[operation.type as RelabelingOperationType];
  return route;
};

interface DataLabelingActionsProps {
  operation: DatasetOperationModel;
}

export const DataLabelingActions = ({ operation }: DataLabelingActionsProps) => {
  const navigate = useNavigate();
  const { getDatasetDetailsRoute } = useAbsoluteRoutes();
  const relabelingRoute = useRelabelingRoute(operation);

  const handleViewOperation = () => {
    navigate(relabelingRoute);
  };

  const handleViewDataset = (datasetId: string) => {
    navigate(getDatasetDetailsRoute(datasetId));
  };

  const derivedDatasetId = operation.details?.derivedDatasetId;

  return (
    <Horizontal position="right">
      <Text
        variant="textLink"
        color="blue.6"
        sx={{ cursor: 'pointer' }}
        onClick={handleViewOperation}
      >
        View
      </Text>
      {derivedDatasetId && (
        <Text
          variant="textLink"
          color="blue.6"
          sx={{ cursor: 'pointer' }}
          onClick={() => handleViewDataset(derivedDatasetId)}
        >
          Go to labeled data
        </Text>
      )}
    </Horizontal>
  );
};
