import { Text, openModal } from '@/shared/design-system/v2';
import { DataLabelingEditNameModalContainer } from './DataLabelingEditNameModalContainer';

export const useDataLabelingEditNameModal = (
  datasetId: string,
  dataLabelingId: string,
  currentName: string,
) => ({
  open: () =>
    openModal({
      title: <Text variant="subTitle01">Edit data labeling operation name</Text>,
      size: 'lg',
      children: (
        <DataLabelingEditNameModalContainer
          datasetId={datasetId}
          dataLabelingId={dataLabelingId}
          currentName={currentName}
        />
      ),
    }),
});
