import { SuggestionProps } from '@tiptap/suggestion';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { UserDetail } from '../../../../../../apps/main/components/settings/UserDetail';
import { WorkspaceMember } from '../../../../../../apps/main/generated/api';
import { Box, Card, ScrollArea } from '../../../core';
import { useScrollIntoView } from '../../../hooks';

type MentionsListProps = SuggestionProps<WorkspaceMember>;

export interface MentionListComponent {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

export const MentionsList = forwardRef<MentionListComponent, MentionsListProps>(
  ({ items, command }, ref) => {
    const itemsRefs = useRef<Record<string, HTMLDivElement>>({});
    const { scrollIntoView, scrollableRef, targetRef } = useScrollIntoView<HTMLDivElement>({
      isList: true,
      offset: 30,
      duration: 0,
    });
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      const item = items[index];

      if (item) {
        command(item);
      }
    };
    const upHandler = () => {
      if (selectedIndex === 0) {
        return;
      }
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    };

    const downHandler = () => {
      if (selectedIndex === items.length - 1) {
        return;
      }
      setSelectedIndex((selectedIndex + 1) % items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        targetRef.current = itemsRefs.current[selectedIndex];

        if (event.key === 'ArrowUp') {
          scrollIntoView({ alignment: 'start' });
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          scrollIntoView({ alignment: 'end' });
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          event.stopPropagation();
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    const getHandleMouseOver = (idx: number) => () => {
      setSelectedIndex(idx);
    };

    const getHandleSelectItem = (idx: number) => () => {
      selectItem(idx);
    };

    return (
      <Card shadow="xl" withBorder p={0}>
        <ScrollArea.Autosize mah={300} bg="white.0" viewportRef={scrollableRef}>
          {items.map((item, idx) => (
            <Box
              key={item.userId}
              py="xs"
              px="sm"
              onClick={getHandleSelectItem(idx)}
              onMouseOver={getHandleMouseOver(idx)}
              onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
              bg={selectedIndex === idx ? 'blue.3' : ''}
              color={selectedIndex === idx ? 'white' : ''}
              ref={(node: HTMLDivElement) => {
                if (itemsRefs && itemsRefs.current) {
                  // eslint-disable-next-line no-param-reassign
                  itemsRefs.current[idx] = node;
                }
              }}
              sx={{ cursor: 'pointer' }}
            >
              <UserDetail
                name={item.userDetails?.name ?? ''}
                email={item.userDetails?.email ?? ''}
                avatar={item.userDetails?.avatar}
              />
            </Box>
          ))}
        </ScrollArea.Autosize>
      </Card>
    );
  },
);

MentionsList.displayName = 'MentionsList';
