import { Box, Button, FileWithPath, Flex } from '@/shared/design-system/v2';
import { getFilePreviewComponentForFile } from './util';

const UPLOAD_FOOTER_HEIGHT = '48px';

export interface FilePreviewProps {
  file: FileWithPath;
  handleBackClick: () => void;
  setFileName: (e: React.ChangeEvent<any>) => void;
  isUploading: boolean;
  handleUpload: () => void;
}

export const FilePreview = ({
  file,
  handleBackClick,
  setFileName,
  isUploading,
  handleUpload,
}: FilePreviewProps) => (
  <Box h="100%" w="100%">
    <Box h={`calc(100% - ${UPLOAD_FOOTER_HEIGHT})`} sx={{ overflow: 'auto' }}>
      {getFilePreviewComponentForFile(file, handleBackClick, { setFileName })}
    </Box>
    <Flex mt="md" justify="end">
      <Button variant="primary" loading={isUploading} onClick={handleUpload}>
        Upload
      </Button>
    </Flex>
  </Box>
);
