import { useState } from 'react';
import { notifications } from '@/shared/design-system/v2';
import { useSimpleUploadToS3 } from '../../../../../queries/data-upload';
import {
  useGetInputFileUri,
  useStartInference,
} from '../../../../../queries/model-apps/model-apps';

export const useUploadAndStartFileInference = (modelId: string) => {
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: startInference } = useStartInference(modelId);
  const { mutateAsync: getInputUri } = useGetInputFileUri(modelId);
  const uploadToS3 = useSimpleUploadToS3();

  const uploadAndStartInference = async (
    {
      file,
      featureColumns,
    }: {
      file: File;
      featureColumns: string[];
    },
    { onSuccess }: { onSuccess?: () => void } = {},
  ) => {
    setIsLoading(true);

    try {
      const response = await getInputUri();
      const { fileUri, signedUrl } = response.data ?? {};

      if (!fileUri || !signedUrl) {
        throw new Error('File url not found!');
      }

      await uploadToS3({ file, signedUri: signedUrl });
      await startInference({ featureColumns, fileUri: fileUri });

      notifications.success('Successfully uploaded file');
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      notifications.error('Error uploading file');
    }

    setIsLoading(false);
  };

  return { mutate: uploadAndStartInference, isLoading };
};
