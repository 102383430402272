import { useState } from 'react';
import { Alert, Button, Text, Vertical, closeAllModals } from '@/shared/design-system/v2';
import { redirectToProviderConsent } from '@/shared/lib/oauth/oauth';
import { useInvalidateConnectorsList } from '../../../queries/connectors';
import { useGetConsentUrl } from '../../../queries/connectors/google-drive';

interface GoogleDriveModalProps {
  onClickBack?: any;
  onCreate?: (connectorId: string) => void;
  onClose?: () => void;
}

export const GoogleDriveModal = ({ onClickBack }: GoogleDriveModalProps) => {
  const [error, setError] = useState('');
  const invalidateConnectorsList = useInvalidateConnectorsList();
  const { mutateAsync: getConsent } = useGetConsentUrl();

  const handleGoogleDriveConnect = async () => {
    try {
      const url = await getConsent();
      await redirectToProviderConsent(url);

      invalidateConnectorsList();
    } catch (e) {
      setError('Failed to authorize Google Drive account');
    }

    closeAllModals();
  };

  return (
    <Vertical>
      <Alert color="yellow">
        When you connect your Google account with Markov, everyone your workspace will be able to
        add any file or folder within your drive as a source.
      </Alert>
      {error ? (
        <Alert color="red">
          Seems like there was an issue authorizing your account. Please try again!
        </Alert>
      ) : null}
      <Text variant="bodyShort01">Connect your Google Drive account to get started</Text>
      <Button onClick={handleGoogleDriveConnect} variant="primary" w="240px">
        Connect your Google Drive
      </Button>
    </Vertical>
  );
};
