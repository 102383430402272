import { Accept } from 'react-dropzone';
import { MIME_TYPES } from '@/shared/design-system/v2';
import { PDFPreview } from '../file-preview/PDFPreview';
import { TabularFilePreviewWrapper } from '../file-preview/TabularFilePreview.wrapper';

interface FilePreviewOptions {
  setFileName: (e: React.ChangeEvent<any>) => void;
}

export const getFilePreviewComponentForFile = (
  file: File,
  onBackClick: () => void,
  options: FilePreviewOptions,
) => {
  if (file.type === 'text/csv') {
    return (
      <TabularFilePreviewWrapper
        file={file}
        fileName={file.name}
        onFileNameChange={options.setFileName}
        onBackClick={onBackClick}
      />
    );
  }

  if (file.type === 'application/pdf') {
    return <PDFPreview file={file} />;
  }
};

export const getSupportedFilesLabel = (supportedFilesTypes: string[]) => {
  const supportedFilesTypesLabels = supportedFilesTypes
    .map(type => `.${type.toLowerCase()}`)
    .join(', ');
  return `Supported file formats: ${supportedFilesTypesLabels}`;
};

export const defaultDropzoneAccept = {
  [MIME_TYPES.csv]: ['.csv'],
  [MIME_TYPES.txt]: ['.txt'],
};

// Note: This function is used in FileUploadInput component to generate
// dropzone accept prop for the Dropzone component from the list of supported file types.
// This list of supported file type will be an enum defined by markov defining what file
// types are supported for upload.
// Currently, we have checked cases for MimeTypes for MacOS but in future if needed,
// changes for Windows or Linux, the supported file types to mimetype mapping should be
// done here.
export const getDropzoneAccept = (supportedFilesTypes?: string[]): Accept => {
  if (!supportedFilesTypes?.length) {
    // by default, we support csv and txt files. But it is not applicable everywhere
    return defaultDropzoneAccept;
  }

  return supportedFilesTypes.reduce<Accept>((acc, type) => {
    const mimeTypeKey = type as keyof typeof MIME_TYPES;
    const mimeType = MIME_TYPES[mimeTypeKey];

    if (mimeType) {
      acc[mimeType] = [`.${type.toLowerCase()}`];
    }
    return acc;
  }, {});
};
