import {
  Layout,
  LayoutProps,
  RankedTester,
  Scoped,
  UISchemaElement,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid } from '@/shared/design-system/v2';

interface GridLayout extends Layout {
  type: 'GridLayout';
  elements: (UISchemaElement & Scoped)[];
}

export const GridLayoutComponent = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  data,
  cells,
  direction,
  label,
}: LayoutProps) => {
  if (!visible) {
    return null;
  }

  const gridLayout = uischema as GridLayout;

  return (
    <Grid columns={12} h="100%" m={0}>
      {gridLayout.elements.map((element, idx) => (
        <Grid.Col
          key={`${path}_${idx}`}
          span={element.options?.width}
          h="100%"
          sx={{ overflow: 'auto' }}
        >
          <JsonFormsDispatch
            uischema={element}
            schema={schema}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
          />
        </Grid.Col>
      ))}
    </Grid>
  );
};

export const GridLayoutControl = withJsonFormsLayoutProps(GridLayoutComponent);

export const gridControlTester: RankedTester = rankWith(1, uiTypeIs('GridLayout'));
