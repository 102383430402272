import first from 'lodash/first';
import {
  Card,
  Center,
  Flex,
  Image,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { OperatorModel, TemplateModel } from '../../../../generated/api';
import { TemplateActions } from '../../../workflows/home/overview/TemplatesActions';
import { useTemplateDelete } from '../../../workflows/home/templates/useTemplateDelete';

const MARKOV_DB = 'markov';

interface TemplateCardProps {
  templateData: TemplateModel;
  handleFlowButtonClick: (templateData: TemplateModel) => void;
  operatorList: OperatorModel[];
  variant?: 'big' | 'small';
  titleLineClamp?: number;
  width?: number;
}

export const TemplateCard = ({
  templateData,
  handleFlowButtonClick,
  operatorList,
  variant = 'small',
  titleLineClamp,
  width,
}: TemplateCardProps): JSX.Element => {
  const theme = useMarkovTheme();
  const { name, description, workspaceId, keyOperators } = templateData;

  const firstKeyOperatorId = first(keyOperators);
  const keyOperator = operatorList.find(operator => operator.operatorId === firstKeyOperatorId);

  const { openDeleteTemplateModal } = useTemplateDelete(templateData.templateId);

  const deleteAction = {
    id: 'delete-user-template',
    label: 'Delete',
    handler: openDeleteTemplateModal,
  };
  const actions = [deleteAction];
  /*
  User templates are stored in common db and markov templates are stored in markov
  */
  const showTemplateActions = workspaceId !== MARKOV_DB;

  return (
    <Card
      data-testid={`workflow-template-${templateData.templateId}`}
      onClick={() => handleFlowButtonClick(templateData)}
      radius="sm"
      withBorder
      p={0}
      h="100%"
      w={width ?? '100%'}
      sx={{ cursor: 'pointer' }}
    >
      <Vertical p="xxl" spacing="xs" h="100%">
        <Flex justify="space-between" w="100%">
          <Center h={48} w={48} bg="gray.0" mb="xs" sx={{ borderRadius: '50%' }}>
            <Image src={templateData.iconUrl} height={32} width={32} />
          </Center>
          {showTemplateActions && <TemplateActions actions={actions} />}
        </Flex>
        <Text variant="subTitle02" color="gray.9" lineClamp={titleLineClamp ?? 2}>
          {name}
        </Text>
        <Text variant="bodyLong02" color="dark.3" lineClamp={variant === 'small' ? 2 : 3}>
          {description}
        </Text>
      </Vertical>
    </Card>
  );
};
