import { Text, closeAllModals, openModal } from '@/shared/design-system/v2';
import { WorkspaceMemberDetail } from './MembersTable';
import { UpdateMembership } from './UpdateMembership';

export const useUpdateMembershipInWorkspace = () => {
  const handleCloseModal = () => {
    closeAllModals();
  };

  const openUpdateMemberShipModal = (workspaceMember: WorkspaceMemberDetail) => {
    openModal({
      title: <Text variant="subTitle02">Update membership</Text>,
      size: 'lg',
      children: (
        <UpdateMembership
          currentRole={workspaceMember.role}
          userId={workspaceMember.userId}
          onClose={handleCloseModal}
        />
      ),
    });
  };

  return { openUpdateMemberShipModal };
};
