import { IconInfoCircle } from '@tabler/icons-react';
import {
  Alert,
  Box,
  Horizontal,
  PasswordInput,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
  useForm,
} from '@/shared/design-system/v2';
import { CreateConnectorRequest, StorageType } from '../../../generated/api';
import { useCreateConnectorMutation } from '../../../queries/connectors';
import { getConnectorTypeDisplayName, getConnectorTypeIcon } from '../util';
import { CreateConnectorModalActions } from './CreateConnectorModalActions';

const DEFAULT_POSTGRESQL_PORT = 5432;

const usePostgresConnectorForm = () =>
  useForm({
    initialValues: {
      connectorName: '',
      hostname: '',
      port: DEFAULT_POSTGRESQL_PORT.toString(),
      dbName: '',
      username: '',
      password: '',
    },
    validate: {
      connectorName: value => {
        if (!value.trim()) {
          return 'Connector name cannot be empty';
        }
        return null;
      },
      hostname: value => {
        if (!value.trim()) {
          return 'Hostname cannot be empty';
        }
        return null;
      },
      // Note: Port may be omitted (default will be used)
      dbName: value => {
        if (!value.trim()) {
          return 'Database name cannot be empty';
        }
        return null;
      },
      username: value => {
        if (!value.trim()) {
          return 'Username cannot be empty';
        }
        return null;
      },
      password: value => {
        if (!value) {
          return 'Password cannot be empty';
        }
        return null;
      },
    },
    transformValues: values => ({
      connectorName: values.connectorName.trim(),
      hostname: values.hostname.trim(),
      port: parseInt(values.port.trim()),
      dbName: values.dbName.trim(),
      username: values.username.trim(),
      // NOTE: Don't trim spaces from password
    }),
  });

interface PostgresConnectorModalProps {
  onClickBack?: () => void;
  onCreate?: (connectorId: string) => void;
  onClose?: () => void;
}

export const PostgresConnectorModal = ({
  onClickBack,
  onCreate,
  onClose = closeAllModals,
}: PostgresConnectorModalProps) => {
  const form = usePostgresConnectorForm();

  const { mutate: createConnector, isLoading } = useCreateConnectorMutation();

  const handleCreateConnector = () => {
    if (form.validate().hasErrors) {
      return;
    }

    const { connectorName, hostname, port, dbName, username } = form.getTransformedValues();
    const { password } = form.values;

    const createReq: CreateConnectorRequest = {
      name: connectorName,
      connectorType: StorageType.Postgresql,
      credData: {
        postgresUsername: username,
        postgresPassword: password,
      },
      connectorMetadata: {
        hostName: hostname,
        port,
        dbName,
      },
    };

    createConnector(createReq, {
      onError: () => {
        notifications.error('Unable to create connector');
      },
      onSuccess: res => {
        notifications.success('Connector created successfully');
        const connectorId = res.data.response?.connectorId ?? '';
        onCreate?.(connectorId);
        onClose();
      },
    });
  };

  const icon = getConnectorTypeIcon(StorageType.Postgresql);
  const connectorName = getConnectorTypeDisplayName(StorageType.Postgresql);

  return (
    <Vertical spacing="xl">
      <Box>
        <Alert color="yellow" icon={<IconInfoCircle />}>
          Access credentials will be encrypted and stored securely.
        </Alert>
      </Box>
      <Horizontal>
        <Text variant="subTitle03">Service:</Text>
        <Horizontal spacing="xs">
          {icon}
          <Text variant="subTitle03">{connectorName}</Text>
        </Horizontal>
      </Horizontal>
      <Vertical spacing="sm">
        <TextInput
          required
          ariaLabel="Connector name"
          label="Connector name"
          {...form.getInputProps('connectorName')}
        />
        <Horizontal noWrap>
          <Box sx={{ flexGrow: 1 }}>
            <TextInput
              required
              ariaLabel="Hostname"
              label="Hostname"
              {...form.getInputProps('hostname')}
            />
          </Box>
          <Box sx={{ flex: '0 0 110px' }}>
            <TextInput
              placeholder={`${DEFAULT_POSTGRESQL_PORT} (default)`}
              ariaLabel="Port"
              label="Port"
              {...form.getInputProps('port')}
            />
          </Box>
        </Horizontal>
        <TextInput
          required
          ariaLabel="Database name"
          label="Database name"
          {...form.getInputProps('dbName')}
        />
        <TextInput
          required
          ariaLabel="Username"
          label="Username"
          {...form.getInputProps('username')}
        />
        <PasswordInput
          required
          aria-label="Password"
          label="Password"
          {...form.getInputProps('password')}
        />
      </Vertical>

      <CreateConnectorModalActions
        onClickBack={onClickBack}
        onCreate={handleCreateConnector}
        onClose={onClose}
        isCreating={isLoading}
      />
    </Vertical>
  );
};
