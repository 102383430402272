import { Tabs, TabsProps } from '@/shared/design-system/v2';

export const ViewModeTabs = (props: TabsProps) => (
  <Tabs
    unstyled
    styles={theme => ({
      tab: {
        ...theme.fn.focusStyles(),
        backgroundColor: theme.colors.white[0],
        color: `${theme.colors.gray[6]} !important`,
        border: `1px solid ${theme.colors.gray[3]}`,
        padding: `${theme.spacing.sm} ${theme.spacing.md} !important`,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',

        '&:not(:first-of-type)': {
          borderLeft: 0,
        },

        '&:first-of-type': {
          borderTopLeftRadius: theme.radius.sm,
          borderBottomLeftRadius: theme.radius.sm,
        },

        '&:last-of-type': {
          borderTopRightRadius: theme.radius.sm,
          borderBottomRightRadius: theme.radius.sm,
        },

        '&[data-active]': {
          backgroundColor: theme.colors.blue[0],
          color: `${theme.colors.blue[6]} !important`,
        },
      },

      tabIcon: {
        marginRight: theme.spacing.sm,
        display: 'flex',
        alignItems: 'center',
      },

      tabsList: {
        display: 'flex',
      },
    })}
    {...props}
  />
);
