import { Badge } from '@mantine/core';
import { Box, Skeleton, Text, Vertical } from '@/shared/design-system/v2';
import { ICellRendererParams } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { Dataset } from '../../../../queries/datasets/list';
import { DEFAULT_ROW_HEIGHT } from './util';

export const DataFamilyRenderer = ({
  value,
  data,
}: ICellRendererParams<Dataset, { dataFamilyId: string; dataFamilyName: string }>) => {
  if (!data) {
    return (
      <Box py="md" h={DEFAULT_ROW_HEIGHT}>
        <Skeleton width={100} height={30} />
      </Box>
    );
  }

  if (!value.dataFamilyName) {
    return (
      <Vertical justify="center" h={DEFAULT_ROW_HEIGHT}>
        <Text variant="subTitle04" color="gray.7">
          --
        </Text>
      </Vertical>
    );
  }

  return (
    <Box h={DEFAULT_ROW_HEIGHT}>
      <Badge bg="gray.0" color="gray.7">
        <Text variant="small01" color="gray.7">
          {value.dataFamilyName}
        </Text>
      </Badge>
    </Box>
  );
};
