import { IconInfoCircle } from '@tabler/icons-react';
import first from 'lodash/first';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { workflowEvents } from '@/main/analytics/workflowEvents';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import { Alert, Box, Center, Loader, ScrollArea } from '@/shared/design-system/v2';
import { sendAnalytics } from '@/shared/initializers/analytics';
import { useWorkflowRunContext } from '../../../../contexts/workflows/WorkflowRunContext';
import { WorkflowRun } from '../../../../generated/api';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { WorkflowRunsList } from './WorkflowRunsList';
import { WorkflowRunsListFooter } from './WorkflowRunsListFooter';
import { WorkflowRunTabs, WorkflowRunsListTabs } from './WorkflowRunsListTabs';

const TAB_HEIGHT = 44;

interface WorkflowRunsListWrapperProps {
  workflowId: string;
  currentPage: number;
  totalRows: number;
  workflowRuns: WorkflowRun[];
  isLoading: boolean;
  isError: boolean;
  activeTab: WorkflowRunTabs;
  onPageChange: (page: number) => void;
  onTabChange: (tabId: WorkflowRunTabs) => void;
  isPanelExpanded: boolean;
}

export const WorkflowRunsListWrapper = ({
  workflowId,
  currentPage,
  totalRows,
  workflowRuns = [],
  isLoading,
  isError,
  activeTab,
  onPageChange,
  onTabChange,
  isPanelExpanded,
}: WorkflowRunsListWrapperProps): JSX.Element => {
  const { setRunId } = useWorkflowRunContext();
  const { workspaceId } = useAppMetadata();
  const { getWorkflowRunDetailsRoute } = useAbsoluteRoutes();
  const navigate = useNavigate();

  const handleWorkflowRunDetailsClick = (workflowRunId: string) => {
    sendAnalytics(
      workflowEvents.runs.workflowRunsRunNumberCardClicked({
        workflowId: workflowId,
        workspaceId: workspaceId,
        runId: workflowRunId,
      }),
    );

    const route = getWorkflowRunDetailsRoute(workflowId, workflowRunId);
    navigate(route);
  };

  useEffect(() => {
    if (workflowRuns) {
      setRunId(runId => (runId ? runId : first(workflowRuns)?.workflowRunId ?? ''));
    }
  }, [workflowRuns, setRunId]);

  let content: ReactNode;

  if (isLoading) {
    // TODO: Replace this with overlay loader
    content = (
      <Center h={200}>
        <Loader text="Loading workflow runs" />
      </Center>
    );
  } else if (isError) {
    content = (
      <Center h={200}>
        <Alert color="red" icon={<IconInfoCircle />}>
          There was an error while fetching workflow runs. Please try again or contact support
        </Alert>
      </Center>
    );
  } else if (!totalRows) {
    // TODO: Update the message based on the selected tab
    content = (
      <Center h={200}>
        <Alert icon={<IconInfoCircle />}>There are no workflow runs found</Alert>
      </Center>
    );
  } else {
    content = (
      <WorkflowRunsList
        workflowRuns={workflowRuns}
        onWorkflowRunDetailsClick={handleWorkflowRunDetailsClick}
        isClickable={activeTab === WorkflowRunTabs.ALL}
        isPanelExpanded={isPanelExpanded}
      />
    );
  }

  return (
    <Box h="100%" bg="white.0" pos="relative">
      <WorkflowRunsListTabs activeTab={activeTab} onTabChange={onTabChange} />
      <ScrollArea h={`calc(100% - ${TAB_HEIGHT}px)`}>
        {content}
        <WorkflowRunsListFooter
          totalRows={totalRows}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </ScrollArea>
    </Box>
  );
};
