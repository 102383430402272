import { PropsWithChildren } from 'react';
import { AnalyzingSpinner } from '@/shared/design-system';
import { Horizontal, Text, Vertical } from '@/shared/design-system/v2';
import { rem } from '@/shared/design-system/v2/styles';
import { toPlural } from '@/shared/lib/ui';
import { AnalysisStatusData } from '../../../queries/datasets/list';
import { StyledBubbles } from '../../common/TableWrapper';
import { StyledClickableWrapper, StyledWarning } from '../DatasetsTable.style';

const DatasetStateWrapper = ({ children }: PropsWithChildren<unknown>): JSX.Element => (
  <StyledClickableWrapper onClick={e => e.stopPropagation()}>
    <Horizontal noWrap>{children}</Horizontal>
  </StyledClickableWrapper>
);

export const AnalyzingState = ({ isUploading = false }) => (
  <DatasetStateWrapper>
    <AnalyzingSpinner />
    <Text variant="small01" color="dark.5" data-testid="datasets-table-Analyzing">
      {isUploading ? 'Uploading...' : 'Analyzing...'}
    </Text>
  </DatasetStateWrapper>
);

export const AnalyzingInProgressState = ({ numCompleted = 0 }) => (
  <DatasetStateWrapper>
    <AnalyzingSpinner />
    <Vertical spacing={0}>
      <Text variant="small01" color="dark.5" data-testid="datasets-table-ViewAnalysis">
        Analyzing...
      </Text>
      {numCompleted > 0 && (
        <Text variant="small02" color="green" data-testid="datasets-table-Analyzing">
          {`${toPlural(numCompleted, 'analysis', 'analyses')} completed`}
        </Text>
      )}
    </Vertical>
  </DatasetStateWrapper>
);

export const CompletedState = ({ numError = 0 }) => (
  <DatasetStateWrapper>
    <StyledBubbles />
    <Vertical spacing={0}>
      <Text variant="small01" color="dark.5" data-testid="datasets-table-ViewAnalysis">
        Finished
      </Text>
      {numError > 0 && (
        <Text variant="small02" color="red" data-testid="datasets-table-Analyzing">
          {`${toPlural(numError, 'analysis', 'analyses')} failed`}
        </Text>
      )}
    </Vertical>
  </DatasetStateWrapper>
);

export const ErrorState = () => (
  <DatasetStateWrapper>
    <StyledWarning />
    <Text variant="small01" color="red.9" data-testid="datasets-table-Error">
      Error
    </Text>
  </DatasetStateWrapper>
);

interface DatasetStateProps {
  analysisStatus: AnalysisStatusData;
}

/**
 * Evaluation Logic
 *  
TOTAL ANALYZERS = 50
50 ANALYZING => ANALYZING WITH SPINNER
40 ANALYZING 10 ERROR => ANALYZING WITH SPINNER
40 ANALYZING 10 SUCCESS => ANALYZING WITH SPINNER (SHOW AVAILABLE NUMBER)
30 ANALYZING 10 SUCCESS 10 ERROR => ANALYZING WITH SPINNER (SHOW AVAILABLE NUMBER)
40 SUCCESS 10 ERROR => FINISHED (SHOW ERROR NUMBER)
50 SUCCESS => FINISHED
50 ERROR => ERROR
 */
export const DatasetState = ({ analysisStatus }: DatasetStateProps): JSX.Element => {
  const { analysis, isUploading } = analysisStatus;
  const { tasksStateSummary } = analysis ?? {};

  if (!tasksStateSummary) {
    return <Text variant="small03">-</Text>;
  }

  const {
    numCompleted = 0,
    numCreated = 0,
    numError = 0,
    numInProgress = 0,
    numTasks = 0,
  } = tasksStateSummary;

  if (numCreated === numTasks || numInProgress === numTasks) {
    return <AnalyzingState isUploading={isUploading} />;
  }

  if (numInProgress > 0 || numCreated > 0) {
    return <AnalyzingInProgressState numCompleted={numCompleted} />;
  }

  if (numCompleted > 0) {
    return <CompletedState numError={numError} />;
  }

  if (numError === numTasks) {
    return <ErrorState />;
  }

  return (
    <Text variant="small03" sx={{ lineHeight: rem(42) }} miw={80}>
      -
    </Text>
  );
};
