import { ControlProps, RankedTester, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import first from 'lodash/first';
import { useEffect, useState } from 'react';
import { useInvalidateDagNodeSchemaQuery } from '@/main/queries/workflows/operators';
import { Alert, Loader, Text, Vertical } from '@/shared/design-system/v2';
import {
  DataUploadDetails,
  UploadState,
  useDataUpload,
} from '../../../../../contexts/data-upload/DataUpload';
import { useInvalidateDataSourcesList } from '../../../../../queries/data-sources';
import { CompleteParams } from '../../../../../queries/data-upload';
import { FolderSelectionSummary } from '../../../resource-lookup/FolderSelectionSummary';

const FolderDataSourceUploadComponent = ({
  label,
  description,
  handleChange,
  path,
  uischema,
  visible,
  enabled,
  data,
  config,
  errors,
}: ControlProps): JSX.Element | null => {
  const invalidateDataSources = useInvalidateDataSourcesList();
  const { uploads } = useDataUpload();
  const invalidateDagNodeSchema = useInvalidateDagNodeSchemaQuery(config.workflowId);

  const nodeIdUpload = uploads.find(
    upload => upload.additionalData?.workflowUploadNodeId === config.nodeId,
  );
  const [nodeUpload, setNodeUpload] = useState<DataUploadDetails | undefined>(nodeIdUpload);

  useEffect(() => {
    const nodeIdUpload = uploads.find(
      upload => upload.additionalData?.workflowUploadNodeId === config.nodeId,
    );
    setNodeUpload(nodeIdUpload);
  }, [uploads]);

  if (!visible) {
    return null;
  }

  const onChange = (value: string) => {
    handleChange(path, value);
    invalidateDataSources();
  };

  const onUploadComplete = (state: 'success' | 'error', completeParams: CompleteParams) => {
    if (state === 'success') {
      const resourceId = completeParams.key.split('/')[0];
      if (data && data == resourceId) {
        invalidateDagNodeSchema(config.nodeId);
      } else if (resourceId) {
        onChange(resourceId);
      }
      return;
    }
  };

  const isEditable = !config.viewOnly && enabled;

  let uploadingText = '';

  if (nodeUpload) {
    if (nodeUpload.fileData.length === 1) {
      const firstFileData = first(nodeUpload.fileData);
      uploadingText = `Uploading ${firstFileData?.file.name}`;
    } else {
      uploadingText = `Uploading files...`;
    }
  }

  return (
    <>
      {nodeUpload && nodeUpload.state === UploadState.UPLOAD_STARTED ? (
        <Vertical>
          <Loader text={uploadingText} variant="dots" />
          <Alert>
            Once data has completed uploading, it will be available as a data source. Changes need
            to be applied to be able to run the workflow.
          </Alert>
        </Vertical>
      ) : (
        <Vertical spacing="lg">
          <Vertical spacing={0}>
            <Text variant="subTitle02" color="gray.9">
              {label}
            </Text>
            <Text variant="small02" color="gray.7">
              {description}
            </Text>
          </Vertical>
          <FolderSelectionSummary
            label={label}
            description={description}
            resourceId={data}
            onChange={onChange}
            isEditable={isEditable}
            additionalData={{
              workflowUploadNodeId: config.nodeId,
            }}
            onComplete={onUploadComplete}
          />
        </Vertical>
      )}
    </>
  );
};

export const folderDataSourceUploadControlTester: RankedTester = rankWith(
  8,
  uiSchema => uiSchema?.options?.isFolderSelection,
);

export const FolderDataSourceUploadControl = withJsonFormsControlProps(
  FolderDataSourceUploadComponent,
);
