import HighchartsReact from 'highcharts-react-official';
import { useRef, useState } from 'react';
import { Expand } from '@/shared/design-system';
import { ActionIcon, Horizontal, Modal, Text } from '@/shared/design-system/v2';
import { ChartTypes } from '../../../charts';
import { ChartData } from '../../../charts/config/build';
import { ChartsProvider } from '../../../charts/context/Context';
import { VisualizationComponent } from '../../analyser/visualization/Visualization';
import { DownloadVisualization } from '../../analyser/visualization/actions/DownloadVisualization';

interface SplineChartVisualizationProps {
  data: ChartData;
  xLabel?: string;
  yLabel?: string;
  colors?: string[];
  enableLegends?: boolean;
  title?: string;
  enableChartDataLabels?: boolean;
}

export const SplineChartVisualization = ({
  data,
  xLabel,
  yLabel,
  colors = ['#1665E4'],
  enableLegends = false,
  title = '',
  enableChartDataLabels = true,
}: SplineChartVisualizationProps) => {
  const [expanded, showExpanded] = useState(false);
  const chartRef = useRef<HighchartsReact.RefObject | null>(null);

  const handleExpandViz = () => {
    showExpanded(true);
  };

  const handleModalClose = () => {
    showExpanded(false);
  };

  const header = (
    <VisualizationComponent.Header
      actions={
        !expanded ? (
          <Horizontal>
            <DownloadVisualization
              fileName={title || 'experiment_chart'}
              isDownloadDisabled={!data}
            />
            <ActionIcon color="gray.6" onClick={handleExpandViz} disabled={!data}>
              <Expand />
            </ActionIcon>
          </Horizontal>
        ) : null
      }
    >
      {!expanded && <VisualizationComponent.Header.Title title={title} />}
    </VisualizationComponent.Header>
  );

  let content = null;

  if (data) {
    content = (
      <VisualizationComponent.Chart
        type={ChartTypes.SPLINE}
        data={data}
        colors={colors}
        xAxisOptions={{
          title: xLabel,
        }}
        yAxisOptions={{
          title: yLabel,
        }}
        enableLegends={enableLegends}
        enableDataLabels={enableChartDataLabels}
      />
    );
  }

  const vizContent = (
    <VisualizationComponent
      cardProps={{
        shadow: '',
        withBorder: false,
      }}
    >
      {title && header}
      {content}
    </VisualizationComponent>
  );

  return (
    <>
      <ChartsProvider hcRef={chartRef}>{vizContent}</ChartsProvider>
      <Modal
        opened={expanded}
        onClose={handleModalClose}
        size="80vw"
        centered
        title={<Text variant="subTitle02">{title}</Text>}
      >
        {vizContent}
      </Modal>
    </>
  );
};
