import { IconInfoCircle } from '@tabler/icons-react';
import { ForwardedRef, forwardRef } from 'react';
import { Alert, Box, Button, Center, Horizontal, Text, Vertical } from '@/shared/design-system/v2';
import GenerateSkeletonLoader from '../../GenerateSkeletonLoader';

export interface ResponseContainerProps {
  isLoading: boolean;
  isError: boolean;
  generatedPrompt: string;
  onPromptSelect: (response: string) => void;
}

const GeneratedPromptContainer = forwardRef(
  (
    { isLoading, isError, generatedPrompt, onPromptSelect }: ResponseContainerProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const handlePromptSelectClick = () => {
      onPromptSelect(generatedPrompt);
    };

    return (
      <Box ref={ref} mih={200} p="md" bg="gray.0" style={{ borderRadius: '8px' }}>
        {isLoading ? <GenerateSkeletonLoader /> : null}
        {generatedPrompt ? (
          <Vertical>
            {/* TODO: Add Copy button */}
            <Horizontal position="right">
              <Button onClick={handlePromptSelectClick} color="dark.5" variant="primary">
                Select
              </Button>
            </Horizontal>
            <Text style={{ whiteSpace: 'pre-line' }} variant="bodyLong02">
              {generatedPrompt}
            </Text>
          </Vertical>
        ) : null}
        {isError ? (
          <Center h="100%">
            <Alert icon={<IconInfoCircle size={14} />} color="red">
              There was a transient error while generating the prompt. Please try again after
              sometime or contact support!
            </Alert>
          </Center>
        ) : null}
      </Box>
    );
  },
);

GeneratedPromptContainer.displayName = 'GeneratedPromptContainer';

export default GeneratedPromptContainer;
