import { TooltipFormatterContextObject } from 'highcharts';
import { formatNumberLocaleString } from '@/shared/lib/ui';
import { HeatMapSeriesDataModel } from '../../../../generated/api';
import { TooltipContainer, TooltipHeader, TooltipItemRow } from '../ChartTooltip.style';

interface CustomPointOptionsObject extends HeatMapSeriesDataModel {
  total: number;
}

interface ConfusionMatrixTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

export const ConfusionMatrixTooltip = ({ formatterContext }: ConfusionMatrixTooltipProps) => {
  const point = formatterContext.point as CustomPointOptionsObject;
  const [predictedCategory, actualCategory] = ([point.x, point.y] as number[]).map(
    index => formatterContext.point.series.xAxis.categories[index] ?? '',
  );

  return (
    <TooltipContainer>
      <TooltipHeader>{formatterContext.series.name}</TooltipHeader>
      <TooltipItemRow>
        {actualCategory} was labeled as {predictedCategory}
      </TooltipItemRow>
      <TooltipItemRow>
        {point.count} out of {point.total} times
      </TooltipItemRow>
      <TooltipItemRow>({formatNumberLocaleString(point.value)}%)</TooltipItemRow>
    </TooltipContainer>
  );
};
