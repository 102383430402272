import { CellProps } from 'react-table';
import { Flex, Horizontal, HoverCard, Tag, Text } from '@/shared/design-system/v2';
import { getSortedTags } from '@/shared/lib/tags';
import { TagMetadata } from '../../../generated/api';

const OverflowTagsHoverCard = ({ tags }: { tags: TagMetadata[] }) => (
  <HoverCard withArrow shadow="lg">
    <HoverCard.Target>
      <Text variant="subTitle04" color="dark.4">
        +{tags.length}
      </Text>
    </HoverCard.Target>
    <HoverCard.Dropdown maw={300} onClick={e => e.stopPropagation()}>
      <Flex wrap="wrap" gap="sm" rowGap="xs">
        {tags.map(tag => (
          <Tag key={tag.tagId} name={tag.name} />
        ))}
      </Flex>
    </HoverCard.Dropdown>
  </HoverCard>
);

interface TaggedResource {
  tags: TagMetadata[];
}

export const TagsCell = ({ value: tags }: CellProps<TaggedResource, TagMetadata[]>) => {
  if (!tags?.length) {
    return null;
  }

  const sortedTags = getSortedTags(tags);
  const displayedTags = sortedTags.slice(0, 2);
  const overflowTags = sortedTags.slice(2);

  return (
    <Horizontal spacing={4} style={{ rowGap: 'xs' }}>
      {displayedTags.map(({ tagId, name }) => (
        <Tag key={tagId} name={name} maw={64} />
      ))}
      {overflowTags.length > 0 ? <OverflowTagsHoverCard tags={overflowTags} /> : null}
    </Horizontal>
  );
};
