import { AxiosResponse } from 'axios';
import { logger } from '@/shared/initializers/logging';
import { axiosS3 } from '@/shared/lib/api/api';
import { FilePartInfo, PostUrl, Urls } from '../generated/api';

const BYTE = 1024;
const MB = 1024 * BYTE;
export const MIN_CHUNK_SIZE = 5 * MB;
export const MAX_SINGLE_FILE_UPLOAD_SIZE = MIN_CHUNK_SIZE * 2;

export interface UploadToS3Req {
  urlResponse: Urls | PostUrl;
  file: File;
}

export const getMinChunkSize = (size: number) =>
  size < MAX_SINGLE_FILE_UPLOAD_SIZE ? size : MAX_SINGLE_FILE_UPLOAD_SIZE;

export const getFileUploadConfig = (file: File) => ({
  name: file.name,
  size: file.size,
  uploadChunkSize: getMinChunkSize(file.size || 1),
});

const sendFilePartToS3 = (urlPart: string, filePart: Blob): Promise<AxiosResponse<unknown>> =>
  axiosS3.put(urlPart, filePart);

const sendAllPartRequestsToS3 = (urls: Urls, file: File): Promise<AxiosResponse<unknown>[]> => {
  const maxFilePartSize = file.size / (urls.urlPartNumbers?.length ?? 1);

  return Promise.all(
    urls.urls.map((url, idx) =>
      sendFilePartToS3(url, file.slice(maxFilePartSize * idx, maxFilePartSize * (idx + 1))),
    ),
  );
};

export interface CompleteParams {
  fileName: string;
  uploadId: string;
  key: string;
  parts: FilePartInfo[];
}

export type CompleteUploadCallback = (params: CompleteParams) => void;

export const useUploadToS3 =
  () => async (req: UploadToS3Req, completeUpload: CompleteUploadCallback) => {
    let results;

    const urlsResponse = req.urlResponse as Urls;
    try {
      results = await sendAllPartRequestsToS3(req.urlResponse as Urls, req.file);
    } catch (e) {
      logger.error(e as string);
    }

    return await completeUpload({
      fileName: req.file.name,
      uploadId: urlsResponse.uploadId,
      key: urlsResponse.key,
      parts:
        results?.map(result => ({
          etag: result.headers.etag.replace(/^"|"$/g, ''),
          partNumber: parseInt(
            new URL(result.config.url ?? '').searchParams.get('partNumber') ?? '',
          ),
        })) ?? [],
    });
  };

export interface SimpleUploadToS3Req {
  signedUri: string;
  file: File;
}

export const useSimpleUploadToS3 = () => async (req: SimpleUploadToS3Req) => {
  await axiosS3.put(req.signedUri, req.file, { headers: { 'Content-Type': '' } });
};
