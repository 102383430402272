import { ICellRendererParams } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { Dataset } from '../../../../queries/datasets/list';
import { DatasetAnalysisPanelLink } from '../../../dataset-analysis-status/DatasetAnalysisPanelLink';

export const ViewTasksRenderer = ({ data }: ICellRendererParams<Dataset>) => {
  const datasetId = data?.datasetId;
  const datasetName = data?.name ?? '';

  if (!datasetId) {
    return null;
  }

  const analysis = data?.analysis;
  const isUploading = analysis?.isUploading;
  const tasksStateSummary = analysis?.analysis?.tasksStateSummary;

  if (isUploading) {
    return null;
  }

  const { numError = 0, numTasks = 0 } = tasksStateSummary ?? {};

  return (
    <DatasetAnalysisPanelLink
      datasetId={datasetId}
      datasetName={datasetName}
      showErrors={numError === numTasks}
    >
      {numError === numTasks ? 'View Errors' : 'View Tasks'}
    </DatasetAnalysisPanelLink>
  );
};
