import { Loader } from '@/shared/design-system/v2';
import { useGetWorkspaceCurrentSubscriptionDetailsQuery } from '../../../../queries/account/payment-and-subscription';
import { ErrorState } from '../../../common/ErrorState';
import { useCanManageSubscription } from '../../../subscriptions/util';
import { SubscriptionDetails } from './SubscriptionDetails';

export const SubscriptionDetailsContainer = () => {
  const {
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
    data: subscriptionDetail,
  } = useGetWorkspaceCurrentSubscriptionDetailsQuery();

  const { canManageSubscription } = useCanManageSubscription();

  if (isSubscriptionLoading) {
    return <Loader text="Loading subscription details" />;
  }

  if (isSubscriptionError) {
    return (
      <ErrorState
        title="Seems there is some error"
        subtitle="We are not able to fetch subscription details. Please try after sometime or contact support@markovml.com."
      />
    );
  }

  return (
    <SubscriptionDetails
      subscriptionDetail={subscriptionDetail}
      canManageSubscription={canManageSubscription}
    />
  );
};
