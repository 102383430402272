import { IconFileText, IconTrashX } from '@tabler/icons-react';
import {
  ActionIcon,
  FileWithPath,
  Horizontal,
  Text,
  useMarkovTheme,
} from '@/shared/design-system/v2';

interface FileSelectionProps {
  file: FileWithPath;
  onFileDelete: () => void;
}

export const FileSelection = ({ file, onFileDelete }: FileSelectionProps) => {
  const theme = useMarkovTheme();

  return (
    <Horizontal p="md">
      <Horizontal spacing="sm">
        <IconFileText size={20} color={theme.colors.gray[7]} />
        <Text variant="subTitle03" color="gray.8" underline truncate>
          {file.name}
        </Text>
      </Horizontal>
      <ActionIcon onClick={onFileDelete} aria-label="Delete file" sx={{ marginLeft: 'auto' }}>
        <IconTrashX />
      </ActionIcon>
    </Horizontal>
  );
};
