import { IconArrowLeft } from '@tabler/icons-react';
import { useState } from 'react';
import { FileRejection, FileWithPath } from 'react-dropzone';
import { ulid } from 'ulid';
import {
  Alert,
  Button,
  Collapse,
  Flex,
  Horizontal,
  Spoiler,
  Text,
  TextInput,
  Vertical,
  closeAllModals,
  notifications,
  useDisclosure,
} from '@/shared/design-system/v2';
import { connectorsApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { FileData, useDataUpload } from '../../../../contexts/data-upload/DataUpload';
import {
  useGetPresignedUrlForBulkUploadMutation,
  useInvalidateDataSourcesList,
} from '../../../../queries/data-sources';
import { CompleteParams } from '../../../../queries/data-upload';
import { FileList } from './FileList';
import { Folder, FolderUploadInput } from './FolderUploadInput';

export type Step = 'upload' | 'preview';

const FOLDER_SPOILER_MAX_HEIGHT = 240;

interface ExisitingFolderUploaderProps {
  onFolderDrop?: (files: FileWithPath[]) => void;
  onUploadQueued: (uploadId?: string) => void;
  onUploadComplete: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  additionalData?: Record<string, unknown>;
  folderName: string;
  handleGoBack?: () => void;
}

export const ExistingFolderUploader = ({
  onFolderDrop,
  onUploadComplete,
  onUploadQueued,
  additionalData,
  folderName,
  handleGoBack,
}: ExisitingFolderUploaderProps) => {
  const { workspaceId } = useAppMetadata();
  const [folder, setFolder] = useState<Folder | undefined>(undefined);
  const [fileRejections, setFileRejections] = useState<FileRejection[] | undefined>(undefined);
  const [showUnsupportedFiles, { toggle: toggleShowUnsupportedFiles }] = useDisclosure(false);
  const { mutateAsync: getPresignedUrls, isLoading: loadingPresignedUrl } =
    useGetPresignedUrlForBulkUploadMutation();
  const [folderNameError, setFolderNameError] = useState('');
  const { addUpload } = useDataUpload();
  const invalidateDataSourcesList = useInvalidateDataSourcesList();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const handleFolderDrop = (
    newfolderName: string,
    files: FileWithPath[],
    fileRejections?: FileRejection[],
  ) => {
    setFolder({ name: newfolderName, files });
    if (fileRejections && fileRejections.length > 0) {
      setFileRejections(fileRejections);
      return;
    }

    setFileRejections(undefined);
    onFolderDrop?.(files);
  };

  const handleBack = () => {
    setFolder(undefined);
  };

  const handleUpload = async () => {
    if (!folder || !folder.files) {
      return;
    }

    const uploadId = ulid();

    addUpload(uploadId, folder.files, {
      useUploadWindow: true,
      onSuccess: () => {
        invalidateDataSourcesList();
        notifications.success('Folder upload completed successfully');
      },
      onError: error => {
        notifications.error(`Error uploading some files: ${error}`);
      },
      onFileUploadComplete: async (completeParams: CompleteParams) => {
        try {
          await connectorsApi.completeUploadResourceV2(workspaceId, {
            folderName: folderName,
            request: {
              uploadId: completeParams.uploadId,
              key: completeParams.key,
              fileName: completeParams.fileName,
              parts: completeParams.parts,
            },
          });
          onUploadComplete('success', completeParams);
        } catch (error) {
          notifications.error('Error completing file upload');
          onUploadComplete('error', completeParams);
          throw error;
        }
      },
      fetchPresignedUrls: async (files: File[]): Promise<FileData[]> => {
        const presignedUrls = await getPresignedUrls({
          files,
          folderName: folderName,
        });

        return files.map((file, index) => ({
          file,
          uploadParams: presignedUrls.response[index],
        }));
      },
      additionalData,
    });
    onUploadQueued(uploadId);
    closeAllModals();
  };

  if (folder?.files && folder.files.length > 0) {
    return (
      <Vertical>
        <Horizontal onClick={handleBack} spacing="sm" sx={{ cursor: 'pointer' }}>
          <IconArrowLeft />
          <Text>Back</Text>
        </Horizontal>
        <Vertical spacing="md">
          <TextInput
            label="Uploading to folder"
            value={folderName}
            ariaLabel="Folder name"
            error={folderNameError}
            disabled
          />

          <Spoiler maxHeight={FOLDER_SPOILER_MAX_HEIGHT} showLabel="Show More" hideLabel="Hide">
            <FileList files={folder.files} />
          </Spoiler>
          {errorMessage && (
            <Alert color="red" mt="md">
              {errorMessage}
            </Alert>
          )}
          {fileRejections && fileRejections.length > 0 && (
            <Alert color="red">
              <Text>
                Note: Some of the files added in folder are not supported. Only the supported files
                will be uploaded.
              </Text>
              <Button onClick={toggleShowUnsupportedFiles}>Show unsupported files</Button>
              <Collapse in={showUnsupportedFiles}>
                <FileList files={fileRejections.map(rejection => rejection.file)} areUnsupported />
              </Collapse>
            </Alert>
          )}
          <Flex mt="md" justify="end">
            <Button variant="primary" onClick={handleUpload} loading={loadingPresignedUrl}>
              Upload
            </Button>
          </Flex>
        </Vertical>
      </Vertical>
    );
  }

  const allFilesUnsupported =
    folder &&
    (!folder.files || folder.files.length === 0) &&
    fileRejections &&
    fileRejections.length > 0;

  return (
    <Vertical>
      <Horizontal onClick={handleGoBack} sx={{ cursor: 'pointer' }}>
        <IconArrowLeft />
        <Text>Back</Text>
      </Horizontal>
      <Text variant="subTitle03" color="gray.9">
        Add more files to folder
      </Text>
      <Alert>
        Note: Create a folder on your system with the files you want to add, then proceed with the
        upload.
      </Alert>
      <FolderUploadInput onFolderDrop={handleFolderDrop} />
      {allFilesUnsupported && (
        <Alert color="red">
          Selected folder does not have supported files. Please select folder with one of the
          supported files
        </Alert>
      )}
      <Text variant="bodyShort03" color="gray.7">
        Supported file formats: .csv/.tsv/.txt/.xml/.pdf/.jpeg/jpg/.png. File should be UTF-8
        encoded. Maximum file size: 5GB
      </Text>
    </Vertical>
  );
};
