import { PropsWithChildren } from 'react';
import { Badge, Horizontal } from '@/shared/design-system/v2';

interface TableRowNameProps {
  badgeColor?: string;
  isSampleRecord?: boolean;
  className?: string;
}

export const TableRowName = ({
  badgeColor = 'green.6',
  isSampleRecord,
  className,
  children,
}: PropsWithChildren<TableRowNameProps>): JSX.Element => (
  <Horizontal spacing="xs" noWrap h="24px" className={className}>
    {children}
    {isSampleRecord && (
      <Badge variant="filled" color={badgeColor}>
        Sample
      </Badge>
    )}
  </Horizontal>
);
