import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { datasetApi } from '@/shared/lib/api';
import { HTTPError } from '@/shared/lib/api/api';
import { modelAppsApi } from '@/shared/lib/api/reno';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  CreatePendingModelAppRequest,
  GetFileInferenceStatusResponse,
  GetModelsWithAppMetadataPaginated,
  InferenceServiceStatusResponse,
  InitInferenceRequest,
  ListModelAppsPaginatedResponse,
  ModelApp,
  ModelAppStatus,
  ModelInferenceRequest,
  ModelInferenceStatus,
  ModelWithAppMetadata,
  StartFileInferenceRequest,
} from '../../generated/api';
import { API_PAGE_SIZE } from '../constants';
import { dataAnalysisTaskKeys, modelAppsKeys, modelKeys } from '../queryConstants';
import { getNextPageParamHandler } from '../util';

export const useModelAppsListForDataViewQuery = (dataViewId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<ListModelAppsPaginatedResponse>, AxiosError<HTTPError>, ModelApp[]>(
    modelAppsKeys.listForDataView(workspaceId, dataViewId),
    () => modelAppsApi.getModelAppsListV2(workspaceId, undefined, undefined, dataViewId),
    {
      enabled: Boolean(workspaceId),
      select: data => data.data.response,
    },
  );
};

export const useModelAppsListInfiniteQuery = (filters = {}) => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery<
    AxiosResponse<GetModelsWithAppMetadataPaginated>,
    AxiosError,
    ModelWithAppMetadata[]
  >(
    modelAppsKeys.list(workspaceId, filters),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * API_PAGE_SIZE,
        end = start + API_PAGE_SIZE;
      return modelAppsApi.getModelAppsListV1(workspaceId, start, end, lastTimestamp);
    },
    {
      enabled: Boolean(workspaceId),
      getNextPageParam: getNextPageParamHandler(),
      refetchInterval: 30 * 1000,
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
    },
  );
};

export const useModelAppsPreviewQuery = (userId?: string) => {
  const { workspaceId } = useAppMetadata();
  const PAGINATION_START_INDEX = 0;
  const PAGINATION_END_INDEX = 4;

  const ts = undefined;
  const filters = {
    name: undefined,
    userIds: userId ? [userId] : undefined,
  };

  return useQuery<
    AxiosResponse<GetModelsWithAppMetadataPaginated>,
    AxiosError,
    ModelWithAppMetadata[]
  >(
    modelAppsKeys.listPreview(workspaceId, userId),
    () =>
      modelAppsApi.getModelAppsListV1(
        workspaceId,
        PAGINATION_START_INDEX,
        PAGINATION_END_INDEX,
        ts,
        filters.name,
        filters.userIds,
      ),
    {
      enabled: !!workspaceId,
      refetchInterval: 30 * 1000,
      select: response => response.data.response,
    },
  );
};

export const useModelAppDetailsQuery = ({
  modelId,
  datasetId,
}: {
  modelId?: string;
  datasetId?: string;
}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    modelAppsKeys.detail(workspaceId, modelId ?? '', datasetId ?? ''),
    () => modelAppsApi.getModelAppMetadataV1(workspaceId, modelId, datasetId),
    {
      enabled: Boolean((modelId || datasetId) && workspaceId),
      select: data => data.data,
    },
  );
};

export const useGenerateModelAppFromModel = (modelId: string, projectId: string) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: InitInferenceRequest) => modelAppsApi.initialiseInferenceServiceV1(workspaceId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(modelAppsKeys.list(workspaceId));
        queryClient.invalidateQueries(modelKeys.list(workspaceId, userId));
        queryClient.invalidateQueries(modelKeys.detail(workspaceId, userId, projectId, modelId));
        queryClient.invalidateQueries(modelAppsKeys.detail(workspaceId, modelId, ''));
      },
    },
  );
};

export const useGenerateModelAppFromDataset = (datasetId: string) => {
  const { workspaceId, userId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: CreatePendingModelAppRequest) => modelAppsApi.createModelAppPendingV1(workspaceId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(dataAnalysisTaskKeys.list(workspaceId, datasetId));
        queryClient.invalidateQueries(modelAppsKeys.list(workspaceId));
        queryClient.invalidateQueries(modelKeys.list(workspaceId, userId));
        queryClient.invalidateQueries(modelAppsKeys.detail(workspaceId, '', datasetId));
      },
    },
  );
};

export const useGetModelAppStatus = (modelId: string, enabled = false) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery<
    AxiosResponse<InferenceServiceStatusResponse>,
    AxiosError,
    InferenceServiceStatusResponse
  >(
    modelAppsKeys.status(workspaceId, modelId),
    () => modelAppsApi.inferenceServiceStatusV1(workspaceId, modelId, ''),
    {
      enabled: Boolean(modelId && workspaceId && enabled),
      refetchInterval: 10 * 1000,
      // We want the latest status of the app
      staleTime: 0,
      select: data => data.data,
      onSuccess: response => {
        if (
          response.status === ModelAppStatus.Running ||
          response.status === ModelAppStatus.Stopped
        ) {
          queryClient.invalidateQueries(modelAppsKeys.list(workspaceId));
          queryClient.invalidateQueries(modelAppsKeys.detail(workspaceId, modelId, ''));
        }
      },
    },
  );
};

export const useSubmitModelInput = (modelId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: ModelInferenceRequest) => modelAppsApi.getInferenceV2(workspaceId, modelId, req),
    {
      onSuccess: response => {
        if (response.data.status !== ModelAppStatus.Running) {
          queryClient.invalidateQueries(modelAppsKeys.list(workspaceId));
          queryClient.invalidateQueries(modelAppsKeys.detail(workspaceId, modelId, ''));
        }
      },
    },
  );
};

export const useGetBaselineModelStatus = (datasetId = '') => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    modelAppsKeys.baselineTaskletStatus(workspaceId, datasetId),
    () => datasetApi.workspaceGetBaselineModelTaskletStatusV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      staleTime: 0,
      select: data => data.data.baselineModelTaskletStatus,
    },
  );
};

export const useInvalidateBaselineModelStatus = (datasetId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return () =>
    queryClient.invalidateQueries(modelAppsKeys.baselineTaskletStatus(workspaceId, datasetId));
};

export const useInvalidateModelApps = (modelId = '', datasetId = '') => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useCallback(() => {
    queryClient.invalidateQueries(modelAppsKeys.list(workspaceId));
    modelAppsKeys.status(workspaceId, modelId);
    queryClient.invalidateQueries(modelAppsKeys.detail(workspaceId, modelId, datasetId));
  }, [datasetId, modelId, queryClient, workspaceId]);
};

export const useGetInputFileUri = (modelId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation(() => modelAppsApi.getInputFileUriV1(workspaceId, modelId));
};

export const useStartInference = (modelId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (request: StartFileInferenceRequest) =>
      modelAppsApi.startFileInferenceV1(workspaceId, modelId, request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(modelAppsKeys.fileStatus(workspaceId, modelId));
      },
    },
  );
};

export const useGetModelAppFileStatus = (modelId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery<
    AxiosResponse<GetFileInferenceStatusResponse>,
    AxiosError,
    GetFileInferenceStatusResponse
  >(
    modelAppsKeys.fileStatus(workspaceId, modelId),
    () => modelAppsApi.getFileInferenceStatusV1(workspaceId, modelId, 'latest'),
    {
      enabled: Boolean(modelId && workspaceId),
      refetchInterval: data => {
        const isLoading =
          data?.status === ModelInferenceStatus.Pending ||
          data?.status === ModelInferenceStatus.Running;
        return isLoading ? 5 * 1000 : false;
      },
      // We want the latest status of the file
      staleTime: 0,
      select: data => data.data,
      onSuccess: response => {
        if (response.status === ModelInferenceStatus.Success) {
          queryClient.invalidateQueries(modelAppsKeys.getFileInferenceResult(workspaceId, modelId));
        }
      },
    },
  );
};

export const useGetModelAppFileInferenceResult = (modelId: string, inferenceId?: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    modelAppsKeys.getFileInferenceResult(workspaceId, modelId),
    () => modelAppsApi.getFileInferenceResultV2(workspaceId, modelId, inferenceId ?? 'latest'),
    {
      select: data => data.data,
      enabled: Boolean(modelId && workspaceId),
    },
  );
};
