import { SelectItem } from '@/shared/design-system/v2';
import { Node } from '../../../../../generated/api';

export enum NodeParameterType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  DROPDOWN = 'dropdown',
  MULTISELECT = 'multiselect',
  LIST = 'list',
}

export interface CommonParameterTypeProps {
  name: string;
  label: string;
  type: NodeParameterType;
  description: string;
  required?: boolean;
}

export interface StringParameterTypeProps extends CommonParameterTypeProps {
  default?: string;
}

export interface BooleanParameterTypeProps extends CommonParameterTypeProps {
  default?: boolean;
}

export interface DropdownParameterTypeProps extends CommonParameterTypeProps {
  options: (string | SelectItem)[];
  default?: string;
}

export interface MultiSelectParameterTypeProps extends CommonParameterTypeProps {
  default?: string;
  itemType: string;
}

export type PrimitiveParameterProps =
  | StringParameterTypeProps
  | BooleanParameterTypeProps
  | DropdownParameterTypeProps
  | MultiSelectParameterTypeProps;

export interface ListParameterTypeProps extends CommonParameterTypeProps {
  default?: object[];
  items: (PrimitiveParameterProps | ListParameterTypeProps)[];
}

export type AllParameterTypeProps = PrimitiveParameterProps | ListParameterTypeProps;

export type FormDataTypes = string | string[] | boolean | object[] | null | undefined;

export const getDataTypesFromConfiguration = (initialValue: FormDataTypes) => ({
  [NodeParameterType.STRING]: initialValue ?? '',
  [NodeParameterType.BOOLEAN]: initialValue ?? false,
  [NodeParameterType.DROPDOWN]: initialValue ?? undefined,
  [NodeParameterType.MULTISELECT]: initialValue ?? [],
  [NodeParameterType.LIST]: initialValue ?? [],
});

export interface SourceNodeConfiguration {
  resource_id?: string;
}

export const getResourceIdForSourceNode = (node: Node) => {
  const resourceId = (node.data.configuration as SourceNodeConfiguration).resource_id;
  return resourceId;
};

export const generateInitialState = (
  configurations: AllParameterTypeProps[],
  initialState: Record<string, FormDataTypes>,
) =>
  configurations.reduce((acc, { name, default: defaultValue, type }) => {
    acc[name] = getDataTypesFromConfiguration(initialState[name] ?? defaultValue)[type];
    return acc;
  }, {} as Record<string, FormDataTypes>);

export const mailToSupport = (subject: string) =>
  `mailto:support@markovml.com?subject=${subject}&body=Please describe your issue:`;
