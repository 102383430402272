import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Text, TextProps } from '@/shared/design-system/v2';

interface TextLinkProps extends TextProps {
  to: string;
  state?: unknown;
}

export const TextLink = ({
  to,
  state,
  children,
  ...textProps
}: PropsWithChildren<TextLinkProps>): JSX.Element => (
  // TODO: Use Link as polymorphic component
  <Link to={to} style={{ textDecoration: 'none', cursor: 'pointer' }} state={state}>
    <Text span variant="bodyShort02" color="blue.9" {...textProps}>
      {children}
    </Text>
  </Link>
);
