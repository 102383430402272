import { CopyToClipboardTooltip } from '@/main/components/common/CopyToClipboardTooltip';
import { Box, EllipsisText, Horizontal, useHover } from '@/shared/design-system/v2';
import { AUDIO_URL_REGEX } from '../../../../utils';
import { PreviewAudioButton } from './PreviewAudioButton';

interface DefaultCellProps {
  value: string;
}

export const DefaultCell = ({ value }: DefaultCellProps): JSX.Element => {
  const { hovered, ref } = useHover();

  const isAudioFileUrl = AUDIO_URL_REGEX.test(value);

  return (
    <Horizontal ref={ref} noWrap spacing="xs" h="100%">
      <EllipsisText variant="bodyLong02" closeDelay={100} pt={0}>
        {value}
      </EllipsisText>
      {isAudioFileUrl ? (
        <Box>
          <PreviewAudioButton srcUrl={value} />
        </Box>
      ) : hovered ? (
        <CopyToClipboardTooltip valueToCopy={value} />
      ) : null}
    </Horizontal>
  );
};
