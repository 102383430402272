import { IconBell } from '@tabler/icons-react';
import { Indicator, Text, Tooltip, useMarkovTheme } from '@/shared/design-system/v2';
import { useGetUnreadNotificationsCountQuery } from '../../../queries/notifications/in-app-notifications';

export const NotificationBell = () => {
  const { data } = useGetUnreadNotificationsCountQuery();

  const countUnread = data ?? 0;
  const theme = useMarkovTheme();

  return (
    <Tooltip offset={12} label="Notifications" position="bottom-end">
      <Indicator
        offset={10}
        label={
          <Text align="center" variant="small01" pb={3}>
            {countUnread > 0 && countUnread < 10 ? countUnread : '+9'}
          </Text>
        }
        disabled={countUnread <= 0}
        color={theme.colors.red[6]}
        size="18px"
        position="top-end"
      >
        <IconBell
          color={theme.colors.gray[6]}
          style={{ height: 24, width: 26, marginTop: 5 }}
          strokeWidth="1.5px"
        />
      </Indicator>
    </Tooltip>
  );
};
