import Clarity from '@microsoft/clarity';

export const initClarity = (projectId: string) => {
  Clarity.init(projectId);
};

export const identifyClarityUser = (userId: string, userName: string) => {
  try {
    Clarity.identify(userId, '', '', userName);
  } catch (error) {
    // Do nothing. Clarity throws an error when it is not initialized.
  }
};
