import { useNavigate } from 'react-router-dom';
import { FullPageLoader } from '@/shared/design-system';
import { notifications } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { AppBuilder } from '../../../generated/api';
import {
  usePublishAppFromAppBuilder,
  useUpdateAppBuilderMutation,
} from '../../../queries/app-builder/app-builder';
import {
  ChatResourceTypes,
  useArchiveThreadsByResourceMutation,
  useChatWithResourceThreadsListQuery,
} from '../../../queries/chat-with-data/chat';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { AppBuilderDetailsHeader } from './AppBuilderDetailsHeader';

export interface AppBuilderDetailsHeaderContainerProps {
  appId: string;
  appBuilderDetails: AppBuilder;
  isLoadingAppBuilderDetails: boolean;
  isPromptEmpty: boolean;
  isSavingChanges: boolean;
  hasAppPropertiesChanged: boolean;
}

export const AppBuilderDetailsHeaderContainer = ({
  appId,
  appBuilderDetails,
  isLoadingAppBuilderDetails,
  isPromptEmpty,
  isSavingChanges,
  hasAppPropertiesChanged,
}: AppBuilderDetailsHeaderContainerProps) => {
  const { getAiAppsRoute } = useAbsoluteRoutes();
  const navigate = useNavigate();

  const { isLoading: isLoadingThreads, data: threads } = useChatWithResourceThreadsListQuery(
    appId,
    ChatResourceTypes.CUSTOM_APP,
  );

  const { isLoading: isArchiving, mutateAsync: archiveThread } =
    useArchiveThreadsByResourceMutation(appId, ChatResourceTypes.CUSTOM_APP);

  const { isLoading: isPublishingApp, mutateAsync: publishApp } = usePublishAppFromAppBuilder(
    appId,
    appBuilderDetails.appStatus,
  );

  const { mutateAsync: updateAppBuilder } = useUpdateAppBuilderMutation(appId);

  const updateAppName = (appName: string) => updateAppBuilder({ ...appBuilderDetails, appName });

  const updateAppDescription = (appDescription: string) =>
    updateAppBuilder({ ...appBuilderDetails, appDescription });

  const handlePublishApp = async () => {
    const allThreads =
      threads?.pages?.flatMap?.(page => page?.response?.map?.(thread => thread?.threadId)) ?? [];
    try {
      if (allThreads.length > 0) {
        // Archive all the threads when an app is published. This has to change.
        // We will ideally want to delete the active thread used for testing the application
        await archiveThread(allThreads);
      }
      await publishApp();
      notifications.success('App published successfully to App Store');
      navigate(getAiAppsRoute());
    } catch (error) {
      logger.error(error);
      const errorMessage =
        'There was an error while publishing the application. Please try again or contact support';
      notifications.error(errorMessage);
    }
  };

  if (isArchiving || isPublishingApp) return <FullPageLoader text="Publishing your app" />;

  return (
    <AppBuilderDetailsHeader
      appBuilderDetails={appBuilderDetails}
      onPublish={handlePublishApp}
      onAppDescriptionUpdate={updateAppDescription}
      isLoading={isLoadingAppBuilderDetails}
      isPublishDisabled={isPromptEmpty || isLoadingThreads || hasAppPropertiesChanged}
      onAppNameUpdate={updateAppName}
      isSavingChanges={isSavingChanges}
    />
  );
};
