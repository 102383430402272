import {
  HorizontalLayout,
  LayoutProps,
  RankedTester,
  getAjv,
  getData,
  isVisible,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';
import { JsonFormsDispatch, useJsonForms, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Box, Horizontal } from '@/shared/design-system/v2';

export const HorizontalLayoutComponent = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
  direction,
  label,
}: LayoutProps) => {
  const ctx = useJsonForms();

  if (!visible) {
    return null;
  }

  const horizontalLayout = uischema as HorizontalLayout;

  return (
    <Horizontal sx={{ alignItems: 'flex-end' }}>
      {horizontalLayout.elements.map((element, idx) => {
        const flex = element.options?.flex;
        if (!isVisible(element, getData({ jsonforms: ctx }), '', getAjv({ jsonforms: ctx }))) {
          return null;
        }
        return (
          <Box key={`${path}_${idx}`} sx={{ flex: flex ?? 1 }}>
            <JsonFormsDispatch
              uischema={element}
              schema={schema}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
            />
          </Box>
        );
      })}
    </Horizontal>
  );
};

export const HorizontalLayoutControl = withJsonFormsLayoutProps(HorizontalLayoutComponent);

export const horizontalControlTester: RankedTester = rankWith(1, uiTypeIs('HorizontalLayout'));
