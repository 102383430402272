import { useEffect, useState } from 'react';
import { FullPageLoader } from '@/shared/design-system';
import {
  Center,
  CloseButton,
  Horizontal,
  Loader,
  Text,
  Vertical,
  closeAllModals,
} from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { parseCSV } from '@/shared/lib/fileparse';
import { useDatasetDetailsQuery } from '../../../../../queries/datasets/list';
import { ErrorState } from '../../../../common/ErrorState';
import { FeatureSelection, HEIGHT } from './FeatureSelection';
import { ModelFileInput } from './ModelAppInputFile';
import { useUploadAndStartFileInference } from './use-upload-and-start-file-inference';

interface ModelFileInputModalContainerProps {
  datasetId: string;
  modelId: string;
}

export const ModelFileInputModalContainer = ({
  datasetId,
  modelId,
}: ModelFileInputModalContainerProps) => {
  const [inputFile, setInputFile] = useState<File>();
  const [isDataParsing, setIsDataParsing] = useState(true);
  const [isParsingError, setIsParsingError] = useState(false);
  const [fileResult, setFileResult] = useState<string[][]>();

  const { data, isLoading, isError } = useDatasetDetailsQuery(datasetId);
  const { mutate, isLoading: isStartingFileInference } = useUploadAndStartFileInference(modelId);

  const isFileAdded = Boolean(inputFile);
  const datasetFeatures = data?.xColNames ?? [];

  const handleCloseModal = () => {
    closeAllModals();
  };

  const handleUploadNewFile = () => {
    setInputFile(undefined);
  };

  const handleSubmitFile = (featureColumns: string[]) => {
    if (!inputFile) {
      return;
    }
    mutate(
      { file: inputFile, featureColumns },
      {
        onSuccess: () => {
          closeAllModals();
        },
      },
    );
  };

  let content = null;

  if (!isFileAdded) {
    content = <ModelFileInput setInputFile={setInputFile} />;
  } else if (isLoading || isDataParsing) {
    content = (
      <Center h={HEIGHT} w={2 * HEIGHT}>
        <Loader text="Parsing input file..." />
      </Center>
    );
  } else if (!fileResult || isError || isParsingError) {
    content = (
      <Center h={HEIGHT} w={2 * HEIGHT}>
        <ErrorState title="" subtitle="Error parsing file" />
      </Center>
    );
  } else if (fileResult) {
    content = (
      <FeatureSelection
        datasetFeatures={datasetFeatures}
        fileResult={fileResult}
        uploadNewFile={handleUploadNewFile}
        submitFile={handleSubmitFile}
      />
    );
  }

  useEffect(() => {
    if (inputFile) {
      parseCSV(inputFile, ',')
        .then((result: string[][]) => {
          setFileResult(result);
          setIsParsingError(false);
        })
        .catch(err => {
          setIsParsingError(true);
          logger.error(err);
        })
        .finally(() => {
          setIsDataParsing(false);
        });
    }
  }, [inputFile]);

  return (
    <Vertical spacing="xl">
      {isStartingFileInference && (
        <FullPageLoader text="Uploading file and starting inference..." />
      )}
      <Horizontal noWrap position="apart">
        <Vertical spacing={0}>
          <Text variant="subTitle02" color="gray.9">
            {!isFileAdded ? 'Upload File' : 'Feature Columns'}
          </Text>
          <Text variant="bodyShort02" color="gray.6">
            {!isFileAdded
              ? 'Model app will then process the file, classifying the records accordingly in its response.'
              : 'The following feature columns were identified in the uploaded document'}
          </Text>
        </Vertical>
        <CloseButton onClick={handleCloseModal} />
      </Horizontal>

      {content}
    </Vertical>
  );
};
