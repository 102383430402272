import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { logger } from '@/shared/initializers/logging';
import {
  datasetOperationApi,
  datasetOperationRulesApi,
  ruleBasedRelabelingApi,
} from '@/shared/lib/api';
import { toSentenceCase } from '@/shared/lib/ui';
import { DEFAULT_CONDITION } from '../../../components/dataset-details/rule-relabeling/EditLabelRule';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  AnalysisStateNames,
  CreateDataOperationRequest,
  CreateNewVersionDatasetRequest,
  DataOperationStateModel,
  DataOperationType,
  DatasetOperationDetailsResponse,
  DatasetOperationModel,
  DatasetOperationState,
  DatasetPreviewData,
  FullAnalysisDetailsModel,
  GetDatasetPreviewDataResponse,
  LabelingRuleBody,
  ListDataOperationsResponse,
  ListRulesResponse,
  LogicalOperator,
  ManualRelabelingDetailsModel,
  ManualRelabelingState,
  Rule,
  RuleApplicationResponse,
  RuleApplicationResult,
  RuleBasedRelabelingDetailsModel,
  RuleCoverageSummary,
  RuleCoverageSummaryResponse,
  RuleState,
  RulesWithOrder,
  SubsetRelabelingDetailsModel,
} from '../../../generated/api';
import { dataLabelingQueryKeys } from '../../data-labeling/list/list';
import { useFullDataAnalysisTasksQuery } from './full-analysis';

// TODO: Move Rule-based Relabeling queries out to separate module

export const workflowsKeys = {
  all: ['workflows'] as const,
  lists: () => [...workflowsKeys.all, 'lists'] as const,
  list: (workspaceId: string, datasetId: string, filters = {}) =>
    [...workflowsKeys.lists(), 'default', workspaceId, datasetId, { filters }] as const,
  detail: (workspaceId: string, datasetId: string, workflowId: string) =>
    [...workflowsKeys.all, 'detail', workspaceId, datasetId, workflowId] as const,
  status: (workspaceId: string, datasetId: string, workflowId: string) =>
    [...workflowsKeys.all, 'status', workspaceId, datasetId, workflowId] as const,
  coverageSummary: (workspaceId: string, datasetId: string, workflowId: string) =>
    [...workflowsKeys.all, 'coverage-summary', workspaceId, datasetId, workflowId] as const,
  rules: (workspaceId: string, datasetId: string, workflowId: string) =>
    [...workflowsKeys.all, 'rules', workspaceId, datasetId, workflowId] as const,
  previewDataSample: (workspaceId: string, datasetId: string, workflowId: string) =>
    [...workflowsKeys.all, 'preview-data-sample', workspaceId, datasetId, workflowId] as const,
  previewRulesResults: (workspaceId: string, datasetId: string, workflowId: string) =>
    [...workflowsKeys.all, 'preview-rules-results', workspaceId, datasetId, workflowId] as const,
};

// TODO: Remove these mappings after migrating to new API
const getWorkflowStateFromAnalysis = (analysisState: AnalysisStateNames) => {
  switch (analysisState) {
    case AnalysisStateNames.NotSupported:
    case AnalysisStateNames.Disabled:
    case AnalysisStateNames.NotStarted:
      return DatasetOperationState.NotStarted;

    case AnalysisStateNames.Created:
    case AnalysisStateNames.InProgress:
    case AnalysisStateNames.ResultsAvailable:
      return DatasetOperationState.Ongoing;

    case AnalysisStateNames.Error:
      return DatasetOperationState.Failed;

    case AnalysisStateNames.Completed:
      return DatasetOperationState.Completed;

    case AnalysisStateNames.Stopped:
      return DatasetOperationState.Stopped;
    default:
      throw new Error('Unexpected state encountered');
  }
};

const getOperationDisplayName = (operationType: DataOperationType) => {
  switch (operationType) {
    case DataOperationType.RuleBasedRelabeling:
      return 'Rule-based Relabeling';
    case DataOperationType.SubsetRelabeling:
      return 'Auto-Labeling';
    case DataOperationType.ManualRelabeling:
      return toSentenceCase(operationType);
    default:
      throw new Error('Unexpected operation type encountered');
  }
};

export const useDataWorkflowsQuery = (datasetId: string, filters = {}) => {
  const { workspaceId } = useAppMetadata();

  // TODO: Use infinite query
  return useQuery<AxiosResponse<ListDataOperationsResponse>, AxiosError, DatasetOperationModel[]>(
    workflowsKeys.list(workspaceId, datasetId, filters),
    () =>
      // TODO: Pass filters for workflow type and status once BE support is added
      datasetOperationApi.listDataOperationsV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      select: res => res.data.response,
    },
  );
};

interface WorkflowDetail {
  type: DataOperationType;
  workflowId?: string;
  name: string;
  createDate?: string;
  state?: DatasetOperationState;
  analysisDetails?: {
    taskId: string;
    taskletId: string;
    segmentType: string;
  };
}

// Combine data from full analysis workflows and rule-based/recommendation-based relabeling
// TODO: Read this from separate endpoint on BE (see useDataWorkflowsQuery).
// First, API response needs more details for generating download link for full analysis
export const useDataWorkflowsQueryDeprecated = (datasetId: string) => {
  // TODO: This is stitched together temporarily. This hook will be replaced once API is finished.
  const fullAnalysisQuery = useFullDataAnalysisTasksQuery(datasetId);
  const dataOperationsQuery = useDataWorkflowsQuery(datasetId);

  const fullAnalysisData: WorkflowDetail[] =
    fullAnalysisQuery.data?.map(
      ({ createDate, taskletName, status, taskId, taskletId, segment }) => ({
        type: DataOperationType.FullAnalysis,
        name: taskletName,
        createDate,
        state: getWorkflowStateFromAnalysis(status),
        analysisDetails: { taskId, taskletId, segmentType: segment },
      }),
    ) ?? [];

  const dataOperationsData: WorkflowDetail[] =
    dataOperationsQuery.data?.map(operation => ({
      type: operation.type,
      workflowId: operation.dataOperationId,
      name: operation.name || getOperationDisplayName(operation.type),
      createDate: operation.createDate,
      state: operation.state,
    })) ?? [];

  const data = [...dataOperationsData, ...fullAnalysisData];

  return {
    ...fullAnalysisQuery,
    isLoading: fullAnalysisQuery.isLoading || dataOperationsQuery.isLoading,
    isError: fullAnalysisQuery.isError || dataOperationsQuery.isError,
    data,
  };
};

export const useDataWorkflowDetailsQuery = (datasetId: string, workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<DatasetOperationDetailsResponse>,
    AxiosError,
    | RuleBasedRelabelingDetailsModel
    | ManualRelabelingDetailsModel
    | FullAnalysisDetailsModel
    | SubsetRelabelingDetailsModel
  >(
    workflowsKeys.detail(workspaceId, datasetId, workflowId),
    () => datasetOperationApi.getDataOperationDetailsV1(workspaceId, datasetId, workflowId),
    {
      enabled: Boolean(workspaceId && datasetId && workflowId),
      select: res => res.data.response,
    },
  );
};

export const useCreateDataOperationMutation = (datasetId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (req: CreateDataOperationRequest) =>
      datasetOperationApi.createDataOperationsV1(workspaceId, datasetId, req),
    {
      onError: (error: AxiosError) => {
        logger.error(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(workflowsKeys.lists());
        queryClient.invalidateQueries(dataLabelingQueryKeys.all);
      },
    },
  );
};

export const useWorkflowStatusQuery = (datasetId: string, workflowId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<DataOperationStateModel>, AxiosError, DataOperationStateModel>(
    workflowsKeys.status(workspaceId, datasetId, workflowId),
    () => datasetOperationApi.getDataOperationsStatusV1(workspaceId, datasetId, workflowId),
    {
      enabled: Boolean(workspaceId && datasetId && workflowId),
      select: res => res.data,
      refetchInterval: data => {
        const shouldRefetch =
          // Refetch/poll if state is Processing (will become Completed/Failed)...
          data?.state === DatasetOperationState.Processing ||
          // ...or if the operation has started but data is still being prepared
          (data?.state === DatasetOperationState.Ongoing &&
            data?.stateDetail === ManualRelabelingState.Preparing);
        return shouldRefetch ? 5 * 1000 : false;
      },
      // Refetch the workflow details when status changes from "Processing" to "Completed".
      // The workflow details response should now contain a derivedDatasetId field.
      onSuccess: () => {
        queryClient.invalidateQueries(workflowsKeys.detail(workspaceId, datasetId, workflowId));
      },
    },
  );
};

export const useRuleCoverageSummaryQuery = (datasetId: string, workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<RuleCoverageSummaryResponse>, AxiosError, RuleCoverageSummary>(
    workflowsKeys.coverageSummary(workspaceId, datasetId, workflowId),
    () => ruleBasedRelabelingApi.ruleCoverageSummaryV1(workspaceId, datasetId, workflowId),
    {
      enabled: Boolean(workspaceId && datasetId && workflowId),
      select: res => res.data.response,
    },
  );
};

export const useRelabelingRulesQuery = (datasetId: string, workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<ListRulesResponse>, AxiosError, RulesWithOrder>(
    workflowsKeys.rules(workspaceId, datasetId, workflowId),
    () => datasetOperationRulesApi.listRelabelingRulesV1(workspaceId, datasetId, workflowId),
    {
      enabled: Boolean(workspaceId && datasetId && workflowId),
      select: res => res.data.response,
    },
  );
};

// This is not a separate endpoint in Vienna as of now
export const useRelabelingRuleQuery = (datasetId: string, workflowId: string, ruleId: string) => {
  const rulesQuery = useRelabelingRulesQuery(datasetId, workflowId);

  const rules = rulesQuery.data?.rules ?? [];
  const data = rules.find(rule => rule.ruleId === ruleId);

  return {
    ...rulesQuery,
    data,
  };
};

interface CreateRuleRequest {
  label: string;
}

export const useCreateRuleMutation = (datasetId: string, workflowId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    ({ label }: CreateRuleRequest) => {
      const ruleBody: LabelingRuleBody = {
        operator: LogicalOperator.And,
        conditions: [DEFAULT_CONDITION],
        targetValue: label,
        state: RuleState.Draft,
      };
      return datasetOperationRulesApi.createDataOperationsRuleV1(
        workspaceId,
        datasetId,
        workflowId,
        {
          ruleBody,
        },
      );
    },
    {
      onError: (error: AxiosError) => {
        logger.error(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(workflowsKeys.rules(workspaceId, datasetId, workflowId));
      },
    },
  );
};

// TODO: Change this hook name to reference saving draft specifically
export const useUpdateRuleMutation = (datasetId: string, workflowId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (rule: Rule) =>
      datasetOperationRulesApi.updateRelabelingRuleV1(workspaceId, datasetId, workflowId, { rule }),
    {
      onError: (error: AxiosError) => {
        logger.error(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(workflowsKeys.rules(workspaceId, datasetId, workflowId));
      },
    },
  );
};

export const useSaveRuleMutation = (datasetId: string, workflowId: string, ruleId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (ruleBody: Omit<LabelingRuleBody, 'state'>) => {
      const rule: Rule = {
        ruleId,
        dataOperationId: workflowId,
        ruleBody: { ...ruleBody, state: RuleState.Active },
      };

      return datasetOperationRulesApi.updateRelabelingRuleV1(workspaceId, datasetId, workflowId, {
        rule,
      });
    },
    {
      onError: (error: AxiosError) => {
        logger.error(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          workflowsKeys.coverageSummary(workspaceId, datasetId, workflowId),
        );
        queryClient.invalidateQueries(workflowsKeys.rules(workspaceId, datasetId, workflowId));
        queryClient.invalidateQueries(
          workflowsKeys.previewRulesResults(workspaceId, datasetId, workflowId),
        );
      },
    },
  );
};

// TODO: Update this to new endpoint
// export const useCheckConflictMutation = (datasetId: string, workflowId: string, ruleId: string) => {
//   const { workspaceId } = useAppMetadata();

//   return useMutation(
//     () => datasetWorkflowsApi.checkRuleConflictV1(workspaceId, datasetId, ruleId, workflowId),
//     {
//       onError: (error: AxiosError) => {
//         logger.error(error);
//       },
//     },
//   );
// };

export const useReorderRulesMutation = (datasetId: string, workflowId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (orderedRuleIds: string[]) =>
      datasetOperationRulesApi.reorderDataOperationsRulesV1(workspaceId, datasetId, workflowId, {
        updatedOrder: orderedRuleIds,
      }),
    {
      onError: (error: AxiosError) => {
        logger.error(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(workflowsKeys.rules(workspaceId, datasetId, workflowId));
      },
    },
  );
};

export const usePreviewDataWithRulesAppliedQuery = (datasetId: string, workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<RuleApplicationResponse>, AxiosError, RuleApplicationResult[]>(
    workflowsKeys.previewRulesResults(workspaceId, datasetId, workflowId),
    () => ruleBasedRelabelingApi.applyRelabelingRulesV1(workspaceId, datasetId, workflowId),
    {
      enabled: Boolean(workspaceId && datasetId && workflowId),
      select: res => res.data.response,
    },
  );
};

export const useDatasetSampleQuery = (datasetId: string, workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetDatasetPreviewDataResponse>, AxiosError, DatasetPreviewData>(
    workflowsKeys.previewDataSample(workspaceId, datasetId, workflowId),
    () =>
      datasetOperationApi.generateDatasetSampleForDataOperationsV1(
        workspaceId,
        datasetId,
        workflowId,
      ),
    {
      enabled: Boolean(workspaceId && datasetId && workflowId),
      select: res => res.data.response,
    },
  );
};

export const useRegisterNewDatasetMutation = (datasetId: string, workflowId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return useMutation(
    (req: CreateNewVersionDatasetRequest) =>
      ruleBasedRelabelingApi.createNewDatasetRuleRelabelingV1(
        workspaceId,
        datasetId,
        workflowId,
        req,
      ),
    {
      onError: (error: AxiosError) => {
        logger.error(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(workflowsKeys.status(workspaceId, datasetId, workflowId));
      },
    },
  );
};
