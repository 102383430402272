import { useFlags } from 'launchdarkly-react-client-sdk';
import { Drawer, Tabs } from '@/shared/design-system/v2';
import { AddChartsContextProvider } from '../../../../contexts/snippets/add-charts/AddChartsContext';
import { TableContextProvider } from '../../../../contexts/table/TableContext';
import { useSnippetDetail } from '../context/SnippetContext';
import { SelectedDatasetsHeader } from './datasets/SelectDatasetsHeader';
import { SelectDatasetsListContainer } from './datasets/SelectDatasetsList.container';
import { EvaluationsChartsContainer } from './evaluations/EvaluationsCharts.container';
import { EvaluationsHeader } from './evaluations/EvaluationsHeader';
import { ExperimentsChartsContainer } from './experiments/ExperimentsCharts.container';
import { Header } from './header/Header';
import { ModelAppsListWrapper } from './model-apps/ModelAppsList.wrapper';

export enum TabsTypes {
  Dataset = 'Datasets',
  Experiments = 'Experiments',
  Evaluations = 'Evaluations',
  ModelApps = 'Model Apps',
}

const useTabs = () => {
  const { featureModelStudio } = useFlags();

  const TABS = [
    {
      name: TabsTypes.Dataset,
      component: (
        <TableContextProvider>
          <SelectDatasetsListContainer />
        </TableContextProvider>
      ),
      headerComponent: <SelectedDatasetsHeader />,
    },
    {
      name: TabsTypes.Experiments,
      component: <ExperimentsChartsContainer />,
      visible: featureModelStudio,
    },
    {
      name: TabsTypes.Evaluations,
      component: <EvaluationsChartsContainer />,
      headerComponent: <EvaluationsHeader />,
      visible: featureModelStudio,
    },
    {
      name: TabsTypes.ModelApps,
      component: <ModelAppsListWrapper />,
    },
  ];

  return TABS.filter(({ visible = true }) => visible);
};

export const AddChartsDrawer = (): JSX.Element => {
  const { addChartsOpen, closeAddCharts } = useSnippetDetail();
  const tabs = useTabs();

  return (
    <Drawer.Root
      opened={Boolean(addChartsOpen)}
      onClose={closeAddCharts}
      position="right"
      size="xl"
      padding={0}
    >
      <Drawer.Overlay />
      <Drawer.Content style={{ display: 'inline-table' }}>
        <Drawer.CloseButton
          onClick={closeAddCharts}
          pos="absolute"
          top={12}
          left={-38}
          bg="white.0"
          color="dark"
          size="md"
          sx={{ zIndex: 200 }}
        />
        <Drawer.Body>
          <AddChartsContextProvider>
            <Tabs defaultValue={addChartsOpen} keepMounted={false}>
              <Header tabs={tabs.map(({ name }) => name)}>
                {tabs.map(({ name, headerComponent = null }) => (
                  <Tabs.Panel key={`header-${name}`} value={name}>
                    {headerComponent}
                  </Tabs.Panel>
                ))}
              </Header>
              {tabs.map(({ name, component }) => (
                <Tabs.Panel key={`panel-${name}`} value={name}>
                  {component}
                </Tabs.Panel>
              ))}
            </Tabs>
          </AddChartsContextProvider>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
