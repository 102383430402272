import { Sparkles } from '@/shared/design-system';
import {
  Box,
  Horizontal,
  Skeleton,
  Text,
  Vertical,
  useMarkovTheme,
} from '@/shared/design-system/v2';

const GenerateSkeletonLoader = () => {
  const theme = useMarkovTheme();

  return (
    <Horizontal w="100%" align="flex-start" noWrap>
      <Sparkles />
      <Box w="100%" h="100%" style={{ borderRadius: theme.radius.sm }}>
        <Text variant="bodyShort01">Generating Response...</Text>
        <Vertical mt={16}>
          <Skeleton h={12} />
          <Skeleton h={12} />
          <Skeleton h={12} w="50%" />
        </Vertical>
      </Box>
    </Horizontal>
  );
};

export default GenerateSkeletonLoader;
