import { IFilterParams } from '@ag-grid-community/core';
import { forwardRef } from 'react';
import { FilterWithButtons } from '@/shared/design-system/v2/core/data-display/table/filters/FilterWithButtons';
import { DataCategory } from '../../../../../generated/api';

const getDataCategoryOptions = () =>
  // Ideally we should be able to map all these data categories
  // and automatically handle different types. For now, we will
  // use hard coded options since BE does not support them.
  // Object.keys(DataCategory).map((category: any) => ({
  //   label: (DataCategory as any)[category],
  //   value: category,
  // }));
  [
    {
      value: DataCategory.Text,
      label: 'Text',
    },
    {
      value: DataCategory.MixedOrCategorical,
      label: 'Mixed or Categorical',
    },
  ];

export const TypeFilter = forwardRef((props: IFilterParams, ref) => (
  <FilterWithButtons {...props} options={getDataCategoryOptions()} ref={ref} />
));

TypeFilter.displayName = 'TypeFilter';
