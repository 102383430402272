import { JsonSchema4, JsonSchema7 } from '@jsonforms/core';
import get from 'lodash/get';
import { Skeleton, Text } from '@/shared/design-system/v2';
import { OperatorModel } from '../../../generated/api';
import { useDataSourceDetailsQuery } from '../../../queries/data-sources';
import { FormSchema } from '../../common/form/Form';
import { StorageMetadataModel, storageConfigMap } from '../../data-sources/cell-renderers/config';
import { ICON_SIZE, NODE_WIDTH } from './utils';

interface ResourceNameProps {
  operator?: OperatorModel;
  resourceId?: string;
  fileName?: string;
  identifier?: string;
}

export const ResourceName = ({ operator, resourceId, fileName, identifier }: ResourceNameProps) => {
  const query = useDataSourceDetailsQuery(resourceId ?? '');

  const { isLoading } = query;
  const connectorType = query.data?.connectorType;
  const resourceMetadata = query.data?.resourceMetadata as StorageMetadataModel;
  let name;

  if (resourceId) {
    name =
      connectorType && resourceMetadata
        ? storageConfigMap[connectorType]?.getResourceName(resourceMetadata) ?? ''
        : '';
  }

  if (fileName) {
    const fileNameOptions = get(
      operator?.parameters as FormSchema | undefined,
      'schema.properties.file_name.oneOf',
    ) as (JsonSchema4 | JsonSchema7)[] | undefined;
    const label = fileNameOptions?.find(item => item.const === fileName)?.title;

    name = label;
  }

  if (identifier) {
    name = identifier;
  }

  if (isLoading && resourceId) {
    return <Skeleton h={32} />;
  }

  return (
    <Text
      variant="small03"
      maw={NODE_WIDTH - ICON_SIZE - 32}
      color="gray.8"
      sx={{
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      title={name}
    >
      {name}
    </Text>
  );
};
