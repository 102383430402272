import { Link } from 'react-router-dom';
import { Button, Grid, Text } from '@/shared/design-system/v2';
import { BaseUser, WorkspaceWithAccountDetails } from '../../../../generated/api';

interface UserInviteProps {
  invitingUser: BaseUser;
  workspace: WorkspaceWithAccountDetails;
  onAccept: () => void;
}

export const UserInvite = ({ invitingUser, workspace, onAccept }: UserInviteProps): JSX.Element => (
  <Grid columns={12} justify="space-between" align="center">
    <Grid.Col span={10}>
      <Text variant="bodyLong02" color="dark.6">
        New workspace invite from{' '}
        <Link to={`/user/${invitingUser.userId}`} target="_blank" rel="noopener noreferrer">
          {invitingUser.name}
        </Link>{' '}
        to {workspace.workspaceName}
      </Text>
    </Grid.Col>
    <Grid.Col span="auto">
      <Grid justify="flex-end">
        <Button variant="primary" shape="rounded" onClick={onAccept}>
          Accept
        </Button>
      </Grid>
    </Grid.Col>
  </Grid>
);
