import { IconBuildingSkyscraper } from '@tabler/icons-react';
import { Horizontal, Text, Vertical } from '@/shared/design-system/v2';
import { AccountInfo, WorkspaceMember } from '../../../generated/api';
import { UserDetail } from '../UserDetail';
import { getWorkspaceOwnerAndAdmins } from '../settings.util';
import { useAboutStyles } from './About.style';

interface AccountManagersProps {
  workspaceMembers: WorkspaceMember[];
  accountDetails: AccountInfo;
}

interface AccountDetailsRowProps {
  avatar?: string | React.ReactNode;
  name: string;
  domain: string;
  isWorkspaceOwner?: boolean;
}

const AccountDetailsRow = ({
  avatar,
  name,
  domain,
  isWorkspaceOwner,
}: AccountDetailsRowProps): JSX.Element => {
  const { classes } = useAboutStyles();
  return (
    <Horizontal position="apart" className={classes.accountManagerList}>
      <UserDetail avatar={avatar} name={name ?? ''} email={domain ?? ''} />
      <Text variant="small01" color="gray.7">
        {isWorkspaceOwner ? 'Owner' : 'Admin'}
      </Text>
    </Horizontal>
  );
};

export const AccountManagers = ({
  accountDetails,
  workspaceMembers,
}: AccountManagersProps): JSX.Element => {
  const { workspaceOwner, workspaceAdmins } = getWorkspaceOwnerAndAdmins(
    accountDetails,
    workspaceMembers,
  );

  return (
    <Vertical>
      <AccountDetailsRow
        avatar={workspaceOwner.avatar || <IconBuildingSkyscraper />}
        name={workspaceOwner.name}
        domain={workspaceOwner.domain}
        isWorkspaceOwner
      />
      {workspaceAdmins.map(member => (
        <AccountDetailsRow
          key={member.userId}
          avatar={member.userDetails?.avatar}
          name={member.userDetails?.name ?? ''}
          domain={member.userDetails?.email ?? ''}
        />
      ))}
    </Vertical>
  );
};
