// When running in Cypress mode, we don't want any pages to be authenticated
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '@/main/contexts/auth-provider/Auth';
import { FullPageLoader } from '@/shared/design-system';
import { isWebpageInIframe } from '@/shared/lib/util';
import { ErrorFallback } from '../ErrorBoundary';
import { Loading } from '../components/common/Loading';
import { AppRoutes, getRoute } from './constants';

export const IframeAuthRoute = ({ element }: RouteProps) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) return <FullPageLoader text="Authenticating..." />;

  if (isAuthenticated) return <>{element}</>;

  const currentPath = location.pathname;
  const iframeHomePath = getRoute(AppRoutes.IFRAME_HOME_PAGE);

  const redirectUrl = `${iframeHomePath}?redirectTo=${encodeURIComponent(currentPath)}`;
  return <Navigate to={redirectUrl} replace />;
};

// Authentication is enforced for each protected route
export const AuthRoute = ({ element }: RouteProps) => {
  if (window.Cypress) {
    return <>{element}</>;
  }

  if (isWebpageInIframe()) {
    return <IframeAuthRoute element={element} />;
  }

  const Component = withAuthenticationRequired(() => <>{element}</>, {
    onRedirecting: () => <FullPageLoader text="Authenticating..." />,
  });

  return <Component />;
};

// Each auth route is wrapped in a React Suspense instance
export const renderAuthRoute = ({ path, element }: RouteProps): JSX.Element => (
  <Route
    key={path}
    path={path}
    element={
      <ErrorBoundary fallback={ErrorFallback}>
        <Suspense fallback={<Loading />}>
          <AuthRoute path={path} element={element} />
        </Suspense>
      </ErrorBoundary>
    }
  />
);
