import { Box, Horizontal, Text, Tooltip, UnstyledButton } from '@/shared/design-system/v2';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { FeatureFamily } from '../../../router/constants';
import { useSidebarStyles } from '../sidebar-v2/Sidebar.style';
import { SideBarOptions } from '../sidebar-v2/SidebarLinks';

interface PrimaryNavbarLinkProps {
  id: FeatureFamily | SideBarOptions;
  label: string;
  icon: JSX.Element;
  onClick?: () => void;
}

export const PrimaryNavbarLink = ({ id, label, icon, onClick }: PrimaryNavbarLinkProps) => {
  const { activeSection } = useAppMetadata();
  const { classes, cx } = useSidebarStyles();

  const active = activeSection === id;

  return (
    <Tooltip
      label={<Text variant="bodyShort03">{label}</Text>}
      withArrow
      withinPortal
      position="right"
      offset={10}
      arrowSize={6}
      bg="dark.4"
      sx={{ borderRadius: '8px' }}
    >
      <UnstyledButton
        m="sm"
        component={Horizontal}
        noWrap
        key={label}
        onClick={onClick}
        className={cx(classes.navButton, {
          [classes.active]: active,
        })}
        data-testid={`sidebar-link-${label}`}
      >
        <Box w={24} h={24}>
          {icon}
        </Box>
      </UnstyledButton>
    </Tooltip>
  );
};
