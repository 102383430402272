import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import sortedLastIndex from 'lodash/sortedLastIndex';
import { datasetAnalysisApi, datasetApi } from '@/shared/lib/api';
import { publicApis } from '@/shared/lib/api/vienna';
import { usePublicSnippetDetail } from '../../../components/snippets/detail/context/PublicSnippetContext';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  AnalysisDataPoint,
  BaselineMetricsResponse,
  ClassOverlapInfoResponse,
  DataQualityClassResponse,
  TopKFeaturesResponse,
  TriggerAnalysisResponse,
} from '../../../generated/api';
import { useChartQuery } from '../../charts-query-wrapper';
import { AnalyzerTaskletEnum } from '../../constants';
import { dataAnalysisTaskKeys, dataQualityWidgetTaskKeys, datasetKeys } from '../../queryConstants';

export const useClassDataQualityQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery<
    AxiosResponse<DataQualityClassResponse>,
    AxiosError,
    DataQualityClassResponse
  >(
    dataQualityWidgetTaskKeys.classQualityScore(workspaceId, datasetId),
    () => datasetApi.workspaceGetClassDataQualityV1(workspaceId, datasetId),
    {
      enabled: !!datasetId,
      select: res => res.data,
    },
  );
};

export const useBaselineModelMetricsQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery<AxiosResponse<BaselineMetricsResponse>, AxiosError, BaselineMetricsResponse>(
    dataQualityWidgetTaskKeys.baselineModelStats(workspaceId, datasetId),
    () => datasetApi.workspaceGetBaselineMetricsV1(workspaceId, datasetId),
    {
      enabled: !!datasetId,
      select: res => res.data,
    },
  );
};

export const useOverlappingClassMetricsQuery = (datasetId: string, pageNumber?: number) => {
  const { workspaceId } = useAppMetadata();
  const { chartId, snippetId } = usePublicSnippetDetail();

  return useQuery<AxiosResponse<ClassOverlapInfoResponse>, AxiosError, ClassOverlapInfoResponse>(
    dataQualityWidgetTaskKeys.overlappingClassMetrics(workspaceId, datasetId, pageNumber ?? 0),
    () =>
      chartId
        ? publicApis
            .getChartPublicSnippetV11(snippetId, chartId, pageNumber)
            .then(data => data.data as AxiosResponse<ClassOverlapInfoResponse>)
        : datasetApi.workspaceGetOverlappingClassMetricsV1(workspaceId, datasetId, pageNumber),
    {
      enabled: !!datasetId,
      select: res => res.data,
    },
  );
};

export const selectChartData = (data: AxiosResponse<TopKFeaturesResponse>) => {
  const categories = data.data.response?.data.categories;
  const series = data.data.response?.data.series;
  const orderedCategories: string[] = [];

  // Ranks the data values and set order of categories accordingly.
  // Note: Should go away once API is updated.
  const getSortedSeriesData = (origData: AnalysisDataPoint[]) => {
    const orderedValues: number[] = [];
    origData.forEach((datum, i) => {
      const value = datum.y ?? 0;
      const insertionIndex = sortedLastIndex(orderedValues, value);
      orderedValues.splice(insertionIndex, 0, value);
      // only needed on the first series data pass
      if (categories && orderedCategories.length !== categories.length) {
        const category = categories[i];
        // casting to string type needed for chart, since category is possibly a number
        orderedCategories.splice(insertionIndex, 0, category.toString());
      }
    });

    return orderedValues.map(value => [value]);
  };

  return {
    analysisState: data.data.analysisState,
    response: {
      categories: orderedCategories,
      series:
        series?.map(serie => ({
          name: serie.name,
          data: getSortedSeriesData(serie.data),
        })) ?? [],
    },
  };
};
export const useTopKFeaturesQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useChartQuery(
    dataQualityWidgetTaskKeys.topKFeatures(workspaceId, datasetId),
    () => datasetApi.workspaceGetTopKFeaturesV1(workspaceId, datasetId),
    {
      enabled: !!datasetId,
      select: selectChartData,
    },
  );
};

export const useTriggerDataQualityScoreMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const taskletIds = [AnalyzerTaskletEnum.DATA_QUALITY_SCORE];

  return useMutation<AxiosResponse<TriggerAnalysisResponse>, AxiosError>(
    () => datasetAnalysisApi.triggerDataAnalysisV1(workspaceId, datasetId, taskletIds),
    {
      onSuccess: () => {
        // Update analyzing status on dataset
        queryClient.invalidateQueries(datasetKeys.detail(workspaceId, datasetId));
        queryClient.invalidateQueries(
          dataAnalysisTaskKeys.dataQualityStatus(workspaceId, datasetId),
        );
        queryClient.invalidateQueries(
          dataQualityWidgetTaskKeys.overlappingClassMetrics(workspaceId, datasetId),
        );
        queryClient.invalidateQueries(
          dataQualityWidgetTaskKeys.topKFeatures(workspaceId, datasetId),
        );
        queryClient.invalidateQueries(
          dataQualityWidgetTaskKeys.classQualityScore(workspaceId, datasetId),
        );
      },
    },
  );
};

export const useTriggerBaselineModelMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const taskletIds = [AnalyzerTaskletEnum.BASELINE_MODEL];

  return useMutation<AxiosResponse<TriggerAnalysisResponse>, AxiosError>(
    () => datasetAnalysisApi.triggerDataAnalysisV1(workspaceId, datasetId, taskletIds),
    {
      onSuccess: () => {
        // Update analyzing status on dataset
        queryClient.invalidateQueries(datasetKeys.detail(workspaceId, datasetId));
        queryClient.invalidateQueries(
          dataQualityWidgetTaskKeys.baselineModelStats(workspaceId, datasetId),
        );
      },
    },
  );
};
