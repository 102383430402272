import { IconAlertCircle } from '@tabler/icons-react';
import { Alert, Horizontal } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { ChartTypes } from '../../../../../../charts';
import { ChartData, NameYSeries } from '../../../../../../charts/config/build';
import { getColors } from '../../../../../../charts/utils/colors';
import { VisualizationComponent } from '../../../../../analyser/visualization/Visualization';
import { isStringNullish } from '../util';

const getChartData = (rawData: any[], xColName: string, yColName: string): ChartData => {
  const seriesData: NameYSeries[] = [];
  const categories: string[] = [];

  rawData.forEach((datum, index) => {
    const rawValue = datum[yColName] ?? '';
    const yValue = isStringNullish(rawValue) ? 0 : parseFloat(rawValue);
    // Throw error if parsing failed
    if (Number.isNaN(yValue)) {
      throw new Error(`Failed to parse data: reading field ${yColName} of ${datum}`);
    }
    const category = datum[xColName] ?? '';
    seriesData.push({ x: index, y: yValue, name: category });
    categories.push(category);
  });

  return {
    categories,
    series: [{ data: seriesData }],
  };
};

interface ChartViewProps {
  title: string;
  chartType: ChartTypes;
  rawData: Record<string, unknown>[];
  xColName: string;
  yColName: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

export const ChartView = ({
  title,
  chartType,
  rawData,
  xColName,
  yColName,
  xAxisTitle,
  yAxisTitle,
}: ChartViewProps): JSX.Element => {
  // getChartData throws an error if unable to parse; in this case, display a message
  try {
    const data = getChartData(rawData, xColName, yColName);
    return (
      <VisualizationComponent cardProps={{ shadow: '', withBorder: true, sx: { height: '100%' } }}>
        <VisualizationComponent.Header>
          <Horizontal p="12px" position="center">
            <VisualizationComponent.Header.Title title={title} />
          </Horizontal>
        </VisualizationComponent.Header>
        <VisualizationComponent.Chart
          type={chartType}
          data={data}
          colors={getColors(rawData.length)}
          xAxisOptions={{
            title: xAxisTitle,
            labels: { enabled: chartType !== ChartTypes.BAR },
            // Reverse for bar chart to preserve order
            reversed: chartType === ChartTypes.BAR,
          }}
          yAxisOptions={{ title: yAxisTitle }}
        />
      </VisualizationComponent>
    );
  } catch (error) {
    logger.error(error);
    return (
      <Alert color="red" icon={<IconAlertCircle />}>
        Unable to parse chart data. Try using another query.
      </Alert>
    );
  }
};
