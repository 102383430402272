import { Suspense } from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';
import { IframeHomePage } from '@/main/pages/IframeHomePage';
import { TracedRoutes } from '@/shared/initializers/sentry';
import { ErrorBoundary, ErrorFallback } from '../ErrorBoundary';
import { Loading } from '../components/common/Loading';
import { Home } from '../components/home/Home';
import { AccessDeniedPage } from '../pages/AccessDenied';
import { LogoutPage } from '../pages/Logout';
import { SignupPage } from '../pages/Signup';
import { PublicSnippetPage } from '../pages/Snippets/PublicSnippet';
import { UnavailablePage } from '../pages/Unavailable';
import { TemplatesPublicPage } from '../pages/Workflows/TemplatesPublicPage';
import { AppContainer } from '../pages/home/AppContainer';
import { AuthRoute, renderAuthRoute } from './AuthRouter';
import { OAuthRouter } from './OauthRouter';
import { RouteRedirection } from './RouteRedirection';
import { WorkspaceRouter } from './WorkspaceRouter';
import { AppRoutes, getDescendantRoute, getRoute } from './constants';
import { redirectionRouteDetails } from './redirectionConstants';
import { lazyWithRetry } from './util';

declare global {
  interface Window {
    Cypress: any;
  }
}

const Onboarding = lazyWithRetry(() => import('../pages/Onboarding'));
const UserProfilePage = lazyWithRetry(() => import('../pages/UserProfile'));
const AcceptInvitePage = lazyWithRetry(() => import('../pages/AcceptInvite'));

const appRoutes: RouteProps[] = [
  {
    path: getRoute(AppRoutes.ONBOARDING),
    element: <Onboarding />,
  },
  {
    path: getRoute(AppRoutes.INVITE),
    element: <AcceptInvitePage />,
  },
  {
    path: getRoute(AppRoutes.LOGOUT),
    element: <LogoutPage />,
  },
  {
    path: getDescendantRoute(AppRoutes.OAUTH_ROUTE),
    element: <OAuthRouter />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: getRoute(AppRoutes.ACCESS_DENIED),
    element: <AccessDeniedPage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const AppRouter = (): JSX.Element => (
  <TracedRoutes>
    {/* Adding Signup page before so it gets intercepted before other URLs */}
    <Route path={getRoute(AppRoutes.SIGNUP)} element={<SignupPage />} />
    <Route path={getRoute(AppRoutes.PUBLIC_SNIPPET)} element={<PublicSnippetPage />} />
    <Route
      path={getRoute(AppRoutes.WORKFLOWS_PUBLIC_TEMPLATES)}
      element={<TemplatesPublicPage />}
    />
    <Route path={getRoute(AppRoutes.IFRAME_HOME_PAGE)} element={<IframeHomePage />} />
    <Route path="/" element={<AppContainer />}>
      <Route index element={<AuthRoute element={<Home />} />} />
      <Route
        path={getRoute(AppRoutes.USER_PROFILE)}
        element={
          <Suspense fallback={<Loading />}>
            <UserProfilePage />
          </Suspense>
        }
      />
      <Route path=":workspaceId/*" element={<WorkspaceRouter />} />
    </Route>
    {Object.entries(redirectionRouteDetails).map(([path, route]) => (
      <Route
        key={path}
        path={path}
        element={
          <ErrorBoundary fallback={ErrorFallback}>
            <Suspense fallback={<Loading />}>
              <AuthRoute path={path} element={<RouteRedirection route={route} />} />
            </Suspense>
          </ErrorBoundary>
        }
      />
    ))}
    {appRoutes.map(renderAuthRoute)}
  </TracedRoutes>
);
