import toNumber from 'lodash/toNumber';
import { Skeleton, Text, Vertical } from '@/shared/design-system/v2';
import { ICellRendererParams } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { formatNumber } from '@/shared/lib/ui';
import { abbreviateNumber } from '@/shared/lib/util';
import { Dataset } from '../../../../queries/datasets/list';
import { DEFAULT_ROW_HEIGHT } from './util';

export const DataQualityRenderer = ({ value, data }: ICellRendererParams<Dataset, number>) => {
  if (!data) {
    return (
      <Vertical spacing="0px" justify="center" h={DEFAULT_ROW_HEIGHT}>
        <Skeleton width={100} height={30} />
      </Vertical>
    );
  }

  const displayDataQualityScore = value === -1 ? 'N/A' : `${formatNumber(100 * value, 2)}%`;
  const numRows = toNumber(data.numRecords ?? 0);
  const numCols = toNumber(data.noOfColumns ?? 0);
  const rowSummary = `${abbreviateNumber(numRows)} ${numRows === 1 ? 'Row' : 'Rows'}`;
  const colSummary = `${abbreviateNumber(numCols)} ${numCols === 1 ? 'Feature' : 'Features'}`;
  const rowAndColSummary = `${rowSummary}, ${colSummary}`;

  return (
    <Vertical spacing="0px" justify="center" h={DEFAULT_ROW_HEIGHT}>
      <Text variant="subTitle04" color="gray.7">
        {value ? displayDataQualityScore : '--'}
      </Text>
      <Text variant="small03" color="gray.6">
        {rowAndColSummary}
      </Text>
    </Vertical>
  );
};
