import { Loader } from '@/shared/design-system';
import { useGetWorkspaceSettingsDetailsQuery } from '../../../queries/account/workspace';
import { ErrorState } from '../../common/ErrorState';
import { About } from './About';

export const AboutContainer = (): JSX.Element => {
  const { isLoading, data, isError } = useGetWorkspaceSettingsDetailsQuery();

  if (isLoading) {
    return <Loader text="Loading workspace details" />;
  }

  if (isError || !data) {
    return (
      <ErrorState
        title="Seems there is some error"
        subtitle="We are not able to populate data. Please try after sometime or contact support"
      />
    );
  }

  return <About workspaceSettings={data} />;
};
