import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { logger } from '@/shared/initializers/logging';
import { embeddingsApi } from '@/shared/lib/api/vienna';
import { ScatterData } from '../../../charts/providers/deck-gl/layers.util';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { ClusteringStateResponse } from '../../../generated/api';
import { DATASET_CLUSTER } from '../../queryConstants';
import { clusterSelector } from './clusterSelector';

export const datasetClusterKeys = {
  getClusterFullData: (workspaceId: string, datasetId: string, embeddingId: string) => [
    workspaceId,
    datasetId,
    embeddingId,
  ],
  getSimilarPoints: (
    workspaceId: string,
    datasetId: string,
    recordIds: string[],
    embeddingId: string,
    inputTextList?: string[],
  ) => ['similarity-search', workspaceId, datasetId, recordIds, embeddingId, inputTextList],
  getEmbeddingsList: (workspaceId: string, datasetId: string) => [
    'embeddings-list',
    workspaceId,
    datasetId,
  ],
  getClusterState: (workspaceId: string, datasetId: string) => [
    'clustering-state-v1',
    workspaceId,
    datasetId,
  ],
  triggerPod: (workspaceId: string, datasetId: string, embeddingId: string) => [
    'trigger-search-pod',
    workspaceId,
    datasetId,
    embeddingId,
  ],
  searchPodStatus: (workspaceId: string, datasetId: string, embeddingId: string) => [
    'search-pod-status',
    workspaceId,
    datasetId,
    embeddingId,
  ],
  indexfileAvailable: (workspaceId: string, datasetId: string, embeddingId: string) => [
    'is-index-file-available',
    workspaceId,
    datasetId,
    embeddingId,
  ],
};

export const useGetDatasetClusterStateQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    datasetClusterKeys.getClusterState(workspaceId, datasetId),
    () => embeddingsApi.getDatasetClusteringStateV1(datasetId, workspaceId),
    {
      select: (response: AxiosResponse<ClusteringStateResponse>) => response.data,
      enabled: Boolean(datasetId),
    },
  );
};

export const useGetDatasetClusterFullQuery = (
  datasetId: string,
  embeddingId?: string,
  taskletId?: string,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery(
    datasetClusterKeys.getClusterFullData(datasetId, workspaceId, embeddingId ?? 'default'),
    () =>
      embeddingsApi
        .getDatasetClusteringV3(
          datasetId,
          workspaceId,
          embeddingId === 'DEFAULT' ? undefined : embeddingId,
          taskletId,
          {
            responseType: 'arraybuffer',
          },
        )
        .then((resp: AxiosResponse<any>) =>
          clusterSelector(
            resp,
            embeddingId !== 'DEFAULT'
              ? queryClient.getQueryData(
                  datasetClusterKeys.getClusterFullData(datasetId, workspaceId, 'default'),
                )
              : undefined,
          ),
        ),
    {
      enabled: Boolean(datasetId),
      staleTime: Infinity,
      refetchInterval: Infinity,
      cacheTime: Infinity,
    },
  );
};

export const useTriggerPodQuery = (datasetId: string, embeddingId?: string) => {
  const { workspaceId } = useAppMetadata();
  return useQuery(
    datasetClusterKeys.triggerPod(workspaceId, datasetId, embeddingId ?? 'default'),
    () =>
      embeddingsApi.spinPodForClusteringSimilarity(
        datasetId,
        workspaceId,
        embeddingId === 'DEFAULT' ? undefined : embeddingId,
      ),
    {
      select: data => data.data.ack ?? false,
    },
  );
};

export const useCheckPodStatusQuery = (
  datasetId: string,
  checkStatus = false,
  embeddingId?: string,
) => {
  const { workspaceId } = useAppMetadata();
  return useQuery(
    datasetClusterKeys.searchPodStatus(workspaceId, datasetId, embeddingId ?? 'default'),
    () =>
      embeddingsApi.searchPodStatus(
        datasetId,
        workspaceId,
        embeddingId === 'DEFAULT' ? undefined : embeddingId,
      ),
    {
      select: data => data.data.status,
      enabled: checkStatus,
      refetchInterval: 10 * 1000,
    },
  );
};
export const useIsIndexFileAvailableQuery = (datasetId: string, embeddingId?: string) => {
  const { workspaceId } = useAppMetadata();
  return useQuery(
    datasetClusterKeys.indexfileAvailable(workspaceId, datasetId, embeddingId ?? 'default'),
    () =>
      embeddingsApi.indexFileExists(
        datasetId,
        workspaceId,
        embeddingId === 'DEFAULT' ? undefined : embeddingId,
      ),
    {
      select: data => data.data.ack,
    },
  );
};

const MARKOV_DELIMITER = '--mkv-delim';

const transformClusterRawDataToColumns = (rawData: string, columnHeaders: string[]) => {
  if (!columnHeaders.length) {
    return undefined;
  }

  const rawDataItems = rawData.split(MARKOV_DELIMITER);

  return columnHeaders.reduce(
    (acc, header, index) => ({
      ...acc,
      [header]: rawDataItems[index],
    }),
    {},
  );
};

export const useGetClusterRawDataQuery = (
  datasetId: string,
  records: ScatterData[],
  enabled?: boolean,
) => {
  const { workspaceId } = useAppMetadata();
  const recordIds = records.reduce<string[]>((acc, record) => {
    if (record?.metadata?.recordId) {
      acc.push(record.metadata.recordId);
    } else {
      logger.error(
        `recordId or metadata not found in provided record - ${record.metadata} ${record}`,
      );
    }

    return acc;
  }, []);

  return useQuery(
    [DATASET_CLUSTER.GET_CLUSTER_CHART_RAW_DATA, workspaceId, datasetId, { recordIds }],
    () =>
      embeddingsApi.getDatasetClusteringRawDataV1(datasetId, workspaceId, {
        recordIdList: recordIds,
      }),
    {
      enabled: Boolean(enabled && recordIds.length),
      select: response => {
        const recordsData = records.map(record => {
          const recordId = record.metadata.recordId;
          return {
            recordId,
            tableRawData: transformClusterRawDataToColumns(
              response.data.response.recordIdMapping[recordId],
              response.data.response.colsUsed ?? [],
            ),
            rawDataColumnHeaders: response.data.response.colsUsed ?? [],
            rawData: response.data.response.recordIdMapping[recordId].replaceAll(
              MARKOV_DELIMITER,
              ' ',
            ),
            labels: record.metadata.label,
            cluster: record.metadata.cluster,
            qualityScore: record.metadata.dataQuality,
            isMislabelledData: record.metadata.isMislabelled,
          };
        });
        return {
          data: recordsData,
          columnHeaders: response.data.response.colsUsed ?? [],
        };
      },
    },
  );
};

export const useGetSimilarPointsQuery = (
  datasetId: string,
  recordIds: string[],
  embeddingId?: string,
  inputTextList?: string[],
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery(
    datasetClusterKeys.getSimilarPoints(
      workspaceId,
      datasetId,
      recordIds,
      embeddingId ?? 'default',
      inputTextList,
    ),
    () =>
      embeddingsApi.getDatasetClusteringSimilarityV1(
        datasetId,
        workspaceId,
        {
          recordIds,
          inputTextList,
        },
        embeddingId === 'DEFAULT' ? undefined : embeddingId,
      ),
    {
      enabled: Boolean(datasetId && (recordIds?.length || inputTextList?.length)),
      select: data => data.data.recordIds,
      onError: error => {
        queryClient.invalidateQueries(
          datasetClusterKeys.searchPodStatus(workspaceId, datasetId, embeddingId ?? 'default'),
        );
        logger.error(error);
      },
    },
  );
};

export const useGetEmbeddingsListQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    datasetClusterKeys.getEmbeddingsList(workspaceId, datasetId),
    () => embeddingsApi.getEmbeddingsListV1(workspaceId, datasetId),
    {
      select: data => data.data.response,
    },
  );
};
