import styled from 'styled-components';
import { Box, Bubbles } from '@/shared/design-system';
import {
  StyledTableBody,
  StyledTableRow,
} from '@/shared/design-system/Table/components/data-table/DataTable.style';

export const StyledBubbles = styled(Bubbles)``;

export const StyledTableWrapper = styled(Box)`
  ${StyledTableBody} > ${StyledTableRow} {
    &:hover {
      ${StyledBubbles} path {
        fill: ${({ theme }) => theme.colors.primary.base};
      }
    }
  }
`;
