import { Drawer, Horizontal, Text, Vertical, useMarkovTheme } from '@/shared/design-system/v2';
import { DatasetAnalysisStatusPanelContainer } from './DatasetAnalysisStatusPanel.container';

interface DataAnalysisDrawerProps {
  datasetId: string;
  datasetName: string;
  opened: boolean;
  onClose: () => void;
  showErrors?: boolean;
}

export const DataAnalysisDrawer = ({
  datasetId,
  datasetName,
  opened,
  onClose,
  showErrors = false,
}: DataAnalysisDrawerProps): JSX.Element => {
  const theme = useMarkovTheme();

  return (
    <Drawer.Root position="right" opened={opened} onClose={onClose}>
      <Drawer.Overlay />
      <Drawer.Content>
        <Drawer.Header
          display="contents"
          sx={{
            bg: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.white[0],
          }}
        >
          <Vertical p="lg" sx={{ flexShrink: 0 }}>
            <Horizontal position="apart">
              <Text variant="subTitle02" color="dark.8">
                Data Analysis Tasks
              </Text>
              <Drawer.CloseButton ml={0} />
            </Horizontal>
            <Text title={datasetName} variant="subTitle03" lineClamp={1}>
              {datasetName}
            </Text>
          </Vertical>
        </Drawer.Header>
        <Drawer.Body px={0} h="calc(100vh - 200px)">
          <DatasetAnalysisStatusPanelContainer
            datasetId={datasetId}
            showAnalysesErrors={showErrors}
          />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
