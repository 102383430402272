import { IconDownload } from '@tabler/icons-react';
import { ActionIcon, Menu } from '@/shared/design-system/v2';
import { useChartsContext } from '../../../../charts/context/Context';

interface DownloadVisualizationProps {
  fileName: string;
  isDownloadDisabled?: boolean;
}

export const DownloadVisualization = ({
  fileName,
  isDownloadDisabled = false,
}: DownloadVisualizationProps) => {
  const chartContext = useChartsContext();
  const chartRef = chartContext?.chartRef;

  const handleDownloadChartPNG = () => {
    if (chartRef?.current?.chart) {
      if (chartRef.current.chart.options.exporting) {
        chartRef.current.chart.options.exporting.filename = fileName ?? 'chart';
      }
      chartRef.current.chart.exportChart({ type: 'image/png' }, {});
    }
  };

  const handleDownloadChartCSV = () => {
    if (chartRef?.current?.chart) {
      if (chartRef.current.chart.options.exporting) {
        chartRef.current.chart.options.exporting.filename = fileName ?? 'chart';
      }
      chartRef.current.chart.downloadCSV();
    }
  };

  return (
    <Menu>
      <Menu.Target>
        <ActionIcon disabled={isDownloadDisabled} color="gray.6">
          <IconDownload size={22} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={handleDownloadChartCSV}>Download as CSV</Menu.Item>
        <Menu.Item onClick={handleDownloadChartPNG}>Download as PNG</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
