import HighchartsReact from 'highcharts-react-official';
import { useRef } from 'react';
import { Expand } from '@/shared/design-system';
import {
  ActionIcon,
  Box,
  Center,
  Horizontal,
  Loader,
  Modal,
  Text,
} from '@/shared/design-system/v2';
import { useDisclosure } from '@/shared/design-system/v2/hooks';
import { ChartTypes } from '../../../../charts';
import { ChartsProvider } from '../../../../charts/context/Context';
import { ChartData } from '../../../../generated/api';
import { VisualizationComponent } from '../../../analyser/visualization/Visualization';
import { DownloadVisualization } from '../../../analyser/visualization/actions/DownloadVisualization';
import { SECTION_TITLE } from './ClassDistributionSection';

interface ClassDistributionProps {
  chartData: ChartData;
  colors: string[];
  isLoading: boolean;
  expandable?: boolean;
}

export const ClassDistribution = ({
  chartData,
  colors,
  isLoading,
  expandable = true,
}: ClassDistributionProps) => {
  const [expanded, handlers] = useDisclosure();
  const chartRef = useRef<HighchartsReact.RefObject | null>(null);

  const handleCarouselOpen = () => handlers.open();
  const handleCarouselClose = () => handlers.close();

  const header = (
    <VisualizationComponent.Header
      actions={
        !expanded ? (
          <Horizontal>
            <DownloadVisualization
              fileName={'distribution_comparison'}
              isDownloadDisabled={isLoading}
            />
            {expandable && (
              <ActionIcon color="gray.6" onClick={handleCarouselOpen} disabled={isLoading}>
                <Expand />
              </ActionIcon>
            )}
          </Horizontal>
        ) : (
          <Box p="14px" />
        )
      }
    >
      <div />
    </VisualizationComponent.Header>
  );

  const content = isLoading ? (
    <Center h="100%">
      <Loader />
    </Center>
  ) : (
    <VisualizationComponent.Chart
      type={ChartTypes.GROUPED_COLUMN}
      colors={colors}
      data={chartData}
      enableLegends
      colorByPoint={false}
      xAxisOptions={{ title: 'Classes' }}
      yAxisOptions={{ title: 'No. of records' }}
    />
  );

  const vizContent = (
    <VisualizationComponent
      cardProps={{ shadow: '', withBorder: !expanded, sx: { height: '100%' } }}
    >
      {header}
      {content}
    </VisualizationComponent>
  );

  return (
    <>
      <ChartsProvider hcRef={chartRef}>{vizContent}</ChartsProvider>
      <Modal
        opened={expanded}
        onClose={handleCarouselClose}
        size="80vw"
        centered
        title={<Text variant="subTitle02">{SECTION_TITLE}</Text>}
      >
        {vizContent}
      </Modal>
    </>
  );
};
