import { IconTrashFilled } from '@tabler/icons-react';
import startCase from 'lodash/startCase';
import {
  ActionIcon,
  Box,
  Horizontal,
  Select,
  TextInput,
  Vertical,
  useHover,
} from '@/shared/design-system/v2';
import { ExpressionType } from '../../../../generated/api';
import { DEFAULT_QUERY_OPERATORS } from './constants';

interface ConditionBlockProps {
  editable: boolean;
  columns: string[];
  expressionTypes?: string[];
  column: string | undefined;
  onChangeColumn: (columnId: string) => void;
  expressionType: ExpressionType;
  onChangeExpressionType: (op: ExpressionType) => void;
  value: string;
  onChangeValue: (value: string) => void;
  onDelete?: () => void;
}

export const ConditionBlock = ({
  editable,
  columns,
  expressionTypes = DEFAULT_QUERY_OPERATORS,
  column,
  expressionType,
  value,
  onChangeColumn,
  onChangeExpressionType,
  onChangeValue,
  onDelete,
}: ConditionBlockProps): JSX.Element => {
  const { ref: hoverRef, hovered } = useHover();

  const showDeleteButton = editable && !!onDelete && hovered;

  return (
    <Vertical spacing="sm" ref={hoverRef} pos="relative">
      {showDeleteButton && (
        <Box pos="absolute" sx={{ top: 4, right: 0, zIndex: 1 }}>
          <ActionIcon onClick={onDelete}>
            <IconTrashFilled size={20} />
          </ActionIcon>
        </Box>
      )}
      <Horizontal>
        <Box sx={{ flex: 3 }}>
          <Select
            disabled={!editable}
            withinPortal
            ariaLabel="Feature column"
            hideLabel
            placeholder="Select column"
            options={columns}
            value={column}
            onChange={onChangeColumn}
          />
        </Box>
        <Box mr={40} sx={{ flex: 2 }}>
          <Select
            disabled={!editable}
            ariaLabel="Query operator"
            hideLabel
            options={expressionTypes.map(op => startCase(op))}
            value={expressionType}
            onChange={onChangeExpressionType}
          />
        </Box>
      </Horizontal>
      <Horizontal>
        <Box sx={{ flex: 3 }}>
          <TextInput
            disabled={!editable}
            ariaLabel="Condition value"
            value={value}
            onChange={e => onChangeValue(e.target.value)}
          />
        </Box>
        <Box mr={40} sx={{ flex: 2 }}></Box>
      </Horizontal>
    </Vertical>
  );
};
