import { AnalyzingSpinner, IconNotSupported, Info, Sparkles } from '@/shared/design-system';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Horizontal,
  Loader,
  Tooltip,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { getIsTaskPending } from '@/shared/lib/analysis';
import { ChartTypes } from '../../../../charts';
import { AnalysisStateNames } from '../../../../generated/api';
import { useRerunAnalysisMutation } from '../../../../queries/datasets/analysis/tasks';
import { useTopKFeaturesQuery } from '../../../../queries/datasets/statistics/data-quality';
import { VisualizationComponent } from '../../../analyser/visualization/Visualization';
import { EmptyState } from '../../../common/EmptyState';
import { ErrorState } from '../../../common/ErrorState';
import { handleReRunDataQualityScore } from './RerunDataQualityScore';

const TOP_K_FEATURES_DESCRIPTION = 'Top K engineered features with their relative importance.';

interface TopKFeaturesChartContainerProps {
  datasetId: string;
}

export const TopKFeaturesChartContainer = ({
  datasetId,
}: TopKFeaturesChartContainerProps): JSX.Element => {
  const theme = useMarkovTheme();
  const { isLoading, isError, data } = useTopKFeaturesQuery(datasetId);
  const { mutate: retriggerDataQualityAnalysis, isLoading: isRetriggering } =
    useRerunAnalysisMutation(datasetId);

  const handleRetrigger = () => {
    handleReRunDataQualityScore(retriggerDataQualityAnalysis);
  };

  if (isLoading) {
    return (
      <Center h="100%">
        <Loader color="yellow.6" size="lg" text="Loading top k features" />
      </Center>
    );
  }

  if (isError || !data) {
    const errorMsg = 'There was an error retrieving top k features';
    return (
      <ErrorState
        compact
        title=""
        subtitle={errorMsg}
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  const analysisState = data.analysisState;

  if (analysisState === AnalysisStateNames.Error) {
    return (
      <ErrorState
        compact
        title=""
        subtitle="There was an error while computing top k features results"
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  if (analysisState === AnalysisStateNames.NotSupported) {
    return (
      <EmptyState
        compact
        title=""
        subtitle="Top k features is not supported for this dataset"
        icon={<IconNotSupported />}
        type="notSupported"
      />
    );
  }

  if (analysisState === AnalysisStateNames.Outdated) {
    return (
      <EmptyState
        compact
        title=""
        subtitle="Re-run Markov Quality Score analysis to view top k features"
        icon={<Sparkles />}
        button={
          <Button variant="outline" onClick={handleRetrigger} loading={isRetriggering}>
            Re-run
          </Button>
        }
      />
    );
  }

  // Handle case when analyzer results aren't available yet
  if (getIsTaskPending(analysisState)) {
    return (
      <EmptyState
        compact
        title="Top K Features analysis in progress"
        subtitle="Results will become available once the analysis is complete"
        icon={<AnalyzingSpinner />}
      />
    );
  }

  return (
    <VisualizationComponent
      cardProps={{
        shadow: '',
        p: '24px',
      }}
    >
      <Horizontal spacing="xs" mb="xl">
        <VisualizationComponent.Header.Title
          title="Top K features"
          titleProps={{ variant: 'subTitle03', color: 'gray.8' }}
        />
        <Tooltip label={TOP_K_FEATURES_DESCRIPTION} withArrow withinPortal multiline maw={325}>
          <ActionIcon variant="transparent">
            <Info />
          </ActionIcon>
        </Tooltip>
      </Horizontal>
      <Box w="100%">
        <VisualizationComponent.Chart
          colors={[theme.colors.blue[1], theme.colors.blue[2]]}
          colorByPoint={false}
          data={data.response}
          type={ChartTypes.BAR}
        />
      </Box>
    </VisualizationComponent>
  );
};
