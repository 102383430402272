import { useEffect, useState } from 'react';
import { useGetS3PresignedUrlForFileQuery } from '@/main/queries/workflows/s3-preview';
import { Alert, Box, Center, HTMLPreview, Loader } from '@/shared/design-system/v2';

const MODAL_HEIGHT = 400;

const useBlobToText = (blob: Blob | undefined) => {
  const [text, setText] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const convertBlobToText = async (blob: Blob) => {
      try {
        const text = await blob.text();
        setText(text);
      } catch (err) {
        setError(err as Error);
      }
    };

    if (blob) {
      convertBlobToText(blob);
    }
  }, [blob]);

  return { text, error };
};

interface TxthtmlPreviewProps {
  url: string;
  workflowId: string;
}

export const TxtHtmlPreview = ({ url, workflowId }: TxthtmlPreviewProps) => {
  const { isLoading, isError, data } = useGetS3PresignedUrlForFileQuery(workflowId, url);
  const { text: content, error: textError } = useBlobToText(data);

  if (isLoading) {
    return (
      <Center w="100%" h={MODAL_HEIGHT}>
        <Loader />
      </Center>
    );
  }

  if (isError || !data || textError) {
    return (
      <Center w="100%" h={MODAL_HEIGHT}>
        <Alert color="red">Not able to load file</Alert>
      </Center>
    );
  }

  return (
    <Box w="100%" h={600} py="xl">
      <HTMLPreview
        style={{
          pointerEvents: 'none',
        }}
      >
        {content}
      </HTMLPreview>
    </Box>
  );
};
