import { TooltipFormatterContextObject, numberFormat } from 'highcharts';
import { Box } from '@/shared/design-system/v2';
import { TooltipContainer, TooltipHeader, TooltipItemRow } from '../ChartTooltip.style';

interface StackedColumnTooltipProps {
  formatterContext: TooltipFormatterContextObject;
}

export const StackedColumnTooltip = ({ formatterContext }: StackedColumnTooltipProps) => {
  const points = formatterContext.points;

  return (
    <TooltipContainer>
      <TooltipHeader>{formatterContext.x}</TooltipHeader>

      {points?.map((point, i) => (
        <Box key={`${point.series.name}-${i}`}>
          <TooltipHeader>{point.series.name}</TooltipHeader>
          <TooltipItemRow>{numberFormat(point.y, 2)}%</TooltipItemRow>
        </Box>
      ))}
    </TooltipContainer>
  );
};
