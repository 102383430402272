import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import {
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Connection,
  EdgeChange,
  EdgeMouseHandler,
  NodeChange,
  NodePositionChange,
  ReactFlowInstance,
  XYPosition,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  updateEdge,
} from 'reactflow';
import { sendAnalytics } from '@/shared/initializers/analytics';
import { workflowEvents } from '../../analytics';
import { Mode } from '../../components/workflows/create/utils';
import {
  isInvalidConnection,
  transformEdgeToWorkflowEdge,
  transformWorkflowEdgeToEdge,
} from '../../components/workflows/edges/util';
import {
  WorkflowEdge,
  WorkflowNode,
  createWorkflowNode,
  findNodesAfter,
  positionCalculator,
  transformNodeToWorkflowNode,
  transformWorkflowNodeToNode,
} from '../../components/workflows/nodes/utils';
import { Edge, Node, OperatorCategory, OperatorModel, UpsertDAGRequest } from '../../generated/api';
import { queryKeys } from '../../queries/resource-lookup';
import { useSaveWorkflowGraphMutation } from '../../queries/workflows/builder';
import { workflowsQueryKeys } from '../../queries/workflows/list/list';
import { operatorKeys } from '../../queries/workflows/operators';
import { useAppMetadata } from '../app-metadata/AppMetadata';

interface CreateWorkflowContext {
  workflowId: string;
  dagId: string;
  setDagId: (dagId: string) => void;

  nodes: WorkflowNode[];
  setNodes: React.Dispatch<SetStateAction<WorkflowNode[]>>;
  onNodesChange: (changes: NodeChange[]) => void;

  edges: WorkflowEdge[];
  setEdges: React.Dispatch<SetStateAction<WorkflowEdge[]>>;
  onEdgesChange: (changes: EdgeChange[]) => void;

  reactFlowInstance: ReactFlowInstance<any, any> | null;
  setReactFlowInstance: React.Dispatch<SetStateAction<ReactFlowInstance<any, any> | null>>;

  mode: Mode;
  setMode: (mode: CreateWorkflowContext['mode']) => void;

  onNodeAdd: (
    operatorId: string,
    nodeCategory: OperatorCategory,
    position?: XYPosition,
    name?: string,
  ) => WorkflowNode | undefined;
  onNodeDelete: (id: string) => void;

  onEdgeDelete: (edgeId: string) => void;
  onEdgeConnect: (params: Connection, checkCycle?: boolean) => void;
  onEdgeUpdateStart: () => void;
  onEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  onEdgeUpdateEnd: (event: MouseEvent | TouchEvent, edge: Edge) => void;
  onEdgeMouseEnter: EdgeMouseHandler;
  onEdgeMouseLeave: EdgeMouseHandler;

  saveWorkflowDAG: (
    nodes: WorkflowNode[],
    edges: WorkflowEdge[],
    invalidateSchema?: boolean,
    nodesList?: string[],
  ) => void;
  isSaving: boolean;
}

const CreateWorkflowContext = createContext<CreateWorkflowContext | undefined>(undefined);

export const useCreateWorkflow = () => {
  const context = useContext(CreateWorkflowContext);

  if (context === undefined) {
    throw new Error('useCreateWorkflow must be used within a CreateWorkflowProvider');
  }

  return context;
};

interface CreateWorkflowProviderProps {
  workflowId: string;
  dag: UpsertDAGRequest;
  operators: OperatorModel[];
}

export const CreateWorkflowProvider = ({
  children,
  workflowId,
  dag,
  operators,
}: PropsWithChildren<CreateWorkflowProviderProps>) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const [dagState, setDagState] = useState({
    nodes: dag.nodes.map(node => transformNodeToWorkflowNode(node, operators)),
    edges: dag.edges.map(edge => transformEdgeToWorkflowEdge(edge)),
  });

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<any, any> | null>(
    null,
  );
  const [mode, setMode] = useState<Mode>(Mode.Build);
  const [dagId, setDagId] = useState('');
  const edgeUpdateSuccessful = useRef(true);

  const { mutateAsync: saveWorkflow, isLoading: isSaving } =
    useSaveWorkflowGraphMutation(workflowId);

  const saveWorkflowDAG = (
    nodes: WorkflowNode[],
    edges: WorkflowEdge[],
    invalidateSchema?: boolean,
    nodesList?: string[],
  ) => {
    const newNodes = nodes.map<Node>(node => transformWorkflowNodeToNode(node));
    const newEdges = edges.map<Edge>(edge => transformWorkflowEdgeToEdge(edge));
    const req: UpsertDAGRequest = { nodes: newNodes, edges: newEdges };

    saveWorkflow(req, {
      onSuccess: data => {
        setDagId(data.data.dagId);
        queryClient.invalidateQueries(workflowsQueryKeys.dag(workspaceId, workflowId));
        if (invalidateSchema) {
          queryClient.invalidateQueries(operatorKeys.getDagIOSchema(workspaceId, workflowId));
          queryClient.invalidateQueries(queryKeys.list(workspaceId));
          if (nodesList?.length) {
            nodesList.forEach(nodeId => {
              queryClient.invalidateQueries(
                operatorKeys.getWorkflowDagNodesSchema(workspaceId, workflowId, undefined, nodeId),
              );
            });
          }
        }
      },
    });
  };

  const saveWorkflowDAGDebounced = useCallback(debounce(saveWorkflowDAG, 500), []);

  const onNodeAdd = (
    operatorId: string,
    nodeCategory: OperatorCategory,
    position?: XYPosition,
    name?: string,
  ) => {
    if (!reactFlowInstance) return;

    const calculateDefaultPosition = positionCalculator(
      dagState.nodes,
      dagState.edges,
      reactFlowInstance,
    );
    const newNode: WorkflowNode = createWorkflowNode(
      operatorId,
      nodeCategory,
      position ?? calculateDefaultPosition,
      name,
    );

    setDagState(currentState => {
      const updatedNodes = currentState.nodes.concat(newNode);

      saveWorkflowDAGDebounced(updatedNodes, currentState.edges, true);

      return { ...currentState, nodes: updatedNodes };
    });

    return newNode;
  };

  const onNodeDelete = (id: string) => {
    setDagState(currentState => {
      const newNodes = currentState.nodes.filter(node => node.id !== id);
      const newEdges = currentState.edges.filter(edge => edge.source !== id && edge.target !== id);

      const nodesAfter = findNodesAfter(id, newEdges);

      saveWorkflowDAGDebounced(newNodes, newEdges, true, nodesAfter);

      return { nodes: newNodes, edges: newEdges };
    });
  };

  const onEdgeDelete = (edgeId: string) => {
    setDagState(currentState => {
      const newEdges = currentState.edges.filter(edge => edge.id !== edgeId);
      saveWorkflowDAGDebounced(currentState.nodes, newEdges);
      return { ...currentState, edges: newEdges };
    });
  };

  const onEdgeConnect = (connection: Connection, checkCycle = true) => {
    if (isInvalidConnection(connection, dagState.edges, dagState.nodes, checkCycle)) return;

    sendAnalytics(
      workflowEvents.dag.linkNodesWithEdge({
        nodeIds: [connection.source ?? '', connection.target ?? ''],
        workflowId,
        workspaceId,
      }),
    );
    const edge = { ...connection, type: 'custom-edge' };

    setDagState(currentState => {
      const newEdges = addEdge(edge, currentState.edges);
      const nodesAfter = findNodesAfter(connection.target ?? undefined, newEdges);

      saveWorkflowDAGDebounced(currentState.nodes, newEdges, true, nodesAfter);

      return { ...currentState, edges: newEdges };
    });
  };

  const onNodesChange = (changes: NodeChange[]) => {
    setDagState(currentState => {
      const updatedNodes = applyNodeChanges(changes, currentState.nodes);
      const hasSelectChange = changes.some(
        change =>
          change.type === 'select' ||
          change.type === 'dimensions' ||
          (change.type === 'position' && !(change as NodePositionChange).dragging),
      );

      if (!hasSelectChange) {
        saveWorkflowDAGDebounced(updatedNodes, currentState.edges);
      }

      return { ...currentState, nodes: updatedNodes };
    });
  };

  const onEdgesChange = (changes: EdgeChange[]) => {
    setDagState(currentState => {
      const updatedEdges = applyEdgeChanges(changes, currentState.edges);
      const hasSelectChange = changes.some(change => change.type === 'select');

      if (!hasSelectChange) {
        saveWorkflowDAGDebounced(currentState.nodes, updatedEdges);
      }

      return { ...currentState, edges: updatedEdges };
    });
  };

  const onEdgeUpdateStart = () => {
    edgeUpdateSuccessful.current = false;
  };

  const onEdgeUpdate = (oldEdge: Edge, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;
    setDagState(currentState => {
      const updatedEdges = updateEdge(oldEdge, newConnection, currentState.edges);
      saveWorkflowDAGDebounced(currentState.nodes, updatedEdges);
      return { ...currentState, edges: updatedEdges };
    });
  };

  const onEdgeUpdateEnd = (event: MouseEvent | TouchEvent, edge: Edge) => {
    if (!edgeUpdateSuccessful.current) {
      setDagState(currentState => {
        const newEdges = currentState.edges.filter(e => e.id !== edge.id);
        saveWorkflowDAGDebounced(currentState.nodes, newEdges);
        return { ...currentState, edges: newEdges };
      });
    }
    edgeUpdateSuccessful.current = true;
  };

  const onEdgeMouseEnter: EdgeMouseHandler = (_, edge: WorkflowEdge) => {
    setDagState(currentState => ({
      ...currentState,
      edges: currentState.edges.map(e =>
        e.id === edge.id ? { ...e, data: { isHovered: true } } : e,
      ),
    }));
  };

  const onEdgeMouseLeave: EdgeMouseHandler = (_, edge: WorkflowEdge) => {
    setDagState(currentState => ({
      ...currentState,
      edges: currentState.edges.map(e =>
        e.id === edge.id ? { ...e, data: { isHovered: false } } : e,
      ),
    }));
  };

  const value = useMemo(
    () => ({
      workflowId,
      dagId,
      setDagId,
      nodes: dagState.nodes,
      setNodes: (newNodes: SetStateAction<WorkflowNode[]>) =>
        setDagState(state => ({
          ...state,
          nodes: typeof newNodes === 'function' ? newNodes(state.nodes) : newNodes,
        })),
      edges: dagState.edges,
      setEdges: (newEdges: SetStateAction<WorkflowEdge[]>) =>
        setDagState(state => ({
          ...state,
          edges: typeof newEdges === 'function' ? newEdges(state.edges) : newEdges,
        })),
      onNodesChange,
      onEdgesChange,
      reactFlowInstance,
      setReactFlowInstance,
      mode,
      setMode,
      onNodeAdd,
      onNodeDelete,
      onEdgeDelete,
      onEdgeConnect,
      onEdgeUpdateStart,
      onEdgeUpdate,
      onEdgeUpdateEnd,
      onEdgeMouseEnter,
      onEdgeMouseLeave,
      saveWorkflowDAG,
      isSaving,
    }),
    [workflowId, dagId, dagState, mode, reactFlowInstance, saveWorkflowDAG, isSaving],
  );

  return <CreateWorkflowContext.Provider value={value}>{children}</CreateWorkflowContext.Provider>;
};
