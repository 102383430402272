import range from 'lodash/range';
import { ScrollArea, SimpleGrid, Skeleton } from '@/shared/design-system/v2';
import { ChartCard } from '../analysers/ChartsList';
import { useGetExperimentCharts } from '../hooks/experiment';

interface ExperimentsChartsProps {
  experimentId: string;
}

export const ExperimentsCharts = ({ experimentId }: ExperimentsChartsProps) => {
  const { isLoading, chartsDetails } = useGetExperimentCharts(experimentId);

  const content = isLoading
    ? range(6).map(i => <Skeleton key={i} h={200} />)
    : chartsDetails.map(chart => <ChartCard key={chart.id} {...chart} />);

  return (
    <ScrollArea p="md" w="58%" h="calc(100vh - 120px)">
      <SimpleGrid cols={2}>{content}</SimpleGrid>
    </ScrollArea>
  );
};
