export const MARKOVML_DOCS = '//docs.markovml.com';
export const MARKOVML_PRODUCT_PAGE = '//www.markovml.com';

export const DOC_SETUP_SDK = `${MARKOVML_DOCS}/docs/setup-your-machine`;
export const DOC_UPDATE_CLI_VERSION = `${MARKOVML_DOCS}/docs/setup-your-machine#upgrading-your-sdk`;
export const DOC_GET_STARTED_WITH_DATASETS = `${MARKOVML_DOCS}/docs/working-with-datasets`;
export const DOC_MANAGE_MODELS_AND_PROJECTS = `${MARKOVML_DOCS}/docs/working-with-projects`;
export const DOC_REGISTER_MODEL_EVALUATIONS = `${MARKOVML_DOCS}/docs/evaluate-your-models`;
export const DOC_REGISTER_EXPERIMENT_RECORDINGS = `${MARKOVML_DOCS}/docs/track-your-model-experiments`;
export const DOC_DATA_ANALYTICS = `${MARKOVML_DOCS}/docs/data-insights`;
export const DOC_GENAI_APP_BUILDER = `${MARKOVML_DOCS}/docs/app-builder`;

export const MARKOVML_PRODUCT_PRICING_PAGE = `${MARKOVML_PRODUCT_PAGE}/pricing`;
export const MARKOVML_CONTACT_US_PAGE = `${MARKOVML_PRODUCT_PAGE}/contact-us`;
export const MARKOVML_LEARN_ABOUT_CREDITS = `${MARKOVML_PRODUCT_PRICING_PAGE}#learn-about-credits`;

export const COPY_COMPASS_PRODUCT_PAGE = '//copycompass.com';
