import type { JsonFormsCore, JsonSchema, UISchemaElement } from '@jsonforms/core';
import { createAjv } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import type Ajv from 'ajv';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { OperatorCategory, OperatorModel } from '@/main/generated/api';
import { Mode } from '../../workflows/create/utils';
import { renderers } from './renderers';

export interface FormSchema {
  schema: JsonSchema;
  uischema?: UISchemaElement;
}

interface FormProps {
  schema: FormSchema;
  data?: any;
  setData?: React.Dispatch<any>;
  isFormDirty?: boolean;
  setIsFormDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  initialState?: any;

  onErrors: (errors: any[]) => void;

  viewOnly?: boolean;
  workflowId?: string;
  runId?: string;
  nodeId?: string;
  appId?: string;
  operator?: OperatorModel;
  prevNodeId?: string;
  isFormSaving?: boolean;
  mode?: Mode;
}

export const Form = ({
  schema: { schema, uischema },
  data: values,
  setData,
  isFormDirty,
  setIsFormDirty,
  initialState,
  onErrors,
  viewOnly,
  operator,
  workflowId,
  runId,
  nodeId,
  appId,
  prevNodeId,
  isFormSaving,
  mode,
}: FormProps) => {
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(!isEqual(values, initialState));
    }
  }, [values, initialState]);

  useEffect(() => {
    if (showErrors || isEqual(values, initialState)) {
      return;
    }

    setShowErrors(true);
  }, [values]);

  const handleFormChange = ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
    if (errors && errors.length > 0) {
      onErrors(errors);
    }

    if (setData && !isEqual(data, values)) {
      setData(data);
    }
  };

  const operatorCategory = operator?.category as OperatorCategory;
  const operatorId = operator?.operatorId;

  // There is some issue with types here. CreateAjv return is AJV but does not match
  // with JsonForms prop somehow (using same package).
  // TODO:: Debug type issue further
  const handleDefaultsAjv = createAjv({ useDefaults: true }) as unknown as Ajv;

  return (
    <JsonForms
      schema={schema}
      uischema={uischema}
      data={values}
      config={{
        workflowId,
        runId,
        nodeId,
        appId,
        viewOnly,
        prevNodeId,
        operatorCategory,
        operatorId,
        isFormDirty,
        isFormSaving,
        mode,
      }}
      renderers={renderers}
      onChange={handleFormChange}
      readonly={viewOnly}
      validationMode="ValidateAndShow"
      ajv={handleDefaultsAjv}
    />
  );
};
