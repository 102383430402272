import { Flex, Skeleton } from '@/shared/design-system/v2';
import { ICellRendererParams } from '@/shared/design-system/v2/core/data-display/table/ag-grid';
import { AnalysisStatusData, Dataset } from '../../../../queries/datasets/list';
import { DatasetState } from '../../components/DatasetState';
import { DEFAULT_ROW_HEIGHT } from './util';

export const AnalysisRenderer = ({
  value,
  data,
}: ICellRendererParams<Dataset, AnalysisStatusData>) => {
  if (!data) {
    return (
      <Flex h={DEFAULT_ROW_HEIGHT} py="md">
        <Skeleton width={100} height={30} />
      </Flex>
    );
  }

  if (!value) {
    return null;
  }

  return (
    <Flex h={DEFAULT_ROW_HEIGHT}>
      <DatasetState analysisStatus={value} />
    </Flex>
  );
};
