import { StorageType } from '@/main/generated/api';
import { Alert, Button, Text, Vertical } from '@/shared/design-system/v2';
import { getConnectorTypeDisplayName } from '../util';

interface NotionOauthModalProps {
  handleConnect: () => void;
  connectorType: StorageType;
  error?: string;
}

export const NotionOauthModal = ({
  handleConnect,
  connectorType,
  error,
}: NotionOauthModalProps) => (
  <Vertical>
    {error && (
      <Alert color="red">
        Seems like there was an issue authorizing your account. Please try again!
      </Alert>
    )}
    <Text variant="bodyShort01">
      Connect your {getConnectorTypeDisplayName(connectorType)} account to get started
    </Text>
    <Button onClick={handleConnect} variant="primary" w="240px">
      Sign in with {getConnectorTypeDisplayName(connectorType)}
    </Button>
  </Vertical>
);
