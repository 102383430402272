import { closeAllModals, openConfirmModal } from '@mantine/modals';
import { IconInfoCircle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { Alert, Text, Vertical } from '@/shared/design-system/v2';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { PanelTab } from '../../clusters/util';
import { DatasetDetailsTab } from '../../header/DatasetDetailsTabs';

const SubsetRelabelingInfoModal = (): JSX.Element => (
  <Vertical>
    <Alert
      icon={<IconInfoCircle />}
      title="Run auto-labeling on your dataset based on a labeled subset"
    >
      You can assign labels to the data points in a subset, then use these to auto-generate labels
      for the rest of your dataset. Select a saved subset to begin this flow.
    </Alert>
  </Vertical>
);

export const useSubsetRelabelingInfoModal = () => {
  const navigate = useNavigate();
  const { getDatasetDetailsRoute } = useAbsoluteRoutes();

  const goToSubsets = (datasetId: string) => {
    const subsetsListRoute = getDatasetDetailsRoute(
      datasetId,
      DatasetDetailsTab.EMBEDDINGS,
      undefined,
      PanelTab.VIEW_SUBSETS,
    );
    navigate(subsetsListRoute);
  };

  const open = (datasetId: string) =>
    openConfirmModal({
      title: <Text variant="subTitle01">Auto-Labeling</Text>,
      size: 'lg',
      children: <SubsetRelabelingInfoModal />,
      labels: { confirm: 'View subsets', cancel: 'Cancel' },
      onConfirm: () => goToSubsets(datasetId),
      onCancel: closeAllModals,
    });

  return { open };
};
