import { v4 as uuid } from 'uuid';
import { WorkspaceMember } from '../../../../../../apps/main/generated/api';
import { getSuggestionOptions } from '../suggestions/suggestion';
import { MentionsList } from './MentionList';

const getUniqueMentionId = () => uuid();

export const getMentionSuggestionOptions = (members: WorkspaceMember[]) =>
  getSuggestionOptions({
    items: ({ query }) => {
      if (!query) {
        return members;
      }

      return members
        .filter(item => item.userDetails?.name.toLowerCase().startsWith(query.toLowerCase()))
        .slice(0, 5);
    },
    command: ({ editor, range, props }) => {
      // increase range.to by one when the next node is of type "text"
      // and starts with a space character
      const nodeAfter = editor.view.state.selection.$to.nodeAfter;
      const overrideSpace = nodeAfter?.text?.startsWith(' ');

      if (overrideSpace) {
        range.to += 1;
      }

      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          {
            type: 'mention',
            attrs: {
              id: props.userId,
              mentionId: getUniqueMentionId(),
              label: props.userDetails?.name,
              src: props.userDetails?.avatar,
            },
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run();

      window.getSelection()?.collapseToEnd();
    },
    suggestionComponent: MentionsList,
  });
