import { Box } from '@/shared/design-system';
import { Horizontal } from '@/shared/design-system/v2';
import {
  useGetPRCurveQuery,
  useGetRocCurveQuery,
} from '../../../../queries/evaluations/rocPrCurve';
import { EvaluationVizType, ROCAndPRCurve } from './ROCAndPRCurve';

interface ROCAndPRCurveContainerProps {
  evaluationIds: string[];
  hideBorders?: boolean;
  showBorders?: boolean;
  expandable?: boolean;
}

export const ROCAndPRCurveContainer = ({
  evaluationIds,
  showBorders = true,
  expandable = true,
}: ROCAndPRCurveContainerProps) => {
  const {
    data: rocCurve,
    isLoading: rocCurveLoading,
    isError: rocCurveError,
  } = useGetRocCurveQuery(evaluationIds);

  const {
    data: prCurve,
    isLoading: prCurveLoading,
    isError: prCurveError,
  } = useGetPRCurveQuery(evaluationIds);

  return (
    <Horizontal grow align="stretch">
      <Box>
        <ROCAndPRCurve
          chartData={rocCurve?.data ?? {}}
          metadata={rocCurve?.metadata}
          isLoading={rocCurveLoading}
          isError={rocCurveError}
          chartType={EvaluationVizType.ROC}
          showBorders={showBorders}
          expandable={expandable}
        />
      </Box>
      <Box>
        <ROCAndPRCurve
          chartData={prCurve?.data ?? {}}
          metadata={prCurve?.metadata}
          isLoading={prCurveLoading}
          isError={prCurveError}
          chartType={EvaluationVizType.PR}
          showBorders={showBorders}
          expandable={expandable}
        />
      </Box>
    </Horizontal>
  );
};
