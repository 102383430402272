import { Mark, mergeAttributes } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { Range, getMarkRange } from '@tiptap/react';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { logger } from '@/shared/initializers/logging';

export interface CommentOptions {
  HTMLAttributes: Record<string, any>;
  onClick: (conversationId: string, pos: number, selection: { from: number; to: number }) => void;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    comment: {
      setComment: (attributes?: { conversation: string }, range?: Range) => ReturnType;
      activateComment: (attributes?: { conversation: string }) => ReturnType;
      unsetComment: () => ReturnType;

      removeComment: (conversationId: string) => ReturnType;
    };
  }
}

export const Comment = Mark.create<CommentOptions>({
  name: 'comment',

  // https://tiptap.dev/docs/editor/api/schema#inclusive
  inclusive: false,
  exitable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      onClick: () => undefined,
    };
  },

  addAttributes() {
    return {
      conversation: {
        default: null,
        parseHTML: element => element.getAttribute('data-conversation') ?? 'NEW_CONV',
        renderHTML: attributes => ({
          'data-conversation': attributes.conversation,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'mark',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['mark', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setComment:
        (attributes, range) =>
        ({ commands, chain }) => {
          if (!range) {
            return commands.setMark(this.name, attributes);
          }

          return chain().setTextSelection(range).setMark(this.name, attributes).run();
        },
      activateComment:
        attributes =>
        ({ commands }) =>
          commands.setMark(this.name, attributes),
      unsetComment:
        () =>
        ({ commands }) =>
          commands.unsetMark(this.name),

      removeComment:
        conversationId =>
        ({ state, chain }) => {
          let found: undefined | number;

          state.doc.descendants((node, pos) => {
            if (node.isText) {
              const nodeHasConversation = node.marks.find(
                mark => mark.type.name === 'comment' && mark.attrs.conversation === conversationId,
              );

              if (nodeHasConversation) {
                // the given position here is the position of the node which contains the mark.
                // Mark would start after 1 position and doc would be able to resolve.
                // TODO:: Test this logic thouroughly with multiple use cases.
                found = pos + 1;
              }

              return !nodeHasConversation;
            }

            if (node.type.name === 'embeddingChartBlock') {
              const blockConversationId = get(node, 'attrs.data-conversation-id');
              if (blockConversationId === conversationId) {
                found = pos;
              }
            }

            if (node.type.name === 'chartGridBlock') {
              const gridLayout = get(node, 'attrs.data-grid');

              if (gridLayout) {
                try {
                  JSON.parse(gridLayout).forEach((gl: any) => {
                    if (gl.layout.conversationId === conversationId) {
                      found = pos;
                    }
                  });
                } catch (e) {
                  logger.error(
                    `Error parsing gridLayout while finding position for conversation ${conversationId}`,
                  );
                }
              }
            }
          });

          if (!isUndefined(found)) {
            const resolvedPos = state.doc.resolve(found);
            const markRange = getMarkRange(resolvedPos, state.schema.marks.comment);
            if (markRange) {
              return chain()
                .focus(resolvedPos.pos)
                .setTextSelection(markRange)
                .unsetComment()
                .run();
            }

            if (resolvedPos.parent.attrs['data-blocktype'] === 'embedding-chart') {
              return chain().focus(found).removeConversationIdFromEmbeddingBlock().run();
            }

            return chain().focus(found).removeConversationIdFromLayout(conversationId).run();
          }

          return false;
        },
    };
  },

  addProseMirrorPlugins() {
    // eslint-disable-next-line
    const self = this;
    const plugins = [
      new Plugin({
        props: {
          handleClick(view, pos) {
            const { schema, doc, tr } = view.state;

            const resolvedProps = doc.resolve(pos);
            const range = getMarkRange(resolvedProps, schema.marks.comment);

            if (range) {
              self.options.onClick(self.editor.getAttributes('comment').conversation, pos, {
                from: range.from,
                to: range.to,
              });
            }
          },
        },
      }),
    ];

    return plugins;
  },
});
