import { v4 as uuid } from 'uuid';
import { SnippetAddCommentProperties } from '@/shared/ampli';
import { sendAnalytics } from '@/shared/initializers/analytics';
import { snippetEvents } from '../../../../../../apps/main/analytics';
import { useSnippetDetail } from '../../../../../../apps/main/components/snippets/detail/context/SnippetContext';
import { useAppMetadata } from '../../../../../../apps/main/contexts/app-metadata/AppMetadata';

const sendEvents = (
  snippetId: string,
  workspaceId: string,
  snippetCommand: SnippetAddCommentProperties['snippetCommand'] = 'DATASETS',
) => {
  const command = ['DATASETS', 'EXPERIMENTS', 'EVALUATIONS', 'MODEL_APPS'].find(command =>
    snippetCommand?.startsWith(command),
  ) as SnippetAddCommentProperties['snippetCommand'];

  sendAnalytics(
    snippetEvents.snippet.addComment({
      snippetId: snippetId,
      workspaceId,
      snippetCommand: command,
    }),
  );
};

export const useAddComment = () => {
  const { snippetId, setActiveConversation } = useSnippetDetail();
  const { userId: currentUserId, workspaceId } = useAppMetadata();

  const handleSetActiveConversation = (conversationId?: string, chartId?: string) => {
    if (conversationId) {
      setActiveConversation({ conversationId, userId: currentUserId });
      return;
    }

    const newConversationId = uuid();
    setActiveConversation({ conversationId: newConversationId, userId: currentUserId });
    sendEvents(snippetId, workspaceId, chartId as SnippetAddCommentProperties['snippetCommand']);
    return newConversationId;
  };

  return { handleSetActiveConversation };
};
