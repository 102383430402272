import { Loader } from '@/shared/design-system';
import { Box, Divider, Text, Vertical } from '@/shared/design-system/v2';
import { WorkspaceMember, WorkspaceMembershipRoles } from '../../generated/api';
import { useGetWorkspaceMembersQuery } from '../../queries/account/workspace';
import { ErrorState } from '../common/ErrorState';
import { UserDetail } from '../settings/UserDetail';

export const InvitationNotAllowed = () => {
  const { isLoading, data } = useGetWorkspaceMembersQuery();

  if (isLoading) {
    return <Loader text="Loading details..." />;
  }

  const adminList: WorkspaceMember[] =
    data?.workspaceMembers.filter(
      member =>
        member.workspaceMembershipRole === WorkspaceMembershipRoles.Owner ||
        member.workspaceMembershipRole === WorkspaceMembershipRoles.Maintainer,
    ) ?? [];

  return (
    <ErrorState
      iconName="access_denied"
      title=""
      subtitle={
        <Box>
          <Text variant="bodyShort02">
            You do not have permission to invite users to this workspace.
          </Text>
          <Text variant="bodyShort02">Please reach out to your workspace admin(s).</Text>
          <Divider my="lg" />
          <Vertical>
            {adminList.map(admin => (
              <Box key={admin.userId}>
                <UserDetail
                  avatar={admin.userDetails?.avatar ?? ''}
                  email={admin.userDetails?.email ?? ''}
                  name={admin.userDetails?.name ?? ''}
                />
              </Box>
            ))}
          </Vertical>
        </Box>
      }
    />
  );
};
