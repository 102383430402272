import isEmpty from 'lodash/isEmpty';
import { Loader } from '@/shared/design-system';
import { DatasetVariableTableData, SegmentType } from '../../../../generated/api';
import { useVariableTableQuery } from '../../../../queries/datasets/statistics/variables';
import { InfoMessage } from '../../../common/InfoMessage';
import { BORDER, HEADER_HEIGHT, PADDING, ProfilingTableWidget } from '../ProfilingTableWidget';
import { VariableTable } from './VariableTable';

const TABLE_CONTAINER_HEIGHT = 326;

interface VariableTableContainerProps {
  datasetId: string;
  segmentType: SegmentType;
  variableName: string;
  title: string;
  visualizationId: string;
  showBorder?: boolean;
  height?: number | string;
}

const TABLE_HEIGHT = TABLE_CONTAINER_HEIGHT - (PADDING * 2 + BORDER + HEADER_HEIGHT);

export const VariableTableContainer = ({
  datasetId,
  segmentType,
  variableName,
  title,
  visualizationId,
  showBorder = true,
  height = TABLE_HEIGHT,
}: VariableTableContainerProps): JSX.Element => {
  const variableTableQuery = useVariableTableQuery(
    datasetId,
    segmentType,
    variableName,
    visualizationId,
  );

  let content = null;

  if (variableTableQuery.isLoading) {
    content = <Loader text={`Loading ${title} data`} />;
  }

  if (variableTableQuery.isError) {
    content = <InfoMessage text="Error loading data" />;
  }

  const tableData = variableTableQuery.data as DatasetVariableTableData[];

  if (isEmpty(tableData)) {
    content = <InfoMessage text="No data available" />;
  } else {
    content = (
      <VariableTable visualizationId={visualizationId} data={tableData} showBorder={showBorder} />
    );
  }

  return (
    <ProfilingTableWidget title={title} height={height} showBorder={showBorder}>
      {content}
    </ProfilingTableWidget>
  );
};
