import { TimeInput } from '@mantine/dates';
import { useEffect, useState } from 'react';
import { Button, DatePicker, MODAL_LEVEL_3_Z_INDEX, Modal, Text } from '@/shared/design-system/v2';
import { formatDateToAPISpec, initializeStartDate } from './connector-details/actions/util';

interface DatePickerModalProps {
  isModalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  onConfirm: (startDate: string) => void;
  title: string;
  initialDate: Date | null;
  subtitle?: string;
}

export const DatePickerModal = ({
  isModalOpen,
  setModalOpen,
  onConfirm,
  title,
  initialDate,
  subtitle = 'Data will be fetched from this date onwards. You can edit this start date and sync again to fetch more data.',
}: DatePickerModalProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initialDate || initializeStartDate(),
  );
  const [selectedTime, setSelectedTime] = useState<string>(
    initialDate
      ? `${initialDate.getHours().toString().padStart(2, '0')}:${initialDate
          .getMinutes()
          .toString()
          .padStart(2, '0')}`
      : '00:00',
  );

  useEffect(() => {
    if (isModalOpen && !initialDate) {
      setSelectedDate(initializeStartDate());
      setSelectedTime('00:00');
    }
  }, [isModalOpen, initialDate]);

  const handleConfirmClick = () => {
    if (selectedDate) {
      const [hours, minutes] = selectedTime.split(':').map(Number);
      const fullDateTime = new Date(selectedDate);
      fullDateTime.setHours(hours, minutes, 0, 0);
      const formattedStartDate = formatDateToAPISpec(fullDateTime);
      onConfirm(formattedStartDate);
    }
    setModalOpen(false);
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setModalOpen(false)}
      centered
      title={<Text variant="subTitle01">{title}</Text>}
      style={{
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        maxHeight: '100%',
        zIndex: MODAL_LEVEL_3_Z_INDEX,
      }}
      padding={26}
    >
      <Text
        variant="subTitle03"
        style={{ marginTop: '-8px', paddingBottom: '6px', zIndex: '450' }}
        color="gray.7"
      >
        {subtitle}
      </Text>
      <DatePicker
        defaultDate={selectedDate ?? undefined}
        value={selectedDate}
        onChange={setSelectedDate}
        maxDate={new Date()}
        style={{ marginLeft: '62px' }}
      />
      <TimeInput
        defaultValue={selectedTime}
        value={selectedTime}
        onChange={event => setSelectedTime(event.currentTarget.value)}
        placeholder="Select time"
        style={{ marginTop: '10px' }}
      />
      <Text variant="subTitle03" style={{ marginTop: '10px' }}>
        {selectedDate && selectedTime
          ? `Selected DateTime: ${selectedDate.toLocaleDateString()} ${selectedTime}`
          : 'No date and time selected'}
      </Text>
      <Button
        onClick={handleConfirmClick}
        shape="rounded"
        mt="md"
        disabled={!selectedDate || !selectedTime}
        style={{ marginLeft: '280px' }}
        variant="primary"
      >
        Confirm
      </Button>
    </Modal>
  );
};
