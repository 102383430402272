import { Button, Center, Image, Text, Vertical } from '@/shared/design-system/v2';

// TODO: Move this to a central location
const MARKOV_LOGO_URL = 'https://ik.imagekit.io/markovml/logo/markov-full-logo.svg';

const LOGIN_ILLUSTRATION_URL =
  'https://ik.imagekit.io/markovml/word-add-in/login_DUSYQvnD6j.png?updatedAt=1730974872257';

export interface LoginProps {
  onLogin: () => void;
}

// TODO: Move this component to a shared directory and use it in the office-add-in's login screen
export const Login = ({ onLogin }: LoginProps) => (
  <Center h="100%" bg="white.0">
    <Vertical spacing={40} align="center">
      <Image src={MARKOV_LOGO_URL} alt="MarkovML" height={32} fit="contain" />
      <Vertical spacing="sm" align="center">
        <Image src={LOGIN_ILLUSTRATION_URL} alt="login" height={120} fit="contain" />
        <Vertical spacing="xs">
          <Text ta="center" variant="subTitle02" color="gray.9">
            Welcome to Copy Edit
          </Text>
          <Text ta="center" variant="bodyShort03">
            Your ultimate copilot for fast, precise and professional writing
          </Text>
        </Vertical>
      </Vertical>
      <Vertical spacing="xl" align="center">
        <Button w={160} variant="primary" onClick={onLogin}>
          Sign in
        </Button>
      </Vertical>
    </Vertical>
  </Center>
);
