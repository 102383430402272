import {
  ControlProps,
  RankedTester,
  and,
  hasType,
  optionIs,
  rankWith,
  schemaMatches,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { IconLock } from '@tabler/icons-react';
import { ChangeEvent } from 'react';
import { PasswordInput, Text } from '@/shared/design-system/v2';

const PasswordInputControlComponent = ({
  label,
  description,
  path,
  required,
  uischema,
  data,
  visible,
  enabled,
  handleChange,
  config,
  errors,
}: ControlProps) => {
  if (!visible) {
    return null;
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(path, e.target.value);
  };

  const isViewOnlyForm = config.viewOnly;

  return (
    <PasswordInput
      aria-label={label ?? ' '}
      label={<Text variant="subTitle02">{label}</Text>}
      description={
        <Text variant="small02" color="gray.7" pb="sm">
          {description}
        </Text>
      }
      required={required}
      placeholder={uischema.options?.placeholder}
      icon={<IconLock size="1rem" />}
      onChange={onChange}
      value={data}
      disabled={!isViewOnlyForm && !enabled}
      readOnly={isViewOnlyForm}
      error={config.isFormDirty ? errors : undefined}
    />
  );
};

export const passwordInputControlTester: RankedTester = rankWith(
  7,
  and(
    uiTypeIs('Control'),
    optionIs('secret', true),
    schemaMatches(schema => hasType(schema, 'string')),
  ),
);

export const PasswordInputControl = withJsonFormsControlProps(PasswordInputControlComponent);
