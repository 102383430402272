import { InvitationRequest, WorkspaceMembershipRoles } from '@/main/generated/api';
import { useAppInfo } from '@/shared/contexts/app-info';
import { FullPageLoader } from '@/shared/design-system';
import { notifications } from '@/shared/design-system/v2';
import { sendAnalytics } from '@/shared/initializers/analytics';
import { logger } from '@/shared/initializers/logging';
import { UserflowTrackingEvents, trackUserflowEvent } from '@/shared/initializers/userflow';
import { globalEvents } from '../../analytics';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useCreateInviteToWorkspaceMutation } from '../../queries/account/invite';
import { UserInvitation } from './UserInvitation';

interface UserInvitationContainerProps {
  onClose: () => void;
}

export const UserInvitationContainer = ({ onClose }: UserInvitationContainerProps): JSX.Element => {
  const { redirectToAppType } = useAppInfo();
  const { userId, workspaceId } = useAppMetadata();

  const { isLoading, error, isSuccess, mutate, reset } = useCreateInviteToWorkspaceMutation();

  if (error) {
    const errorMessage = error.response?.data.detail ?? 'Unable to send invite.';
    notifications.show({
      variant: 'error',
      message: errorMessage,
    });
    reset();
  }

  if (isSuccess) {
    notifications.show({
      variant: 'success',
      message: 'Invite sent successfully',
    });

    // This event is being tracked to check off an item from
    // Getting-started checklist in Userflow
    trackUserflowEvent(UserflowTrackingEvents.INVITE_FRIEND_SUCCESS);

    reset();
    onClose();
  }

  const handleSendInvite = async (emails: string[]) => {
    if (!emails.length) {
      logger.error(`Invite member was clickable without adding any emails`);
      notifications.show({
        variant: 'error',
        message: 'Unable to send invite. No emails detected. Please contact MarkovML support',
      });
      return;
    }

    sendAnalytics(
      globalEvents.inviteSentClicked({
        workspaceId,
        emailsForInvite: emails,
      }),
    );

    const invitationReq: InvitationRequest = {
      emails,
      invitedByUser: userId,
      invitedToTeams: {},
      role: WorkspaceMembershipRoles.Member,
      redirectToAppType,
    };

    mutate(invitationReq);
  };

  return (
    <>
      {isLoading && <FullPageLoader text="Sending invite..." />}
      <UserInvitation isSending={isLoading} onSend={handleSendInvite} />
    </>
  );
};
