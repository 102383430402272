import { closeAllModals, openConfirmModal } from '@mantine/modals';
import toLower from 'lodash/toLower';
import { Text, notifications } from '@/shared/design-system/v2';
import { logger } from '@/shared/initializers/logging';
import { useDeleteTemplateMutation } from '../../../../queries/workflows/operators';

export const useTemplateDelete = (templateId: string) => {
  const displayName = 'template';
  const displayState = 'Delete';
  const headingText = `${displayState} ${displayName}`;
  const subheading = `Are you sure you would like to permanently ${toLower(
    displayState,
  )} the selected ${displayName}?`;

  const { mutateAsync: deleteTemplate } = useDeleteTemplateMutation();

  const onConfirmDeletion = async () => {
    try {
      await deleteTemplate(templateId);
      notifications.success(`${displayState}d selected ${displayName}`);
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${displayState} selected ${displayName}`);
      logger.error(error);
    }
  };

  const openDeleteTemplateModal = () =>
    openConfirmModal({
      title: <Text variant="heading03">{headingText}</Text>,
      size: 'lg',
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => closeAllModals(),
      onConfirm: () => onConfirmDeletion(),
      children: <Text variant="bodyShort01">{subheading}</Text>,
    });

  return { openDeleteTemplateModal };
};
