import { Box, Divider, Horizontal, Skeleton, Text, Vertical } from '@/shared/design-system/v2';
import { interleaveComponent } from '@/shared/lib/util-react';

const NotificationItemLoading = (): JSX.Element => (
  <Horizontal p={12} my={2}>
    <Skeleton circle height={16} />
    <Vertical spacing="xs">
      <Skeleton height={12} width={60} />
      <Skeleton height={16} width={320} />
      <Skeleton height={16} width={240} />
    </Vertical>
  </Horizontal>
);

const NOTIFICATION_NUMBER = 7;

export const NotificationLoading = (): JSX.Element => (
  <>
    <Text align="left" variant="subTitle02" px={8} py={4}>
      Notifications
    </Text>
    <Box pt={34}>
      {interleaveComponent(
        Array.from({ length: NOTIFICATION_NUMBER }).map((_, index) => (
          <NotificationItemLoading key={`notification-loading-${index}`} />
        )),
        () => (
          <Divider ml={40} size={1} color="gray.1" />
        ),
      )}
    </Box>
  </>
);
