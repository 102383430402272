import css from '@styled-system/css';
import styled, { keyframes } from 'styled-components';
import { ColorProps, HeightProps, WidthProps } from 'styled-system';
import { getInterpolatedColor } from '@/shared/lib/ui';
import { Box } from '../../design-system';
import theme, { colorGetter } from '../theme';

export interface LoadingRingProps extends ColorProps, HeightProps, WidthProps {
  color?: string;
  borderWidth?: string;
}

const loadingRingKeyframe = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const LoadingRingAnimation = styled(Box)`
  animation: ${loadingRingKeyframe} 1s infinite linear;
`;

export const LoadingRing = styled(LoadingRingAnimation)<LoadingRingProps>(
  css({
    display: 'inline-block',
    borderRadius: '50%',
  }),
  ({ height = '64px', width = '64px', borderWidth = '6px', color = 'loader' }) =>
    css({
      height,
      width,
      border: `${borderWidth} solid`,
      // Workaround for getInterpolatedColor to play nicely with styled-system
      borderColor: getInterpolatedColor(colorGetter(color)({ theme }) ?? color, 0.4),
      borderTop: `${borderWidth} solid`,
      borderTopColor: color,
    }),
);
