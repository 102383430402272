import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { DataTable } from '@/shared/design-system';
import { Text } from '@/shared/design-system/v2';
import { formatNumberLocaleString } from '@/shared/lib/ui';
import { DatasetVariableTableData } from '../../../../generated/api';

const getColumns = () => [
  {
    Header: 'Name',
    accessor: 'displayName',
    width: 250,
    Cell: ({ value }: CellProps<DatasetVariableTableData>) => (
      <Text variant="subTitle04" color="dark.5">
        {value}
      </Text>
    ),
  },
  {
    Header: 'Value',
    accessor: 'value',
    width: 100,
    Cell: ({ value }: CellProps<DatasetVariableTableData>) => (
      <Text variant="subTitle04" color="dark.5" ml="auto">
        {formatNumberLocaleString(value, 6)}
      </Text>
    ),
  },
];

interface VariableTableProps {
  visualizationId: string;
  data: DatasetVariableTableData[];
  showBorder?: boolean;
}

export const VariableTable = ({
  visualizationId,
  data,
  showBorder = true,
}: VariableTableProps): JSX.Element => {
  const columns = useMemo(getColumns, []);
  const rows = useMemo(() => data.map(d => ({ ...d })), [data]);

  const getTableCellProps = () => ({
    style: {
      height: '40px',
      border: showBorder ? undefined : 0,
    },
  });

  return (
    <DataTable
      dataTestId={`dataset-variable-table-${visualizationId}`}
      columns={columns}
      data={rows}
      height="100%"
      getCellProps={getTableCellProps}
      displayConfig={{ hideHeaders: true }}
    />
  );
};
