import { Alert, IconAlertCircle, Prism, Vertical } from '@/shared/design-system/v2';
import { getDateFromTimeStamp } from '@/shared/lib/time-format';
import { APIToken } from '../../../generated/api';

interface TokenProps {
  token: APIToken;
}

export const Token = ({ token }: TokenProps): JSX.Element => {
  const code = `markov init --api-token=${token.apiToken ?? ''}`;
  return (
    <Vertical>
      <Alert icon={<IconAlertCircle size={18} />} color="blue">
        This page is meant for developers using Markov SDK to authenticate headless machines. Copy
        the below command to your terminal.
      </Alert>
      <Prism language="bash">{code}</Prism>
      <Alert icon={<IconAlertCircle size={16} />} color="yellow" w="fit-content">
        Expiry date: {getDateFromTimeStamp(token.expiryDate)}
      </Alert>
    </Vertical>
  );
};
