import { CreateTRPCProxyClient, createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { getAPIBaseUrl } from '@/shared/lib/env.util';

let trpc: CreateTRPCProxyClient<any>;

export const initTRPC = async (userId: string, getTokenFn: () => Promise<string>) => {
  if (!userId || !getTokenFn) {
    throw 'Please pass userId and getToken FN';
  }

  if (!trpc) {
    trpc = createTRPCProxyClient<any>({
      links: [
        httpBatchLink({
          async headers() {
            const token = await getTokenFn();
            return {
              Authorization: `Bearer ${token}`,
              'member-id': userId,
            };
          },
          url: `${getAPIBaseUrl() ?? ''}/aggr`,
        }),
      ],
    });
  }

  return trpc;
};

export const getTRPC = (): any => trpc;
