import { useMemo } from 'react';
import { Column, Row } from 'react-table';
import { Loader, Text, Vertical } from '@/shared/design-system/v2';
import { useModelAppsListInfiniteQuery } from '../../queries/model-apps/model-apps';
import { PageEmptyState } from '../common/EmptyState';
import { PageErrorScreen } from '../common/ErrorState';
import { ModelApps } from './ModelApps';

export interface ModelAppsContainerProps {
  height: string;
  columns: Column<any>[];
  onRowClick: (row: Row<Record<string, unknown>>) => void;
}

export const ModelAppsContainer = ({ height, columns, onRowClick }: ModelAppsContainerProps) => {
  const { isLoading, isError, data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useModelAppsListInfiniteQuery();

  const modelApps = useMemo(() => data?.pages.flatMap(page => page) || [], [data]);

  if (isLoading) {
    return (
      <Vertical align="center" justify="center" h={400}>
        <Loader variant="bars" size="lg" />
        <Text variant="bodyShort02">Loading model apps...</Text>
      </Vertical>
    );
  }

  if (isError) {
    return (
      <PageErrorScreen
        title="Unable to load model apps"
        subtitle="There was an error retrieving model apps for the current workspace. Try refreshing."
        iconName="model"
      />
    );
  }

  if (!modelApps?.length) {
    return <PageEmptyState title="No model apps have been created" iconName="model" />;
  }

  return (
    <ModelApps
      modelApps={modelApps}
      isLoading={isLoading}
      fetchNextPage={fetchNextPage}
      hasNextPage={!!hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      height={height}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};
