import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { chatterApi } from '@/shared/lib/api';
import { HTTPError } from '@/shared/lib/api/api';
import { ThreadProps } from '../../components/common/chat-with-data/menu/panels/threads-panel/ThreadsItem';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  AnswerTypes,
  ArtifactStateTypes,
  ChatterRequestModel,
  DataSourceConversationModel,
  ListChatWithResourceConversationsModel,
  ListChatWithResourceThreadsModel,
} from '../../generated/api';
import { chatWithDataKeys } from '../queryConstants';
import { getNextPageParamHandler } from '../util';

const LIST_CONVERSATIONS_PAGE_SIZE = 5;
const LIST_THREADS_PAGE_SIZE = 15;

export enum ChatResourceTypes {
  DATA_ANALYTICS = 'data-analytics',
  COPY_EDIT = 'copy-edit',
  CUSTOM_APP = 'custom-app',
}

const createThreadLocally = (
  workspaceId: string,
  resourceId: string,
  newConversation: DataSourceConversationModel,
  queryClient: QueryClient,
  resourceType: ChatResourceTypes,
  conversationsKey: Readonly<string[]>,
) => {
  const newConversationKey = chatWithDataKeys.threadConversations(
    workspaceId,
    resourceId,
    newConversation.threadId,
    resourceType,
  );
  const newConversationsData = {
    pages: [{ data: { response: newConversation } }],
  };

  // This will set the conversations data for a new thread, before
  // we switch active thread to this new thread in the following.
  queryClient.setQueryData(newConversationKey, newConversationsData);

  // TODO: Fix this (Akshat).
  setTimeout(() => {
    const threadsKey = chatWithDataKeys.threads(workspaceId, resourceId, resourceType);
    const localThread: ThreadProps = {
      threadId: newConversation.threadId,
      threadTitle: newConversation.question,
      createDate: new Date().toString(),
    };
    const oldThreadsData = queryClient.getQueryData(threadsKey) as InfiniteData<
      ListChatWithResourceConversationsModel[] | undefined
    >;
    const updatedThreadsData = {
      ...(oldThreadsData ?? {}),
      pages: [{ data: { response: localThread } }, ...(oldThreadsData?.pages ?? [])],
    };

    // Locally add this new thread.
    queryClient.setQueryData(threadsKey, updatedThreadsData);

    // Sync threads data with server.
    queryClient.invalidateQueries(threadsKey);

    // Remove queries for next new thread (When user clicks on `+ New chat` again).
    queryClient.removeQueries(conversationsKey);
  }, 500);
};

export const onMutateChatWithData =
  (
    queryClient: ReturnType<typeof useQueryClient>,
    workspaceId: string,
    resourceId: string,
    threadId: string,
    resourceType: ChatResourceTypes,
  ) =>
  (askMarkovRequestModel: ChatterRequestModel) => {
    const localConversation: DataSourceConversationModel & { isLoading?: boolean } = {
      threadId: threadId,
      conversationId: '',
      question: askMarkovRequestModel.question,
      questionType: askMarkovRequestModel.questionType,
      answer: '',
      answerType: AnswerTypes.Text,
      createDate: new Date().toDateString(),
      isLoading: true,
    };

    const conversationsKey = chatWithDataKeys.threadConversations(
      workspaceId,
      resourceId,
      threadId,
      resourceType,
    );

    const conversationsData = queryClient.getQueryData(conversationsKey) as
      | InfiniteData<DataSourceConversationModel[]>
      | undefined;
    const nextConversationsData = {
      ...(conversationsData ?? {}),
      pages: [{ data: { response: [localConversation] } }, ...(conversationsData?.pages ?? [])],
    };

    queryClient.setQueryData(conversationsKey, nextConversationsData);
  };

export const onSuccessChatWithData =
  (
    queryClient: ReturnType<typeof useQueryClient>,
    workspaceId: string,
    resourceId: string,
    threadId: string,
    resourceType: ChatResourceTypes,
  ) =>
  (response: AxiosResponse<DataSourceConversationModel, unknown>) => {
    const newConversation = response.data;

    const conversationsKey = chatWithDataKeys.threadConversations(
      workspaceId,
      resourceId,
      threadId,
      resourceType,
    );

    const conversationsData = queryClient.getQueryData(conversationsKey) as InfiniteData<
      DataSourceConversationModel[]
    >;

    const updatedConversationsData = {
      ...conversationsData,
      pages: [{ data: { response: newConversation } }, ...conversationsData.pages.slice(1)],
    };

    queryClient.setQueryData(conversationsKey, updatedConversationsData);

    if (!threadId) {
      // Handle create new thread logic.
      createThreadLocally(
        workspaceId,
        resourceId,
        newConversation,
        queryClient,
        resourceType,
        conversationsKey,
      );
    }
  };

export const onErrorChatWithData =
  (
    queryClient: ReturnType<typeof useQueryClient>,
    workspaceId: string,
    resourceId: string,
    threadId: string,
    resourceType: ChatResourceTypes,
  ) =>
  () => {
    const conversationsKey = chatWithDataKeys.threadConversations(
      workspaceId,
      resourceId,
      threadId,
      resourceType,
    );
    const conversationsData = queryClient.getQueryData(conversationsKey) as {
      pages: Array<{
        data: {
          response: DataSourceConversationModel[];
        };
      }>;
      pageParams: any;
    };

    const lastConversation = conversationsData.pages.splice(0, 1)[0].data.response[0];

    const updatedLastConversation = {
      ...lastConversation,
      isLoading: false,
      isError: true,
    };

    const updatedConversationsData = {
      ...conversationsData,
      pages: [{ data: { response: [updatedLastConversation] } }, ...conversationsData.pages],
    };

    queryClient.setQueryData(conversationsKey, updatedConversationsData);
  };

export const useChatWithDataSourceThreadConversationsQuery = (
  resourceId: string,
  threadId: string,
  resourceType: ChatResourceTypes,
) => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery(
    chatWithDataKeys.threadConversations(workspaceId, resourceId, threadId, resourceType),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * LIST_CONVERSATIONS_PAGE_SIZE,
        end = start + LIST_CONVERSATIONS_PAGE_SIZE;
      return chatterApi.listChatWithResourceConversationsV2(
        workspaceId,
        threadId,
        start,
        end,
        lastTimestamp,
      );
    },
    {
      getNextPageParam: getNextPageParamHandler(LIST_CONVERSATIONS_PAGE_SIZE),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data.response),
      }),
      enabled: Boolean(workspaceId && resourceId && threadId),
    },
  );
};

export const useChatWithResourceThreadsListQuery = (
  resourceId: string,
  resourceType: ChatResourceTypes,
) => {
  const { workspaceId } = useAppMetadata();

  return useInfiniteQuery<
    AxiosResponse<ListChatWithResourceThreadsModel, unknown>,
    AxiosError<HTTPError>,
    ListChatWithResourceThreadsModel
  >(
    chatWithDataKeys.threads(workspaceId, resourceId, resourceType),
    ({ pageParam = {} }) => {
      const { pageNumber = 0, lastTimestamp = '0' } = pageParam;
      const start = pageNumber * LIST_THREADS_PAGE_SIZE,
        end = start + LIST_THREADS_PAGE_SIZE;
      return chatterApi.listDataSourceThreadsV2(workspaceId, resourceId, start, end, lastTimestamp);
    },
    {
      getNextPageParam: getNextPageParamHandler(LIST_THREADS_PAGE_SIZE),
      select: data => ({
        pageParams: data.pageParams,
        pages: data.pages.map(page => page.data),
      }),
      enabled: Boolean(workspaceId),
    },
  );
};

export const useIsNewConversationMutating = (
  resourceId: string,
  threadId: string,
  resourceType: ChatResourceTypes,
) => {
  const { workspaceId } = useAppMetadata();

  return useIsMutating({
    mutationKey: chatWithDataKeys.query(workspaceId, resourceId, threadId, resourceType),
  });
};

export const useArchiveThreadsByResourceMutation = (
  resourceId: string,
  resourceType: ChatResourceTypes,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (threadIds: Array<string>) =>
      chatterApi.updateConversationThreadsArtifactStateV1(workspaceId, {
        threadIds,
        artifactState: ArtifactStateTypes.Archived,
      }),
    {
      onSuccess: () => {
        // TODO: check if removeQueries makes sense
        queryClient.invalidateQueries(
          chatWithDataKeys.threads(workspaceId, resourceId, resourceType),
        );

        // TODO: Invalidate conversations if needed
      },
    },
  );
};
