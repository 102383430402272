import { MantineSize } from '@mantine/styles';
import first from 'lodash/first';
import { Skeleton, Text } from '@/shared/design-system/v2';
import { UserWithAccountDetails } from '../../generated/api';
import { useUserInfo } from '../../queries/user';
import { UserDetail } from '../settings/UserDetail';

interface UserInfoContainerProps {
  userId: string;
  avatarSize?: MantineSize | number;
  hideAvatar?: boolean;
  spacing?: MantineSize | number;
  showOnlyFirstName?: boolean;
}

export const UserInfoContainer = ({
  userId,
  avatarSize = 'sm',
  hideAvatar = false,
  spacing = 'md',
  showOnlyFirstName = false,
}: UserInfoContainerProps): JSX.Element => {
  const userInfoQuery = useUserInfo(userId);

  if (userInfoQuery.isLoading) {
    return <Skeleton width={100} height="16px" />;
  }

  if (userInfoQuery.isError) {
    return (
      <Text span variant="small01" color="dark.2">
        --
      </Text>
    );
  }

  const user = userInfoQuery.data as UserWithAccountDetails;
  const { name = '', avatar } = user.user;

  return (
    <UserDetail
      name=""
      email={showOnlyFirstName ? first(name.split(' ')) ?? name : name}
      avatar={avatar}
      avatarSize={avatarSize}
      hideAvatar={hideAvatar}
      spacing={spacing}
    />
  );
};
