import { createStyles } from '@/shared/design-system/v2';

export const useInvitationStyles = createStyles(theme => ({
  container: {
    border: '2px solid',
    borderColor: theme.colors.blue[0],
    backgroundColor: theme.colors.blue[0],
    borderRadius: theme.radius.xs,
    padding: 16,
  },
}));
