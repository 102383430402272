import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { notifications } from '@/shared/design-system/v2';
import { mumbaiApi } from '@/shared/lib/api';
import { slackApi } from '@/shared/lib/api/paris';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  UpdateSlackChannelRequestModel,
  UpdateSlackChannelResponseModel,
} from '../../generated/api';
import { SETTINGS } from '../queryConstants';

export const useIntegrationStatusQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    [SETTINGS.INTEGRATION_STATUS, workspaceId],
    () => mumbaiApi.listIntegrations(workspaceId),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data,
      onError: () => {
        notifications.show({
          variant: 'error',
          message: 'Error occurred while fetching Slack integration status.',
        });
      },
    },
  );
};

export const useSlackBotInstallMutation = () => {
  const { workspaceId } = useAppMetadata();
  const redirectUri = window.location.href;
  const queryClient = useQueryClient();

  return useMutation(() => slackApi.createSlackBotInstallUrl({ workspaceId, redirectUri }), {
    onSuccess: () => {
      queryClient.invalidateQueries([SETTINGS.INTEGRATION_STATUS, workspaceId]);
    },
  });
};

export const useListSlackChannelsQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    [SETTINGS.SLACK_CHANNEL_LIST, workspaceId],
    () => slackApi.listSlackChannels(workspaceId),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data,
    },
  );
};

export const useGetSlackChannelQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    [SETTINGS.SLACK_SELECTED_CHANNEL, workspaceId],
    () => slackApi.getWorkspaceSlackConfiguration(workspaceId),
    {
      enabled: Boolean(workspaceId),
      select: res => res.data,
    },
  );
};

export const useUpdateSlackChannelMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<UpdateSlackChannelResponseModel>,
    AxiosError,
    Omit<UpdateSlackChannelRequestModel, 'workspaceId'>
  >(
    ({ channelId, channelName }) =>
      slackApi.updateSlackChannel({ workspaceId, channelId, channelName }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SETTINGS.SLACK_SELECTED_CHANNEL, workspaceId]);
      },
    },
  );
};
