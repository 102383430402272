import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { operatorsApi, workflowApi } from '@/shared/lib/api';
import { HTTPError } from '@/shared/lib/api/api';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import {
  ExportTemplateRequest,
  FilePreview,
  NodeOutput,
  OperatorCategory,
  OperatorListResponse,
  WorkflowNodeSchema,
  WorkflowNodeSchemaResponse,
} from '../../generated/api';

export const operatorKeys = {
  all: ['workflow-operators'],
  list: (workspaceId: string) => [...operatorKeys.all, 'list', workspaceId],
  publicList: () => [...operatorKeys.all, 'public-list'],
  detail: (workspaceId: string, operatorId: string) => [
    ...operatorKeys.all,
    'detail',
    workspaceId,
    operatorId,
  ],
  getRunLogs: (workspaceId: string, workflowId: string, runId: string) => [
    ...operatorKeys.all,
    'get-run-logs',
    workspaceId,
    workflowId,
    runId,
  ],
  getNodeRunLogs: (workspaceId: string, workflowId: string, runId: string, nodeId: string) => [
    ...operatorKeys.all,
    'get-run-logs',
    workspaceId,
    workflowId,
    runId,
    nodeId,
  ],
  getDagIOSchema: (workspaceId: string, workflowId: string) => [
    ...operatorKeys.all,
    'get-run-preview',
    workspaceId,
    workflowId,
  ],
  getWorkflowDagNodesSchema: (
    workspaceId: string,
    workflowId: string,
    workflowRunId?: string,
    nodeId?: string,
    appId?: string,
  ) => [
    ...operatorKeys.all,
    'get-run-preview-node',
    workspaceId,
    workflowId,
    workflowRunId,
    nodeId,
    appId,
  ],
  getNodeRunPreview: (
    workspaceId: string,
    workflowId: string,
    runId: string,
    nodeId: string,
    operatorCategory: string,
  ) => [
    ...operatorKeys.all,
    'get-run-preview',
    workspaceId,
    workflowId,
    runId,
    nodeId,
    operatorCategory,
  ],
};

export const templateKeys = {
  all: 'workflow-templates',
  list: (workspaceId: string) => [templateKeys.all, 'list', workspaceId],
  publicList: () => [templateKeys.all, 'public-list'],
};

export const useGetOperatorListQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(operatorKeys.list(workspaceId), () => operatorsApi.listOperatorsV1(workspaceId), {
    enabled: !!workspaceId,
    select: data => data.data.operators,
    staleTime: Infinity,
  });
};

export const useGetOperatorDetailsQuery = (operatorId: string) => {
  const { workspaceId } = useAppMetadata();

  const queryClient = useQueryClient();

  return useQuery({
    queryKey: operatorKeys.detail(workspaceId, operatorId),
    queryFn: async () => {
      const response = await operatorsApi.getOperatorDetailsV1(workspaceId, operatorId);
      return response.data;
    },
    initialData: () => {
      const operatorList = queryClient.getQueryData<AxiosResponse<OperatorListResponse>>(
        operatorKeys.list(workspaceId),
      )?.data.operators;
      return operatorList?.find(op => op.operatorId === operatorId) || undefined;
    },
    enabled: !!workspaceId && !!operatorId,
  });
};

export const useNodeRunLogsQuery = (workflowId: string, runId: string, nodeId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    operatorKeys.getNodeRunLogs(workspaceId, workflowId, runId, nodeId),
    () => workflowApi.getWorkflowRunNodeLogFileV1(workspaceId, workflowId, runId, nodeId),
    {
      enabled: !!workspaceId && !!workflowId && !!runId,
      select: data => data.data as any,
    },
  );
};

export const useGetTemplatesPublicListQuery = () =>
  useQuery(templateKeys.publicList(), async () => {
    const response = await fetch('https://vienna.markovml.com/v1/workflows/templates');
    if (!response.ok) {
      throw new Error('Failed to fetch operator list');
    }
    return response.json();
  });

export const useGetOperatorsPublicListQuery = () =>
  useQuery(operatorKeys.publicList(), async () => {
    const response = await fetch('https://markovml.github.io/SanFrancisco/operators_config.json');
    if (!response.ok) {
      throw new Error('Failed to fetch operator list');
    }
    return response.json();
  });

export const useGetTemplatesListQuery = () => {
  const { workspaceId } = useAppMetadata();

  return useQuery(templateKeys.list(workspaceId), () => operatorsApi.listTemplatesV1(workspaceId), {
    enabled: !!workspaceId,
    select: data => data.data.templates,
    staleTime: Infinity,
  });
};

export const useNodeDataPreviewQuery = (
  workflowId: string,
  runId: string,
  nodeId: string,
  operatorCategory: OperatorCategory | '',
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<NodeOutput>, AxiosError<any>, FilePreview>(
    operatorKeys.getNodeRunPreview(workspaceId, workflowId, runId, nodeId, operatorCategory),
    () =>
      workflowApi.getWorkflowRunNodeOutputV1(
        workspaceId,
        workflowId,
        runId,
        nodeId,
        operatorCategory,
      ),
    {
      enabled: !!workspaceId && !!workflowId,
      select: data => data.data.data,
    },
  );
};

export const useDagIOSchemaQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    operatorKeys.getDagIOSchema(workspaceId, workflowId),
    () => workflowApi.getWorkflowDagNodesSchemaV1(workspaceId, workflowId),

    {
      enabled: !!workspaceId && !!workflowId,
      select: data => data.data.response,
      cacheTime: 0,
      staleTime: 0,
    },
  );
};

export const useGetEntireWorkflowDagNodesSchemaV2Query = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<WorkflowNodeSchemaResponse>,
    AxiosError<HTTPError>,
    WorkflowNodeSchema
  >(
    operatorKeys.getDagIOSchema(workspaceId, workflowId),
    () => workflowApi.getWorkflowDagNodesSchemaV2(workspaceId, workflowId),
    {
      enabled: Boolean(workspaceId && workflowId),
      select: data => data.data.response,
    },
  );
};

export const useGetWorkflowDagNodesSchemaV2Query = (
  workflowId: string,
  nodeId: string,
  workflowRunId?: string,
  appId?: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<WorkflowNodeSchemaResponse>,
    AxiosError<HTTPError>,
    WorkflowNodeSchema
  >(
    operatorKeys.getWorkflowDagNodesSchema(workspaceId, workflowId, workflowRunId, nodeId, appId),
    () =>
      workflowApi.getWorkflowDagNodesSchemaV2(
        workspaceId,
        workflowId,
        workflowRunId,
        nodeId,
        appId,
      ),
    {
      enabled: Boolean(workspaceId && workflowId),
      select: data => data.data.response,
    },
  );
};

export const useInvalidateDagNodeSchemaQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return (nodeId: string, workflowRunId?: string, appId?: string) => {
    queryClient.invalidateQueries(
      operatorKeys.getWorkflowDagNodesSchema(workspaceId, workflowId, workflowRunId, nodeId, appId),
    );
  };
};

export const useDeleteTemplateMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useMutation(
    (templateId: string) => operatorsApi.deleteWorkflowTemplatesV1(workspaceId, templateId),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: templateKeys.list(workspaceId) }),
    },
  );
};

export const useExportAsJsonMutation = () => {
  const { workspaceId } = useAppMetadata();

  return useMutation({
    mutationFn: (request: ExportTemplateRequest) =>
      operatorsApi.exportAsWorkflowTemplatesV1(workspaceId, request),
  });
};
