import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { workflowApi } from '@/shared/lib/api';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { ListWorkflowsResponse, UpdateWorkflowRequest, Workflow } from '../../../generated/api';
import { workflowsQueryKeys } from '../list/list';

export const useGetWorkflowDetailsQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: workflowsQueryKeys.detail(workspaceId, workflowId),
    queryFn: async () => {
      const response = await workflowApi.getWorkflowV1(workspaceId, workflowId);
      return response.data;
    },
    initialData: () => {
      const workflowsQueriesData = queryClient.getQueriesData<AxiosResponse<ListWorkflowsResponse>>(
        workflowsQueryKeys.list(workspaceId),
      );
      for (const [_, queryData] of workflowsQueriesData) {
        const workflows = queryData?.data.response;
        if (workflows) {
          const cachedWorkflow = workflows.find(
            (workflow: Workflow) => workflow.workflowId === workflowId,
          );
          if (cachedWorkflow) {
            return cachedWorkflow;
          }
        }
      }
    },
    select: data => data,
    enabled: !!workflowId && !!workspaceId,
  });
};

export const useUpdateWorkflowDetailsMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: UpdateWorkflowRequest) => workflowApi.updateWorkflowV1(workspaceId, workflowId, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workflowsQueryKeys.all);
      },
    },
  );
};
