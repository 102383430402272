import { MantineTheme, createStyles } from '@/shared/design-system/v2/styles';

export const ACTIONS_OFFSET_BOTTOM = 16;
export const ACTIONS_OFFSET_X = 16;

export const useChartActions = createStyles((theme: MantineTheme) => ({
  actions: {
    position: 'absolute',
    bottom: ACTIONS_OFFSET_BOTTOM,
    right: ACTIONS_OFFSET_X,
  },

  actionIcon: {
    backgroundColor: theme.colors.dark[8],
    height: '40px',
    width: '48px',

    ...theme.fn.hover({
      backgroundColor: theme.colors.blue[4],
    }),
  },
  selectedActionIcon: {
    backgroundColor: theme.colors.blue[8],
  },
}));
