import { ControlProps, RankedTester, and, optionIs, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import castArray from 'lodash/castArray';
import last from 'lodash/last';
import { Box } from '@/shared/design-system/v2';
import { OperatorDataPreviewContainer } from '../../../../workflows/create/workflow-builder/operator-parameter-form/operator-data-preview/OperatorDataPreview.container';

const TableControlComponent = ({
  handleChange,
  path,
  config,
  schema,
  uischema,
  data,
  visible,
}: ControlProps) => {
  const type = schema.type;
  const {
    workflowId,
    runId,
    nodeId,
    prevNodeId,
    operatorCategory,
    operatorId,
    isFormDirty,
    isFormSaving,
    appId,
  } = config;

  if (!visible) {
    return null;
  }

  const allowColumnSelection = uischema.options?.selection === true;

  const onChange = (val: string[]) => {
    if (type === 'string') {
      handleChange(path, last(val) ?? null);
      return;
    }
    handleChange(path, val);
  };

  return (
    <Box h="100%">
      <OperatorDataPreviewContainer
        nodeId={nodeId}
        workflowId={workflowId}
        runId={runId}
        appId={appId}
        prevNodeId={prevNodeId}
        operatorCategory={operatorCategory}
        selectedColumns={castArray(data)}
        setSelectedColumns={onChange}
        allowColumnSelection={allowColumnSelection}
        operatorId={operatorId}
        isFormDirty={isFormDirty}
        isFormSaving={isFormSaving}
      />
    </Box>
  );
};

export const tableControlTester: RankedTester = rankWith(
  10,
  and(uiTypeIs('Control'), optionIs('variant', 'table')),
);

export const TableControl = withJsonFormsControlProps(TableControlComponent);
