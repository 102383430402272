import { Tabs, Text } from '@/shared/design-system/v2';

export enum WorkflowRunTabs {
  ALL = 'Runs',
  UPCOMING = 'Upcoming',
}
const TABS = [
  {
    id: WorkflowRunTabs.ALL,
    label: 'Runs',
  },
  {
    id: WorkflowRunTabs.UPCOMING,
    label: 'Upcoming',
  },
];

export interface WorkflowRunsListTabsProps {
  activeTab: WorkflowRunTabs;
  onTabChange: (tabId: WorkflowRunTabs) => void;
}

export const WorkflowRunsListTabs = ({ activeTab, onTabChange }: WorkflowRunsListTabsProps) => (
  <Tabs
    value={activeTab}
    onTabChange={onTabChange}
    styles={theme => ({
      tabsList: {
        borderBottom: `none`,
      },
      tab: {
        height: '36px',
        '&[data-active]': {
          borderBottom: `3px solid ${theme.colors.blue[4]}`,
        },
      },
      panel: {
        display: 'flex',
      },
    })}
    px="lg"
    pt="sm"
  >
    <Tabs.List>
      {TABS.map(tab => (
        <Tabs.Tab key={tab.id} value={tab.id} h={32}>
          <Text
            variant="overline"
            color={tab.id === activeTab ? 'gray.9' : 'gray.6'}
            tt="uppercase"
          >
            {tab.label}
          </Text>
        </Tabs.Tab>
      ))}
    </Tabs.List>
  </Tabs>
);
