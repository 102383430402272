import { PropsWithChildren } from 'react';
import { SimpleGrid, SimpleGridProps } from '@/shared/design-system/v2';

export type AppDetailsHeaderLayoutProps = SimpleGridProps;

export const AppDetailsHeaderLayout = ({
  children,
  cols = 1,
  ...rest
}: PropsWithChildren<SimpleGridProps>): JSX.Element => (
  <SimpleGrid cols={cols} {...rest}>
    {children}
  </SimpleGrid>
);
