import noop from 'lodash/noop';
import { useState } from 'react';
import { Box, Button, Horizontal, Skeleton, Text, Vertical } from '@/shared/design-system/v2';
import { DataResourceType, StorageType } from '../../../generated/api';
import { useDataSourceDetailsQuery } from '../../../queries/data-sources';
import { CompleteParams } from '../../../queries/data-upload';
import { StorageMetadataModel, storageConfigMap } from '../../data-sources/cell-renderers/config';
import { ConnectorSourceOperatorTableView } from '../../workflows/create/workflow-builder/operator-parameter-form/connector-source/ConnectorSourceOperatorTableView';
import { ExistingFolderUploader } from '../uploader/folder-uploader/ExisitingFolderUploader';
import { FolderUploader } from '../uploader/folder-uploader/FolderUploader';

interface FolderSelectionSummaryProps {
  label: string;
  description?: string;
  resourceId: string;
  isEditable?: boolean;

  onUploadQueued?: (uploadId?: string) => void;
  onChange: (resourceId: string) => void;
  onComplete: (state: 'success' | 'error', completeParams: CompleteParams) => void;
  additionalData?: Record<string, any>;
}

export const FolderSelectionSummary = ({
  resourceId,
  label,
  description,
  onChange,
  isEditable = true,
  onUploadQueued = noop,
  additionalData,
  onComplete,
}: FolderSelectionSummaryProps): JSX.Element => {
  const [showTableView, setShowTableView] = useState(false);
  const query = useDataSourceDetailsQuery(resourceId);

  const isLoading = query.isInitialLoading;
  const connectorType = query.data?.connectorType;
  const resourceMetadata = query.data?.resourceMetadata as StorageMetadataModel;
  const [uploadFilesTriggered, setUploadFilesTriggered] = useState(false);
  const name =
    connectorType && resourceMetadata
      ? storageConfigMap[connectorType]?.getResourceName(resourceMetadata) ?? ''
      : '';

  const handleChangeFolder = () => {
    onChange('');
  };

  const handleUploadFilesToFolder = () => {
    setUploadFilesTriggered(true);
  };

  const toggleTableView = () => {
    setShowTableView(prev => !prev);
  };

  const handleResourceSelection = (resourceId: string) => {
    onChange(resourceId);
    toggleTableView();
  };

  return (
    <>
      {showTableView && (
        // Fill form modal with table when user is selecting a data source
        <Box pos="absolute" top={0} left={0} right={0} bottom={0} bg="white.0" sx={{ zIndex: 10 }}>
          <ConnectorSourceOperatorTableView
            connectorType={StorageType.MarkovLibrary}
            onSelectResource={handleResourceSelection}
            allowAddResource={false}
            dataResourceType={DataResourceType.Folder}
            onClickBack={toggleTableView}
          />
        </Box>
      )}

      <Vertical spacing="lg">
        {isLoading ? (
          <Skeleton mt="sm" h={40} w="60%" />
        ) : name ? (
          uploadFilesTriggered ? (
            <ExistingFolderUploader
              onUploadQueued={onUploadQueued}
              onUploadComplete={onComplete}
              additionalData={additionalData}
              folderName={name}
              handleGoBack={() => setUploadFilesTriggered(false)}
            />
          ) : (
            <Vertical spacing="xs" pt="sm">
              <Box w="fit-content" bg="gray.1" py="sm" px="md" sx={{ borderRadius: '8px' }}>
                <Text
                  variant="subTitle03"
                  lineClamp={1}
                  title={name}
                  sx={{ wordBreak: 'break-all' }}
                >
                  {name}
                </Text>
              </Box>
              <Horizontal noWrap align="space-between" mt="sm">
                {isEditable && (
                  <>
                    <Button variant="subtle" onClick={handleChangeFolder} px="xs">
                      Change Folder
                    </Button>
                    <Button variant="subtle" onClick={handleUploadFilesToFolder} px="xs">
                      Add more files to folder
                    </Button>
                  </>
                )}
              </Horizontal>
            </Vertical>
          )
        ) : (
          <Vertical>
            <FolderUploader
              onUploadQueued={onUploadQueued}
              onUploadComplete={onComplete}
              additionalData={additionalData}
            />
            <Text variant="subTitle04" color="gray.7">
              Or pick a folder previously uploaded to the Markov Library
            </Text>
            <Button variant="light" onClick={toggleTableView} sx={{ alignSelf: 'center' }}>
              Select from Library
            </Button>
          </Vertical>
        )}
      </Vertical>
    </>
  );
};
