import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { PaymentSubscriptionType } from '../../../generated/api';
import { useCreateCheckoutSessionMutation } from '../../../queries/account/payment-and-subscription';
import { getStripeInstanceWithRetry } from '../stripe';

interface CheckoutFormProps {
  subscriptionType: PaymentSubscriptionType;
  onComplete?: () => void;
}

export const SubscriptionPlanCheckoutForm = ({
  subscriptionType,
  onComplete,
}: CheckoutFormProps) => {
  const { mutateAsync: createCheckoutSession } = useCreateCheckoutSessionMutation();
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await getStripeInstanceWithRetry();
      setStripe(stripeInstance);
    };
    initializeStripe();
  }, []);

  const fetchClientSecret = useCallback(async () => {
    const res = await createCheckoutSession(subscriptionType);
    return res.data.clientSecret;
  }, [subscriptionType]);

  const options = {
    fetchClientSecret,
    onComplete,
  };

  return (
    <EmbeddedCheckoutProvider stripe={stripe} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};
