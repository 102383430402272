import { Grid, Text, openModal } from '@/shared/design-system/v2';
import { HelpAndSupportModalTabs } from './HelpAndSupportModalTabs';
import { HelpCategoriesSection } from './HelpCategoriesSection';

const HelpAndSupportModal = () => (
  <Grid mx={0}>
    <Grid.Col span="content">
      <HelpAndSupportModalTabs />
    </Grid.Col>
    <Grid.Col span="auto">
      <HelpCategoriesSection />
    </Grid.Col>
  </Grid>
);

export const useHelpAndSupportModal = () => {
  const open = () =>
    openModal({
      title: (
        <Text component="div" variant="subTitle01">
          Help and support
        </Text>
      ),
      size: '900px',
      children: <HelpAndSupportModal />,
    });

  return {
    open,
  };
};
