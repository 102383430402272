import { useMemo, useRef } from 'react';
import {
  Cell,
  Column,
  HeaderGroup,
  HeaderGroupPropGetter,
  Row,
  useFilters,
  useFlexLayout,
  useTable,
} from 'react-table';
import { Loading } from '../../../../../apps/main/components/common/Loading';
import { useIntersectionObserver } from '../../../../../apps/main/hooks/useIntersectionObserver';
import { Box, Button, Center, Horizontal, Text } from '../../../v2';
import {
  StyledDataTableWrapper,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeader,
  StyledTableRow,
} from './DataTable.style';

export interface TableDisplayConfig {
  hideHeaders?: boolean;
}

interface DataTableProps {
  // TODO: Fix type
  columns: Column<any>[];
  data: Record<string, unknown>[];
  dataTestId: string;
  height?: string;
  tableStyle?: Record<string, unknown>;
  isLoading?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  getHeaderProps?: (header: HeaderGroup<any>) => HeaderGroupPropGetter<any>;
  getRowProps?: (row: Row<any>) => any;
  getCellProps?: (cell: Cell<any>) => any;
  getIsRowDisabled?: (row: Row<any>) => boolean;
  getIsRowSelected?: (row: Row<any>) => boolean;
  displayConfig?: TableDisplayConfig;
}

export const DataTable = ({
  columns,
  data,
  dataTestId,
  height,
  tableStyle = {},
  isLoading = false,
  isFetchingNextPage = false,
  hasNextPage = false,
  fetchNextPage = () => undefined,
  getHeaderProps = () => ({}),
  getRowProps = () => ({}),
  getCellProps = () => ({}),
  getIsRowDisabled = () => false,
  getIsRowSelected = () => false,
  displayConfig,
}: DataTableProps): JSX.Element => {
  // TODO: Replace this with the new default text filter
  const defaultColumn = useMemo(
    () => ({
      Filter: <></>,
    }),
    [],
  );

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // TODO: Set manualFilters: true once server-side filtering is supported on all columns
    },
    useFilters,
    useFlexLayout,
  );

  const listEndRef = useRef(null);

  // Infinite scroll pagination is supported using optional props hasNextPage and fetchNextPage
  useIntersectionObserver({
    enabled: hasNextPage,
    target: listEndRef,
    onIntersect: fetchNextPage,
  });

  const tableProps = getTableProps();
  const tbodyProps = getTableBodyProps();

  return (
    <StyledDataTableWrapper height={height} style={tableStyle}>
      <StyledTable dataTestId={dataTestId} {...tableProps}>
        {!displayConfig?.hideHeaders && (
          <StyledTableHead>
            {headerGroups.map(headerGroup => {
              // Must explicitly pass key prop to avoid eslint error
              // https://github.com/tannerlinsley/react-table/discussions/2647
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

              return (
                <StyledTableRow key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(header => {
                    const { key, ...restHeaderProps } = header.getHeaderProps(
                      getHeaderProps(header),
                    );

                    return (
                      <StyledTableHeader key={key} {...restHeaderProps}>
                        <Horizontal position="apart">
                          <Text variant="subTitle04" color="dark.7" lineClamp={1}>
                            {header.render('Header')}
                          </Text>
                          {header.canFilter ? <Box ml="10px">{header.render('Filter')}</Box> : null}
                        </Horizontal>
                      </StyledTableHeader>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </StyledTableHead>
        )}
        <StyledTableBody {...tbodyProps}>
          {rows.map(row => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps(getRowProps(row));
            const rowDataTestId = `${dataTestId}-${key}`;

            return (
              <StyledTableRow
                key={key}
                dataTestId={rowDataTestId}
                isDisabled={getIsRowDisabled(row)}
                isSelected={getIsRowSelected(row)}
                {...restRowProps}
              >
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps(getCellProps(cell));

                  return (
                    <StyledTableCell key={key} {...restCellProps}>
                      {cell.render('Cell')}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
          {isLoading && (
            <tr style={{ height: '300px', position: 'relative' }}>
              <td>
                <Center>
                  <Loading />
                </Center>
              </td>
            </tr>
          )}
          {hasNextPage && (
            <tr ref={listEndRef} style={{ height: '50px', position: 'relative' }}>
              <td>
                <Center>
                  {/* Intersection Observer is behaving weirdly for some edge case in larger screen, added load more button for now. */}
                  {/* TODO: Debug and fix this */}
                  {isFetchingNextPage ? (
                    <Loading />
                  ) : (
                    <Button onClick={fetchNextPage}>Load More</Button>
                  )}
                </Center>
              </td>
            </tr>
          )}
        </StyledTableBody>
      </StyledTable>
    </StyledDataTableWrapper>
  );
};
