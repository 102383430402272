import {
  IconBucket,
  IconDatabase,
  IconFile,
  IconFolderFilled,
  IconSchema,
  IconTable,
} from '@tabler/icons-react';
import { useMemo } from 'react';
import { Box, Tooltip, useMarkovTheme } from '@/shared/design-system/v2';
import { toSentenceCase } from '@/shared/lib/ui';
import { ResourceKey } from '../../../generated/api';

interface ResourceIconDetails {
  icon: JSX.Element;
  tooltipLabel: string;
}

export const useResourceIcon = () => {
  const theme = useMarkovTheme();
  const iconColor = theme.colors.gray[6];

  const resourceIconDetailsMap: Partial<Record<ResourceKey, ResourceIconDetails>> = useMemo(
    () => ({
      [ResourceKey.SnowflakeDb]: {
        tooltipLabel: 'Database',
        icon: <IconDatabase color={iconColor} />,
      },
      [ResourceKey.SnowflakeSchema]: {
        tooltipLabel: 'Schema',
        icon: <IconSchema color={iconColor} />,
      },
      [ResourceKey.SnowflakeTable]: {
        tooltipLabel: 'Table',
        icon: <IconTable color={iconColor} />,
      },
      [ResourceKey.S3Bucket]: {
        tooltipLabel: 'S3 Bucket',
        icon: <IconBucket color={iconColor} />,
      },
      [ResourceKey.S3Folder]: {
        tooltipLabel: 'Folder',
        icon: (
          // Note: Filled icons require defining path color
          <Box
            sx={{
              path: {
                color: iconColor,
              },
            }}
          >
            <IconFolderFilled />
          </Box>
        ),
      },
      [ResourceKey.S3File]: {
        tooltipLabel: 'File',
        icon: <IconFile color={iconColor} />,
      },
      [ResourceKey.PostgresDb]: {
        tooltipLabel: 'PostgreSQL DB',
        icon: <IconDatabase color={iconColor} />,
      },
      [ResourceKey.PostgresTable]: {
        tooltipLabel: 'PostgreSQL Table',
        icon: <IconTable color={iconColor} />,
      },
      [ResourceKey.AzureBlobContainer]: {
        tooltipLabel: 'Azure Blob Container',
        icon: <IconBucket color={iconColor} />,
      },
      [ResourceKey.AzureBlobFolder]: {
        tooltipLabel: 'Azure Blob Folder',
        icon: (
          <Box
            sx={{
              path: {
                color: iconColor,
              },
            }}
          >
            <IconFolderFilled />
          </Box>
        ),
      },
      [ResourceKey.AzureBlob]: {
        tooltipLabel: 'Azure Blob',
        icon: <IconFile color={iconColor} />,
      },
      [ResourceKey.SpreadsheetWorksheet]: {
        tooltipLabel: 'Google Sheet',
        icon: <IconFile color={iconColor} />,
      },
      [ResourceKey.GoogledriveFile]: {
        tooltipLabel: 'Google Drive File',
        icon: <IconFile color={iconColor} />,
      },
      [ResourceKey.MkvLibrary]: {
        tooltipLabel: 'Markov Files',
        icon: <IconFile color={iconColor} />,
      },
      [ResourceKey.HubspotStream]: {
        tooltipLabel: 'Hubspot',
        icon: <IconFile color={iconColor} />,
      },
    }),
    [iconColor],
  );

  const getIconForResourceType = (type: ResourceKey) => {
    const resourceIconDetails = resourceIconDetailsMap[type];
    const { tooltipLabel, icon = <IconFile color={iconColor} /> } = resourceIconDetails ?? {};

    return (
      <Tooltip withArrow withinPortal label={tooltipLabel ?? toSentenceCase(type)}>
        {icon}
      </Tooltip>
    );
  };

  return getIconForResourceType;
};
