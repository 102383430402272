import { useAuth0 } from '@auth0/auth0-react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import noop from 'lodash/noop';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FullPageLoader } from '@/shared/design-system';
import { initializeAnalytics } from '@/shared/initializers/analytics';
import { identifyUserChecksum } from '@/shared/initializers/checksum';
import { identifyClarityUser } from '@/shared/initializers/clarity';
import { setSentryUser } from '@/shared/initializers/sentry';
import { initTRPC } from '@/shared/initializers/trpc';
import { registerUserflow } from '@/shared/initializers/userflow';
import { registerAxiosInterceptor } from '@/shared/lib/api';
import { isDevelopment } from '@/shared/lib/env.util';
import { IDTokenInfo } from '../../generated/api';
import { FeatureFamily, getBasePathFromPathname, getConfigFromPath } from '../../router/constants';

const AUTH0_CLAIMS_NAMESPACE = 'https://app.markovml.com/auth';

export interface AppMetadata {
  workspaceId: string;
  setWorkspaceId: (workspaceId: string) => void;
  activeSection: FeatureFamily;
  setActiveSection: (section: FeatureFamily) => void;
  userId: string;
  loginError: string;
  areFlagsLoaded: boolean;
}

const initialContext: AppMetadata = {
  workspaceId: '',
  setWorkspaceId: noop,
  activeSection: FeatureFamily.DATA_STUDIO,
  setActiveSection: noop,
  userId: '',
  loginError: '',
  areFlagsLoaded: false,
};

export interface ExtendedIDTokenInfo extends IDTokenInfo {
  email: string;
}

const AppMetadataContext = createContext<AppMetadata>(initialContext);

export const useAppMetadata = () => useContext(AppMetadataContext);

const extractIDTokenInfo = (userObject: any): ExtendedIDTokenInfo =>
  userObject[AUTH0_CLAIMS_NAMESPACE];

export const AppMetadataContextProvider = ({
  children,
}: PropsWithChildren<Record<never, unknown>>) => {
  const location = useLocation();
  const { featureGlobalHomepage } = useFlags();

  // TODO: Move LaunchDarkly code to a new file.
  // AppMetadata should only act as a global app context provider
  const [areFlagsLoaded, setFlagsLoaded] = useState(false);

  // LaunchDarkly client
  const ldClient = useLDClient();

  const { isLoading, user, getAccessTokenSilently, error } = useAuth0();

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>('');
  const initSection = featureGlobalHomepage
    ? FeatureFamily.GLOBAL_HOME
    : initialContext.activeSection;
  const [activeSection, setActiveSection] = useState<FeatureFamily>(initSection);

  // Suppressing the error to be reported on sentry
  // See: https://support.launchdarkly.com/hc/en-us/articles/12998125691419-Error-LaunchDarklyFlagFetchError-network-error-
  useEffect(() => {
    if (ldClient) {
      ldClient.on('error', noop);
    }
  }, [ldClient]);

  useEffect(() => {
    // BE microservices now mandates the member-id header to be present in the requests
    // All the APIs will be removing userid from url path
    if (user) {
      const userInfo = extractIDTokenInfo(user);
      const userId = userInfo?.userId;
      registerAxiosInterceptor(getAccessTokenSilently, userId);
      initTRPC(userId, getAccessTokenSilently);

      if (isDevelopment()) {
        identifyUserChecksum(userId);
      }
    }
  }, [getAccessTokenSilently, user]);

  // Setting context for LaunchDarkly
  useEffect(() => {
    if (user && selectedWorkspaceId) {
      const userInfo = extractIDTokenInfo(user);
      const userContext = {
        kind: 'user',
        key: userInfo.userId,
        name: userInfo.name ?? '',
        email: userInfo.email,
      };
      const workspaceContext = {
        kind: 'workspace',
        key: selectedWorkspaceId,
      };

      const ldContext = {
        kind: 'multi',
        user: userContext,
        workspace: workspaceContext,
      };
      // Identify user for LaunchDarkly
      ldClient?.identify(ldContext).then(() => {
        setFlagsLoaded(true);
      });
    }
  }, [user, selectedWorkspaceId]);

  // Initializations for third-party apps using user information
  useEffect(() => {
    if (user) {
      const userInfo = extractIDTokenInfo(user);
      if (!userInfo) {
        return;
      }

      const { userId, name = '', email } = userInfo;

      // Initialize analytics
      initializeAnalytics(userId, email);

      // Register user flow event
      registerUserflow(userId, name, email);

      setSentryUser(userId, name, email);

      identifyClarityUser(userId, name);
    }
  }, [user]);

  // When page loads or location changes, update the active section. If no section is
  // associated with the current bath path, maintain the existing section.
  useEffect(() => {
    const basePath = getBasePathFromPathname(location.pathname);
    // Handle special case for global homepage
    if (basePath === '' && featureGlobalHomepage) {
      setActiveSection(FeatureFamily.GLOBAL_HOME);
      return;
    }

    const config = getConfigFromPath(basePath);
    if (config?.family) {
      setActiveSection(config.family);
    }
  }, [location.pathname, featureGlobalHomepage]);

  // Memoizing this, as LaunchDarkly SDK hook can cause rerender
  const appMetadata: AppMetadata = useMemo(
    () => ({
      workspaceId: selectedWorkspaceId,
      setWorkspaceId: setSelectedWorkspaceId,
      userId: user ? extractIDTokenInfo(user)?.userId ?? '' : '',
      loginError: error?.message ?? '',
      activeSection,
      setActiveSection,
      areFlagsLoaded,
    }),
    [
      user,
      error,
      selectedWorkspaceId,
      setSelectedWorkspaceId,
      activeSection,
      setActiveSection,
      areFlagsLoaded,
    ],
  );

  if (isLoading) {
    return <FullPageLoader text="Authenticating..." />;
  }

  return <AppMetadataContext.Provider value={appMetadata}>{children}</AppMetadataContext.Provider>;
};
