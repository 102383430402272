import { IconCheck, IconCopy } from '@tabler/icons-react';
import { useEffect } from 'react';
import {
  ActionIcon,
  Alert,
  Box,
  Center,
  CopyButton,
  Horizontal,
  Loader,
  Text,
  Vertical,
} from '@/shared/design-system/v2';
import { useTriggerEmailWorkflowRunQuery } from '../../../queries/workflows/list/list';

interface FetchTextFromApiContainerProps {
  workflowId: string;
  onChange: (value: string) => void;
}

export const FetchTextFromApiContainer = ({
  workflowId,
  onChange,
}: FetchTextFromApiContainerProps) => {
  const { isLoading, isError, data } = useTriggerEmailWorkflowRunQuery(workflowId);

  useEffect(() => {
    if (data) {
      onChange(data.email);
    }
  }, [data]);

  if (isLoading) {
    return (
      <Center w="100%" h="100%">
        <Loader />
      </Center>
    );
  }

  if (isError || !data) {
    return <Alert color="red">There was an error fetching the email. Please try again</Alert>;
  }

  return (
    <Vertical spacing="xs">
      <Box bg="blue.1" sx={theme => ({ border: `1px solid ${theme.colors.blue[4]}` })}>
        <Text
          p="sm"
          variant="subTitle05"
          color="blue.8"
          lineClamp={1}
          sx={{ wordBreak: 'break-all' }}
        >
          {data.email}
        </Text>
      </Box>
      <Horizontal position="right">
        <CopyButton value={data.email} timeout={2000}>
          {({ copied, copy }) => (
            <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
              {copied ? <IconCheck style={{ width: 16 }} /> : <IconCopy style={{ width: 16 }} />}
            </ActionIcon>
          )}
        </CopyButton>
      </Horizontal>
    </Vertical>
  );
};
