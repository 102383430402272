import range from 'lodash/range';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Center,
  Loader,
  Skeleton,
  Text,
  closeAllModals,
  notifications,
} from '@/shared/design-system/v2';
import { TemplateModel } from '../../../../generated/api';
import { useCreateWorkflowMutation } from '../../../../queries/workflows/builder';
import {
  useGetOperatorListQuery,
  useGetTemplatesListQuery,
} from '../../../../queries/workflows/operators';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { useTemplateFlowModal } from '../../../workflows/create/templates/useTemplateFlowModal';
import { getTemplateIdSearchParam } from '../../../workflows/create/templates/util';
import { TemplateCard } from '../../studio-home/workflow/TemplateCard';
import { CardGrid } from '../CardGrid';

const CARD_HEIGHT = 206;
const NUM_DISPLAYED_TEMPLATES = 4;

export const WorkflowsSectionContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const { getWorkflowBuilderRoute } = useAbsoluteRoutes();
  const {
    mutateAsync: createWorkflow,
    isLoading: creatingWorkflow,
    isError: errorCreatingWorkflow,
  } = useCreateWorkflowMutation();
  const { isLoading, isError, data } = useGetTemplatesListQuery();
  const {
    isLoading: operatorListLoading,
    isError: operatorListError,
    data: operatorList,
  } = useGetOperatorListQuery();
  const { open: openTemplateFlowModal } = useTemplateFlowModal();

  if (isLoading || operatorListLoading) {
    return (
      <CardGrid>
        {range(NUM_DISPLAYED_TEMPLATES).map(value => (
          <Skeleton key={value} h={CARD_HEIGHT} />
        ))}
      </CardGrid>
    );
  }

  if (isError || !data) {
    return <Alert color="red">Error occurred while fetching templates</Alert>;
  }

  const handleAddWorkflow = async (templateId?: string, workflowName?: string) => {
    try {
      const data = await createWorkflow({ workflowName, templateId });
      if (data?.data?.workflowId) {
        navigate({
          pathname: getWorkflowBuilderRoute(data.data.workflowId),
          search: getTemplateIdSearchParam(templateId),
        });

        closeAllModals();
      }
    } catch (e) {
      notifications.error('Error while creating workflow. Please try again and contact support');
    }
  };

  const handleFlowButtonClick = (templateData: TemplateModel) => {
    openTemplateFlowModal(templateData, operatorList ?? [], handleAddWorkflow);
  };

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader text="Loading workflow templates..." />
      </Center>
    );
  }

  if (isError || !data) {
    return (
      <Box py="lg">
        <Alert color="red">
          <Text color="gray.7" variant="bodyLong01">
            We have encountered an issue trying to load list of available templates. Please refresh
            and if issue persist, please contact support
          </Text>
        </Alert>
      </Box>
    );
  }

  return (
    <CardGrid spacing={24}>
      {data.slice(0, NUM_DISPLAYED_TEMPLATES).map(item => (
        <TemplateCard
          key={item.templateId}
          templateData={item}
          handleFlowButtonClick={handleFlowButtonClick}
          operatorList={operatorList ?? []}
        />
      ))}
    </CardGrid>
  );
};
