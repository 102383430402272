import first from 'lodash/first';
import { useEffect } from 'react';
import { Chip, Horizontal, Loader } from '@/shared/design-system/v2';
import { capitalize } from '@/shared/lib/ui';
import { useAddChartsContext } from '../../../../../contexts/snippets/add-charts/AddChartsContext';
import { MLModelClass } from '../../../../../generated/api';
import { useRecordingsSummaryQuery } from '../../../../../queries/recordings';

export const EvaluationsHeader = () => {
  const { isLoading, data: recordingsSummary = [] } = useRecordingsSummaryQuery();

  const { selectedEvaluationClassType, setSelectedEvaluationClassType } = useAddChartsContext();

  useEffect(() => {
    if (recordingsSummary?.length > 0 && !selectedEvaluationClassType) {
      setSelectedEvaluationClassType(first(recordingsSummary)?.name as MLModelClass);
    }
  }, [recordingsSummary, selectedEvaluationClassType, setSelectedEvaluationClassType]);

  if (isLoading) {
    return <Loader size="sm" mt="md" />;
  }

  return (
    <Horizontal mt="md">
      <Chip.Group
        value={selectedEvaluationClassType ?? undefined}
        onChange={setSelectedEvaluationClassType}
      >
        {recordingsSummary.map(({ name: classType }) => (
          <Chip value={classType} key={classType} variant="filled" radius="xs" size="md">
            {capitalize(classType)}
          </Chip>
        ))}
      </Chip.Group>
    </Horizontal>
  );
};
