import {
  Box,
  MultiSelect,
  MultiSelectValueProps,
  SelectItem,
  Tag,
} from '@/shared/design-system/v2';
import { useStyles } from './TagSelect.style';

const getCreateLabel = (query: string) => `+ Create ${query}`;

const TagValue = ({
  value,
  label,
  onRemove,
  classNames,
  ...others
}: MultiSelectValueProps & { value: string }) => {
  const { classes } = useStyles();

  return (
    <Box {...others} className={classes.root}>
      <Tag size="lg" name={label} onRemove={onRemove} />
    </Box>
  );
};

interface TagSelectProps {
  options: SelectItem[];
  value: string[];
  onChange: (value: string[]) => void;
  onCreate: (query: string) => undefined;
  disabled?: boolean;
}

export const TagSelect = ({
  options,
  value,
  onChange,
  onCreate,
  disabled = false,
}: TagSelectProps) => (
  <MultiSelect
    label="Select tags"
    placeholder="Click to select or search for tags"
    data={options}
    value={value}
    onChange={onChange}
    disabled={disabled}
    valueComponent={TagValue}
    size="lg"
    searchable
    creatable
    multiple
    getCreateLabel={getCreateLabel}
    onCreate={onCreate}
    withinPortal
  />
);
