import { COORDINATE_SYSTEM, WebMercatorViewport } from '@deck.gl/core/typed';
import { PointCloudLayer, ScatterplotLayer } from '@deck.gl/layers/typed';
import { DrawPolygonByDraggingMode, DrawRectangleMode } from '@nebula.gl/edit-modes';
import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import { v4 as uuid } from 'uuid';
import { grays, pinks } from '@/shared/design-system/v2/theme/colors/colorList';
import { hexToRGB } from '@/shared/lib/ui';
import {
  PointState,
  getInactiveLabelName,
  getLabelColor,
} from '../../../components/dataset-details/clusters/util';
import { ColorByOptions } from '../../../contexts/datasets/DatasetClusterContext';
import {
  ChartExtremes,
  ClusterCounts,
  ClusterMetadata,
} from '../../../queries/datasets/clustering/clusterSelector';

export enum ScatterChartType {
  SCATTER_2D = 'SCATTER_2D',
  SCATTER_3D = 'SCATTER_3D',
}

export enum ChartMode {
  ZOOM = 'ZOOM',
  SELECT_RECTANGLE = 'SELECT_RECTANGLE',
  SELECT_LASSO = 'SELECT_LASSO',
  PAN = 'PAN',

  ROTATE_ORBIT = 'ROTATE_ORBIT',
  ROTATE_TURNTABLE = 'ROTATE_TURNTABLE',
}

export interface ScatterData {
  x: number;
  y: number;
  z: number;
  // FIXME:: Fix type
  metadata: any;
}

// Viewport settings
export const INITIAL_VIEW_STATE = {
  latitude: undefined,
  longitude: undefined,
  zoom: undefined,
  minZoom: 5,
  maxZoom: 20,
  target: undefined,
  rotationX: 0,
  rotationOrbit: 0,
};

export interface LabelBucket {
  label: string;
  points: ScatterData[];
  state: PointState;
}

export const getColorCounts = (colorBy: ColorByOptions, metadata: ClusterMetadata) => {
  if (colorBy === ColorByOptions.CLUSTERS) {
    return metadata.clustersCount;
  }

  if (colorBy === ColorByOptions.TOPICS) {
    return metadata.topicsCount;
  }

  if (colorBy === ColorByOptions.OUTLIERS) {
    return metadata.outliersCount;
  }

  return metadata.labelsCount;
};

export const getColorCountKey = (colorBy: ColorByOptions) => {
  let colorBucketKey = '';

  if (colorBy === ColorByOptions.CLASSES) {
    colorBucketKey = 'label';
  } else if (colorBy === ColorByOptions.CLUSTERS) {
    colorBucketKey = 'cluster';
  } else if (colorBy === ColorByOptions.TOPICS) {
    colorBucketKey = 'topic';
  } else {
    colorBucketKey = 'outlier';
  }

  return colorBucketKey;
};

const getLabelColorForBucket = (
  bucket: LabelBucket,
  colorMap: Record<string, string> = {},
  labels: ClusterCounts[],
) => {
  if (bucket.state === PointState.ACTIVE) {
    const labelColor = colorMap[bucket.label] ?? getLabelColor(bucket.label, labels, bucket.state);
    return hexToRGB(labelColor);
  }

  const inactiveLabel = getInactiveLabelName(bucket.label);
  const labelColor = colorMap[inactiveLabel] ?? getLabelColor(bucket.label, labels, bucket.state);
  return hexToRGB(labelColor);
};

export const createScatter2DLayer = (
  metadata: ClusterMetadata,
  colorBy: ColorByOptions,
  bucketedData: LabelBucket[],
  tableSelectedPointId?: string,
  pointSize = 2,
  colorMap: Record<string, string> = {},
  hideInactivePoints = false,
) =>
  bucketedData
    .filter(b => b.state === PointState.ACTIVE || !hideInactivePoints)
    .map(bucket => {
      const labelColor = getLabelColorForBucket(
        bucket,
        colorMap,
        getColorCounts(colorBy, metadata),
      );

      return new ScatterplotLayer<ScatterData>({
        id: `scatter-plot-2d-${bucket.label}_${bucket.state}-${uuid()}}`,
        data: bucket.points,
        radiusUnits: 'pixels',
        radiusScale: 1,
        radiusMinPixels: 4,
        radiusMaxPixels: 16,
        getRadius: (d: ScatterData) => {
          const meanPointSize = pointSize;
          if (bucket.state === PointState.ACTIVE) {
            return tableSelectedPointId === d.metadata.recordId ? meanPointSize * 5 : meanPointSize;
          }

          return meanPointSize * 0.8;
        },
        lineWidthMinPixels: 1,
        lineWidthMaxPixels: 2,
        getPosition: (d: ScatterData) => [d.x, d.y, 0],
        stroked: true,
        getLineColor: [255, 255, 255],
        getFillColor: (d: ScatterData) =>
          tableSelectedPointId === d.metadata.recordId ? hexToRGB(pinks.pink6) : labelColor,
        pickable: true,
      });
    });

export const createScatter3DLayer = (
  metadata: ClusterMetadata,
  colorBy: ColorByOptions,
  bucketedData: LabelBucket[],
  tableSelectedPointId?: string,
  pointSize?: number,
  colorMap: Record<string, string> = {},
  hideInactivePoints = false,
) =>
  bucketedData
    .filter(b => b.state === PointState.ACTIVE || !hideInactivePoints)
    .map(bucket => {
      const labelColor = getLabelColorForBucket(
        bucket,
        colorMap,
        getColorCounts(colorBy, metadata),
      );

      return new PointCloudLayer({
        id: `scatter-plot-3d-${bucket.label}_${bucket.state}-${uuid()}}` as string,
        data: bucket.points,
        getPosition: (d: ScatterData) => [d.x, d.y, d.z],
        getColor: (d: ScatterData) =>
          tableSelectedPointId === d.metadata.recordId ? hexToRGB(pinks.pink6) : labelColor,
        stroked: true,
        getLineColor: [255, 255, 255],
        lineWidthMinPixels: 1,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        pointSize: (pointSize ?? 0) * 0.25,
        pickable: true,
        extruded: true,
        material: {
          ambient: 1,
        },
      });
    });

export const SELECTION_LAYER_FEATURE_COLLECTION = {
  type: 'FeatureCollection',
  features: [],
};

export const createEditableGeoJsonLayer = (
  chartMode: ChartMode,
  onEditPolygon: (selection: any) => void,
) =>
  // EditableGeoJsonLayer has typing issue.
  new (EditableGeoJsonLayer as any)({
    data: SELECTION_LAYER_FEATURE_COLLECTION,
    id: `selection_layer_${uuid()}`,
    mode: chartMode === ChartMode.SELECT_LASSO ? DrawPolygonByDraggingMode : DrawRectangleMode,
    modeConfig: {
      dragToDraw: true,
    },
    getEditHandlePointColor: hexToRGB(grays.gray6),
    getEditHandlePointRadius: 1,
    selectedFeatureIndexes: [],
    onEdit: onEditPolygon,
  });

let viewport: WebMercatorViewport;
export const getViewport = (height: number, width: number, extremes: ChartExtremes) => {
  viewport = new WebMercatorViewport({
    width,
    height,
  });

  const { longitude, latitude, zoom } = viewport.fitBounds([
    [extremes.minX, extremes.minY],
    [extremes.maxX, extremes.maxY],
  ]);

  return {
    latitude: latitude,
    longitude: longitude,
    zoom: zoom,
  };
};
