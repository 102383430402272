import { SegmentType } from '../../../../../../../../apps/main/generated/api';

export interface AnalyserParameters {
  datasetId: string;
  analysisId: string;
  segmentType: SegmentType;
  title: string;
}

export interface ClassDistributionParameters {
  datasetId: string;
  analysisId: string;
  segmentType: SegmentType;
}

export interface ClassQualityScoreParameters {
  datasetId: string;
  dataQualityScore: number;
}

export interface TopKFeaturesParameters {
  datasetId: string;
}

export interface BaselineModelStatsParameters {
  datasetId: string;
}

export interface OverlappingClassMetricsParameters {
  datasetId: string;
}

export interface EmbeddingsParameters {
  datasetId: string;
  subsetId?: string;
}

export interface DataProfileParameters {
  datasetId: string;
  segmentType: SegmentType;
}

export interface DataProfileVariableParameters {
  title: string;
  datasetId: string;
  segmentType: SegmentType;
  visualizationId: string;
  chartType: string;
  variableName: string;
  groupByTarget: boolean;
}

export interface ExperimentParameters {
  experimentId: string;
  metricKey: string;
}

export interface EvaluationParameters {
  evaluationId: string;
}

export interface ModelAppParameters {
  modelId: string;
}

export enum VisualizationType {
  DATASET_ANALYSER = 'DATASET_ANALYSER',

  DATASET_DATA_PROFILE_VARIABLE = 'DATASET_DATA_PROFILE_VARIABLE',
  DATASET_DATA_PROFILE_CORRELATION = 'DATASET_DATA_PROFILE_CORRELATION',
  DATASET_DATA_PROFILE_MISSING_VALUE = 'DATASET_DATA_PROFILE_MISSING_VALUE',
  DATASET_DATA_PROFILE_SUMMARY = 'DATASET_DATA_PROFILE_SUMMARY',
  DATASET_DATA_PROFILE_INSIGHTS = 'DATASET_DATA_PROFILE_INSIGHTS',

  DATASET_CLASS_DISTRIBUTION = 'DATASET_CLASS_DISTRIBUTION',
  DATASET_CLASS_QUALITY_SCORE = 'DATASET_CLASS_QUALITY_SCORE',
  DATASET_TOP_K_FEATURES = 'DATASET_TOP_K_FEATURES',
  DATASET_BASELINE_MODEL_STATS = 'DATASET_BASELINE_MODEL_STATS',
  DATASET_OVERLAPPING_CLASS_METRICS = 'DATASET_OVERLAPPING_CLASS_METRICS',

  DATASET_EMBEDDINGS = 'DATASET_EMBEDDINGS',

  EXPERIMENT_CHART = 'EXPERIMENT_CHART',

  EVALUATION_ROC_PR_CURVE = 'EVALUATION_ROC_PR_CURVE',
  EVALUATION_CLASS_LEVEL_METRICS = 'EVALUATION_CLASS_LEVEL_METRICS',
  EVALUATION_CLASS_DISTRIBUTION = 'EVALUATION_CLASS_DISTRIBUTION',
  EVALUATION_CUSTOM_METRICS = 'EVALUATION_CUSTOM_METRICS',
  EVALUATION_CONFUSION_MATRIX = 'EVALUATION_CONFUSION_MATRIX',
  EVALUATION_MODEL_DISAGREEMENTS = 'EVALUATION_MODEL_DISAGREEMENTS',

  MODEL_APPS = 'MODEL_APPS',
}

interface AnalysisModel {
  visualizationType: VisualizationType.DATASET_ANALYSER;
  visualizationParameters: AnalyserParameters;
}

interface ClassDistributionModel {
  visualizationType: VisualizationType.DATASET_CLASS_DISTRIBUTION;
  visualizationParameters: ClassDistributionParameters;
}

interface ClassQualityScoreModel {
  visualizationType: VisualizationType.DATASET_CLASS_QUALITY_SCORE;
  visualizationParameters: ClassQualityScoreParameters;
}

interface TopKFeaturesModel {
  visualizationType: VisualizationType.DATASET_TOP_K_FEATURES;
  visualizationParameters: TopKFeaturesParameters;
}

interface BaselineModelStatsModel {
  visualizationType: VisualizationType.DATASET_BASELINE_MODEL_STATS;
  visualizationParameters: BaselineModelStatsParameters;
}

interface OverlappingClassMetricsModel {
  visualizationType: VisualizationType.DATASET_OVERLAPPING_CLASS_METRICS;
  visualizationParameters: OverlappingClassMetricsParameters;
}

interface EmbeddingsModel {
  visualizationType: VisualizationType.DATASET_EMBEDDINGS;
  visualizationParameters: EmbeddingsParameters;
}

interface DataProfileModel {
  visualizationType:
    | VisualizationType.DATASET_DATA_PROFILE_VARIABLE
    | VisualizationType.DATASET_DATA_PROFILE_SUMMARY
    | VisualizationType.DATASET_DATA_PROFILE_INSIGHTS
    | VisualizationType.DATASET_DATA_PROFILE_MISSING_VALUE
    | VisualizationType.DATASET_DATA_PROFILE_CORRELATION;
  visualizationParameters: DataProfileParameters;
}

interface DataProfileVariableModel {
  visualizationType: VisualizationType.DATASET_DATA_PROFILE_VARIABLE;
  visualizationParameters: DataProfileVariableParameters;
}

interface ExperimentsModel {
  visualizationType: VisualizationType.EXPERIMENT_CHART;
  visualizationParameters: ExperimentParameters;
}

interface EvaluationsModel {
  visualizationType:
    | VisualizationType.EVALUATION_ROC_PR_CURVE
    | VisualizationType.EVALUATION_CLASS_LEVEL_METRICS
    | VisualizationType.EVALUATION_CLASS_DISTRIBUTION
    | VisualizationType.EVALUATION_CUSTOM_METRICS
    | VisualizationType.EVALUATION_CONFUSION_MATRIX
    | VisualizationType.EVALUATION_MODEL_DISAGREEMENTS;
  visualizationParameters: EvaluationParameters;
}

interface ModelApps {
  visualizationType: VisualizationType.MODEL_APPS;
  visualizationParameters: ModelAppParameters;
}

export type VisualizationModel =
  | AnalysisModel
  | ClassDistributionModel
  | DataProfileModel
  | DataProfileVariableModel
  | ExperimentsModel
  | EvaluationsModel
  | EmbeddingsModel
  | ModelApps
  | ClassQualityScoreModel
  | TopKFeaturesModel
  | BaselineModelStatsModel
  | OverlappingClassMetricsModel;
