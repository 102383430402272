import { Card, Image, Text, Vertical } from '@/shared/design-system/v2';

export interface FeatureCardProps {
  title: string;
  description: string;
  illustrationSrc: string;
}

export const FeatureCard = ({ title, description, illustrationSrc }: FeatureCardProps) => (
  <Card bg="transparent" hoverEffect={false}>
    <Card.Section>
      <Image src={illustrationSrc} />
    </Card.Section>
    <Vertical py="lg" spacing="sm">
      <Text variant="heading04">{title}</Text>
      <Text variant="bodyLong02" color="dark.3">
        {description}
      </Text>
    </Vertical>
  </Card>
);
