import { IconArrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { workflowEvents } from '@/main/analytics';
import { useAppMetadata } from '@/main/contexts/app-metadata/AppMetadata';
import { useUserInfo } from '@/main/queries/user';
import { useAbsoluteRoutes } from '@/main/router/hooks';
import {
  Box,
  Button,
  Image,
  Space,
  Text,
  Vertical,
  closeAllModals,
  openModal,
} from '@/shared/design-system/v2';
import { sendAnalytics } from '@/shared/initializers/analytics';
import { WORKFLOW_BUILDER_FLOW, startFlow } from '@/shared/initializers/userflow';
import { OnboardingInfo } from '../user-onboarding/user-details/util';
import { useCreateBlankWorkflow } from '../workspace/studio-home/workflow/use-add-workflow';
import { useGetColorsTextFromIntent } from './util';

const MKV_COMMUNITY_SLACK_WORKSPACE_URL =
  'https://join.slack.com/t/markovmlcommunity/shared_invite/zt-2tras9ume-EjL8r2hEVFLJqC~yOHuGLA';

const BUTTON_WIDTH = 260;

const FreeTrialModal = (): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const navigate = useNavigate();
  const { getWorkflowsAppsRoute } = useAbsoluteRoutes();

  const { createBlankWorkflow } = useCreateBlankWorkflow();

  const { data } = useUserInfo();

  const onboardingInfo = data?.user.onboardingInfo as OnboardingInfo;
  const userIntent = onboardingInfo.userIntent;

  const { bgColor, imageUrl, buttonText } = useGetColorsTextFromIntent(userIntent ?? 'Others');

  const handleNavigateToSlackCommunity = () => {
    window.open(MKV_COMMUNITY_SLACK_WORKSPACE_URL, '_blank');
  };

  const handleStartTour = () => {
    sendAnalytics(workflowEvents.onboarding.freeTrailModalButtonClicked({ workspaceId }));

    if (userIntent !== 'RevOps') {
      navigate(getWorkflowsAppsRoute());
      closeAllModals();
      return;
    }

    createBlankWorkflow();
    startFlow(WORKFLOW_BUILDER_FLOW);
    closeAllModals();
  };

  return (
    <Vertical pb={16}>
      <Box bg={bgColor} px={60} py={32} mb="md">
        <Image src={imageUrl} />
      </Box>
      <Vertical spacing="sm" px={36} ta="center">
        <Text variant="heading02">Welcome to MarkovML</Text>
        <Text variant="bodyLong01" mx="xxl">
          As an early MarkovML adopter, enjoy a complimentary{' '}
          <Text span variant="bodyLong01" fw={600} color="blue.6">
            30-day Team plan trial
          </Text>
        </Text>
      </Vertical>
      <Space h={24} />
      <Vertical align="center">
        <Button
          variant="primary"
          size="lg"
          w={BUTTON_WIDTH}
          onClick={handleStartTour}
          rightIcon={
            // Padding to improve alignment
            <Box pl="xs" pt="xs">
              <IconArrowRight strokeWidth="1.5px" />
            </Box>
          }
        >
          <Text variant="bodyShort02">{buttonText}</Text>
        </Button>
        {/* Removing this for now as per feedback, might get added later on
        <Button
          variant="subtle"
          size="lg"
          w={BUTTON_WIDTH}
          onClick={handleNavigateToSlackCommunity}
          leftIcon={
            <Box mr="xs">
              <SlackLogo width={20} />
            </Box>
          }
        >
          Join Slack Community
        </Button> */}
      </Vertical>
    </Vertical>
  );
};

export const useFreeTrialModal = () => {
  const open = () =>
    openModal({
      size: 540,
      radius: 'sm',
      padding: 0,
      withCloseButton: false,
      children: <FreeTrialModal />,
    });

  return { open };
};
