import { createContext, useContext } from 'react';
import { RedirectToAppType } from '@/main/generated/api';

interface AppInfo {
  redirectToAppType: RedirectToAppType;
}

const initialValue = {
  redirectToAppType: RedirectToAppType.Markov,
};

export const AppInfoContext = createContext<AppInfo>(initialValue);

export const useAppInfo = () => useContext(AppInfoContext);
