import {
  IconAlarm,
  IconCircleCheckFilled,
  IconCircleMinus,
  IconClockStop,
  IconExclamationCircle,
  IconExclamationCircleFilled,
} from '@tabler/icons-react';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { ReactNode } from 'react';
import { AnalyzingSpinner, IconCreditCoin } from '@/shared/design-system';
import { Horizontal, Text, useMarkovTheme } from '@/shared/design-system/v2';
import { MantineColor, MantineTheme } from '@/shared/design-system/v2/styles';
import { convertDurationToShortFormat } from '@/shared/lib/time-format';
import { formatDateTime } from '@/shared/lib/ui';
import {
  BaseAPIFilter,
  ExecutedFromSourceType,
  ExecutionType,
  Operator,
  WorkflowRun,
  WorkflowRunStatus,
} from '../../../../generated/api';
import { ViewLogsContainer } from '../../actions/ViewLogs.container';
import { StopRunButton } from '../../detail-v2/header/actions/StopRunButton.container';

export const WorkflowRunDuration = ({ duration }: Record<'duration', string>) => {
  const theme = useMarkovTheme();

  return (
    <Horizontal spacing="xs" align="center">
      <IconAlarm color={theme.colors.gray[7]} size={16} />
      <Text variant="small03" color="gray.7" sx={{ lineHeight: '16px' }}>
        {convertDurationToShortFormat(duration)}
      </Text>
    </Horizontal>
  );
};

export const WorkflowCreditInformation = ({ credits }: Record<'credits', number | undefined>) =>
  credits !== undefined && credits !== 0 ? (
    <Horizontal spacing={0} noWrap>
      <IconCreditCoin />
      <Text variant="subTitle04" color="gray.7">
        {credits}
      </Text>
    </Horizontal>
  ) : null;

const getExecutionTypeLabel = (executionType: ExecutionType) => {
  if (executionType === ExecutionType.OneTime) {
    return 'Manual';
  }

  return startCase(capitalize(executionType));
};

export const getWorkflowRunIcon = (status: WorkflowRunStatus, theme: MantineTheme) => {
  switch (status) {
    case WorkflowRunStatus.Success:
      return <IconCircleCheckFilled size={16} color={theme.colors.green[6]} />;
    case WorkflowRunStatus.Running:
      return <AnalyzingSpinner width={16} height={16} />;
    case WorkflowRunStatus.ExecutionFailed:
    case WorkflowRunStatus.CreationFailed:
      return <IconExclamationCircleFilled size={16} color={theme.colors.red[5]} />;
    case WorkflowRunStatus.Created:
      return <AnalyzingSpinner width={16} height={16} />;
    case WorkflowRunStatus.NotFound:
      return <IconExclamationCircle size={16} color="gray" />;
    case WorkflowRunStatus.Terminated:
      return <IconClockStop size={16} color="gray" />;
    case WorkflowRunStatus.Scheduled:
      return <IconAlarm size={16} color="gray" />;
    case WorkflowRunStatus.Skipped:
      return <IconCircleMinus size={16} color="gray" />;
    default:
      return <IconExclamationCircle size={16} color="gray" />;
  }
};

type WorkflowRunItemDetails = {
  title: string;
  executionType: string;
  info?: ReactNode;
  subtitle?: ReactNode;
  subtitleColor?: MantineColor;
};

export const getWorkflowRunItemDetails = (run: WorkflowRun): WorkflowRunItemDetails => {
  const { status, startDate, scheduledTime, executionType, totalDuration } = run;

  let info;
  switch (status) {
    case WorkflowRunStatus.Success:
    case WorkflowRunStatus.Running:
    case WorkflowRunStatus.Terminated:
      info = totalDuration
        ? {
            subtitle: formatDateTime(startDate),
          }
        : {};
      break;
    case WorkflowRunStatus.CreationFailed:
    case WorkflowRunStatus.ExecutionFailed:
      info = {
        subtitle: totalDuration ? formatDateTime(startDate) : '',
        subtitleColor: 'gray.8',
      };
      break;
    case WorkflowRunStatus.Scheduled:
      info = {
        subtitle: formatDateTime(scheduledTime || '--'),
      };
      break;

    default:
      info = {};
  }

  const executionTypeLabel = getExecutionTypeLabel(executionType);
  const title = status == WorkflowRunStatus.Scheduled ? 'Scheduled Run' : `Run #${run.runNumber}`;

  return { ...info, title, executionType: executionTypeLabel };
};

export const getRunArtifact = (run: WorkflowRun, runStatus?: WorkflowRunStatus) => {
  const status = runStatus ?? run.status;
  if (status === WorkflowRunStatus.Success) {
    // Temporarily disabling this to fix multiple file download issue
    return null;
  }

  if (
    [WorkflowRunStatus.NotFound, WorkflowRunStatus.Created, WorkflowRunStatus.Running].includes(
      status,
    )
  ) {
    return (
      <StopRunButton
        workflowRunStatus={status}
        workflowId={run.workflowId}
        workflowRunId={run.workflowRunId}
      />
    );
  }

  if ([WorkflowRunStatus.ExecutionFailed].includes(status)) {
    return <ViewLogsContainer run={run} />;
  }

  return null;
};

export const PANEL_WIDTH = 376;

export const SMALL_PANEL_WIDTH = 220;

export const PANEL_TOP_MARGIN = 8;

export const PANEL_LEFT_MARGIN = 12;

export const upcomingRunsFilter: BaseAPIFilter = {
  field: 'status',
  operator: Operator.In,
  value: WorkflowRunStatus.Scheduled,
};

export const runTabFilter: BaseAPIFilter = {
  field: 'status',
  operator: Operator.NotIn,
  value: WorkflowRunStatus.Scheduled,
};

export const executedFromBuilderFilter: BaseAPIFilter = {
  field: 'executedFrom',
  operator: Operator.Eq,
  value: ExecutedFromSourceType.Builder,
};

export const executedFromAppFilter: BaseAPIFilter = {
  field: 'executedFrom',
  operator: Operator.Eq,
  value: ExecutedFromSourceType.App,
};

export const RUNS_WAIT_MODAL_KEY = 'RUNS_WAIT_MODAL_KEY';

export const RUNS_WAIT_MODAL_ILLUSTRATION_URL =
  'https://ik.imagekit.io/markovml/workflows/miscellaneous/illustration-runs-wait.svg?updatedAt=1733655931034';
