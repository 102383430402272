import { Button, Chip, Flex, Horizontal, ScrollArea, Text } from '@/shared/design-system/v2';
import { useDatasetClusterContext } from '../../../../../contexts/datasets/DatasetClusterContext';
import { useGetDatasetClusterFullQuery } from '../../../../../queries/datasets/clustering/cluster';
import { ChipGroupLoading } from '../ChipGroupLoading';
import { ClassFilterItem } from './ClassFilterItem';

export const ClassesFilter = () => {
  const { selectedClasses, setSelectedClasses, setSelectedClusters, setSelectedTopics, datasetId } =
    useDatasetClusterContext();
  const { data, isLoading: dataLoading } = useGetDatasetClusterFullQuery(datasetId);

  const handleChipSelection = (value: string[]) => {
    setSelectedClasses(value);
  };

  const handleResetSelection = () => {
    setSelectedClasses([]);
    setSelectedClusters([]);
    setSelectedTopics([]);
  };

  const showReset = !dataLoading && selectedClasses.length > 0;
  return (
    <>
      <Horizontal noWrap h={30}>
        <Text variant="subTitle04" color="dark.4">
          Filter by Class
        </Text>
        {showReset && (
          <Button size="xs" onClick={handleResetSelection}>
            Reset
          </Button>
        )}
      </Horizontal>
      {dataLoading ? (
        <ChipGroupLoading />
      ) : (
        <ScrollArea.Autosize mah={340}>
          <Chip.Group multiple value={selectedClasses} onChange={handleChipSelection}>
            <Flex wrap="wrap" gap="xs" columnGap="sm" rowGap="sm">
              {data?.metadata?.labelsCount.map((info, idx) => {
                const count = info.count ?? 0;

                return (
                  <ClassFilterItem
                    key={`${info.name}${idx}`}
                    index={idx}
                    labelsCount={data?.metadata?.labelsCount}
                    name={String(info.name)}
                    count={count}
                  />
                );
              })}
            </Flex>
          </Chip.Group>
        </ScrollArea.Autosize>
      )}
    </>
  );
};
