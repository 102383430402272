import { ChangeEvent, useState } from 'react';
import {
  ColorPicker,
  ColorSwatch,
  Horizontal,
  Popover,
  Tabs,
  TextInput,
  Vertical,
  useInputState,
} from '@/shared/design-system/v2';
import { isColorValid } from '@/shared/lib/ui';
import { useDatasetClusterContext } from '../../../../../contexts/datasets/DatasetClusterContext';
import { ClusterCounts } from '../../../../../queries/datasets/clustering/clusterSelector';
import { LabelChip } from '../../label-chip/LabelChip';
import { PointState, getFilterItemLabel, getInactiveLabelName, getLabelColor } from '../../util';

interface LabelColorSelectionProps {
  labelColor: string;
  inactiveLabelColor: string;
  onColorChange: (type: string, color: string) => void;
}

export const LabelColorSelection = ({
  labelColor,
  inactiveLabelColor,
  onColorChange,
}: LabelColorSelectionProps) => {
  const [tabValue, setTabValue] = useState<'active' | 'inactive'>('active');
  const [colorInput, setColorInput] = useInputState(labelColor);

  const handleTabChange = (val: 'active' | 'inactive') => {
    setColorInput(val === 'active' ? labelColor : inactiveLabelColor);
    setTabValue(val);
  };

  const handleColorChange = (color: string) => {
    setColorInput(color);
    onColorChange(tabValue, color);
  };

  const handleColorInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.currentTarget.value;
    setColorInput(inputVal);
    if (isColorValid(inputVal)) {
      onColorChange(tabValue, inputVal);
    }
  };

  return (
    <Popover>
      <Popover.Target>
        <ColorSwatch color={labelColor} />
      </Popover.Target>
      <Popover.Dropdown>
        <Tabs onTabChange={handleTabChange} value={tabValue}>
          <Tabs.List>
            <Tabs.Tab size="sm" value="active">
              Active
            </Tabs.Tab>
            <Tabs.Tab size="sm" value="inactive">
              Inactive
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="active">
            <Vertical pt="sm">
              <TextInput ariaLabel="active color" value={colorInput} onChange={handleColorInput} />
              <ColorPicker value={labelColor} format="hexa" onChange={handleColorChange} />
            </Vertical>
          </Tabs.Panel>
          <Tabs.Panel value="inactive">
            <Vertical pt="sm">
              <TextInput
                ariaLabel="inactive color"
                value={colorInput}
                onChange={handleColorInput}
              />
              <ColorPicker value={inactiveLabelColor} format="hexa" onChange={handleColorChange} />
            </Vertical>
          </Tabs.Panel>
        </Tabs>
      </Popover.Dropdown>
    </Popover>
  );
};

interface ClassFilterItemProps {
  index: number;
  name: string;
  count: number;
  labelsCount: ClusterCounts[];
}

export const ClassFilterItem = ({ index, name, count = 0, labelsCount }: ClassFilterItemProps) => {
  const { colorMap, setColorMap } = useDatasetClusterContext();
  const label = getFilterItemLabel(name, count);
  const labelColor = colorMap[name] ?? getLabelColor(name, labelsCount, PointState.ACTIVE);
  const inactiveLabel = getInactiveLabelName(name);
  const inactiveLabelColor =
    colorMap[inactiveLabel] ?? getLabelColor(name, labelsCount, PointState.INACTIVE);

  const handleColorChange = (type: string, color: string) => {
    const isInactive = type === 'inactive';
    setColorMap({
      ...colorMap,
      [isInactive ? inactiveLabel : name]: color,
    });
  };

  return (
    <Horizontal key={`${name}_${index}`}>
      <LabelColorSelection
        labelColor={labelColor}
        inactiveLabelColor={inactiveLabelColor}
        onColorChange={handleColorChange}
      />

      <LabelChip value={name} variant="filled" labelsCount={labelsCount} labelColor={labelColor}>
        {label}
      </LabelChip>
    </Horizontal>
  );
};
