import { createStyles } from '@mantine/styles';
import { LineHeight } from '../constants';

export const useStyles = createStyles({
  provider: {
    fontSize: 'sm',
    fontWeight: 300,
    lineHeight: LineHeight.MD,
    wordBreak: 'break-word',

    '& pre': {
      whiteSpace: 'break-spaces',
    },
  },
});
