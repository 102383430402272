import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { DataTable } from '@/shared/design-system';
import { Button, Horizontal, Text, Vertical } from '@/shared/design-system/v2';
import { capitalize, formatDateTime } from '@/shared/lib/ui';
import {
  RevokeUserInviteFromWorkspaceRequest,
  WorkspaceInvitationDetails,
  WorkspaceMembershipRoles,
} from '../../../generated/api';
import { WorkspaceMemberDetail } from './MembersTable';

interface InvitationTableDataProps {
  email: string;
  updateDate: string;
  inviteId: string;
  role: WorkspaceMembershipRoles;
}
const getInviteTableData = (
  onResend: (inviteId: string) => void,
  onRevoke: (inviteId: string) => void,
) => [
  {
    Header: 'Member invited',
    accessor: 'email',
    width: 200,
    Cell: ({ row, value }: CellProps<InvitationTableDataProps>) => (
      <Vertical spacing="xs">
        <Text variant="bodyShort02">{value}</Text>
        <Text variant="bodyShort03" color="dark.2">
          Sent on: {formatDateTime(row.original.updateDate)}
        </Text>
      </Vertical>
    ),
  },
  {
    Header: 'Role',
    accessor: 'role',
    width: 100,
    Cell: ({ value }: { value: string }) => <Text variant="bodyShort03">{capitalize(value)}</Text>,
  },
  {
    Header: 'Actions',
    accessor: 'inviteId',
    width: 150,
    disableFilters: true,
    Cell: ({ value }: CellProps<WorkspaceMemberDetail>) => (
      <Horizontal>
        <Button variant="outline" shape="rounded" onClick={() => onResend(value)}>
          Resend invite
        </Button>
        <Button onClick={() => onRevoke(value)}>Revoke</Button>
      </Horizontal>
    ),
  },
];

interface InvitationTableProps {
  invitations: WorkspaceInvitationDetails[];
  onResend: (inviteId: string) => void;
  onRevoke: (invites: RevokeUserInviteFromWorkspaceRequest) => void;
}

export const InvitationTable = ({ invitations, onResend, onRevoke }: InvitationTableProps) => {
  const tableInvitationData = invitations.map(invitation => ({
    email: invitation.invitation.email,
    updateDate: invitation.invitation.updateDate,
    role: invitation.invitation.role,
    inviteId: invitation.invitation.inviteId,
  }));

  const handleRevokeInvite = (inviteId: string) => {
    const invites: RevokeUserInviteFromWorkspaceRequest = {
      inviteIds: [inviteId],
    };
    onRevoke(invites);
  };

  const columns = useMemo(() => getInviteTableData(onResend, handleRevokeInvite), []);

  return (
    <DataTable
      dataTestId="table-workspace_invite_list"
      data={tableInvitationData}
      columns={columns}
    />
  );
};
