import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import merge from 'lodash/merge';
import { logger } from '@/shared/initializers/logging';
import { credentialsApi, datasetApi } from '@/shared/lib/api/vienna';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { useDataUpload } from '../../contexts/data-upload/DataUpload';
import {
  AddCredentialRequest,
  AddCredentialResponse,
  CreateDataFamilyRequest,
  CreateDataFamilyResponse,
  CreateUploadUrlRequest,
  DatasetDelimiterData,
  DatasetPreviewData,
  DatasetRegistrationWorkflowModel,
  EditCredentialRequest,
  EditCredentialResponse,
  GetDatasetDelimiterDataResponse,
  GetDatasetPreviewDataResponse,
  RegistrationFlowStatus,
  UpdateDatasetRegistrationWorkflowResponse,
  VerifyDatasetAccessResponse,
  WorkflowMode,
} from '../../generated/api';
import { dataViewsKeys } from '../data-views';
import { datasetKeys } from '../queryConstants';
import { getWorkflowsListKey } from './list';

const getWorkflowUpdateSuccessHandler =
  (queryClient: QueryClient, workflowId: string, workspaceId: string) =>
  (data: AxiosResponse<UpdateDatasetRegistrationWorkflowResponse>) => {
    const workflowKey = datasetKeys.workflow(workflowId, workspaceId);
    const currentWorkflow =
      queryClient.getQueryData<AxiosResponse<UpdateDatasetRegistrationWorkflowResponse>>(
        workflowKey,
      );
    queryClient.setQueryData(workflowKey, merge(currentWorkflow, data));
  };

export const useRemovePreviewDataCache = (workflowId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return () => {
    const snapshotQueryKey = datasetKeys.snapshot(workspaceId, workflowId);
    queryClient.removeQueries(snapshotQueryKey);
  };
};

export const useInvalidatePreviewDataCache = (workflowId: string) => {
  const queryClient = useQueryClient();
  const { workspaceId } = useAppMetadata();

  return () => {
    const snapshotQueryKey = datasetKeys.snapshot(workspaceId, workflowId);
    queryClient.invalidateQueries(snapshotQueryKey);
  };
};

export const useDataSourceCredentialList = () => {
  const { workspaceId } = useAppMetadata();
  return useQuery(
    ['data-source-credential', workspaceId],
    () => credentialsApi.workspaceListCredentialsV1(workspaceId, 0, 1000),
    {
      select: data =>
        data.data.response?.sort((cred1, cred2) =>
          new Date(cred1.createDate).getTime() > new Date(cred2.createDate).getTime() ? -1 : 1,
        ),
    },
  );
};

export const useAddDataSourceCredential = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<AddCredentialResponse>,
    AxiosError<AddCredentialResponse>,
    AddCredentialRequest
  >((req: AddCredentialRequest) => credentialsApi.workspaceAddCredentialV1(workspaceId, req), {
    onSuccess: () => queryClient.invalidateQueries(['data-source-credential', workspaceId]),
  });
};

export const useEditDataSourceCredential = (credentialId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<EditCredentialResponse>,
    AxiosError<EditCredentialResponse>,
    EditCredentialRequest
  >(
    (req: EditCredentialRequest) =>
      credentialsApi.workspaceEditCredentialsV1(workspaceId, credentialId, req),
    {
      onSuccess: () => queryClient.invalidateQueries(['data-source-credential', workspaceId]),
    },
  );
};

export const useDataSourceList = () => {
  const { workspaceId } = useAppMetadata();
  return useQuery(
    ['data-source-list', workspaceId],
    () => datasetApi.workspaceListDataStoresV1(workspaceId),
    {
      select: data => data.data.response,
    },
  );
};

export const useGetDatasetDelimitersQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  return useQuery<
    AxiosResponse<GetDatasetDelimiterDataResponse>,
    AxiosError<string>,
    DatasetDelimiterData,
    string[]
  >(
    ['dataset-delimiters', workspaceId],
    () => datasetApi.workspaceGetDatasetDelimiterDataV1(workspaceId, workflowId),
    {
      select: data => data.data.response,
    },
  );
};

export const useGetDataSnapshotQuery = (workflowId: string, delimiter?: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<GetDatasetPreviewDataResponse>,
    AxiosError<string>,
    DatasetPreviewData
  >(
    datasetKeys.snapshot(workspaceId, workflowId, delimiter ?? ''),
    () => datasetApi.workspaceGetDatasetPreviewDataV1(workspaceId, workflowId, delimiter),
    {
      select: data => data.data.response,
    },
  );
};

export const useDatafamilyListQuery = () => {
  const { workspaceId } = useAppMetadata();
  return useQuery(
    ['datafamily-list', workspaceId],
    () => datasetApi.workspaceGetDataFamilyListV1(workspaceId),
    {
      select: data => data.data.response,
    },
  );
};

export const useAddDataFamily = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<CreateDataFamilyResponse>,
    AxiosError<CreateDataFamilyResponse>,
    CreateDataFamilyRequest
  >((req: CreateDataFamilyRequest) => datasetApi.workspaceCreateDataFamilyV1(workspaceId, req), {
    onSuccess: () => queryClient.invalidateQueries(['datafamily-list', workspaceId]),
  });
};

export const useCreateDatasetWorkflowQuery = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    datasetKeys.workflow(workflowId, workspaceId),
    () => datasetApi.workspaceCreateWorkflowV1(workspaceId, { id: workflowId }),
    {
      staleTime: Infinity,
    },
  );
};

export const useUpdateDatasetWorkflowMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const workflowQueryKey = datasetKeys.workflow(workflowId, workspaceId);

  return useMutation<
    AxiosResponse<UpdateDatasetRegistrationWorkflowResponse>,
    AxiosError,
    Partial<DatasetRegistrationWorkflowModel>
  >(
    (req: Partial<DatasetRegistrationWorkflowModel>) => {
      const workflowData =
        queryClient.getQueryData<AxiosResponse<UpdateDatasetRegistrationWorkflowResponse>>(
          workflowQueryKey,
        );
      const prevWorkflow = workflowData?.data.response;
      return datasetApi.workspacePatchDatasetWorkflowV1(workspaceId, workflowId, {
        ...(workflowData?.data.response || {}),
        ...req,
        workflowId,
        mode: req.mode ?? prevWorkflow?.mode ?? WorkflowMode.LocalFileUpload,
        status: req.status ?? prevWorkflow?.status ?? RegistrationFlowStatus.Created,
      });
    },
    {
      onMutate: async (req: Partial<DatasetRegistrationWorkflowModel>) => {
        await queryClient.cancelQueries(workflowQueryKey);

        const previousData =
          queryClient.getQueryData<AxiosResponse<UpdateDatasetRegistrationWorkflowResponse>>(
            workflowQueryKey,
          );

        if (previousData) {
          const updatedData = {
            ...(previousData ?? {}),
            data: {
              ...(previousData?.data ?? {}),
              response: {
                ...(previousData?.data?.response ?? {}),
                ...req,
                workflowId: req.workflowId ?? previousData?.data?.response?.workflowId ?? '',
                mode:
                  req.mode ??
                  previousData?.data?.response?.mode ??
                  WorkflowMode.ExternalCloudStorage,
                status:
                  req.status ??
                  previousData?.data?.response?.status ??
                  RegistrationFlowStatus.Created,
              },
            },
          };

          queryClient.setQueryData<AxiosResponse<UpdateDatasetRegistrationWorkflowResponse>>(
            workflowQueryKey,
            updatedData,
          );
        }
      },
      onSuccess: getWorkflowUpdateSuccessHandler(queryClient, workflowId, workspaceId),
      onError: (error: AxiosError) => {
        logger.error(error);
      },
    },
  );
};

export const useGetDatasetWorkflowQuery = (
  workflowId: string,
): DatasetRegistrationWorkflowModel => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const queryResult = useQuery(
    datasetKeys.workflow(workflowId, workspaceId),
    () => {
      const queryKey = datasetKeys.workflow(workflowId, workspaceId);
      const queryData =
        queryClient.getQueryData<AxiosResponse<UpdateDatasetRegistrationWorkflowResponse>>(
          queryKey,
        );

      return Promise.resolve(queryData);
    },
    {
      staleTime: Infinity,
    },
  );

  // Applying fallback to ensure compatibility with all places where it is used.
  return queryResult.data?.data.response as DatasetRegistrationWorkflowModel;
};

export const useAccessVerificationMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<VerifyDatasetAccessResponse>, AxiosError, string, void>(
    (workflowId: string) => datasetApi.workspaceVerifyDatasetAccessV1(workspaceId, workflowId),
    {
      onMutate: (workflowId: string) => {
        //Invalidate getPreviewData query in case the user has come back. modified some things
        // and re-verified
        const snapshotQueryKey = datasetKeys.snapshot(workspaceId, workflowId);
        queryClient.invalidateQueries(snapshotQueryKey);
      },
    },
  );
};

export const useRegisterDatasetMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const { removeUpload } = useDataUpload();

  return useMutation<any, AxiosError>(
    () => datasetApi.workspaceRegisterDatasetV1(workspaceId, workflowId),
    {
      onSuccess: () => {
        const dsListQueryKey = datasetKeys.list(workspaceId);
        queryClient.invalidateQueries(getWorkflowsListKey(workspaceId));
        queryClient.invalidateQueries(dsListQueryKey).then(() => {
          removeUpload(workflowId);
        });
        queryClient.invalidateQueries(dataViewsKeys.list(workspaceId));
      },
      onError: () => {
        queryClient.invalidateQueries(getWorkflowsListKey(workspaceId));
        removeUpload(workflowId);
      },
    },
  );
};

export const useCreateS3Url = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  return useMutation((req: CreateUploadUrlRequest) =>
    datasetApi.workspaceCreateDatasetUploadUrlV1(workspaceId, workflowId, req),
  );
};
