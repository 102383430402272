import { IconSparkles } from '@tabler/icons-react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import {
  Box,
  Button,
  Flex,
  Horizontal,
  Text,
  Vertical,
  useFocusWithin,
  useInputState,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { useGeneratePromptModal } from '../../app-builder/details/generate-prompt/useGeneratePromptModal';
import { useMentionInputStyles } from './FeatureColumnsMentionsInput.style';

const SUGGESTION_WIDTH = 250;

interface FeatureColumnsMentionsInputProps {
  value: string;
  onChange: (value: string) => void;
  suggestions: SuggestionDataItem[];
  disabled?: boolean;
  isLoading?: boolean;
  readOnly?: boolean;
  label?: string;
  description?: string;
  placeholder?: string;
  required?: boolean;
  shouldGeneratePrompt?: boolean;
}

export const FeatureColumnsMentionsInput = ({
  value,
  onChange,
  suggestions,
  disabled,
  isLoading,
  readOnly,
  label,
  description,
  placeholder,
  required,
  shouldGeneratePrompt,
}: FeatureColumnsMentionsInputProps): JSX.Element => {
  const theme = useMarkovTheme();
  const { ref, focused } = useFocusWithin();
  const styles = useMentionInputStyles(focused);
  const { open: openGeneratePromptModal } = useGeneratePromptModal();

  const [currentQueryMarkup, setCurrentQueryMarkup] = useInputState(value ?? '');

  const handleGeneratePromptSelect = (value: string) => {
    onChange(value);
    setCurrentQueryMarkup(value);
  };

  const handleGeneratedPromptClick = () => {
    //Temporary solution - Passing undefined as appId
    openGeneratePromptModal(undefined, handleGeneratePromptSelect, false);
  };

  return (
    <Vertical>
      <Box>
        <Horizontal position="apart">
          <Flex>
            <Text variant="subTitle02">{label}</Text>
            {required && <Text color="red">&nbsp;*</Text>}
          </Flex>
          {shouldGeneratePrompt && (
            <Button
              onClick={handleGeneratedPromptClick}
              leftIcon={<IconSparkles size={12} color={theme.colors.blue[7]} />}
              variant="subtle"
            >
              <Text variant="small03" color="blue.6">
                Generate a prompt
              </Text>
            </Button>
          )}
        </Horizontal>
        <Text span variant="small02" color="gray.7">
          {description}
        </Text>
      </Box>
      <MentionsInput
        inputRef={ref}
        aria-label="Input text"
        placeholder={placeholder}
        allowSpaceInQuery
        forceSuggestionsAboveCursor
        disabled={disabled}
        readOnly={readOnly}
        value={currentQueryMarkup}
        onChange={(_event, newValue, newPlainTextValue) => {
          setCurrentQueryMarkup(newValue);
          onChange(newValue);
        }}
        style={styles.input}
      >
        <Mention
          trigger="@"
          appendSpaceOnAdd
          data={suggestions}
          renderSuggestion={(entry, _search, _highlightedDisplay, _index, focused) => (
            <Box
              p="sm"
              w={SUGGESTION_WIDTH}
              sx={theme => ({
                border: `1px solid ${theme.colors.gray[1]}`,
                backgroundColor: focused ? theme.colors.blue[0] : theme.white,
              })}
            >
              <Text variant="small01" truncate title={entry.display}>
                {entry.display}
              </Text>
            </Box>
          )}
          style={styles.mention}
        />
      </MentionsInput>
    </Vertical>
  );
};
