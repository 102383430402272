import { PropsWithChildren } from 'react';
import { Box, Container, Divider, Horizontal, Text } from '@/shared/design-system/v2';

interface SettingsSectionProps {
  title: React.ReactNode;
}

export const SettingsSection = ({
  title,
  children,
}: PropsWithChildren<SettingsSectionProps>): JSX.Element => (
  <Box>
    <Horizontal py="md" px="xxl">
      {typeof title === 'string' ? (
        // Height needed to match non-string (JSX) title with Button
        <Horizontal h={36}>
          <Text variant="subTitle02">{title}</Text>
        </Horizontal>
      ) : (
        title
      )}
    </Horizontal>
    <Divider />
    <Container mb={32}>{children}</Container>
  </Box>
);
