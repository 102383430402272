import { MantineTheme } from '@mantine/styles';
import first from 'lodash/first';
import { IconFileUpload } from '@/shared/design-system';
import {
  Button,
  Center,
  Dropzone,
  FileButton,
  FileWithPath,
  MIME_TYPES,
  Text,
  ThemeIcon,
  Vertical,
  notifications,
  useMarkovTheme,
} from '@/shared/design-system/v2';
import { MEMORY_UNIT_LIMITS } from '@/shared/lib/resource-usage';

interface ModelFileInputProps {
  setInputFile: (file: File) => void;
}

const FILE_SIZE_ALLOWED = 200; // MB

export const ModelFileInput = ({ setInputFile }: ModelFileInputProps) => {
  const theme = useMarkovTheme();

  const handleDropFile = (file: FileWithPath[]) => {
    const firstFile = first(file);
    if (!firstFile) {
      return;
    }
    const fileSize = Number(
      ((firstFile.size ?? FILE_SIZE_ALLOWED) / MEMORY_UNIT_LIMITS.MB).toFixed(4),
    );
    if (firstFile.type !== 'text/csv') {
      notifications.error('The file format is not permitted');
      return;
    }
    if (fileSize > FILE_SIZE_ALLOWED) {
      notifications.error(`The file size exceeds ${FILE_SIZE_ALLOWED}mb.`);
      return;
    }

    setInputFile(firstFile);
  };

  const handleUploadFileSelect = (file: File | null) => {
    if (file) {
      setInputFile(file);
    }
  };
  return (
    <Vertical pos="relative" p="xl">
      <Dropzone
        accept={{
          [MIME_TYPES.csv]: ['.csv'],
          'text/plain': ['.txt'],
          'text/tab-separated-values': ['.tsv'],
        }}
        onDrop={handleDropFile}
        p={44}
        w="100%"
        bg={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.0'}
        sx={(theme: MantineTheme) => ({
          border: `2px dashed ${theme.colors.gray[4]}`,

          ...theme.fn.hover({
            backgroundColor: theme.fn.themeColor('white.1'),
            borderColor: theme.fn.themeColor('white.1'),
          }),
        })}
      >
        <Center>
          <Vertical align="center" spacing="xl">
            <Text variant="subTitle02" color="gray.7">
              Upload data from your local device
            </Text>
            <Vertical align="center" spacing={0}>
              <Text variant="bodyShort03" color="gray.5">
                Supported file formats: .csv
              </Text>
              <Text variant="bodyShort03" color="gray.5">
                Maximum file size: 2MB or 1,000 records
              </Text>
            </Vertical>
            <ThemeIcon variant="light" radius="xl" color="gray.4" size={64}>
              <IconFileUpload />
            </ThemeIcon>
            <FileButton onChange={handleUploadFileSelect} accept="text/csv">
              {props => (
                <Vertical align="center" spacing={0}>
                  <Text variant="subTitle03" color="gray.6">
                    Drag or drop to upload your file
                  </Text>
                  <Text variant="subTitle03" color="gray.6">
                    or
                  </Text>
                  <Button variant="subtle" {...props}>
                    Browse file
                  </Button>
                </Vertical>
              )}
            </FileButton>
          </Vertical>
        </Center>
      </Dropzone>
      <Text variant="bodyShort03" color="gray.7">
        Please ensure that the schema of the file you are uploading matches the schema of the
        dataset used to train this baseline model app.
      </Text>
    </Vertical>
  );
};
