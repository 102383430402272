import { MantineSize } from '@mantine/styles';
import { Info } from '@/shared/design-system';
import { ActionIcon, Tooltip } from '@/shared/design-system/v2';

interface InfoTooltipProps {
  text: string;
  maw?: number;
  size?: MantineSize;
}

export const InfoTooltip = ({ text, maw = 250, size = 'md' }: InfoTooltipProps) => (
  <Tooltip label={text} withArrow withinPortal multiline maw={maw}>
    {/* div needed since button can't be nested inside another button (e.g. Accordion.Control) */}
    <ActionIcon variant="transparent" component="div" size={size}>
      <Info />
    </ActionIcon>
  </Tooltip>
);
